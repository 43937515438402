import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { ConfigContext } from "./Hooks/UseConfigHook";
import { Auth0Provider, Auth0ProviderOptions, useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ATOLoading } from "./Components/ATOSpinner";
import { Config, envVarConfig } from "./Models/Config";
import {
  BrowserRouter,
  Link,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from "react-router-dom";
import { ATOButton, ButtonType } from "./Components/ATOButton";

import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const AuthenticatedApp = withAuthenticationRequired(App, {
  onRedirecting: () => <ATOLoading />,
  onBeforeAuthentication: async () => {},
});

const router = createBrowserRouter([
  {
    path: "*",
    Component: () => (
      <AuthErrorChecker>
        <AuthenticatedApp />
      </AuthErrorChecker>
    ),
  },
]);

const AuthErrorChecker = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const error = query.get("error");

  if (error) {
    return <LoginErrorScreen />;
  }

  return <>{children}</>;
};

const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const providerConfig: Auth0ProviderOptions = {
    domain: envVarConfig.auth0_domain,
    clientId: envVarConfig.auth0_clientId,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: envVarConfig.auth0_audience,
    },
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

const Router = () => {
  return <RouterProvider router={router} />;
};

const Index = () => {
  return (
    <RecoilRoot>
      <StrictMode>
        <ConfigContext.Provider value={envVarConfig}>
          <AuthWrapper>
            <Router />
          </AuthWrapper>
        </ConfigContext.Provider>
      </StrictMode>
    </RecoilRoot>
  );
};

root.render(<Index />);

const LoginErrorScreen = () => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const error = query.get("error");
  const errorDesc = query.get("error_description");

  const { logout } = useAuth0();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <p>{error}</p>
      <p>{errorDesc}</p>
      <ATOButton buttonType={ButtonType.Error} onClick={() => logout()}>
        Logout
      </ATOButton>
    </div>
  );
};
