import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../../App";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { useEffect, useState } from "react";
import { DMSDocOptionType, DMSName, SuitePracticeInfo } from "../../../../generated";
import { ItemPendingChanges, usePendingChangesWithState } from "../../../../Hooks/PendingChangesHook";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { SuiteDoc } from "./Models/SuiteDoc";
import { DMSDocSetupColumnInfo } from "../../../../Components/DMSDoc/DMSDocSetupColumnInfo";
import { ATOSpinner } from "../../../../Components/ATOSpinner";
import { DMSDocSetup } from "../../../../Components/DMSDoc/DMSDocSetup";
import { DMSDocCell } from "../../../../Components/DMSDoc/DMSDocCell";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import SuiteFilesLogo from "../SuiteFiles/SuiteFiles-Logo.png";

const suiteFilesAtom = atom<ItemPendingChanges<SuiteDoc>[]>({
  key: "suiteFilesMaster",
  default: [],
});

export const SuiteFiles = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const apiClient = useAMClient(config.suiteFilesUrl, user, custId);
  const setModal = useSetRecoilState(AppModalAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [practiceInfo, setPracticeInfo] = useState<SuitePracticeInfo>();
  const pendingChanges = usePendingChangesWithState<SuiteDoc>(useRecoilState(suiteFilesAtom));

  const actualSuiteFilesDetail = pendingChanges.applyChanges("-1", {
    fileName: practiceInfo?.fileName ?? "",
    unknownClientID: practiceInfo?.unknownClientID ?? "",
    FilingCabinet: "",
    DocID: "-1",
    Level1ID: "",
    Level2ID: "",
    Level3ID: "",
    Level4ID: "",
  });

  useEffect(() => {
    apiClient.suiteEnrolmentActions
      .getSuitePracticeInfo({})
      .then((resp) => {
        setPracticeInfo(resp.data);
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  }, []);

  const columnInfo: DMSDocSetupColumnInfo<SuiteDoc>[] = [
    {
      name: "Filing Cabinet",
      valueKey: "FilingCabinet",
      popupInitialHeaderText: "System Provided",
      isMandatory: () => true,
      allowCustom: () => true,
      getDatasource: () =>
        practiceInfo?.filingCabinets?.map((fc) => {
          return {
            name: fc.filingCabinetName,
            value: fc.filingCabinetName,
          };
        }) ?? [],
      columnOptionType: "all",
      width: "1fr",
    },
    {
      name: "Level 1",
      valueKey: "Level1ID",
      popupInitialHeaderText: "Level 1",
      isMandatory: () => true,
      allowCustom: () => true,
      columnOptionType: DMSDocOptionType.Client,
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 2",
      valueKey: "Level2ID",
      popupInitialHeaderText: "Level 2",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 3",
      valueKey: "Level3ID",
      popupInitialHeaderText: "Level 3",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 4",
      valueKey: "Level4ID",
      popupInitialHeaderText: "Level 4",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
  ];

  if (practiceInfo === undefined) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOSpinner />
      </div>
    );
  }

  return (
    <div className="box-border flex h-screen flex-col items-center justify-center">
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.5)]">
          <ATOSpinner />
        </div>
      )}
      <>
        <DMSDocSetup<SuiteDoc>
          dmsui={DMSName.SuiteFiles}
          columnInfo={columnInfo}
          isLoading={isLoading}
          isActive={true}
          setIsLoading={setIsLoading}
          setModal={setModal}
          pendingChanges={pendingChanges}
          preSave={(docSave) => {
            apiClient.suiteEnrolmentActions
              .saveSuitePracticeInfo({
                fileName: actualSuiteFilesDetail.fileName ?? "",
                unknownClientId: actualSuiteFilesDetail.unknownClientID ?? "",
              })
              .then(() => {
                docSave();
              })
              .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
          }}
          getDefaultItem={(opts, docs) => {
            return {
              FilingCabinet: practiceInfo?.filingCabinets?.[0]?.filingCabinetName ?? "test",
              DocID: "0",
              Level1ID: "",
              Level2ID: "",
              Level3ID: "",
              Level4ID: "",
            };
          }}
        >
          {(types, options) => {
            return (
              <>
                <div className="flex justify-center">
                  <img src={SuiteFilesLogo} className="w-200px mt-2 h-16 flex-shrink-0" alt="SuiteFiles Logo" />
                </div>
                <div className="my-6 grid w-full grid-cols-[1fr_1fr] gap-4">
                  <>
                    <div className="flex h-min w-full flex-col items-center justify-center p-2">
                      <p>Filename</p>
                      <div className="z-20 h-8 w-[min(480px,80%)]">
                        <DMSDocCell<SuiteDoc>
                          pendingChanges={pendingChanges}
                          columnInfo={{
                            name: "fileName",
                            valueKey: "fileName",
                            isMandatory: () => false,
                            allowCustom: () => true,
                            getDatasource: () => options?.[DMSDocOptionType.FileName],
                            columnOptionType: DMSDocOptionType.FileName,
                            width: "",
                          }}
                          isDefaultDocType={false}
                          rowIndex={-1}
                          setModal={setModal}
                          value={{
                            DocID: "",
                            fileName: actualSuiteFilesDetail?.fileName ?? "",
                            FilingCabinet: "",
                            Level1ID: "",
                            Level2ID: "",
                            Level3ID: "",
                            Level4ID: "",
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex h-min w-full flex-col items-center justify-center p-2">
                      <p>Unknown Client ID</p>
                      <ATOTextBox
                        className="w-[min(480px,80%)]"
                        value={actualSuiteFilesDetail?.unknownClientID ?? ""}
                        onChange={(str) => pendingChanges.setChange("-1", "unknownClientID", str)}
                      />
                    </div>
                  </>
                </div>
              </>
            );
          }}
        </DMSDocSetup>
      </>
    </div>
  );
};
