import { ATOModal } from "../../../Components/ATOModal";
import { AMClient } from "../../../generated";
import { DomainDetailsAccordion } from "./DomainDetailsAccordion";

export const ViewDomainDetailsModal = (props: {
  amClient: AMClient;
  setModal: (modal: React.ReactNode) => void;
  domainName?: string | null;
  onClose: () => void;
}) => {
  return (
    <ATOModal onClose={props.onClose} title={`Domain Details`}>
      <div className="h-[450px] w-[900px]">
        <DomainDetailsAccordion amClient={props.amClient} setModal={props.setModal} domainName={props.domainName} />
      </div>
    </ATOModal>
  );
};
