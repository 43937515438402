import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { ATOLoading } from "../../../../Components/ATOSpinner";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";

export const PracticeDetailBillingInvoiceHistory = () => {
  const columns = {
    InvoiceId: { name: "Invoice ID", width: "1fr" },
    Receivers: { name: "Receiver/s", width: "1fr" },
    IssueDate: { name: "Issue Date", width: "1fr" },
    DueDate: { name: "Due Date", width: "1fr" },
    Amount: { name: "Amount", width: "1fr" },
    Actions: { name: "Actions", width: "1fr" },
    Expand: { name: "", width: "52px" },
  };

  const spanString = `span ${Object.keys(columns).length * 2 - 1} / span ${Object.keys(columns).length * 2 - 1}`;

  const isLoading = false;

  return (
    <div className="flex h-full flex-col">
      <div className="flex w-full items-center border-b border-black bg-gray-200 py-2">
        <ATOButton buttonType={ButtonType.Confirm}>Regenerate Last Invoice</ATOButton>
        <h1 className="text-center text-xl">Invoice History</h1>
      </div>
      <div
        style={{
          gridTemplateColumns: Object.values(columns)
            .map((v, i) => (i === 0 ? v.width : "1px " + v.width))
            .join(" "),
        }}
        className="grid h-full grid-rows-[min-content_minmax(0,1fr)]"
      >
        <div
          style={{
            gridTemplateColumns: "subgrid",
            gridColumn: spanString,
          }}
          className="grid items-center border-b border-black bg-primary text-white"
        >
          {Object.values(columns).map((c, i) => (
            <>
              {i > 0 ? <div className="h-4/5 bg-white" /> : <></>}
              <p className="w-full text-center">{c.name}</p>
            </>
          ))}
        </div>
        {isLoading ? (
          <div style={{ gridColumn: spanString }}>
            <ATOLoading />
          </div>
        ) : (
          <div
            style={{
              gridTemplateColumns: "subgrid",
              gridColumn: spanString,
            }}
            className="grid h-full auto-cols-fr place-content-start gap-y-2 overflow-y-scroll pt-2"
          >
            {[0, 0, 0].map(() => (
              <div
                style={{
                  gridTemplateColumns: "subgrid",
                  gridColumn: spanString,
                  gridTemplateRows: "1fr min-content min-content",
                }}
                className="mx-2 grid overflow-clip rounded-lg border border-black"
              >
                <div
                  style={{
                    gridTemplateColumns: "subgrid",
                    gridColumn: spanString,
                  }}
                  className="grid h-8 place-items-center bg-gray-200"
                >
                  <p className="cursor-pointer italic text-primary underline">123456</p>
                  <div className="h-4/5 w-px bg-black" />
                  <p className="px-1">eejays@teambaw.com.au</p>
                  <div className="h-4/5 w-px bg-black" />
                  <p>01/01/2024</p>
                  <div className="h-4/5 w-px bg-black" />
                  <p>06/01/2024</p>
                  <div className="h-4/5 w-px bg-black" />
                  <p>$420.69</p>
                  <div className="h-4/5 w-px bg-black" />
                  <div className="flex w-full justify-evenly">
                    <FontAwesomeIcon icon={faSvg.faMailBulk} className="cursor-pointer text-xl text-yellow-500" />
                    <FontAwesomeIcon icon={faSvg.faFilePdf} className="cursor-pointer text-xl  text-red-500" />
                  </div>
                  <div className="h-4/5 w-px bg-black" />
                  <FontAwesomeIcon icon={faSvg.faChevronDown} />
                </div>
                <div style={{ gridTemplateColumns: "subgrid", gridColumn: spanString }} className="grid max-h-32">
                  <div style={{ gridColumn: spanString }} className="w-full bg-primary text-center text-lg text-white">
                    Invoice Lines
                  </div>
                  <div
                    style={{
                      gridTemplateColumns: "subgrid",
                      gridColumn: spanString,
                    }}
                    className="grid place-items-center border-t-0 border-black"
                  >
                    <p className="col-span-5">ATOmate Subscription</p>
                    <div className="h-4/5 w-px bg-black" />
                    <p>1</p>
                    <div className="h-4/5 w-px bg-black" />
                    <p>$420</p>
                    <div className="h-4/5 w-px bg-black" />
                    <p className="col-span-3">$420</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
