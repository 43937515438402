import { useEffect } from "react";
import { ATOLoading } from "../../../Components/ATOSpinner";
import { useSetRecoilState } from "recoil";
import { BlockerAtom } from "../../../App";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { ATOTextBox } from "../../../Components/ATOTextBox";

import { ATODropDown } from "../../../Components/ATODropDown";
import React from "react";
import { DMSName, IngestType, ModuleDetail, PMS, PortalType, SmsfType, ToolDetail } from "../../../generated";
import { ATOScopeChecker } from "../../../Components/ATOScopeChecker";
import { ATOSwitch } from "../../../Components/ATOSwitch";
import { SinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";
import { camelCaseSpace } from "../../../Helpers/StringHelpers";
import { useATOAuth } from "../../../Hooks/ATOAuthHook";
import { ATOValueHeader } from "../../../Components/ATOValueHeader";
import { PracticeDetailDisplay } from "../Model/PracticeDetailDisplay";
import { ATOMutliSelect } from "../../../Components/ATOMutliSelect";

interface Props {
  data?: PracticeDetailDisplay;
  pendingChanges: SinglePendingChanges<PracticeDetailDisplay>;
  isNewPractice?: boolean;
}

export const PracticeDetailScreen = ({
  data: practiceDetail,
  pendingChanges: practiceChanges,
  isNewPractice,
}: Props) => {
  const user = useATOAuth();

  const setIsBlocked = useSetRecoilState(BlockerAtom);

  const hasChanges = practiceChanges.getChangeCount() > 0;

  const actualPracticeDetail: PracticeDetailDisplay = practiceChanges.applyChanges(practiceDetail ?? {});

  useEffect(() => {
    setIsBlocked(hasChanges);
  }, [hasChanges]);

  if (user.isLoading) {
    return <ATOLoading />;
  }

  const getSelectableToolOptions = (search: string) => {
    const selectedToolIds = actualPracticeDetail?.tools ?? [];
    const selectableTools = practiceDetail?.toolList?.filter((tool) => !selectedToolIds.includes(tool.toolId!));

    const loweredSearch = search.toLowerCase();
    return (
      selectableTools?.filter((tool) => tool.toolName?.toLowerCase().includes(loweredSearch) || loweredSearch === "") ??
      []
    );
  };

  const getSelectedToolOptions = () => {
    var selectedToolIds = actualPracticeDetail?.tools ?? [];
    const selectedTools = practiceDetail?.toolList?.filter((tool) => selectedToolIds.includes(tool.toolId!));

    return selectedTools ?? [];
  };

  const selectToolOption = (item: ToolDetail) => {
    if (actualPracticeDetail?.tools?.includes(item.toolId!)) {
      const remainingTools = actualPracticeDetail?.tools.filter((x) => x !== item.toolId);
      practiceChanges.setChange("tools", [...remainingTools]);
    } else {
      practiceChanges.setChange("tools", [item.toolId, ...(actualPracticeDetail?.tools ?? [])]);
    }
  };

  const getSelectableModuleOptions = (search: string) => {
    var selectedModuleIds = actualPracticeDetail?.modules ?? [];
    const selectableModules = practiceDetail?.moduleList?.filter(
      (module) => !selectedModuleIds.includes(module.moduleId!)
    );

    search = search.toLowerCase();
    return (
      selectableModules?.filter((module) => module.moduleName?.toLowerCase().includes(search) || search === "") ?? []
    );
  };

  const getSelectedModuleOptions = () => {
    var selectedModuleIds = actualPracticeDetail?.modules ?? [];
    const selectedModules = practiceDetail?.moduleList?.filter((module) =>
      selectedModuleIds.includes(module.moduleId!)
    );

    return selectedModules ?? [];
  };

  const selectModuleOption = (item: ModuleDetail) => {
    if (actualPracticeDetail?.modules?.includes(item.moduleId!)) {
      const remainingModules = actualPracticeDetail?.modules.filter((x) => x !== item.moduleId);
      practiceChanges.setChange("modules", [...remainingModules]);
    } else {
      practiceChanges.setChange("modules", [item.moduleId, ...(actualPracticeDetail?.modules ?? [])]);
    }
  };

  return (
    <ATOScopeChecker className="h-full w-full" user={user} scope="practice:write" denyAction={"disable"}>
      <div className={`flex h-full justify-center gap-4 overflow-y-auto p-4 ${isNewPractice ? "" : "px-[12.5%]"}`}>
        <div className={`flex h-full ${isNewPractice ? "w-full" : "w-[50vw]"} flex-col gap-2`}>
          <ATOValueHeader
            hasChange={practiceChanges.hasChange("custName")}
            hasError={(actualPracticeDetail.custName?.length ?? 0) < 1}
          >
            Practice Name:
          </ATOValueHeader>
          <ATOTextBox
            className="h-8 flex-shrink-0"
            value={actualPracticeDetail.custName ?? ""}
            onChange={(val) => practiceChanges.setChange("custName", val)}
            hasChange={practiceChanges.hasChange("custName")}
            hasError={(actualPracticeDetail.custName?.length ?? 0) < 1}
          />
          <ATOValueHeader
            hasChange={practiceChanges.hasChange("practiceEmail")}
            hasError={(actualPracticeDetail.practiceEmail ?? "").length < 1}
          >
            Practice Email:
          </ATOValueHeader>
          <div className="flex w-full gap-2">
            <ATOTextBox
              className="h-8 w-full"
              hasChange={practiceChanges.hasChange("practiceEmail")}
              hasError={(actualPracticeDetail.practiceEmail ?? "").length < 1}
              value={actualPracticeDetail.practiceEmail ?? ""}
              onChange={(val) => practiceChanges.setChange("practiceEmail", val)}
            />
            {!isNewPractice && (
              <ATOButton className="h-8 text-nowrap" buttonType={ButtonType.Warning}>
                Upload Signature
              </ATOButton>
            )}
          </div>
          <div className="grid grid-flow-col grid-cols-2 grid-rows-[25px_1fr] place-items-baseline gap-2">
            <ATOValueHeader hasError={!actualPracticeDetail.pmS1} hasChange={practiceChanges.hasChange("pmS1")}>
              PMS:
            </ATOValueHeader>
            <div className="flex w-full gap-2">
              <ATODropDown<PMS | undefined>
                className="h-8 w-full"
                hasChange={practiceChanges.hasChange("pmS1")}
                hasError={!actualPracticeDetail.pmS1}
                getOptions={() => [...(Object.values(PMS).filter((p) => typeof p === "number") as PMS[])]}
                onChange={(pms) => practiceChanges.setChange("pmS1", pms)}
                value={actualPracticeDetail.pmS1}
                getDisplay={(v) => (v ? camelCaseSpace(PMS[v]) : "N/A")}
              />
              {!isNewPractice && (
                <ATOButton
                  className="h-8 text-nowrap"
                  disabled={practiceDetail?.pmsWithSync?.[actualPracticeDetail.pmS1?.toString() ?? "0"] === undefined}
                  buttonType={ButtonType.Primary}
                >
                  Sync
                </ATOButton>
              )}
            </div>
            <ATOValueHeader hasChange={practiceChanges.hasChange("pmS2")}>SECONDARY PMS:</ATOValueHeader>
            <ATODropDown<PMS | undefined>
              className="h-8 w-full"
              hasChange={practiceChanges.hasChange("pmS2")}
              getOptions={() => [undefined, ...(Object.values(PMS).filter((p) => typeof p === "number") as PMS[])]}
              onChange={(pms) => practiceChanges.setChange("pmS2", pms)}
              value={actualPracticeDetail.pmS2}
              getDisplay={(v) => (v ? camelCaseSpace(PMS[v]) : "N/A")}
            />
          </div>
          <div className="grid grid-flow-col grid-cols-2 grid-rows-[25px_1fr] place-items-baseline gap-2">
            <ATOValueHeader
              hasError={actualPracticeDetail.dms === undefined}
              hasChange={practiceChanges.hasChange("dms")}
            >
              DMS:
            </ATOValueHeader>
            <ATODropDown<DMSName | undefined>
              className="h-8 w-full"
              hasChange={practiceChanges.hasChange("dms")}
              hasError={!actualPracticeDetail.dms}
              getOptions={() => [...(Object.values(DMSName).filter((p) => typeof p === "number") as DMSName[])]}
              onChange={(dms) => practiceChanges.setChange("dms", dms)}
              value={actualPracticeDetail.dms}
              getDisplay={(v) => (v ? camelCaseSpace(DMSName[v]) : "N/A")}
            />
            <ATOValueHeader hasChange={practiceChanges.hasChange("portal")}>PORTAL:</ATOValueHeader>
            <ATODropDown<PortalType>
              className="h-8 w-full"
              hasChange={practiceChanges.hasChange("portal")}
              getOptions={() => Object.values(PortalType).filter((p) => typeof p === "number") as PortalType[]}
              onChange={(portal) => practiceChanges.setChange("portal", portal)}
              value={actualPracticeDetail.portal ?? PortalType.None}
              getDisplay={(v) => (v != undefined ? camelCaseSpace(PortalType[v]) : "N/A")}
            />
          </div>
          <div className="grid grid-flow-col grid-cols-2 grid-rows-[25px_1fr] place-items-baseline gap-2">
            <ATOValueHeader hasError={!actualPracticeDetail.ingest} hasChange={practiceChanges.hasChange("ingest")}>
              INGEST:
            </ATOValueHeader>
            <ATODropDown<IngestType | undefined>
              className="h-8 w-full"
              hasChange={practiceChanges.hasChange("ingest")}
              hasError={!actualPracticeDetail.ingest}
              getOptions={() => [...(Object.values(IngestType).filter((p) => typeof p === "number") as IngestType[])]}
              onChange={(ingest) => practiceChanges.setChange("ingest", ingest)}
              value={actualPracticeDetail.ingest}
              getDisplay={(v) => (v == 0 || v ? camelCaseSpace(IngestType[v]) : "N/A")}
            />
            <ATOValueHeader hasChange={practiceChanges.hasChange("smsfId")}>SMSF:</ATOValueHeader>
            <ATODropDown<SmsfType>
              className="h-8 w-full"
              hasChange={practiceChanges.hasChange("smsfId")}
              getOptions={() => [...(Object.values(SmsfType).filter((p) => typeof p === "number") as SmsfType[])]}
              onChange={(smsfId) => practiceChanges.setChange("smsfId", smsfId)}
              value={actualPracticeDetail.smsfId ?? SmsfType.None}
              getDisplay={(v) => (v == 0 || v ? camelCaseSpace(SmsfType[v]) : "N/A")}
            />
          </div>
          <div className="grid grid-flow-col grid-cols-2 grid-rows-[25px_1fr] place-items-baseline gap-2">
            <ATOValueHeader
              hasError={actualPracticeDetail.isSsoEnabled === undefined}
              hasChange={practiceChanges.hasChange("isSsoEnabled")}
            >
              Authentication Source:
            </ATOValueHeader>
            <ATODropDown<string>
              className="h-8 w-full"
              getOptions={() => ["Office 365", "Username & Password \\ Google"]}
              onChange={(val) => practiceChanges.setChange("isSsoEnabled", val === "Office 365")}
              value={actualPracticeDetail?.isSsoEnabled ? "Office 365" : "Username & Password \\ Google"}
              getDisplay={(v) => v}
            />
          </div>
          <div className="grid grid-flow-col grid-cols-2 grid-rows-[25px_1fr] place-items-baseline gap-2 pb-5">
            <ATOValueHeader hasChange={practiceChanges.hasChange("modules")}>Modules:</ATOValueHeader>
            <ATOMutliSelect<ModuleDetail>
              className="w-full"
              getOptions={(search) => getSelectableModuleOptions(search)}
              selectedOptions={() => getSelectedModuleOptions()}
              onItemClick={(item) => selectModuleOption(item)}
              getDataKey={(module) => `${module?.moduleName} `}
              getItemDisplay={(module) => {
                return <>{module?.moduleName}</>;
              }}
              isItemDisabled={(module) => {
                return module.isEnabled!;
              }}
            />

            <ATOValueHeader hasChange={practiceChanges.hasChange("tools")}>Tools:</ATOValueHeader>
            <ATOMutliSelect<ToolDetail>
              className="w-full"
              getOptions={(search) => getSelectableToolOptions(search)}
              selectedOptions={() => getSelectedToolOptions()}
              onItemClick={(item) => selectToolOption(item)}
              getDataKey={(tool) => `${tool?.toolName} `}
              getItemDisplay={(tool) => {
                return <>{tool?.toolName}</>;
              }}
              isItemDisabled={(tool) => {
                return tool.isEnabled!;
              }}
            />
          </div>
        </div>
        {/* <div className="h-full w-px bg-gray-300" /> */}
        {!isNewPractice && (
          <div className="flex h-min flex-col items-center justify-start gap-1 rounded-lg bg-gray-200 p-4 pt-3 shadow-inner">
            <ATOValueHeader hasChange={practiceChanges.hasChange("active")}>Active:</ATOValueHeader>
            <ATOSwitch
              size="xl"
              hasChange={practiceChanges.hasChange("active")}
              value={actualPracticeDetail.active ?? false}
              onChange={() => practiceChanges.setChange("active", !actualPracticeDetail.active)}
            />
            <ATOScopeChecker
              scope={"billing:write"}
              user={user}
              className="flex w-full flex-col items-center justify-center gap-1"
            >
              <>
                <ATOValueHeader hasChange={practiceChanges.hasChange("billingEnabled")}>Billing:</ATOValueHeader>
                <ATOSwitch
                  size="xl"
                  hasChange={practiceChanges.hasChange("billingEnabled")}
                  value={actualPracticeDetail.billingEnabled ?? false}
                  onChange={() => practiceChanges.setChange("billingEnabled", !actualPracticeDetail.billingEnabled)}
                />
              </>
            </ATOScopeChecker>
            <ATOValueHeader hasChange={practiceChanges.hasChange("ingestionEnabled")}>Ingestion:</ATOValueHeader>
            <ATOSwitch
              size="xl"
              hasChange={practiceChanges.hasChange("ingestionEnabled")}
              value={actualPracticeDetail.ingestionEnabled ?? false}
              onChange={() => practiceChanges.setChange("ingestionEnabled", !actualPracticeDetail.ingestionEnabled)}
            />
          </div>
        )}
      </div>
    </ATOScopeChecker>
  );
};
