import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

import { ATOSwitch } from "../../../../Components/ATOSwitch";
import { useState } from "react";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { SinglePendingChanges } from "../../../../Hooks/SinglePendingChagesHook";
import { ATOLoading } from "../../../../Components/ATOSpinner";
import { ATOValueHeader } from "../../../../Components/ATOValueHeader";
import { ModuleDetail, SubscriptionInfo } from "../../../../generated";
import { BillingInfoDisplay } from "../../Model/BillingInfoDisplay";

export const PracticeDetailBillingModules = ({
  data,
  pendingChanges,
}: {
  data: BillingInfoDisplay;
  pendingChanges: SinglePendingChanges<BillingInfoDisplay>;
}) => {
  const [expandedModule, setExpandedModule] = useState<string | undefined>("documentAutomation");

  const modules = [
    { id: "documentAutomation", name: "Document Automation", content: DocumentAutomation },
    { id: "documentAutomation2", name: "Document Automation2", content: DocumentAutomation },
  ];

  const isLoading = false;

  return (
    <div className="grid h-full grid-rows-[min-content_minmax(0,1fr)]">
      <h1 className="w-full border-b border-black bg-primary py-2 text-center text-xl text-white">Products</h1>
      <div className="h-full overflow-y-auto">
        {isLoading ? (
          <ATOLoading />
        ) : (
          [
            ...modules.map((m) => {
              return (
                <>
                  <div className="flex h-12 w-full items-center justify-between border-b border-black bg-gray-200 px-2">
                    <div className="flex h-full w-24 items-center">
                      <ATOSwitch onChange={() => {}} value disabled />
                    </div>
                    <p className="text-lg">{m.name}</p>
                    <div
                      className="flex h-full w-24 cursor-pointer items-center justify-end"
                      onClick={() => setExpandedModule(expandedModule === m.id ? undefined : m.id)}
                    >
                      <FontAwesomeIcon
                        icon={faSvg.faChevronDown}
                        className={`${expandedModule === m.id ? "rotate-180" : ""} text-xl text-black transition-transform`}
                      />
                    </div>
                  </div>
                  <m.content
                    isExpanded={expandedModule === m.id}
                    subscriptionInfo={data}
                    pendingChanges={pendingChanges}
                  />
                </>
              );
            }),
          ]
        )}
      </div>
    </div>
  );
};

const DocumentAutomation = ({
  isExpanded,
  subscriptionInfo,
  pendingChanges,
}: {
  isExpanded: boolean;
  subscriptionInfo: BillingInfoDisplay;
  pendingChanges: SinglePendingChanges<BillingInfoDisplay>;
}) => {
  const billingInfo = pendingChanges.applyChanges(subscriptionInfo ?? {});

  const onChangeCheck = (val: string, prop: keyof SubscriptionInfo) => {
    (val === "" || !isNaN(val as any)) && pendingChanges.setChange(prop, val);
  };

  const moduleList = subscriptionInfo.moduleList?.sort((a, b) => (a.isEnabled ? -1 : 0));
  const commPrefModuleId = 4;
  const commprefEnabled =
    moduleList?.find((x) => x.moduleId === commPrefModuleId && x.isEnabled === true) !== undefined;

  return (
    <>
      <div
        className={`h-72 ${isExpanded ? "max-h-72" : "max-h-0"} grid w-full grid-cols-[1fr_min-content] overflow-clip ${isExpanded ? "border-b" : ""} border-black bg-gray-200 transition-all`}
      >
        <div className="grid auto-rows-min grid-cols-[min-content_1fr] place-items-center justify-items-end gap-2 p-4 *:h-min *:text-nowrap">
          <ATOValueHeader className="mt-1 flex-shrink-0" hasChange={pendingChanges.hasChange("onboardingCost")}>
            Onboarding Fee:
          </ATOValueHeader>
          <ATOTextBox
            className="w-full bg-white"
            hasChange={pendingChanges.hasChange("onboardingCost")}
            disallowZeroLength
            value={billingInfo.onboardingCost?.toString() ?? ""}
            valuePrefix="$"
            onChange={(val) => onChangeCheck(val, "onboardingCost")}
          />
          <ATOValueHeader className="mt-1 flex-shrink-0" hasChange={pendingChanges.hasChange("subscriptionCost")}>
            Monthly Subscription Fee:
          </ATOValueHeader>
          <ATOTextBox
            className="w-full bg-white"
            hasChange={pendingChanges.hasChange("subscriptionCost")}
            disallowZeroLength
            value={billingInfo.subscriptionCost?.toString() ?? ""}
            valuePrefix="$"
            onChange={(val) => onChangeCheck(val, "subscriptionCost")}
          />
          <ATOValueHeader className="mt-1 flex-shrink-0" hasChange={pendingChanges.hasChange("includedPages")}>
            Included Pages:
          </ATOValueHeader>
          <ATOTextBox
            className="w-full bg-white"
            hasChange={pendingChanges.hasChange("includedPages")}
            disallowZeroLength
            value={billingInfo.includedPages?.toString() ?? ""}
            onChange={(val) => onChangeCheck(val, "includedPages")}
          />
          <ATOValueHeader className="mt-1 flex-shrink-0" hasChange={pendingChanges.hasChange("additionalPagesCost")}>
            Additional Page Cost:
          </ATOValueHeader>
          <ATOTextBox
            className="w-full bg-white"
            hasChange={pendingChanges.hasChange("additionalPagesCost")}
            disallowZeroLength
            value={billingInfo.additionalPagesCost?.toString() ?? ""}
            valuePrefix="$"
            onChange={(val) => onChangeCheck(parseFloat(val) > 0.99 ? "0.99" : val, "additionalPagesCost")}
          />
          {commprefEnabled && (
            <>
              <ATOValueHeader className="mt-1 flex-shrink-0" hasChange={pendingChanges.hasChange("commPrefFee")}>
                Commpref Fee:
              </ATOValueHeader>
              <ATOTextBox
                className="w-full bg-white"
                hasChange={pendingChanges.hasChange("commPrefFee")}
                disallowZeroLength
                value={billingInfo.commPrefFee?.toString() ?? ""}
                valuePrefix="$"
                onChange={(val) => onChangeCheck(val, "commPrefFee")}
              />
              <ATOValueHeader className="mt-1 flex-shrink-0" hasChange={pendingChanges.hasChange("commPrefUsers")}>
                Commpref Users:
              </ATOValueHeader>
              <ATOTextBox
                className="w-full bg-white"
                hasChange={pendingChanges.hasChange("commPrefUsers")}
                disallowZeroLength
                value={billingInfo.commPrefUsers?.toString() ?? ""}
                onChange={(val) => onChangeCheck(val, "commPrefUsers")}
              />
            </>
          )}
        </div>
        <div className="flex flex-col border-l border-black bg-white">
          <h2 className="w-full bg-primary py-1 text-center text-lg text-white">Modules</h2>
          <div className="flex h-full min-w-[350px] flex-col items-center justify-start gap-2 px-2 pt-3">
            {moduleList?.map((module, i) => {
              return (
                <div className="flex w-full">
                  <ATOSwitch onChange={() => {}} value={module.isEnabled === true} />
                  <p className="whitespace-nowrap">&nbsp;{module.moduleName}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
