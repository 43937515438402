/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_SuitePracticeInfo_ } from '../models/ApiResponse_SuitePracticeInfo_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SuiteEnrolmentActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_SuitePracticeInfo_ Success
     * @throws ApiError
     */
    public getSuitePracticeInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_SuitePracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SuiteEnrolmentActions/GetSuitePracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public saveSuitePracticeInfo(
        formData: {
            fileName?: string;
            unknownClientId?: string;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SuiteEnrolmentActions/SaveSuitePracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
