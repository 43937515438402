/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * SubscriptionOwner(0)
 * Billing(1)
 * IT(2)
 */
export enum CustomerContactRole {
    SubscriptionOwner = 0,
    Billing = 1,
    IT = 2,
}
