/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_BillingInfo_ } from '../models/ApiResponse_BillingInfo_';
import type { ApiResponse_SubscriptionInfo_ } from '../models/ApiResponse_SubscriptionInfo_';
import type { BillingInfo } from '../models/BillingInfo';
import type { SubscriptionInfo } from '../models/SubscriptionInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BillingActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_BillingInfo_ Success
     * @throws ApiError
     */
    public updateBillingInfo(
        formData: {
            billingInfo?: BillingInfo;
        },
    ): CancelablePromise<ApiResponse_BillingInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/BillingActions/UpdateBillingInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_BillingInfo_ Success
     * @throws ApiError
     */
    public getBillingInfo(
        formData: {
            custId?: number;
        },
    ): CancelablePromise<ApiResponse_BillingInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/BillingActions/GetBillingInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_SubscriptionInfo_ Success
     * @throws ApiError
     */
    public getSubscriptionInfo(
        formData: {
            custId?: number;
        },
    ): CancelablePromise<ApiResponse_SubscriptionInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/BillingActions/GetSubscriptionInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_SubscriptionInfo_ Success
     * @throws ApiError
     */
    public updateSubscriptionInfo(
        formData: {
            subscriptionInfo?: SubscriptionInfo;
        },
    ): CancelablePromise<ApiResponse_SubscriptionInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/BillingActions/UpdateSubscriptionInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
