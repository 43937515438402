/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_IEnumerable_PracticeUserListing_ } from '../models/ApiResponse_IEnumerable_PracticeUserListing_';
import type { ApiResponse_IEnumerable_ToolDetail_ } from '../models/ApiResponse_IEnumerable_ToolDetail_';
import type { ApiResponse_PracticeUserDisplay_ } from '../models/ApiResponse_PracticeUserDisplay_';
import type { ApiResponse_string_ } from '../models/ApiResponse_string_';
import type { PracticeUserDisplay } from '../models/PracticeUserDisplay';
import type { PrimaryContactChangeRequest } from '../models/PrimaryContactChangeRequest';
import type { PrimarySuperuserChangeRequest } from '../models/PrimarySuperuserChangeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PracticeUsers {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_PracticeUserDisplay_ Success
     * @throws ApiError
     */
    public getPracticeUserDetail(
        formData: {
            custId?: number;
            userId?: number;
        },
    ): CancelablePromise<ApiResponse_PracticeUserDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeUsers/GetPracticeUserDetail',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_PracticeUserListing_ Success
     * @throws ApiError
     */
    public listUsers(
        formData: {
            custId?: number;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_PracticeUserListing_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeUsers/ListUsers',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public savePracticeUserDetail(
        formData: {
            custId?: number;
            pracitce?: PracticeUserDisplay;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeUsers/SavePracticeUserDetail',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public deleteUser(
        formData: {
            custId?: number;
            userId?: number;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeUsers/DeleteUser',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public resetMfa(
        formData: {
            custId?: number;
            userId?: number;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeUsers/ResetMfa',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public updatePrimarySuperuser(
        formData: {
            request?: PrimarySuperuserChangeRequest;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeUsers/UpdatePrimarySuperuser',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public updatePrimaryContact(
        formData: {
            request?: PrimaryContactChangeRequest;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeUsers/UpdatePrimaryContact',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_ToolDetail_ Success
     * @throws ApiError
     */
    public getPracticeTools(
        formData: {
            custId?: number;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_ToolDetail_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeUsers/GetPracticeTools',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_string_ Success
     * @throws ApiError
     */
    public getSignatures(
        formData: {
            custId?: number;
            userId?: number;
        },
    ): CancelablePromise<ApiResponse_string_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeUsers/GetSignatures',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
