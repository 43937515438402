import axios, { AxiosError } from "axios";
import { ApiResponse, ApiResponseStatus } from "../../generated";

import type { ApiRequestOptions } from "../../generated/core/ApiRequestOptions";
import { CancelablePromise } from "../../generated/core/CancelablePromise";
import type { OpenAPIConfig } from "../../generated/core/OpenAPI";

const axiosInstance = axios.create({
  // Your custom Axios instance config
});

export const request = <T>(config: OpenAPIConfig, options: ApiRequestOptions): CancelablePromise<T> => {
  return new CancelablePromise((resolve, reject, onCancel) => {
    const url = `${config.BASE}${options.url}`;

    let form = new FormData();

    Object.keys(options.formData ?? {}).forEach((k) => {
      let data = options.formData![k];

      if (data instanceof Blob === false) {
        data = JSON.stringify(data);
      }

      form.append(k, data);
    });

    new Promise<string>((resolve) => {
      const token = typeof config.TOKEN === "string" ? config.TOKEN : config.TOKEN?.(options) ?? "";

      resolve(token);
    })
      .then((token) =>
        axiosInstance.request<T>({
          url,
          data: form,
          method: options.method,
          headers: {
            ...config.HEADERS,
            "Content-Type": "multipart/form-data",
            Authorization: `${config.VERSION === "2" ? "Bearer2" : "Bearer"} ${token}`,
          },
        })
      )
      .then((data) => {
        if ((data.data as ApiResponse)?.status !== ApiResponseStatus.Success) {
          throw { data: data.data, trace: data.headers["distributedtracingdata"] };
        }

        resolve(data.data);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.data) {
          reject({ ...error.response.data, trace: error.response.headers["distributedtracingdata"] });
        } else {
          reject({ ...error, trace: error.response?.headers["distributedtracingdata"] });
        }

        return error;
      });
  });
};
