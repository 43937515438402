import { Route, Routes } from "react-router-dom";
import { UserListing } from "./UserListing";
import { UserDetailNavigation } from "./UserDetailNavigation";

const Users = ({ custId }: { custId: number }) => {
  return (
    <Routes>
      <Route path="/" element={<UserListing custId={custId} />} />
      <Route path="/:id/*" element={<UserDetailNavigation custId={custId} />} />
    </Routes>
  );
};

export default Users;
