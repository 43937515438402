import { CategoryModuleViewModel } from "../../../generated";
import { ATOCheckBox } from "../../../Components/ATOCheckBox";
import { useEffect, useState } from "react";

export const ELearningDetails = (props: {
  categories: CategoryModuleViewModel[];
  setIndexCategory: (val: number | null) => void;
  setCurrentCategory: (val: CategoryModuleViewModel | null) => void;
}) => {
  const [selectedId, setSelectedId] = useState<number | null>(null);

  useEffect(() => {
    setSelectedId(null);
  }, [props.categories]);

  const onSelectCategory = (category: CategoryModuleViewModel, cidx: number) => {
    if (selectedId === cidx) {
      setSelectedId(null);
      props.setIndexCategory(null);
      props.setCurrentCategory(null);
    } else {
      setSelectedId(cidx);
      props.setIndexCategory(cidx);
      props.setCurrentCategory(category);
    }
  };

  return (
    <div className="grid gap-4" style={{ gridTemplateColumns: `repeat(2, 1fr)` }}>
      {props.categories.map((category, cidx) => (
        <div key={cidx} className="w-49 bg-[#f7f5f5] p-2 text-center shadow-xl hover:shadow-[#6e6969]">
          <ATOCheckBox
            className="float-start size-6"
            key={cidx}
            value={cidx === selectedId}
            onChange={() => onSelectCategory(category, cidx)}
          />
          <span className="text-2xl font-extrabold text-blue-500">
            {category.categoryName}-({category.categoryWeight})
          </span>
          {category.modules &&
            category.modules.length > 0 &&
            (category.modules.length > 1 ? (
              <div className="grid grid-cols-2 gap-2 pt-8">
                {category.modules.map((module, midx) => (
                  <div key={midx} className="bg-white p-2 text-center shadow-xl hover:shadow-[#6e6969]">
                    <span className="text-base font-extrabold">
                      {module.moduleName && module.moduleName.toUpperCase()}-({module.moduleWeight})
                    </span>
                    <div className="grid grid-cols-2 divide-x-2 divide-blue-600 pt-6 text-[12px] font-semibold">
                      <div className="pr-2">
                        <div>{module.readingTimeInMinute}</div>
                        <div>Minutes</div>
                      </div>
                      <div className="pl-2">
                        <div>{module.questions}</div>
                        <div>Questions</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex place-content-center pt-8">
                <div key={cidx} className="bg-white p-2 text-center shadow-xl hover:shadow-[#6e6969]">
                  <span className="text-base font-extrabold">
                    {category.modules[0].moduleName!.toUpperCase()}-({category.modules[0].moduleWeight})
                  </span>
                  <div className="grid grid-cols-2 divide-x-2 divide-blue-600 pt-6 text-[12px] font-semibold">
                    <div className="pr-2">
                      <div>{category.modules![0].readingTimeInMinute}</div>
                      <div>Minutes</div>
                    </div>
                    <div className="pl-2">
                      <div>{category.modules![0].questions}</div>
                      <div>Questions</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};
