import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

import { ATOLoading } from "../../../../Components/ATOSpinner";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useEffect, useState } from "react";
import { PracticeUserListing } from "../../../../generated";
import { Tooltip } from "../../../../Components/Tooltip";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { ATOButton } from "../../../../Components/ATOButton";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { Link } from "react-router-dom";

export const sanitiseUserTypeLabel = (userType: string | undefined | null): string => {
  return (userType ?? "")?.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
};

export const UserListing = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const amClient = useAMClient(config.adminUrl, user);

  const [isLoading, setIsLoading] = useState(true);

  const [users, setUsers] = useState<PracticeUserListing[]>([]);
  const [filterUserType, setFilterUsertype] = useState("All");
  const [filterText, setFilterText] = useState("");

  const clearFilter = () => {
    setFilterUsertype("All");
    setFilterText("");
  };

  const currentFilterUserType = filterUserType.toLowerCase().replaceAll(" ", "");

  const filterUsers = users
    .filter((u) => currentFilterUserType === "all" || u.userType === currentFilterUserType)
    .filter((u) => filterText === "" || [u.email, u.name, u.userId].find((t) => t?.toString()?.includes(filterText)));

  const reloadUsers = () => {
    setIsLoading(true);

    amClient.practiceUsers
      .listUsers({ custId })
      .then((resp) => setUsers(resp.data ?? []))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    reloadUsers();
  }, []);

  const columns = {
    rowNumber: { name: "#", width: "52px" },
    name: { name: "Name", width: "1fr" },
    email: { name: "Email", width: "1fr" },
    userType: { name: "User Type", width: "1fr" },
    status: { name: "Status", width: "1fr" },
    actions: { name: "Actions", width: "150px" },
  };
  const spanString = `span ${Object.keys(columns).length * 2 - 1} / span ${Object.keys(columns).length * 2 - 1}`;

  return (
    <>
      <div className="flex h-full w-full overflow-hidden">
        <div className="grid w-full grid-rows-[min-content_1fr]">
          <div className=" grid h-full w-full grid-cols-[250px_1fr_100px] gap-2 p-2 pr-8">
            <div>
              <ATODropDown<string>
                className="w-full"
                value={filterUserType ?? "All"}
                getDisplay={(r) => r}
                getOptions={() => ["All", "Primary Superuser", "Superuser", "User"]}
                onChange={(val) => setFilterUsertype(val)}
              />
            </div>
            <div>
              <ATOTextBox className="h-8" onChange={(val) => setFilterText(val)} value={filterText}></ATOTextBox>
            </div>
            <div>
              <ATOButton onClick={clearFilter} className="h-8 w-32">
                Clear
              </ATOButton>
            </div>
          </div>
          <div className="h-full overflow-hidden">
            <div
              style={{
                gridTemplateColumns: Object.values(columns)
                  .map((v, i) => (i === 0 ? v.width : "1px " + v.width))
                  .join(" "),
              }}
              className="grid h-full w-full grid-rows-[min-content_minmax(0,1fr)]"
            >
              <div
                style={{
                  gridTemplateColumns: "subgrid",
                  gridColumn: spanString,
                }}
                className="grid items-center border-b border-black bg-primary text-white"
              >
                {Object.values(columns).map((c, i) => (
                  <>
                    {i > 0 ? <div className="h-4/5 bg-white" /> : <></>}
                    <p className="w-full text-center">{c.name}</p>
                  </>
                ))}
              </div>
              {isLoading ? (
                <div style={{ gridColumn: spanString }}>
                  <ATOLoading />
                </div>
              ) : (
                <div
                  style={{
                    gridTemplateColumns: "subgrid",
                    gridColumn: spanString,
                  }}
                  className="grid h-full auto-cols-fr place-content-start gap-y-2 overflow-y-scroll pt-2 last:pb-2"
                >
                  {filterUsers.map((c, i) => (
                    <div
                      key={(c.userId?.toString() ?? "") + i}
                      style={{
                        gridTemplateColumns: "subgrid",
                        gridColumn: spanString,
                      }}
                      className="mx-2 grid h-8 place-items-center overflow-clip rounded-lg border border-black bg-gray-200 *:overflow-clip *:text-ellipsis *:whitespace-nowrap"
                    >
                      <p>{i + 1}</p>
                      <div className="h-4/5 w-px bg-black" />
                      <p>{c.name}</p>
                      <div className="h-4/5 w-px bg-black" />
                      <p>{c.email}</p>
                      <div className="h-4/5 w-px bg-black" />
                      <p className="flex">
                        {sanitiseUserTypeLabel(c.userType)}
                        {c.isPrimaryContact && (
                          <span className="ml-1 mt-1 h-4 rounded-full bg-green-500 px-2 align-bottom text-xs text-white">
                            Primary Contact
                          </span>
                        )}
                      </p>
                      <div className="h-4/5 w-px bg-black" />
                      <p className="">
                        <span className={`${c.status === "Pending" ? " font-semibold text-red-600" : ""}`}>
                          {c.status}
                        </span>
                        {c.status === "Pending" && (
                          <span>
                            <FontAwesomeIcon icon={faSvg.faEnvelope} className={`px-1 pt-1 text-lg text-primary`} />
                            Reinvite
                          </span>
                        )}
                      </p>
                      <div className="h-4/5 w-px bg-black" />
                      <div className="flex gap-2">
                        <Link
                          to={`./${c.userId}`}
                          className="flex items-center justify-end gap-2 text-2xl text-primary"
                        >
                          <Tooltip
                            icon={
                              <FontAwesomeIcon icon={faSvg.faEdit} className="cursor-pointer text-lg text-primary" />
                            }
                          >
                            <div
                              className="-ml-[15px] flex h-0
                                -translate-x-[calc(100%+6px)] -translate-y-1/2
                                items-center justify-center text-white"
                            >
                              <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Edit</p>
                              <div className="-ml-[9px] h-4 w-4 rotate-45 bg-black" />
                            </div>
                          </Tooltip>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
