import { useState } from "react";
import { DMSDocSetupColumnInfo } from "./DMSDocSetupColumnInfo";

import { PendingChanges, usePendingChanges } from "../../Hooks/PendingChangesHook";
import { ATOModal } from "../ATOModal";
import { ATOButton, ButtonType } from "../ATOButton";
import { ATOTextBox } from "../ATOTextBox";
import { IDMSDoc } from "./IDMSDoc";

export const DMSDocCustomModal = <TDMSDoc extends IDMSDoc>(props: {
  columnInfo: DMSDocSetupColumnInfo<TDMSDoc>;
  setModal: (elem: React.ReactNode) => void;
  value: TDMSDoc;
  rowIndex: number;
  // pendingChangesKey?: string;
  pendingChanges: PendingChanges<TDMSDoc>;
}) => {
  const initialValue = props.value[props.columnInfo.valueKey] ?? "";

  // const pendingChanges = usePendingChanges<TDMSDoc>();
  const pendingChanges = props.pendingChanges;

  const [text, setText] = useState(initialValue);

  return (
    <ATOModal
      onClose={() => props.setModal(undefined)}
      title={`${props.columnInfo.name} - Custom Value`}
      className="w-80"
      footerContent={
        <div className="flex gap-2">
          <ATOButton
            buttonType={ButtonType.Error}
            onClick={() => {
              pendingChanges.setChange(props.rowIndex.toString(), props.columnInfo.valueKey, initialValue);
              props.setModal(undefined);
            }}
          >
            Cancel
          </ATOButton>
          <ATOButton
            buttonType={ButtonType.Primary}
            onClick={() => {
              pendingChanges.setChange(props.rowIndex.toString(), props.columnInfo.valueKey, text);
              props.setModal(undefined);
            }}
          >
            Save
          </ATOButton>
        </div>
      }
    >
      <div className="p-2">
        <ATOTextBox value={text} onChange={setText} />
      </div>
    </ATOModal>
  );
};
