import { ATOTextBox } from "../../../Components/ATOTextBox";
import { Tooltip } from "../../../Components/Tooltip";
import { SinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { ATOValueHeader } from "../../../Components/ATOValueHeader";

export interface Props<T> {
  pendingChanges: SinglePendingChanges<T>;
  errors?: Record<keyof T, string>;
  label: string;
  propName: keyof T;
  intialValue?: string;
  disallowZeroLength?: boolean;
}

export const ControlGroup = <T extends unknown>({
  pendingChanges,
  errors,
  label,
  propName,
  intialValue,
  disallowZeroLength,
}: Props<T>) => {
  return (
    <>
      <div className="flex h-full w-full items-center justify-between gap-2">
        <ATOValueHeader
          className="mt-1 flex flex-shrink-0 items-center gap-1 text-nowrap"
          hasChange={pendingChanges.hasChange(propName)}
        >
          <p>{label}:</p>
        </ATOValueHeader>
        {errors && errors[propName] && (
          <Tooltip icon={<FontAwesomeIcon icon={faSvg.faWarning} className="cursor-help pb-1 text-lg text-red-600" />}>
            <p className="w-full -translate-y-1/2 text-nowrap pl-4 text-center text-red-600"> {errors[propName]}</p>
          </Tooltip>
        )}
      </div>
      <div className="w-full">
        <ATOTextBox
          className="h-full w-full"
          hasChange={pendingChanges.hasChange(propName)}
          disallowZeroLength={disallowZeroLength}
          value={intialValue ?? ""}
          onChange={(val) => pendingChanges.setChange(propName, val)}
        />
      </div>
    </>
  );
};
