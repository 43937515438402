/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * Email(0)
 * Portal(1)
 * Secure(2)
 */
export enum TemplateVariant {
    Email = 0,
    Portal = 1,
    Secure = 2,
}
