import { useCallback, useEffect, useState } from "react";
import { ATOLoading } from "../../../Components/ATOSpinner";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { useAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { Tooltip } from "../../../Components/Tooltip";
import { ATOTextBox } from "../../../Components/ATOTextBox";
import { ATOSwitch } from "../../../Components/ATOSwitch";
import {
  AccountingSettingsDisplay,
  AdvancedWorkflow,
  AdvancedWorkflowUpdateRequest,
  AdvancedWorkflowUser,
  ClientGroupUserType,
} from "../../../generated";
import { useSinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../App";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { DefaultWorkflowUsers } from "./DefaultWorkflowUsers";
import { useParams } from "react-router-dom";
import { ATOSuccessModal } from "../../../Components/ATOSuccessModal";

export const Accounting = () => {
  const config = useConfig();
  const user = useATOAuth();
  const params = useParams();
  const custId = parseInt(params.id ?? "");
  const amClient = useAMClient(config.adminUrl, user, custId);
  const amClientConfig = useAMClient(config.practiceConfigUrl, user, custId);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<AdvancedWorkflowUser[]>([]);
  const [defaultWorkflow, setDefaultWorkflow] = useState<AdvancedWorkflow>();
  const setModal = useSetRecoilState(AppModalAtom);
  const setGroupUsers = (userType: ClientGroupUserType, userIds: number[]) => {
    pendingUserChanges.setChange(userType + "", userIds);
  };
  const [unmodifiedAccountingSettings, setUnmodifiedAccountingSettings] = useState<AccountingSettingsDisplay>();
  const pendingConfigChanges = useSinglePendingChanges<AccountingSettingsDisplay>();
  const pendingUserChanges = useSinglePendingChanges<Record<string, number[]>>();
  const clientGroupUsers = pendingUserChanges.applyChanges(defaultWorkflow?.clientGroupUsers ?? {});
  const accountingSettings = pendingConfigChanges.applyChanges(unmodifiedAccountingSettings ?? {});
  const hasConfigChanges = pendingConfigChanges.listChanges().length > 0;
  const hasUserChanges = pendingUserChanges.listChanges().length > 0;

  const handleError = (ex: any) => {
    setModal(<ATODefaultAPIErrorModal error={{ message: ex.message }} onButton={() => setModal(undefined)} />);
  };

  const getAccountingSettings = async () => {
    try {
      const result = await amClientConfig.accountingHandler.getAccountingSettings({});
      setUnmodifiedAccountingSettings(result.data ?? {});
    } catch (ex) {
      handleError(ex);
    }
  };

  const getDefaultWorkflow = async () => {
    try {
      const resp = await amClient.practiceWorkflows.listAdvancedWorkflows({ custId });
      const defaultWorkflow = (resp.data ?? []).find((workflow) => workflow.clientGroupName === "DEFAULT");
      setDefaultWorkflow(defaultWorkflow);
    } catch (ex) {
      handleError(ex);
    }
  };

  const getWorkflowUsers = async () => {
    try {
      const resp = await amClient.practiceWorkflows.getAdvancedWorkflowUsers({ custId });
      setUsers(resp.data ?? []);
    } catch (ex) {
      handleError(ex);
    }
  };

  useEffect(() => {
    if (!user.isLoading) {
      setIsLoading(true);
      Promise.all([getAccountingSettings(), getDefaultWorkflow(), getWorkflowUsers()]).finally(() =>
        setIsLoading(false)
      );
    }
  }, [user]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const updatePromises = [];

    if (hasConfigChanges) {
      const updateAccountingSettingsPromise = await amClientConfig.accountingHandler
        .updateAccountingSettings({ accountingSettings: { ...accountingSettings } })
        .then((res) => {
          setUnmodifiedAccountingSettings(res.data);
          pendingConfigChanges.removeAllChanges();
          setModal(<ATOSuccessModal setModal={setModal} />);
        })
        .catch((ex) => {
          handleError(ex);
        });
      updatePromises.push(updateAccountingSettingsPromise);
    }

    if (hasUserChanges) {
      const updateRequest: AdvancedWorkflowUpdateRequest = {
        custId: custId,
        clientGroupId: defaultWorkflow?.clientGroupId,
        clientGroupUsers: clientGroupUsers,
      };

      const saveWorkflowPromise = await amClient.practiceWorkflows
        .saveWorkflow({ request: updateRequest })
        .then((resp) => {
          setModal(<ATOSuccessModal setModal={setModal} />);
        })
        .catch((ex) => {
          handleError(ex);
        });
      updatePromises.push(saveWorkflowPromise);
    }

    await Promise.all(updatePromises).finally(() => {
      setIsLoading(false);
    });
  };

  if (isLoading || user.isLoading) {
    return <ATOLoading />;
  }

  return (
    <div className="flex h-full w-full flex-col gap-3 overflow-hidden p-3">
      <div className="grid w-full flex-grow">
        <div className="grid w-full grid-cols-3">
          <div className="flex flex-col gap-2">
            <label className="flex items-center text-primary">
              <b>Notice of Assessment (NOA) Tolerance Value</b>
              <Tooltip className="ml-2 h-min w-min">
                <div className="flex h-0 -translate-y-1/2 items-center justify-center pl-2 text-white">
                  <div className="h-4 w-4 rotate-45 bg-black" />
                  <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                    Maximum allowed variation between an estimate and return amount that ATOmate used to determine if a
                    Notice of Assessment is considered accurate.
                  </p>
                </div>
              </Tooltip>
            </label>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faSvg.faDollarSign} className="text-primary" />
              <ATOTextBox
                hasChange={pendingConfigChanges.hasChange("noaTolerance")}
                value={accountingSettings?.noaTolerance?.toString() ?? ""}
                className="w-1/4"
                onChange={(val) => pendingConfigChanges.setChange("noaTolerance", val)}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <label className="flex items-center text-primary">
              <b>Document Overdue After</b>
              <Tooltip className="ml-2 h-min w-min">
                <div className="flex h-0 -translate-y-1/2 items-center justify-center pl-2 text-white">
                  <div className="h-4 w-4 rotate-45 bg-black" />
                  <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                    Document overdue after
                  </p>
                </div>
              </Tooltip>
            </label>
            <div className="flex items-center gap-2">
              <ATOTextBox
                hasChange={pendingConfigChanges.hasChange("documentOverdueAfterInHour")}
                value={accountingSettings?.documentOverdueAfterInHour?.toString() ?? "0"}
                className="w-1/4"
                onChange={(val) => pendingConfigChanges.setChange("documentOverdueAfterInHour", val)}
              />
              Hours
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <label className="flex items-center text-primary">
              <b>Advanced Workflow</b>
              <Tooltip className="ml-2 h-min w-min">
                <div className="flex h-0 -translate-y-1/2 items-center justify-center pl-2 text-white">
                  <div className="h-4 w-4 rotate-45 bg-black" />
                  <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                    Allows for documents to be assigned to specific users\groups rather than single approval & review
                    queues. For more information click Help.
                  </p>
                </div>
              </Tooltip>
            </label>
            <ATOSwitch
              hasChange={pendingConfigChanges.hasChange("isClientGroupActive")}
              value={accountingSettings?.isClientGroupActive ?? false}
              onChange={(val) => pendingConfigChanges.setChange("isClientGroupActive", val)}
            />
          </div>
        </div>

        {!accountingSettings?.isClientGroupActive && (
          <div className="flex w-full flex-col gap-4 pt-2">
            <div className="grid h-full w-full grid-cols-3 gap-2 border-t">
              <div className="flex flex-col gap-2">
                <DefaultWorkflowUsers
                  users={users}
                  clientGroupUsers={clientGroupUsers}
                  userType={ClientGroupUserType.Approver}
                  label="Approver"
                  setGroupUsers={setGroupUsers}
                />
              </div>
              <div className="flex flex-col gap-2">
                <DefaultWorkflowUsers
                  users={users}
                  clientGroupUsers={clientGroupUsers}
                  userType={ClientGroupUserType.Reviewer}
                  label="Reviewer"
                  setGroupUsers={setGroupUsers}
                />
              </div>
              <div className="flex flex-col gap-2">
                <DefaultWorkflowUsers
                  users={users}
                  clientGroupUsers={clientGroupUsers}
                  userType={ClientGroupUserType.SenderOptional}
                  label="Optional Sender"
                  setGroupUsers={setGroupUsers}
                />
              </div>
            </div>

            <div className="grid h-full w-full grid-cols-1 gap-2">
              <div className="flex flex-col gap-2">
                <label className="flex items-center text-primary">
                  <b>Show client group on the queues</b>
                </label>
                <ATOSwitch
                  hasChange={pendingConfigChanges.hasChange("showClientGroupWhenAWisOff")}
                  value={accountingSettings?.showClientGroupWhenAWisOff ?? false}
                  onChange={(val) => pendingConfigChanges.setChange("showClientGroupWhenAWisOff", val)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="mt-auto flex w-full justify-end gap-4 border-t px-4 pt-3">
          <ATOButton buttonType={ButtonType.Confirm} onClick={handleSubmit}>
            Update
          </ATOButton>

          <ATOButton
            buttonType={ButtonType.Warning}
            onClick={() => {
              pendingConfigChanges.removeAllChanges();
              pendingUserChanges.removeAllChanges();
            }}
            disabled={!hasConfigChanges && !hasUserChanges}
          >
            Reset
          </ATOButton>
        </div>
      </div>
    </div>
  );
};
