/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_IEnumerable_PlaceholderWithOperators_ } from '../models/ApiResponse_IEnumerable_PlaceholderWithOperators_';
import type { ApiResponse_string_1 } from '../models/ApiResponse_string_1';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PlaceholderTool {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_string_1 Success
     * @throws ApiError
     */
    public replacePlaceholder(
        formData: {
            template?: string;
            data?: Record<string, string>;
        },
    ): CancelablePromise<ApiResponse_string_1> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PlaceholderTool/ReplacePlaceholder',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_PlaceholderWithOperators_ Success
     * @throws ApiError
     */
    public getValidPlaceholders(
        formData: {
            template?: string;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_PlaceholderWithOperators_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PlaceholderTool/GetValidPlaceholders',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
