import * as faSvg from "@fortawesome/free-solid-svg-icons";

import ATOHeader from "../Components/ATOHeader";
import { NavLink, Route, Routes } from "react-router-dom";
import Practices from "./Practices/Practices";
import { ATOSpinner } from "../Components/ATOSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Templates } from "./Templates/Templates";
import { useATOAuth } from "../Hooks/ATOAuthHook";
import { Tools } from "./Tools/Tools";
import { ELearning } from "./Tools/ELearning/ELearning";

const routes = [
  { name: "Practices", icon: faSvg.faSuitcase, path: "/Practices", scope: "practice" },
  { name: "Templates", icon: faSvg.faEnvelope, path: "/Templates", scope: "bawadmin" },
  // { name: "Settings", icon: faSvg.faClipboard, path: "/Settings" },
  { name: "Tools", icon: faSvg.faTools, path: "/Tools", scope: "bawadmin" },
  // { name: "Billing", icon: faSvg.faMoneyCheckDollar, path: "/billing" }
];

const BawAdmin = () => {
  const user = useATOAuth();

  if (user.isLoading) {
    return <ATOSpinner />;
  }

  return (
    <div className="flex h-screen w-screen flex-col">
      <ATOHeader items={routes} />
      <div className="h-full overflow-auto">
        <Routes>
          <Route path="/*" element={<Tiles />} />
          <Route path="/Practices/*" element={<Practices />} />
          <Route path="/Templates/*" element={<Templates />} />
          <Route path="/Tools/*" element={<Tools />} />
          <Route path="/Profile/*" element={<>Profile</>} />
        </Routes>
      </div>
    </div>
  );
};

const Tiles = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex w-fit gap-2 bg-white p-8 shadow-lg">
        {routes.map((r) => (
          <NavLink
            key={r.name}
            to={r.path}
            className="flex h-32 w-32 flex-col items-center justify-around rounded-md border-2 transition-colors hover:bg-gray-100"
          >
            <FontAwesomeIcon icon={r.icon} className="text-5xl" />
            <p className="uppercase">{r.name}</p>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default BawAdmin;
