import React, { useEffect, useRef, cloneElement } from "react";

interface Props {
  onClick(e: MouseEvent): void;
  children: React.ReactElement;
}

export const ATOClickOutside = ({ children, onClick }: Props) => {
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const handleClickOutside = (e: MouseEvent) => {
      if (onClick && !ref?.current?.contains(e.target as Node)) {
        onClick(e);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClick]);

  return cloneElement(children, { ref });
};
