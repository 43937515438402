export const transformUnderlineToNormal = (text: string = "") => {
  return text.split("_").join(" ");
};

export const camelCaseSpace = (text: string = "") => {
  return text.replace(/([a-z])([A-Z])/g, "$1 $2");
};
export const capitalizeFirstLetter = (text: string = "") => {
  if (text.length === 0) {
    return text; // Return an empty string if input is empty
  }

  const firstLetter = text.charAt(0).toUpperCase();
  const restOfString = text.slice(1);

  return firstLetter + restOfString;
};
