/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_bool_ } from '../models/ApiResponse_bool_';
import type { ApiResponse_List_CategoryModuleViewModel_ } from '../models/ApiResponse_List_CategoryModuleViewModel_';
import type { CategoryModuleViewModel } from '../models/CategoryModuleViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ELearningHandler {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_bool_ Success
     * @throws ApiError
     */
    public isELearningModuleEnabled(
        formData: any,
    ): CancelablePromise<ApiResponse_bool_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ELearningHandler/IsELearningModuleEnabled',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_List_CategoryModuleViewModel_ Success
     * @throws ApiError
     */
    public getModules(
        formData: any,
    ): CancelablePromise<ApiResponse_List_CategoryModuleViewModel_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ELearningHandler/GetModules',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_List_CategoryModuleViewModel_ Success
     * @throws ApiError
     */
    public getAllModules(
        formData: any,
    ): CancelablePromise<ApiResponse_List_CategoryModuleViewModel_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ELearningHandler/GetAllModules',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_bool_ Success
     * @throws ApiError
     */
    public eLearningModuleStatistic(
        formData: {
            categoryId?: number;
            categoryName?: string;
            moduleId?: number;
            moduleName?: string;
        },
    ): CancelablePromise<ApiResponse_bool_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ELearningHandler/ELearningModuleStatistic',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_List_CategoryModuleViewModel_ Success
     * @throws ApiError
     */
    public saveAllModules(
        formData: {
            categories?: Array<CategoryModuleViewModel>;
        },
    ): CancelablePromise<ApiResponse_List_CategoryModuleViewModel_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ELearningHandler/SaveAllModules',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
