/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_AdminTemplateResponse_ } from '../models/ApiResponse_AdminTemplateResponse_';
import type { ApiResponse_bool_ } from '../models/ApiResponse_bool_';
import type { ApiResponse_IEnumerable_CustomerDisplay_ } from '../models/ApiResponse_IEnumerable_CustomerDisplay_';
import type { ApiResponse_Void_ } from '../models/ApiResponse_Void_';
import type { CommunicationDraftTemplateViewModel } from '../models/CommunicationDraftTemplateViewModel';
import type { TemplateAppSearchDisplay } from '../models/TemplateAppSearchDisplay';
import type { TemplateVariant } from '../models/TemplateVariant';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class Template {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_bool_ Success
     * @throws ApiError
     */
    public toggleDocTypeActiveStatus(
        formData: {
            docId?: number;
        },
    ): CancelablePromise<ApiResponse_bool_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Template/ToggleDocTypeActiveStatus',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_bool_ Success
     * @throws ApiError
     */
    public toggleDocConditionActiveStatus(
        formData: {
            docCondId?: number;
        },
    ): CancelablePromise<ApiResponse_bool_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Template/ToggleDocConditionActiveStatus',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_AdminTemplateResponse_ Success
     * @throws ApiError
     */
    public getTemplate(
        formData: {
            docCondId?: number;
            draft?: boolean;
            variant?: TemplateVariant;
        },
    ): CancelablePromise<ApiResponse_AdminTemplateResponse_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Template/GetTemplate',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_Void_ Success
     * @throws ApiError
     */
    public saveDraft(
        formData: {
            args?: CommunicationDraftTemplateViewModel;
        },
    ): CancelablePromise<ApiResponse_Void_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Template/SaveDraft',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_Void_ Success
     * @throws ApiError
     */
    public publishTemplate(
        formData: {
            docCondId?: number;
            variant?: TemplateVariant;
        },
    ): CancelablePromise<ApiResponse_Void_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Template/PublishTemplate',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public dummy(
        formData: {
            templateAppSearchDisplay?: TemplateAppSearchDisplay;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Template/Dummy',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_CustomerDisplay_ Success
     * @throws ApiError
     */
    public getCustomerList(
        formData: {
            searchText?: string;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_CustomerDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Template/GetCustomerList',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public copyTemplates(
        formData: {
            sourcePracticeId?: number;
            copyToPracticeId?: number;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Template/CopyTemplates',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
