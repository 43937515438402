/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * Superuser(10)
 * User(11)
 * PrimarySuperuser(12)
 */
export enum ATOmateUserType {
    Superuser = 10,
    User = 11,
    PrimarySuperuser = 12,
}
