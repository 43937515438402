import { SetterOrUpdater, useSetRecoilState } from "recoil"
import { ATOModalDouble } from "./ATOModalSingleDouble"
import { Blocker } from "@remix-run/router"
import { BlockerAtom } from "../../App";

export const ATOUnsavedChangesModal = ({ blocker, setModal }: { blocker: Blocker, setModal: SetterOrUpdater<React.ReactNode> }) => {
    const setIsBlocked = useSetRecoilState(BlockerAtom);

    return <ATOModalDouble
        className="p-2"
        title="Unsaved Changes"
        message="Are you sure you want to leave, you have unsaved changes"
        onClose={() => {
            setModal(undefined);
            blocker.reset?.();
        }}

        leftButton="cancel"
        onLeftButton={() => {
            setModal(undefined);
            blocker.reset?.();
        }}

        rightButton="ok"
        onRightButton={() => {
            blocker.proceed?.()
            setModal(undefined);
            setIsBlocked(false);
        }}
    />
}   