import { ReactNode, useCallback, useState } from "react";
import { AMClient } from "../../../generated";
import { ATOModal } from "../../../Components/ATOModal";
import { ATOTextBox } from "../../../Components/ATOTextBox";
import { ATOSpinner } from "../../../Components/ATOSpinner";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { ViewDomainDetailsModal } from "./ViewDomainDetailsModal";

export const EditModal = (props: {
  amClient: AMClient;
  title: string;
  validateInput: boolean;
  nullable: boolean;
  initialValue: string;
  setModal: (val: ReactNode) => void;
  onConfirm: (inputValue: string, showDomainDetailsModal: boolean, updatedInputValue?: string | null) => void;
}) => {
  const [currentValue, setCurrentValue] = useState<string>(props.initialValue);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [needsToVerifyOwnership, setNeedsToVerifyOwnership] = useState<boolean>(false);
  const [needsToCreateDomain, setNeedsToCreateDomain] = useState<boolean>(false);
  const [needsToVerifyDomain, setNeedsToVerifyDomain] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const extractDomain = (emailAddress: string | null): string | null => {
    if (!emailAddress) return null;

    const parts = emailAddress.split("@");
    if (parts.length < 2) return null;

    return parts[1];
  };

  const isValidEmailAddress = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleVerifyOwnerhip = useCallback(() => {
    setIsLoading(true);
    let emailAddress = currentValue;
    let domain = extractDomain(emailAddress);
    props.amClient.communicationHandler
      .verifyOwnership({ domainName: domain ?? "" })
      .then((resp) => {
        setIsLoading(false);
        props.onConfirm(props.initialValue, true, extractDomain(currentValue));
      })
      .catch((err) => setError(err.message));
  }, [currentValue, props]);

  const handleCreate = useCallback(() => {
    setIsLoading(true);
    let emailAddress = currentValue;
    let domain = extractDomain(emailAddress);
    props.amClient.communicationHandler
      .createSendgridDomain({ domainName: domain ?? "" })
      .then((res) => {
        props.onConfirm(props.initialValue, true, extractDomain(currentValue));
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [currentValue, props]);

  const resetValidation = useCallback(() => {
    setError(null);
    setNeedsToCreateDomain(false);
    setNeedsToVerifyOwnership(false);
    setNeedsToVerifyDomain(false);
  }, []);

  const handleConfirm = useCallback(() => {
    resetValidation();

    const isEmpty = currentValue === null || currentValue.trim() === "";

    if (isEmpty) {
      if (!props.nullable) {
        return setError("Field cannot be empty");
      }
      return props.onConfirm("", false);
    }

    if (props.validateInput) {
      if (!isValidEmailAddress(currentValue)) {
        return setError("Invalid email address");
      }

      const domain = extractDomain(currentValue);
      setIsLoading(true);

      props.amClient.communicationHandler
        .checkSendgridDomainOwnershipAndAvailability({ domainName: domain ?? "" })
        .then(({ data }) => {
          if (data?.isDomainAvailable) {
            setNeedsToCreateDomain(true);
          } else if (!data?.isDomainMappedToCust) {
            setNeedsToVerifyOwnership(true);
          } else if (!data.hasPermissionOverDomain) {
            setNeedsToVerifyDomain(true);
          } else {
            props.onConfirm(currentValue, false);
          }
        })
        .catch((err) => setError(err.message))
        .finally(() => setIsLoading(false));
    } else {
      props.onConfirm(currentValue, false);
    }
  }, [currentValue, props, resetValidation]);

  const handleOnchange = useCallback(
    (val: string) => {
      if (props.validateInput) {
        setCurrentValue(val.toLocaleLowerCase());
      } else {
        setCurrentValue(val);
      }
    },
    [props]
  );

  return (
    <ATOModal
      className="h-[min(40vh,200px)] w-[min(60vw,800px)]"
      title={`Edit ${props.title}`}
      onClose={() => props.setModal(undefined)}
      footerContent={
        <div className="flex justify-around">
          {!needsToCreateDomain && !needsToVerifyDomain && !needsToVerifyOwnership && (
            <ATOButton
              buttonType={ButtonType.Confirm}
              className="items-center"
              onClick={handleConfirm}
              disabled={isLoading}
            >
              Confirm
            </ATOButton>
          )}
          {needsToCreateDomain && (
            <ATOButton
              buttonType={ButtonType.Primary}
              className="items-center"
              onClick={handleCreate}
              disabled={isLoading}
            >
              Create
            </ATOButton>
          )}
          {needsToVerifyOwnership && (
            <ATOButton
              buttonType={ButtonType.Primary}
              className="items-center"
              onClick={handleVerifyOwnerhip}
              disabled={isLoading}
            >
              Verify Ownership
            </ATOButton>
          )}
          {needsToVerifyDomain && (
            <ATOButton
              buttonType={ButtonType.Primary}
              className="items-center"
              onClick={() => {
                props.setModal(
                  <ViewDomainDetailsModal
                    domainName={extractDomain(currentValue)}
                    amClient={props.amClient}
                    setModal={props.setModal}
                    onClose={() => props.setModal(undefined)}
                  />
                );
              }}
              disabled={isLoading}
            >
              Verify Domain
            </ATOButton>
          )}
          <ATOButton buttonType={ButtonType.Error} onClick={() => props.setModal(undefined)}>
            Cancel
          </ATOButton>
        </div>
      }
    >
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center">
          {isLoading ? (
            <div className="flex h-full w-full items-center justify-center">
              <ATOSpinner />
            </div>
          ) : (
            <>
              <div className="flex w-full items-center justify-center">
                <ATOTextBox value={currentValue} onChange={handleOnchange} className="w-full" />
              </div>
              <div className="mt-2 w-full text-start text-red-500">
                {error && <div>{error}</div>}
                {needsToVerifyDomain && (
                  <div className="m-2 flex flex-col items-center text-center text-sm">
                    <p className="mb-2">Domain needs to be verified to complete the update process</p>
                  </div>
                )}
                {needsToVerifyOwnership && (
                  <div className="m-2 flex flex-col items-center text-center text-sm">
                    <p className="mb-2">
                      To change your domain, ATOmate will require to verify ownership with this domain and the domain to
                      be verified
                    </p>
                  </div>
                )}
                {needsToCreateDomain && (
                  <div className="m-2 flex flex-col items-center text-center text-sm">
                    <p className="mb-2">
                      To change your domain, ATOmate will require new DNS records and the domain to be verified
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </ATOModal>
  );
};
