import { ATOModal } from "../ATOModal";
import { ATOModalSingle } from "./ATOModalSingle";
import { getModalColour, getModalIcon, ModalTypes } from "./ATOModalTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { ATOButton, ButtonType } from "../ATOButton";

type Props = {
  title?: string;
  buttonText?: string;
  onButton: () => void;
  error: any;
};

export const ATODefaultAPIErrorModal = (props: Props) => {
  return (
    <ATOModal
      title={
        <div className="flex h-full w-full items-center gap-2">
          <FontAwesomeIcon
            icon={getModalIcon(ModalTypes.error)}
            className={getModalColour(ModalTypes.error)}
            size="lg"
          />
          <p className="flex-grow">{props.title ?? "An error has occured!"}</p>
        </div>
      }
      footerContent={
        <div className="flex w-full justify-end">
          <ATOButton className="h-6" buttonType={ButtonType.Primary} onClick={props.onButton}>
            {props.buttonText ?? "OK"}
          </ATOButton>
        </div>
      }
      onClose={props.onButton ?? (() => {})}
    >
      <div className="flex items-center justify-center p-3">{props.error?.data?.message || props.error?.message}</div>
      {props.error?.trace && <div className="px-1 text-[10px] text-gray-400">trace: {props.error?.trace}</div>}
    </ATOModal>
  );

  <ATOModalSingle
    message={
      props.error?.message ?? "Oops, an error has occured, if this issue persists please contact ATOmate support."
    }
    title={props.title ?? "An error has occured!"}
    type={ModalTypes.error}
    buttonText={props.buttonText ?? "OK"}
    onButton={props.onButton}
  />;
};
