/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_IEnumerable_string_ } from '../models/ApiResponse_IEnumerable_string_';
import type { ApiResponse_KCPracticeInfo_ } from '../models/ApiResponse_KCPracticeInfo_';
import type { ApiResponse_UnknownClientOptions_ } from '../models/ApiResponse_UnknownClientOptions_';
import type { KCPracticeInfo } from '../models/KCPracticeInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class KloudConnectActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_KCPracticeInfo_ Success
     * @throws ApiError
     */
    public getKloudConnectPracticeInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_KCPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/KloudConnectActions/GetKloudConnectPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_KCPracticeInfo_ Success
     * @throws ApiError
     */
    public updateKloudConnectPracticeInfo(
        formData: {
            practiceInfo?: KCPracticeInfo;
        },
    ): CancelablePromise<ApiResponse_KCPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/KloudConnectActions/UpdateKloudConnectPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_KCPracticeInfo_ Success
     * @throws ApiError
     */
    public disconnectKloudConnect(
        formData: any,
    ): CancelablePromise<ApiResponse_KCPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/KloudConnectActions/DisconnectKloudConnect',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_KCPracticeInfo_ Success
     * @throws ApiError
     */
    public createKloudConnectUnknownClient(
        formData: {
            divisionId?: number;
            partnerId?: string;
        },
    ): CancelablePromise<ApiResponse_KCPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/KloudConnectActions/CreateKloudConnectUnknownClient',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_UnknownClientOptions_ Success
     * @throws ApiError
     */
    public getUserSelectionOptions(
        formData: any,
    ): CancelablePromise<ApiResponse_UnknownClientOptions_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/KloudConnectActions/GetUserSelectionOptions',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_string_ Success
     * @throws ApiError
     */
    public getSiteMetadataOptions(
        formData: {
            siteName?: string;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_string_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/KloudConnectActions/GetSiteMetadataOptions',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
