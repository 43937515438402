import { useSetRecoilState } from "recoil";
import { TypedObject } from "../../../Helpers/TypedObject";
import { buildAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { Config } from "../../../Models/Config";
import { AMClient, CustomerContactDisplay } from "../../../generated";

import { PracticeDetailBillingContacts } from "../Detail/Contacts/PracticeDetailContacts";
import { SetupComponent } from "./SetupComponent";
import { AppModalAtom, BlockerAtom } from "../../../App";
import { useEffect, useState } from "react";
import { SinglePendingChanges, useSinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";
import { useNavigate } from "react-router-dom";

const PracticeCreationContactsValidationRules = {} as {
  [index in keyof CustomerContactDisplay]: (data: CustomerContactDisplay) => boolean;
};

const saveCustomerContacts = async (
  getAmClient: (getUrl: (cfg: Config) => string) => AMClient,
  custId: number,
  data: CustomerContactDisplay[]
) => {
  const amClient = getAmClient((cfg) => cfg.adminUrl);

  // await amClient.practiceDetails.savePracticeDetails({ detail: data }).then((resp) => {});
};

const getCustomerContacts = async (getAmClient: (getUrl: (cfg: Config) => string) => AMClient, custId: number) => {
  const amClient = getAmClient((cfg) => cfg.adminUrl);

  const dbData = (await amClient.contactDetails.getCustomerContacts({ custId })).data;

  return dbData ?? [];
};

export const PracticeContactsComponent: SetupComponent<CustomerContactDisplay[]> = {
  defaultData: getCustomerContacts,
  validateData: (data) =>
    data.map((d) =>
      TypedObject.map(PracticeCreationContactsValidationRules, (key) =>
        PracticeCreationContactsValidationRules[key]!(d)
      )
    ),
  saveData: saveCustomerContacts,
  saveOrder: 3,
  Element: (props) => <PracticeDetailBillingContacts custId={props.custId} contacts={props.data} />, //9001436
};

export const PracticeContactsComponentWrapper = ({ custId }: { custId: number }) => {
  const user = useATOAuth();
  const config = useConfig();
  const setIsBlocked = useSetRecoilState(BlockerAtom);
  const setModal = useSetRecoilState(AppModalAtom);
  const navigate = useNavigate();

  const getAmClient = (getUrl: (cfg: Config) => string, custId?: number) => buildAMClient(getUrl(config), user, custId);

  const [defaultData, setDefaultData] = useState<any>(undefined);

  const loadData = () => {
    if (typeof PracticeContactsComponent.defaultData === "function") {
      const func = PracticeContactsComponent.defaultData as (
        getAmClient: (getUrl: (cfg: Config) => string) => AMClient,
        custId: number
      ) => Promise<any>;

      func(getAmClient, custId)
        .then((data) => setDefaultData(data))
        .catch((ex) =>
          setModal(
            <ATODefaultAPIErrorModal
              error={ex}
              onButton={() => {
                setModal(undefined);
                navigate("../");
              }}
            />
          )
        );
    } else {
      setDefaultData(PracticeContactsComponent.defaultData);
    }
  };

  useEffect(loadData, []);

  const PracticeContactsComponentElement = PracticeContactsComponent.Element;
  const pendingChanges = useSinglePendingChanges<CustomerContactDisplay[]>();

  const hasChanges = pendingChanges.listChanges().length > 0;

  useEffect(() => {
    setIsBlocked(hasChanges);
  }, [hasChanges]);

  return (
    <PracticeContactsComponentElement
      custId={custId}
      data={defaultData}
      pendingChanges={pendingChanges}
      key={`PracticeContactsComponentWrapper-${custId}`}
    />
  );
};
