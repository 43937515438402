import React from "react";
import { ClientGroupUserType, AdvancedWorkflowUser } from "../../../../generated";
import { ATOMutliSelect } from "../../../../Components/ATOMutliSelect";

export const WorkflowEditUsers = ({
  userType,
  clientGroupUsers,
  users,
  label,
  clientGroupName,
  single,
  setGroupUsers,
  inline,
}: {
  userType: ClientGroupUserType;
  clientGroupUsers?: Record<string, number[]> | null | undefined;
  users: AdvancedWorkflowUser[];
  label?: string;
  clientGroupName?: string;
  single?: boolean;
  setGroupUsers: (userType: ClientGroupUserType, userIds: number[]) => any;
  inline?: boolean;
}) => {
  let allUsers = clientGroupUsers?.[userType] ?? [];

  const singatureRequired = userType === ClientGroupUserType.Sender || userType === ClientGroupUserType.SenderOptional;
  if (singatureRequired) {
    users = users.sort((a, b) => (a.hasSignature ? -1 : 0));
  }

  if (userType === ClientGroupUserType.Sender) {
    users = [{ userId: 100, firstName: "Default", lastName: "Sender", hasSignature: true }, ...users];
  } else if (userType === ClientGroupUserType.Postal) {
    users = [{ userId: 200, firstName: "Default", lastName: "Postal Manager", hasSignature: true }, ...users];
  }

  const getSelectedOptions = (userType: ClientGroupUserType) => {
    var selectedUserIds = allUsers ?? [];
    const selectableUsers = users.filter((user) => selectedUserIds.includes(user.userId!));

    return selectableUsers;
  };

  const getSelectableOptions = (search: string, userType: ClientGroupUserType) => {
    var selectedUserIds = allUsers ?? [];
    const selectableUsers = users.filter((user) => !selectedUserIds.includes(user.userId!));

    search = search.toLowerCase();
    return selectableUsers.filter(
      (user) =>
        user.firstName?.toLowerCase().includes(search) ||
        user.lastName?.toLowerCase().includes(search) ||
        user.email?.toLowerCase().includes(search) ||
        search === ""
    );
  };

  const selectOption = (item: AdvancedWorkflowUser) => {
    if (single) {
      setGroupUsers(userType, [item.userId!]);
      return;
    }

    var selectedUser = allUsers;
    if (allUsers?.includes(item.userId!)) {
      selectedUser = allUsers.filter((u) => u !== item.userId);
    } else {
      selectedUser = [item.userId!, ...(allUsers ?? [])];
    }

    setGroupUsers(userType, selectedUser);
  };

  if (inline) {
    return (
      <EditWorkflowUsersInCell
        userType={userType}
        clientGroupName={clientGroupName!}
        getSelectableOptions={getSelectableOptions}
        getSelectedOptions={getSelectedOptions}
        selectOption={selectOption}
      />
    );
  }

  return (
    <WorkflowEditUsersInForm
      userType={userType}
      label={label!}
      getSelectableOptions={getSelectableOptions}
      getSelectedOptions={getSelectedOptions}
      selectOption={selectOption}
    />
  );
};

export const EditWorkflowUsersInCell = ({
  clientGroupName,
  userType,
  isSetup,
  getSelectableOptions,
  getSelectedOptions,
  selectOption,
}: {
  clientGroupName: string;
  userType: ClientGroupUserType;
  isSetup?: boolean;
  getSelectableOptions: (search: string, userType: ClientGroupUserType) => AdvancedWorkflowUser[];
  getSelectedOptions: (userType: ClientGroupUserType) => AdvancedWorkflowUser[];
  selectOption: (item: AdvancedWorkflowUser) => any;
}) => {
  if (clientGroupName === "SMSF" && userType === ClientGroupUserType.Approver) {
    return <>N/A</>;
  }

  return (
    <div className=" p-[2px]">
      <ATOMutliSelect<AdvancedWorkflowUser>
        getOptions={(search) => getSelectableOptions(search, userType)}
        selectedOptions={() => getSelectedOptions(userType)}
        onItemClick={(item) => selectOption(item)}
        getDataKey={(user) => `${user?.firstName} ${user?.lastName} `}
        getItemDisplay={(user) => {
          return (
            <>
              {user?.firstName}&nbsp;
              {user?.lastName}
              {user?.email !== undefined && <span> &lt;{user?.email}&gt;</span>}
            </>
          );
        }}
        isItemDisabled={(user) => {
          if (userType == ClientGroupUserType.Sender || userType == ClientGroupUserType.Postal) {
            return user.hasSignature === false;
          }

          return false;
        }}
        disabledItemMessage="(This user is missing an email signature.)"
      />
    </div>
  );
};

export const WorkflowEditUsersInForm = ({
  userType,
  label,
  getSelectableOptions,
  getSelectedOptions,
  selectOption,
}: {
  userType: ClientGroupUserType;
  label: string;
  getSelectableOptions: (search: string, userType: ClientGroupUserType) => AdvancedWorkflowUser[];
  getSelectedOptions: (userType: ClientGroupUserType) => AdvancedWorkflowUser[];
  selectOption: (item: AdvancedWorkflowUser) => any;
}) => {
  return (
    <React.Fragment>
      <div>{label}</div>
      <div className=" p-[2px]">
        <ATOMutliSelect<AdvancedWorkflowUser>
          getOptions={(search) => getSelectableOptions(search, userType)}
          selectedOptions={() => getSelectedOptions(userType)}
          onItemClick={(item) => selectOption(item)}
          getDataKey={(user) =>
            `${user?.firstName} ${user?.lastName} ${user?.email !== undefined ? `<${user?.email}>` : ""}`
          }
          getItemDisplay={(user) => {
            return (
              <>
                {user?.firstName}&nbsp;
                {user?.lastName}
                {user?.email !== undefined && <span> &lt;{user?.email}&gt;</span>}
              </>
            );
          }}
          isItemDisabled={(user) => {
            if (userType == ClientGroupUserType.Sender || userType == ClientGroupUserType.Postal) {
              return user.hasSignature === false;
            }

            return false;
          }}
          disabledItemMessage="(This user is missing an email signature.)"
        />
      </div>
    </React.Fragment>
  );
};
