import { ATOCheckBox } from "../../Components/ATOCheckBox";
import { ATOTextBox } from "../../Components/ATOTextBox";
import { AppSearchColumnInfo } from "../../Components/AppSearch/Models/AppSearchColumnInfo";
import { PracticeListingDisplay } from "./PracticeListing";

interface Props {
  isHeader?: boolean;
  colInfo: AppSearchColumnInfo<PracticeListingDisplay>[];
  data?: PracticeListingDisplay;
}

export const PracticeListingRow = ({ isHeader, colInfo, data }: Props) => {
  return (
    <div className={`flex w-full ${isHeader ? "bg-black" : ""}`}>
      {colInfo.map((ci) => {
        let display = <>{ci.name}</>;

        if (!isHeader) {
          switch (ci.dataType) {
            case "text":
            case "textbox":
              display = (
                <div className="flex h-full w-full items-center justify-center overflow-hidden whitespace-nowrap">
                  {data![ci.key]}
                </div>
              );
              break;
            case "checkbox":
              display = (
                <div className="flex h-full w-full items-center justify-center">
                  <ATOCheckBox name={data?.id?.toString()} value={data![ci.key]?.toString()} enabled={ci.disabled} />
                </div>
              );
              break;
            default:
              display = <div className="flex h-full w-full items-center justify-center">{ci.dataType(data!, ci)}</div>;
              break;
          }
        }

        return (
          <div
            key={ci.name}
            style={ci.widthProperty ? { width: ci.widthProperty } : { flex: "1" }}
            className="mx-2 flex h-8 min-w-0 items-center justify-center overflow-ellipsis py-0.5 text-center transition-all"
          >
            {display}
          </div>
        );
      })}
    </div>
  );
};
