import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AdminTemplateResponse, AMClient, TemplateVariant } from "../../generated";
import { useATOAuth } from "../../Hooks/ATOAuthHook";
import { useConfig } from "../../Hooks/UseConfigHook";
import { ATOLoading } from "../../Components/ATOSpinner";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../App";
import { ATODefaultAPIErrorModal } from "../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOButton, ButtonType } from "../../Components/ATOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { ATOTextBox } from "../../Components/ATOTextBox";
import { Editor } from "@tinymce/tinymce-react";
import { ATOCheckBox } from "../../Components/ATOCheckBox";

export const TemplateDetailNavigation = () => {
  const params = useParams();
  const user = useATOAuth();
  const config = useConfig();
  const setModal = useSetRecoilState(AppModalAtom);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiClient, setApiClient] = useState<AMClient>();
  const [template, setTemplate] = useState<AdminTemplateResponse>();
  const [templateBody, setTemplateBody] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateVariant, setTemplateVariant] = useState(TemplateVariant.Email);

  const docCondId = parseInt(params.id ?? "");

  useEffect(() => {
    if (!user.isLoading) {

      const client = new AMClient({
        BASE: config.adminUrl,
        VERSION: "2",
        TOKEN: user.getAccessToken,
      });

      setApiClient(client);
    }
  }, [user]);

  useEffect(() => {
    loadTemplate(TemplateVariant.Email);
  }, [apiClient]);

  const loadTemplate = (variant: TemplateVariant) => {
    setIsLoading(true);
    setTemplateVariant(variant);

    apiClient?.template
      .getTemplate({
        docCondId: docCondId,
        draft: true,
        variant: variant,
      })
      .then((resp) => {
        setTemplate(resp.data);
        setTemplateSubject(resp.data?.subject + "");
        setTemplateBody(resp.data?.body + "");
        setIsLoading(false);
      })
      .catch((ex) =>
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
            }}
          />
        )
      );
  };

  if (isLoading || user.isLoading) {
    return <ATOLoading />;
  }

  return (
    <div className="flex h-full flex-col">
      <div className="overflow-y-scroll px-5">
        <div className="flex pt-5">
          <div className="mr-0 flex flex-col">
            <Link to={`/Templates/`} className="">
              <ATOButton className="h-8" buttonType={ButtonType.Primary}>
                <FontAwesomeIcon icon={faSvg.faArrowLeft} className="text-lg" />
                &nbsp; Back
              </ATOButton>
            </Link>
          </div>
          <div className="ml-auto">
            <div className="flex flex-col">
              <div className="pb-1 text-center text-sm font-semibold">Template Version</div>
              <div className="w-60">
                <ul className="grid w-full md:grid-cols-2">
                  <li>
                    <input
                      type="radio"
                      id="template-email"
                      name="hosting"
                      value={TemplateVariant.Email}
                      onChange={() => loadTemplate(TemplateVariant.Email)}
                      className="peer hidden"
                      checked={templateVariant == TemplateVariant.Email}
                      required
                    />
                    <label
                      htmlFor="template-email"
                      className="inline-flex w-full cursor-pointer items-center justify-center rounded-l-lg border
                       border-gray-200 bg-white p-2 text-primary hover:bg-gray-100 hover:text-gray-600 peer-checked:bg-primary peer-checked:text-white"
                    >
                      <div className="block justify-center">
                        <div className="w-full font-semibold">Email</div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="template-portal"
                      name="hosting"
                      value={TemplateVariant.Portal}
                      onChange={() => loadTemplate(TemplateVariant.Portal)}
                      className="peer hidden"
                      checked={templateVariant == TemplateVariant.Portal}
                    />
                    <label
                      htmlFor="template-portal"
                      className="inline-flex w-full cursor-pointer items-center justify-center rounded-r-lg border
                       border-gray-200 bg-white p-2 text-primary hover:bg-gray-100 hover:text-gray-600 peer-checked:bg-primary peer-checked:text-white"
                    >
                      <div className="block">
                        <div className="w-full font-semibold">Portal</div>
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-1 pt-2 text-2xl">
          Workflow Name: <span className="font-bold"> {template?.docConditionName}</span>
        </div>
        <div className="pb-4 text-xs">
          This workflow is applied when {template?.docTypeName} has {template?.docConditionName} on it.
        </div>
        <div className="grid grid-cols-[3fr_1fr] gap-4">
          <div className="p-2 shadow-md">
            <div className="p-2 text-primary">Subject *</div>
            <div>
              <ATOTextBox onChange={setTemplateSubject} value={templateSubject} />
            </div>
            <div className="p-2 text-primary">Body *</div>
            <div>
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                value={template?.body + ""}
                onEditorChange={setTemplateBody}
                init={{
                  resize: false,
                  promotion: false,
                  statusbar: false,
                  menubar: false,
                  toolbar:
                    "undo redo | bold italic underline strikethrough |fontfamily | fontsize | forecolor backcolor | styles | searchreplace | bullist numlist | outdent indent| alignleft aligncenter alignright alignjustify | link unlink | image table charmap emoticons hr | preview removeformat",
                  toolbar_mode: "sliding",
                  plugins:
                    "fullscreen image link media codesample charmap pagebreak anchor searchreplace table emoticons preview lists",
                  font_size_formats:
                    "8pt 9pt 10pt 11pt 12pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 21pt 22pt 23pt 24pt 25pt 26pt 27pt 28pt 29pt 30pt 31pt 32pt 33pt 34pt 35pt 36pt 48pt 60pt 72pt 96pt",
                  font_family_formats:
                    "Andale Mono=andale mono,monospace;Arial=arial,helvetica,sans-serif;Arial Black=arial black,sans-serif;Arial Narrow=arial narrow;AvenirNext LT=Avenir Next LT Pro;Book Antiqua=book antiqua,palatino,serif;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier,monospace;Calibri=calibri;Century Gothic=century gothic;Franklin Gothic Book=Franklin Gothic Book;Georgia=georgia,palatino,serif;Helvetica=helvetica,arial,sans-serif;Impact=impact,sans-serif;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco,monospace;Times New Roman=times new roman,times,serif;Trebuchet MS=trebuchet ms,geneva,sans-serif;Verdana=verdana,geneva,sans-serif;",
                  content_css: process.env.PUBLIC_URL + "/tinymce/custom.css",
                  style_formats: [
                    { title: "Paragraph", format: "p" },
                    { title: "Heading 1", format: "h1" },
                    { title: "Heading 2", format: "h2" },
                    { title: "Heading 3", format: "h3" },
                    { title: "Heading 4", format: "h4" },
                    { title: "Heading 5", format: "h5" },
                    { title: "Heading 6", format: "h6" },
                    { title: "Preformatted", format: "pre" },
                  ],
                  elementpath: false,
                  contextmenu: false,
                  forced_root_block: "p",
                  entity_encoding: "raw",
                  invalid_elements: "script,applet,iframe",
                  schema: "html5",
                  browser_spellcheck: true,
                  paste_as_text: true,
                  format_empty_lines: true,
                  keep_styles: true,
                }}
              />
            </div>
            <div className="py-2">
              <ATOCheckBox
                name="Original"
                className=""
                enabled={true}
                id="1"
                key={1}
                onChange={() => {}}
                displayLabel={true}
                value={true}
              />
              Attach Original Document
            </div>
            <div className="">Attach Another Document</div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-center">
                <ATOButton className="h-8 w-80" buttonType={ButtonType.Warning}>
                  Save As Draft
                </ATOButton>
              </div>
              <div className="flex justify-center">
                <ATOButton className="h-8 w-80" buttonType={ButtonType.Primary}>
                  Preview & Publish
                </ATOButton>
              </div>
            </div>
          </div>
          <div className="p-4 shadow-md">
            <div>
              <ATOButton className="h-8 w-full" buttonType={ButtonType.Primary}>
                Preview & Publish
              </ATOButton>
            </div>
            <div>
              Client fields <br />
              Document fields <br />
              Sample file
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
