/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_VirtualCabinetPortalDisplay_ } from '../models/ApiResponse_VirtualCabinetPortalDisplay_';
import type { VirtualCabinetPortalDisplay } from '../models/VirtualCabinetPortalDisplay';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VirtualCabinetPortal {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_VirtualCabinetPortalDisplay_ Success
     * @throws ApiError
     */
    public getVcPortalSettings(
        formData: any,
    ): CancelablePromise<ApiResponse_VirtualCabinetPortalDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/VirtualCabinetPortal/GetVcPortalSettings',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_VirtualCabinetPortalDisplay_ Success
     * @throws ApiError
     */
    public saveVcPortalSettings(
        formData: {
            model?: VirtualCabinetPortalDisplay;
        },
    ): CancelablePromise<ApiResponse_VirtualCabinetPortalDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/VirtualCabinetPortal/SaveVcPortalSettings',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
