import { useParams } from "react-router-dom";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { useRecoilState } from "recoil";
import { APSCloudAtom } from "./Atoms/APSCloudAtom";
import { useCallback, useEffect, useState } from "react";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { APSPMSFieldsDisplay, ApsPracticeInfo } from "../../../../generated";
import { ATOLoading } from "../../../../Components/ATOSpinner";
import ApsLogo from "../APSCloud/apscloud.png";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { ATOSwitch } from "../../../../Components/ATOSwitch";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import moment from "moment";
import { ATOModalSingle } from "../../../../Components/Modal/ATOModalSingle";
import { useSinglePendingChanges } from "../../../../Hooks/SinglePendingChagesHook";

export const APSCloud = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const params = useParams();
  const amClient = useAMClient(config.apsCloudUrl, user, custId);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [modal, setModal] = useState<React.ReactNode | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isAccessTokenExpired, setIsAccessTokenExpired] = useState(false);
  const [unmodifiedApsPracticeInfo, setUnmodifiedApsPracticeInfo] = useRecoilState(APSCloudAtom);

  const pendingChanges = useSinglePendingChanges<ApsPracticeInfo>();
  const hasChanges = pendingChanges.listChanges().length > 0;

  const practiceInfo = pendingChanges.applyChanges(unmodifiedApsPracticeInfo ?? {});

  useEffect(() => {
    amClient?.apsHandler
      .getApsPracticeInfo({})
      .then((res) => {
        if (res.data != undefined || res.data != null) {
          setIsConnected(true);
        }
        setUnmodifiedApsPracticeInfo(res?.data ?? {});
        setIsDataLoaded(true);
        const todayMoment = moment();
        const ExpiryTokenMoment = moment(res.data?.refreshTokenExpiry);

        if (todayMoment.isAfter(ExpiryTokenMoment)) {
          setIsAccessTokenExpired(true);
        }
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  }, [amClient]);

  const formatDateToLocal = (dateString: string) => {
    let date = moment.utc(dateString).format("YYYY-MM-DD HH:mm:ss");
    let local = moment(date).local().format("Do MMMM YYYY");
    return local;
  };

  const filterAPSPMSField = (search?: string) => {
    const resp = practiceInfo?.apspmsFieldsDisplay?.filter((f) => f.fieldType === search);
    return resp;
  };

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    amClient?.apsActionHandler
      .updateApsMaster({ apsPracticeInfo: practiceInfo })
      .then((res) => {
        setUnmodifiedApsPracticeInfo(res?.data ?? {});
        pendingChanges.removeAllChanges();
        setIsLoading(false);
        setModal(
          <ATOModalSingle title="Success!" message="Successfully updated" onButton={() => setModal(undefined)} />
        );
      })
      .catch((ex) => {
        setIsLoading(false);
        setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />);
      });
  }, [amClient, practiceInfo]);

  if (amClient === undefined || isDataLoaded === false || isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOLoading />
      </div>
    );
  }

  return (
    <div className="box-border flex h-screen flex-col items-center justify-center">
      <div className="flex h-full w-full flex-grow-0 flex-col">
        <div className="flex justify-center">
          <img src={ApsLogo} className="mt-2 w-36 flex-shrink-0" />
        </div>

        {isConnected && !isAccessTokenExpired ? (
          <div className="mt-3">
            <div className="flex flex-col items-center justify-center space-y-4 text-sm">
              <div className="text-center">
                Connection will expire on {formatDateToLocal(practiceInfo?.refreshTokenExpiry ?? "")}
              </div>
            </div>

            <form>
              <div className="flex h-min w-full items-center justify-center p-2">
                <div className="my-6 grid w-full max-w-screen-lg grid-cols-[1fr_1fr] gap-8">
                  <div className="flex h-min w-full p-2">
                    <p className="w-1/2 text-center">Email Source</p>
                    <ATODropDown<APSPMSFieldsDisplay>
                      className={`h-8 w-full`}
                      value={practiceInfo?.emailField ?? {}}
                      getOptions={() => filterAPSPMSField(practiceInfo?.emailField?.fieldType ?? "") ?? []}
                      getDisplay={(data) => data?.description ?? "N/A"}
                      onChange={(val) => pendingChanges.setChange("emailField", val)}
                    />
                  </div>
                  <div className="flex h-min w-full p-2">
                    <p className="w-1/2 text-start">Client Group Source</p>
                    <ATODropDown<APSPMSFieldsDisplay>
                      className={`h-8 w-full`}
                      value={practiceInfo?.userPmsreference ?? {}}
                      getOptions={() => filterAPSPMSField(practiceInfo?.userPmsreference?.fieldType ?? "") ?? []}
                      getDisplay={(data) => data?.description ?? "N/A"}
                      onChange={(val) => pendingChanges.setChange("userPmsreference", val)}
                    />
                  </div>
                  <div className="flex h-min w-full p-2">
                    <p className="w-1/2 text-center">Return Status</p>
                    <ATOTextBox
                      name="returnStatus"
                      className="h-full w-full"
                      value={practiceInfo?.returnStatus ?? ""}
                      onChange={(val) => pendingChanges.setChange("userPmsreference", val)}
                    />
                  </div>
                  <div className="flex h-min w-full p-2">
                    <div className="flex w-full">
                      <p className="w-1/2 text-start">Family Group</p>
                      <ATOSwitch
                        className="text-center"
                        size="medium"
                        value={practiceInfo?.familyGroup ?? false}
                        onChange={(val) => pendingChanges.setChange("familyGroup", val)}
                      />
                    </div>
                    <div className="flex w-full">
                      <p className="w-1/2 text-start">Tax Sync</p>

                      <ATOSwitch
                        className="text-center"
                        size="medium"
                        value={practiceInfo?.taxEnabled ?? false}
                        onChange={(val) => pendingChanges.setChange("taxEnabled", val)}
                      />
                    </div>
                  </div>
                  <div className="flex h-min w-full p-2">
                    <p className="w-1/2 text-center">DMS Primary</p>

                    <ATODropDown<APSPMSFieldsDisplay>
                      className={`h-8 w-full`}
                      value={practiceInfo?.dmsprimary ?? {}}
                      getOptions={() => filterAPSPMSField(practiceInfo.dmsprimary?.fieldType ?? "") ?? []}
                      getDisplay={(data) => data?.description ?? "N/A"}
                      onChange={(val) => pendingChanges.setChange("dmsprimary", val)}
                    />
                  </div>
                  <div className="flex h-min w-full p-2">
                    <p className="w-1/2 text-start">DMS Secondary</p>
                    <ATODropDown<APSPMSFieldsDisplay>
                      className={`h-8 w-full`}
                      value={practiceInfo?.dmssecondary ?? {}}
                      getOptions={() => filterAPSPMSField(practiceInfo?.dmssecondary?.fieldType ?? "") ?? []}
                      getDisplay={(data) => data?.description ?? "N/A"}
                      onChange={(val) => pendingChanges.setChange("dmssecondary", val)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-2 flex w-full justify-center">
                <ATOButton buttonType={ButtonType.Confirm} onClick={handleSubmit} disabled={!hasChanges}>
                  Update
                </ATOButton>
              </div>
              {modal}
            </form>
          </div>
        ) : (
          <div className="my-6 grid w-full grid-cols-[1fr_1fr] gap-4">
            <div className="col-span-2 flex flex-col items-center">
              {isAccessTokenExpired && (
                <div className="relative m-4 flex h-8 w-1/12 items-center justify-center rounded border border-red-400 bg-red-100 text-center text-xs text-red-700">
                  <strong className="font-bold">Connection has expired</strong>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
