import { useCallback, useEffect, useState } from "react";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { ApiResponseStatus, DMSDocOptionType, DMSName, MYOBMasterDisplay } from "../../../../generated";
import { ItemPendingChanges, usePendingChangesWithState } from "../../../../Hooks/PendingChangesHook";
import { IDMSDoc } from "../../../../Components/DMSDoc/IDMSDoc";
import { atom, useRecoilState } from "recoil";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { DMSDocSetupColumnInfo } from "../../../../Components/DMSDoc/DMSDocSetupColumnInfo";
import { ATOSpinner } from "../../../../Components/ATOSpinner";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ATOSuccessModal } from "../../../../Components/ATOSuccessModal";
import { ATOCheckBox } from "../../../../Components/ATOCheckBox";
import { Tooltip } from "../../../../Components/Tooltip";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { DMSDocSetup } from "../../../../Components/DMSDoc/DMSDocSetup";
import { DMSDocCell } from "../../../../Components/DMSDoc/DMSDocCell";
import myobLogo from "../Myob/myob-logo.png";

const myobAtom = atom<ItemPendingChanges<IDMSDoc>[]>({
  key: "myobAtom",
  default: [],
});

export const Myob = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const apiClient = useAMClient(config.practiceConfigUrl, user, custId);
  const [isLoading, setIsLoading] = useState(false);
  const [practiceInfo, setPracticeInfo] = useState<MYOBMasterDisplay>();
  const pendingChanges = usePendingChangesWithState<IDMSDoc>(useRecoilState(myobAtom));
  const [modal, setModal] = useState<React.ReactNode | undefined>(undefined);
  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [customPmsReferenceValue, setCustomPmsReferenceValue] = useState("");

  var pmsReferences: string[] = [];

  const handleOnchange = useCallback((val: MYOBMasterDisplay | string | number | boolean, name: string) => {
    setPracticeInfo((prevPracticeInfo) => ({
      ...prevPracticeInfo,
      [name]: val,
    }));
  }, []);

  const actualMyobDetail = pendingChanges.applyChanges("-1", {
    fileName: practiceInfo?.fileName ?? "",
    unknownClient: practiceInfo?.unknownClient ?? "",
    userPmsReference: practiceInfo?.userPmsReference ?? "",
    baseFolder: practiceInfo?.baseFolder ?? "",
    isTrust: practiceInfo?.isTrust?.toString() ?? "",
    trustAccountName: practiceInfo?.trustAccountName ?? "",
    familyGroups: practiceInfo?.familyGroups?.toString() ?? "",
    DocID: "-1",
    Level1ID: "",
    Level2ID: "",
    Level3ID: "",
    Level4ID: "",
  });

  useEffect(() => {
    apiClient.myobdmsActions
      .getMyobPracticeInfo({})
      .then((resp) => {
        setPracticeInfo(resp.data);

        if (resp.status !== ApiResponseStatus.Success) {
          throw resp.message;
        }

        if (
          practiceInfo &&
          practiceInfo.userPmsReference !== null &&
          !pmsReferences.includes(practiceInfo?.userPmsReference ?? "")
        ) {
          setIsCustomSelected(true);
          setCustomPmsReferenceValue(practiceInfo?.userPmsReference ?? "");
        }
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  }, []);

  const handleDropdownChange = (value: string) => {
    setIsCustomSelected(value === "Custom");
    if (!isCustomSelected) {
      pendingChanges.setChange("-1", "userPmsReference", value);
      setCustomPmsReferenceValue("");
    }
  };

  const columnInfo: DMSDocSetupColumnInfo<IDMSDoc>[] = [
    {
      name: "Level 1",
      valueKey: "Level1ID",
      popupInitialHeaderText: "Level 1",
      isMandatory: () => true,
      allowCustom: () => true,
      columnOptionType: DMSDocOptionType.Client,
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 2",
      valueKey: "Level2ID",
      popupInitialHeaderText: "Level 2",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 3",
      valueKey: "Level3ID",
      popupInitialHeaderText: "Level 3",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 4",
      valueKey: "Level4ID",
      popupInitialHeaderText: "Level 4",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
  ];

  const renderer = (header: React.ReactNode, content: React.ReactNode, footer: React.ReactNode) => (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="p-2 pr-6">{header}</div>
      <div className="flex h-full w-full flex-1 flex-col gap-3 overflow-x-hidden overflow-y-scroll p-2">
        {content}
        <div className="h-[8rem] w-full flex-shrink-0" />
      </div>
      <div className="flex gap-4 p-2">{footer}</div>
    </div>
  );

  if (practiceInfo === undefined) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOSpinner />
      </div>
    );
  }

  let gridSize = "1";
  if (practiceInfo.isPms === true && practiceInfo.isDms === true) {
    gridSize = "3";
  } else if (practiceInfo.isPms === true && practiceInfo.isDms === false) {
    gridSize = "1";
  } else if (practiceInfo.isPms === false && practiceInfo.isDms === true) {
    gridSize = "2";
  }
  pmsReferences = ["Client Partner", "ClientDept", "Manager", "Custom"];

  return (
    <div className="box-border flex h-screen flex-col items-center justify-center">
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.5)]">
          <ATOSpinner />
        </div>
      )}
      <div className="flex justify-center">
        <img src={myobLogo} className="mt-2 h-16 flex-shrink-0" />
      </div>
      {practiceInfo.isPms === true && (
        <div className="flex w-full">
          <div className={`grid h-fit w-full`} style={{ gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}>
            <div className="flex h-min w-full flex-col items-center justify-center px-3 py-2">
              <div className="flex items-center font-semibold text-primary">User PMS Reference</div>
              <ATODropDown<string>
                className={`h-8 w-[min(480px,80%)] px-2`}
                value={actualMyobDetail?.userPmsReference ?? ""}
                getDisplay={(r) => r}
                getOptions={() => pmsReferences}
                onChange={(val) => handleDropdownChange(val)}
              />
            </div>
            {!practiceInfo.isDms && isCustomSelected && (
              <div className="flex h-min w-full flex-col items-center justify-center p-2">
                <div className="flex items-center font-semibold text-primary">User PMS Reference Custom Value</div>
                <ATOTextBox
                  className="w-[min(480px,80%)]"
                  value={actualMyobDetail?.userPmsReference ?? ""}
                  onChange={(str) => pendingChanges.setChange("-1", "userPmsReference", str)}
                />
              </div>
            )}
            {gridSize === "1" && (
              <div className="flex h-min w-full flex-col items-center justify-center p-2">
                <div className="flex font-semibold text-primary">
                  Family Group
                  <Tooltip className="ml-2 h-min w-min">
                    <div
                      className="flex h-0 w-80
                                                      -translate-x-[calc(100%+20px)] -translate-y-1/2
                                                      items-center justify-center pl-2 text-white"
                    >
                      <p className="z-10 -mr-[9px] -translate-y-1/4 rounded bg-black px-2 text-base">
                        This enables the Family Group data if required by your Doc Management System, eg WinFolders
                      </p>
                      <div className=" h-4 w-4 rotate-45 bg-black" />
                    </div>
                  </Tooltip>
                  <ATOCheckBox
                    name="familyGroups"
                    className="ml-48 h-4 w-full border-gray-100"
                    value={
                      pendingChanges.getChange("-1", "familyGroups")
                        ? pendingChanges.getChange("-1", "familyGroups")?.value
                        : actualMyobDetail?.familyGroups
                    }
                    onChange={(str) => {
                      pendingChanges.setChange("-1", "familyGroups", str);
                    }}
                  />
                </div>
                <div className="flex font-semibold text-primary">
                  Fee For Service
                  <Tooltip className="ml-2 h-min w-min">
                    <div
                      className="flex h-0 w-80
                                                      -translate-x-[calc(100%+20px)] -translate-y-1/2
                                                      items-center justify-center pl-2 text-white"
                    >
                      <p className="z-10 -mr-[9px] -translate-y-1/4 rounded bg-black px-2 text-base">
                        Enable additional templates, rules and settings for Fee For Service. For more information click
                        Help
                      </p>
                      <div className=" h-4 w-4 rotate-45 bg-black" />
                    </div>
                  </Tooltip>
                  <ATOCheckBox
                    name="isTrust"
                    className="ml-44 h-4 w-full border-gray-100"
                    value={
                      pendingChanges.getChange("-1", "isTrust")
                        ? pendingChanges.getChange("-1", "isTrust")?.value
                        : actualMyobDetail?.isTrust
                    }
                    onChange={(str) => {
                      pendingChanges.setChange("-1", "isTrust", str);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="flex h-min w-full flex-col items-center justify-center p-2">
              <div className="flex items-center font-semibold text-primary">Trust Account Name</div>
              <ATOTextBox
                className="w-[min(480px,80%)]"
                value={actualMyobDetail?.trustAccountName ?? ""}
                onChange={(str) => pendingChanges.setChange("-1", "trustAccountName", str)}
              />
            </div>
            {gridSize != "1" && (
              <div className="flex h-min w-full flex-col items-center justify-center p-2">
                <div className="flex font-semibold text-primary">
                  Family Group
                  <Tooltip className="ml-2 h-min w-min">
                    <div
                      className="flex h-0 w-80
                                                      -translate-x-[calc(100%+20px)] -translate-y-1/2
                                                      items-center justify-center pl-2 text-white"
                    >
                      <p className="z-10 -mr-[9px] -translate-y-1/4 rounded bg-black px-2 text-base">
                        This enables the Family Group data if required by your Doc Management System, eg WinFolders
                      </p>
                      <div className=" h-4 w-4 rotate-45 bg-black" />
                    </div>
                  </Tooltip>
                  <ATOCheckBox
                    name="familyGroups"
                    className="ml-48 h-4 w-full border-gray-100"
                    value={
                      pendingChanges.getChange("-1", "familyGroups")
                        ? pendingChanges.getChange("-1", "familyGroups")?.value
                        : actualMyobDetail?.familyGroups
                    }
                    onChange={(str) => {
                      pendingChanges.setChange("-1", "familyGroups", str);
                    }}
                  />
                </div>
                <div className="flex font-semibold text-primary">
                  Fee For Service
                  <Tooltip className="ml-2 h-min w-min">
                    <div
                      className="flex h-0 w-80
                                                      -translate-x-[calc(100%+20px)] -translate-y-1/2
                                                      items-center justify-center pl-2 text-white"
                    >
                      <p className="z-10 -mr-[9px] -translate-y-1/4 rounded bg-black px-2 text-base">
                        Enable additional templates, rules and settings for Fee For Service. For more information click
                        Help
                      </p>
                      <div className=" h-4 w-4 rotate-45 bg-black" />
                    </div>
                  </Tooltip>
                  <ATOCheckBox
                    name="isTrust"
                    className="ml-44 h-4 w-full border-gray-100"
                    value={
                      pendingChanges.getChange("-1", "isTrust")
                        ? pendingChanges.getChange("-1", "isTrust")?.value
                        : actualMyobDetail?.isTrust
                    }
                    onChange={(str) => {
                      pendingChanges.setChange("-1", "isTrust", str);
                    }}
                  />
                </div>
              </div>
            )}
            {practiceInfo.isDms === false && (
              <div className="flex h-min w-full flex-col items-center justify-center p-2">
                <ATOButton
                  buttonType={ButtonType.Confirm}
                  onClick={() => {
                    apiClient.myobdmsActions
                      .updateMyobPracticeInfo({
                        fileName: actualMyobDetail?.fileName ?? "",
                        unknownClient: actualMyobDetail?.unknownClient ?? "",
                        baseFolder: actualMyobDetail?.baseFolder ?? "",
                        isTrust: actualMyobDetail?.isTrust === "true",
                        userPmsReference: actualMyobDetail?.userPmsReference ?? "",
                        familyGroups: actualMyobDetail?.familyGroups?.toString() == "true",
                        trustAccountName: actualMyobDetail?.trustAccountName ?? "",
                      })
                      .then(() => {
                        setModal(<ATOSuccessModal setModal={setModal} />);
                      })
                      .catch((ex) =>
                        setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />)
                      );
                  }}
                  type="button"
                >
                  Save
                </ATOButton>
              </div>
            )}
            <div></div>
          </div>
        </div>
      )}
      {practiceInfo.isDms === true && (
        <div className="flex h-full w-full justify-center overflow-hidden">
          <DMSDocSetup<IDMSDoc>
            dmsui={DMSName.MYOBDocumentManager}
            columnInfo={columnInfo}
            isLoading={isLoading}
            isActive={true}
            setIsLoading={setIsLoading}
            setModal={setModal}
            preSave={(docSave) => {
              apiClient.myobdmsActions
                .updateMyobPracticeInfo({
                  fileName: actualMyobDetail?.fileName ?? "",
                  unknownClient: actualMyobDetail?.unknownClient ?? "",
                  baseFolder: actualMyobDetail?.baseFolder ?? "",
                  isTrust: actualMyobDetail?.isTrust === "true",
                  userPmsReference: actualMyobDetail?.userPmsReference ?? "",
                  familyGroups: actualMyobDetail?.familyGroups?.toString() == "true",
                  trustAccountName: actualMyobDetail?.trustAccountName ?? "",
                })
                .then(() => {
                  docSave();
                })
                .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
            }}
            getDefaultItem={(docOptions) => {
              return {
                DocID: "1",
                Level1ID: "",
                Level2ID: "",
                Level3ID: "",
                Level4ID: "",
              };
            }}
            content={renderer}
            pendingChanges={pendingChanges}
          >
            {(types, options) => {
              return (
                <>
                  <div className={`grid h-fit w-full`} style={{ gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}>
                    {isCustomSelected && (
                      <div className="flex h-min w-full flex-col items-center justify-center p-2">
                        <div className="flex items-center font-semibold text-primary">
                          User PMS Reference Custom Value
                        </div>
                        <ATOTextBox
                          className="w-[min(480px,80%)]"
                          value={practiceInfo?.userPmsReference ?? ""}
                          onChange={(val) => {
                            handleOnchange(val, "userPmsReference");
                          }}
                        />
                      </div>
                    )}
                    <div className="flex h-min w-full flex-col items-center justify-center py-2">
                      <div className="flex items-center font-semibold text-primary">
                        Filename
                        <Tooltip className="z-20 ml-2 h-min w-min">
                          <div
                            className="flex h-0 w-80
                                           translate-y-[calc(100%+20px)] items-center justify-center
                                           pl-2  text-white"
                          >
                            <div className=" mb-[45px] h-4 w-4 rotate-45 bg-black" />
                            <p className="z-10 -ml-[9px] -translate-y-1/4 rounded bg-black px-2 text-base">
                              Filename is used to rename the document when it's saved to Myob.
                            </p>
                          </div>
                        </Tooltip>
                      </div>
                      <div className="z-10 h-8 w-[min(480px,80%)]">
                        <DMSDocCell<IDMSDoc>
                          columnInfo={{
                            name: "fileName",
                            valueKey: "fileName",
                            isMandatory: () => false,
                            allowCustom: () => true,
                            getDatasource: () => options?.[DMSDocOptionType.FileName],
                            columnOptionType: DMSDocOptionType.FileName,
                            width: "",
                          }}
                          pendingChanges={pendingChanges}
                          isDefaultDocType={false}
                          rowIndex={-1}
                          setModal={setModal}
                          value={{
                            Docs: "",
                            DocID: "",
                            Level1ID: "",
                            Level2ID: "",
                            Level3ID: "",
                            Level4ID: "",
                            fileName: pendingChanges.getChange("-1", "fileName")?.value ?? actualMyobDetail?.fileName,
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex h-min w-full flex-col items-center justify-center p-2">
                      <div className="flex items-center font-semibold text-primary">
                        Base Folder
                        <Tooltip className="ml-2 h-min w-min">
                          <div
                            className="flex h-0 w-80
                                           translate-y-[calc(100%+20px)] items-center justify-center
                                           pl-2 text-white"
                          >
                            <div className=" mb-[45px] h-4 w-4 rotate-45 bg-black" />
                            <p className="z-10 -ml-[9px] -translate-y-1/4 rounded bg-black px-2 text-base">
                              Local Directory in your computer where the files will be downloaded.
                            </p>
                          </div>
                        </Tooltip>
                      </div>
                      <ATOTextBox
                        className="w-[min(480px,80%)]"
                        value={actualMyobDetail?.baseFolder ?? ""}
                        onChange={(val) => {
                          handleOnchange(val, "baseFolder");
                        }}
                      />
                    </div>
                    <div className="flex h-min w-full flex-col items-center justify-center p-2">
                      <div className="flex items-center font-semibold text-primary">
                        Unknown ATOMate Client
                        <Tooltip className="ml-2 h-min w-min">
                          <div
                            className="flex h-0 w-80
                                           translate-y-[calc(100%+20px)] items-center justify-center
                                           pl-2 text-white"
                          >
                            <div className=" mb-[45px] h-4 w-4 rotate-45 bg-black" />
                            <p className="z-10 -ml-[9px] -translate-y-1/4 rounded bg-black px-2 text-base">
                              The Client for associating documents to when the client is not found.
                            </p>
                          </div>
                        </Tooltip>
                      </div>
                      <ATOTextBox
                        className="w-[min(480px,80%)]"
                        value={actualMyobDetail?.unknownClient ?? ""}
                        onChange={(val) => {
                          handleOnchange(val, "unknownClient");
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            }}
          </DMSDocSetup>
        </div>
      )}
    </div>
  );
};
