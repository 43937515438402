import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { IDMSDoc } from "../../../../Components/DMSDoc/IDMSDoc";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { ItemPendingChanges, usePendingChangesWithState } from "../../../../Hooks/PendingChangesHook";
import { ApiResponseStatus, DMSDocOptionType, DMSName, GooglePracticeInfo } from "../../../../generated";
import { useEffect, useState } from "react";
import { AppModalAtom } from "../../../../App";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOSpinner } from "../../../../Components/ATOSpinner";
import { DMSDocSetupColumnInfo } from "../../../../Components/DMSDoc/DMSDocSetupColumnInfo";
import { DMSDocSetup } from "../../../../Components/DMSDoc/DMSDocSetup";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { DMSDocCell } from "../../../../Components/DMSDoc/DMSDocCell";
import { ATOCheckBox } from "../../../../Components/ATOCheckBox";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";

const googleAtom = atom<ItemPendingChanges<IDMSDoc>[]>({
  key: "googleAtom",
  default: [],
});

export const Google = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const apiClient = useAMClient(config.googleUrl, user, custId);
  const [practiceInfo, setPracticeInfo] = useState<GooglePracticeInfo>();
  const setModal = useSetRecoilState(AppModalAtom);
  const pendingChanges = usePendingChangesWithState<IDMSDoc>(useRecoilState(googleAtom));

  const actualGoogleDetail = pendingChanges.applyChanges("-1", {
    fileName: practiceInfo?.fileName ?? "",
    unknownClient: practiceInfo?.unknownClient ?? "",
    baseFolder: practiceInfo?.baseFolder ?? "",
    useSharedDrive: practiceInfo?.useSharedDrive?.toString() ?? "",
    DocID: "-1",
    Level1ID: "",
    Level2ID: "",
    Level3ID: "",
    Level4ID: "",
  });
  const [isCollapsed, setCollapsed] = useState(true);
  const connectionConfigurationButton = isCollapsed ? "Open General Configuration" : "Close General Configuration";

  const [isLoading, setIsLoading] = useState(false);

  const [selectedSharedDrive, setSelectedSharedDrive] = useState("");
  const [selectedBaseFolder, setSelectedBasefolder] = useState("");

  const [sharedDriveList, setSharedDriveList] = useState<string[]>([]);

  const removeFirstPart = (str: string) => {
    const parts = str.split("\\");
    return parts.slice(1).join("\\");
  };

  const extractFirstPart = (val: string) => {
    if (val.includes("\\")) {
      const parts = val.split("\\");
      return parts[0];
    } else {
      return val;
    }
  };

  const toggleServerSettingCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  const removeTrailingSlash = (inputString: string): string => {
    if (inputString.endsWith("\\")) {
      return inputString.slice(0, -1);
    } else {
      return inputString;
    }
  };

  const determineBaseFolder = (): string => {
    const sharedDriveFromPendingChanges = pendingChanges.getChange("-1", "selectedDrive")?.value ?? "";
    const selectedSharedDriveValue = sharedDriveFromPendingChanges || selectedSharedDrive;

    const baseFolderFromPendingChanges = pendingChanges.getChange("-1", "baseFolder")?.value ?? "";
    const selectedbaseFolderValue = baseFolderFromPendingChanges || selectedBaseFolder;

    const divider = practiceInfo?.useSharedDrive ? "\\" : "";
    const result = `${selectedSharedDriveValue}${divider}${selectedbaseFolderValue}`;
    return removeTrailingSlash(result);
  };

  const getSharedDrives = async () => {
    const resp = (await apiClient?.googleEnrolmentActions.getGoogleSharedDrives({})).data!;

    setSharedDriveList(resp);

    return resp;
  };

  useEffect(() => {
    apiClient.googleEnrolmentActions
      .getGooglePracticeInfo({})
      .then((resp) => {
        setPracticeInfo(resp.data);

        if (resp.status !== ApiResponseStatus.Success) {
          throw resp.message;
        }

        if (resp?.data?.useSharedDrive) {
          const initialFolder = extractFirstPart(resp?.data?.baseFolder ?? "");
          setSelectedSharedDrive(initialFolder);

          const initialBaseFolder = removeFirstPart(resp?.data?.baseFolder ?? "");
          setSelectedBasefolder(initialBaseFolder);
        }
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  }, [apiClient]);

  const columnInfo: DMSDocSetupColumnInfo<IDMSDoc>[] = [
    {
      name: "Level 1",
      valueKey: "Level1ID",
      popupInitialHeaderText: "Level 1",
      isMandatory: () => true,
      allowCustom: () => true,
      columnOptionType: DMSDocOptionType.Client,
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 2",
      valueKey: "Level2ID",
      popupInitialHeaderText: "Level 2",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 3",
      valueKey: "Level3ID",
      popupInitialHeaderText: "Level 3",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 4",
      valueKey: "Level4ID",
      popupInitialHeaderText: "Level 4",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
  ];

  if (practiceInfo === undefined) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOSpinner />
      </div>
    );
  }
  return (
    <div className="flex h-screen flex-col overflow-hidden">
      {isLoading && (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <ATOSpinner />
        </div>
      )}

      {practiceInfo?.isConnected && (
        <>
          <div className="flex justify-center">
            <ATOButton buttonType={ButtonType.Primary} className="ml-2" onClick={toggleServerSettingCollapse}>
              {connectionConfigurationButton}
            </ATOButton>
          </div>
          <div className="flex h-full w-full justify-center overflow-hidden">
            <DMSDocSetup<IDMSDoc>
              dmsui={DMSName.GoogleDrive}
              columnInfo={columnInfo}
              isLoading={isLoading}
              isActive={true}
              setIsLoading={setIsLoading}
              setModal={setModal}
              pendingChanges={pendingChanges}
              preSave={(docSave) => {
                apiClient.googleEnrolmentActions
                  .saveGooglePracticeInfo({
                    fileName: actualGoogleDetail?.fileName ?? "",
                    unknownClient: actualGoogleDetail?.unknownClient ?? "",
                    baseFolder: determineBaseFolder() ?? "",
                    useSharedDrive: actualGoogleDetail.useSharedDrive === "true",
                  })
                  .then(() => {
                    docSave();
                  })
                  .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
              }}
              getDefaultItem={(docOptions) => {
                return {
                  DocID: "1",
                  Level1ID: "",
                  Level2ID: "",
                  Level3ID: "",
                  Level4ID: "",
                };
              }}
            >
              {(types, options) => {
                return (
                  <>
                    {!isCollapsed && (
                      <div className="my-3 grid h-fit w-full grid-cols-[1fr_1fr]">
                        <div className="flex h-min w-full flex-col items-center justify-center gap-2 p-2">
                          <p>Use Shared Drive{isCollapsed}</p>
                          <ATOCheckBox
                            name="useSharedDrive"
                            className="h-4 w-full border-gray-100"
                            value={practiceInfo.useSharedDrive ?? false}
                            onChange={(val) => {
                              pendingChanges.setChange("-1", "useSharedDrive", val);
                              //handleOnchange(val, "useSharedDrive");
                            }}
                          />
                        </div>
                        <div className="flex h-min w-full flex-col items-center justify-center gap-2 p-2">
                          {practiceInfo && practiceInfo.useSharedDrive && (
                            <>
                              <p>Select Drive</p>
                              <div className="h-8 w-[min(480px,80%)]">
                                <ATODropDown<string>
                                  className="h-8 w-2/3"
                                  value={extractFirstPart(actualGoogleDetail?.baseFolder ?? "")}
                                  getDisplay={(item) => item}
                                  getOptions={() => sharedDriveList}
                                  onChange={(val) => {
                                    pendingChanges.setChange("-1", "selectedDrive", val);
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        <div className="flex h-min w-full flex-col items-center justify-center gap-2 p-2">
                          <p>Base Folder</p>

                          <ATOTextBox
                            className="w-[min(480px,80%)]"
                            value={actualGoogleDetail?.baseFolder ?? ""}
                            onChange={(str) => pendingChanges.setChange("-1", "baseFolder", str)}
                          />
                        </div>
                        <div className="flex h-min w-full flex-col items-center justify-center gap-2 p-2">
                          <p>Filename</p>
                          <div className="z-20 h-8 w-[min(480px,80%)]">
                            <DMSDocCell<IDMSDoc>
                              columnInfo={{
                                name: "fileName",
                                valueKey: "fileName",
                                isMandatory: () => false,
                                allowCustom: () => true,
                                getDatasource: () => options?.[DMSDocOptionType.FileName],
                                columnOptionType: DMSDocOptionType.FileName,
                                width: "",
                              }}
                              isDefaultDocType={false}
                              rowIndex={-1}
                              setModal={setModal}
                              value={{
                                Docs: "",
                                DocID: "",
                                Level1ID: "",
                                Level2ID: "",
                                Level3ID: "",
                                Level4ID: "",
                                fileName: pendingChanges.getChange("-1", "fileName")?.value ?? practiceInfo.fileName,
                              }}
                              pendingChanges={pendingChanges}
                            />
                          </div>
                        </div>
                        <div className="flex h-min w-full flex-col items-center justify-center gap-2 p-2">
                          <p>Unknown ATOmate Client</p>
                          <ATOTextBox
                            className="w-[min(480px,80%)]"
                            value={actualGoogleDetail?.unknownClient ?? ""}
                            onChange={(str) => {
                              pendingChanges.setChange("-1", "unknownClient", str);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                );
              }}
            </DMSDocSetup>
          </div>
        </>
      )}
    </div>
  );
};
