/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_GooglePracticeInfo_ } from '../models/ApiResponse_GooglePracticeInfo_';
import type { ApiResponse_List_string_ } from '../models/ApiResponse_List_string_';
import type { ApiResponse5 } from '../models/ApiResponse5';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GoogleEnrolmentActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_GooglePracticeInfo_ Success
     * @throws ApiError
     */
    public getGooglePracticeInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_GooglePracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/GoogleEnrolmentActions/GetGooglePracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse5 Success
     * @throws ApiError
     */
    public saveGooglePracticeInfo(
        formData: {
            fileName?: string;
            baseFolder?: string;
            useSharedDrive?: boolean;
            unknownClient?: string;
        },
    ): CancelablePromise<ApiResponse5> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/GoogleEnrolmentActions/SaveGooglePracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_List_string_ Success
     * @throws ApiError
     */
    public getGoogleSharedDrives(
        formData: any,
    ): CancelablePromise<ApiResponse_List_string_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/GoogleEnrolmentActions/GetGoogleSharedDrives',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
