import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ATOTextBox } from "./ATOTextBox";
import useDebounce from "../Hooks/DebounceHook";
import useDidMountEffect from "../Hooks/DidMountHook";
import { ATOSpinner } from "./ATOSpinner";
import { ATOClickOutside } from "./ATOClickOutside";

interface OldProps<T> {
  className?: string;
  textboxClassName?: string;
  placeholder?: string;
  arrowClassName?: string;
  popupHeightRem?: number;
  popupStyle?: CSSProperties;
  clearOnDependentChange?: boolean;
  initialValue?: T;
  value?: T;

  isAutoComplete?: boolean;
  autofocusOnExpand?: boolean;
  initialText?: string;
  resetTextOnBlur?: boolean;

  getData: (search: string) => Promise<T[]>;
  getDataKey: (item?: T) => string;
  getDataName?: (item?: T) => string;
  getDataDisplay?: (item: T) => JSX.Element;
  onChange?: (e: T | undefined) => boolean | void;
  onItemClick?: (e: T | undefined) => void;
  enabled?: boolean;
  name?: string;
}

interface Props<T> {
  className?: string;
  hasChange?: boolean;
  hasError?: boolean;
  getOptions: () => T[];
  value: T;
  onChange: (val: T) => void | boolean;
  getDisplay: (val: T) => React.ReactNode;
  getRootDisplay?: (val: T) => React.ReactNode;
  getDropdownDisplay?: (
    options: T[],
    isPoppedUp: boolean,
    setIsPoppedUp: (val: boolean) => void,
    onChange: (val: T) => void | boolean
  ) => React.ReactNode;
}

export const ATODropDown = <T extends unknown>(props: Props<T>) => {
  const [isPoppedUp, setIsPoppedUp] = useState(false);

  const defaultDropdownContentRenderer = (options: T[]) => {
    if (options?.length > 0) {
      return options
        .map((o) => (
          <div
            className="cursor-pointer py-1 text-center hover:bg-gray-100"
            onClick={() => {
              if (!props.onChange(o)) {
                setIsPoppedUp(false);
              }
            }}
          >
            {props.getDisplay(o)}
          </div>
        ))
        .reduce(
          (result, val) => (
            <>
              {result}
              <div className="w-full border border-b-0" />
              {val}
            </>
          ),
          <></>
        );
    } else {
      return <div className="flex items-center justify-center bg-white">No Results</div>;
    }
  };

  const options = props.getOptions();

  return (
    <ATOClickOutside onClick={() => setIsPoppedUp(false)}>
      <div className={props.className}>
        {props.getRootDisplay?.(props.value) ?? (
          <div
            className={`h-full px-2 py-1 shadow ${props.hasChange ? "shadow-yellow-400" : ""} ${props.hasError ? "shadow-red-400" : ""} flex cursor-pointer select-none items-center justify-between rounded-lg`}
            onClick={() => setIsPoppedUp(!isPoppedUp)}
          >
            {props.getDisplay(props.value)}
            <FontAwesomeIcon
              icon={faSvg.faChevronDown}
              className={`text-sm transition-transform ${isPoppedUp ? "rotate-180" : ""}`}
            />
          </div>
        )}
        {props.getDropdownDisplay?.(options, isPoppedUp, setIsPoppedUp, props.onChange) ?? (
          <div className="h-0 w-full pt-2">
            <div
              className={`relative w-full ${isPoppedUp ? "max-h-32" : "max-h-0"} z-10 flex flex-col overflow-clip overflow-y-auto rounded-lg bg-white shadow transition-all`}
            >
              {defaultDropdownContentRenderer(options)}
            </div>
          </div>
        )}
      </div>
    </ATOClickOutside>
  );
};

export const OldATODropDown = <T extends unknown>(props: OldProps<T>) => {
  const getCurrentDisplayName = (item?: T) => props.getDataName?.(item) ?? props.getDataKey(item);

  //state
  const [currentResults, setCurrentResults] = useState<T[]>([]);

  const [localSelectedValue, setSelectedValue] = useState(props.initialValue);

  const selectedValue = props.value ?? localSelectedValue;

  const [shouldSearch, setShouldSearch] = useState(true);

  const [isPoppedUp, setIsPoppedUp] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [currentTextValue, setCurrentTextValue] = useState<string>(
    (props.initialText?.length ?? 0 > 0 ? props.initialText : getCurrentDisplayName(selectedValue)) ?? ""
  );
  const debouncedTextValue = useDebounce(currentTextValue, 150);

  useEffect(() => {
    if (props.initialValue === undefined && props.clearOnDependentChange) {
      setCurrentTextValue("");
      setCurrentResults([]);
    }
  }, [selectedValue, props.initialValue, props.clearOnDependentChange]);

  useDidMountEffect(() => {
    const newInitialKey = props.getDataKey(props.initialValue);
    const currentKey = props.getDataKey(selectedValue);

    if (newInitialKey === currentKey) {
      setCurrentTextValue(getCurrentDisplayName(props.initialValue));
    }
  }, [props.initialValue]);

  function SearchText(searchTerm: string) {
    setIsLoading(true);
    //setIsPoppedUp(true);

    props
      .getData(searchTerm)
      .then((data) => {
        setCurrentResults(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useDidMountEffect(() => {
    if (debouncedTextValue?.length > 2 && shouldSearch) {
      SearchText(debouncedTextValue);
    } else {
      setShouldSearch(true);
      setIsPoppedUp(false);
    }
  }, [debouncedTextValue]);

  useEffect(() => {
    setIsLoading(true);
  }, [currentTextValue]);

  useDidMountEffect(() => {
    if (props.onChange) props.onChange(selectedValue);
    setCurrentTextValue(getCurrentDisplayName(selectedValue));
  }, [selectedValue]);

  useEffect(() => {
    if (isPoppedUp) {
      SearchText(currentTextValue);

      if (props.autofocusOnExpand) {
        dropdownRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isPoppedUp]);

  useEffect(() => {
    setIsPoppedUp(false);
  }, [props.enabled]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <ATOClickOutside onClick={() => setIsPoppedUp(false)}>
      <div
        className={`cursor-pointer ${props.enabled === false ? "pointer-events-none opacity-50" : ""} ${props.className} rounded-lg`}
        onClick={() => setIsPoppedUp(!isPoppedUp)}
      >
        <div className="z-10 flex h-full w-full">
          <ATOTextBox
            className={`h-full w-full ${props.textboxClassName} ${props.isAutoComplete ?? false ? "" : "pointer-events-none"}`}
            placeholder={props.placeholder ?? ""}
            value={currentTextValue}
            name={props.name}
            onChange={(e) => {
              if (props.isAutoComplete) {
                setCurrentTextValue(e);
              }
            }}
            onBlur={() => {
              if (props.resetTextOnBlur) {
                setCurrentTextValue(getCurrentDisplayName(selectedValue));
              }
            }}
            disabled={props.enabled}
          />
          <div className="relative">
            <div className="absolute flex h-full w-5 -translate-x-full transform items-center justify-center">
              <FontAwesomeIcon
                icon={faSvg.faAngleDown}
                className={`${props.arrowClassName ?? "text-gray-400"} ${isPoppedUp ? "rotate-180" : ""}`}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            height: `${isPoppedUp ? props.popupHeightRem ?? 10 : 0}rem`,
          }}
          className={`relative w-full rounded-lg ${isPoppedUp ? "" : "h-0"} flex justify-center`}
        >
          <div
            ref={dropdownRef}
            style={{ ...props.popupStyle, maxHeight: `${props.popupHeightRem ?? 10}rem` }}
            className={`absolute z-30 mt-2 flex w-full transform flex-col overflow-hidden overflow-y-auto rounded-lg shadow ${isPoppedUp ? "" : "h-0"}`}
          >
            <RenderItemCollection items={currentResults} />
          </div>
        </div>
      </div>
    </ATOClickOutside>
  );

  function RenderItem(item: T) {
    let itemContent: string | JSX.Element = props.getDataKey(item);

    if (props.getDataDisplay) {
      itemContent = props.getDataDisplay(item);
    }

    return (
      <div
        className="cursor-pointer bg-white text-center hover:bg-blue-50"
        key={props.getDataKey(item)}
        onClick={() => {
          setSelectedValue(item);
          props.onItemClick?.(item);
          setIsPoppedUp(false);
          setShouldSearch(false);
        }}
      >
        {itemContent}
      </div>
    );
  }

  function RenderItemCollection({ items }: { items: T[] }) {
    if (isLoading) {
      return (
        <div className="flex h-24 items-center justify-center bg-white">
          <ATOSpinner />
        </div>
      );
    }

    if (items?.length > 0) {
      return items
        .map((x) => RenderItem(x))
        .reduce(
          (result, val) => (
            <>
              {result}
              <div className="w-full border border-b-0" />
              {val}
            </>
          ),
          <></>
        );
    } else {
      return <div className="flex items-center justify-center bg-white">No Results</div>;
    }
  }
};
