import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { AppModalAtom } from "../../../../App";
import { useEffect, useState } from "react";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import {
  DMSDocOptionType,
  DMSName,
  SharePointCustomerEnrolmentInfo,
  SharePointDriveInfo,
  SharePointPracticeInfo,
  SharePointSiteInfo,
} from "../../../../generated";
import { ATOSpinner } from "../../../../Components/ATOSpinner";
import { ItemPendingChanges, usePendingChangesWithState } from "../../../../Hooks/PendingChangesHook";
import { IDMSDoc } from "../../../../Components/DMSDoc/IDMSDoc";
import { DMSDocSetupColumnInfo } from "../../../../Components/DMSDoc/DMSDocSetupColumnInfo";
import { DMSDocSetup } from "../../../../Components/DMSDoc/DMSDocSetup";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { DMSDocCell } from "../../../../Components/DMSDoc/DMSDocCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ATODropDown } from "../../../../Components/ATODropDown";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

const sharePointAtom = atom<ItemPendingChanges<IDMSDoc>[]>({
  key: "sharePointMaster",
  default: [],
});

export const SharePoint = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const apiClient = useAMClient(config.sharePointUrl, user, custId);
  const [modal, setModal] = useState<React.ReactNode | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [spConfig, setSpConfig] = useState<SharePointCustomerEnrolmentInfo>();
  const [practiceInfo, setPracticeInfo] = useState<SharePointPracticeInfo>();
  const [selectedSite, setSelectedSite] = useState<SharePointSiteInfo>();
  const [selectedDrive, setSelectedDrive] = useState<SharePointDriveInfo>();
  const [completionPopup, setCompletionPopup] = useState(false);

  const [sites, setSites] = useState<SharePointSiteInfo[]>([]);
  const [drives, setDrives] = useState<SharePointDriveInfo[]>([]);

  const [isCollapsed, setCollapsed] = useState(true);
  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };
  const connectionConfigurationButton = isCollapsed
    ? "Open Connection Configuration"
    : "Close Connection Configuration";

  const pendingChanges = usePendingChangesWithState<IDMSDoc>(useRecoilState(sharePointAtom));

  const actualsharePointDetail = pendingChanges.applyChanges("-1", {
    fileName: practiceInfo?.fileName ?? "",
    unknownClient: practiceInfo?.unknownClient ?? "",
    baseFolder: practiceInfo?.baseFolder ?? "",
    DocID: "-1",
    Level1ID: "",
    Level2ID: "",
    Level3ID: "",
    Level4ID: "",
  });

  useEffect(() => {
    apiClient.sharePointEnrolmentActions
      .getSharePointPracticeInfo({})
      .then((resp) => {
        setPracticeInfo(resp.data);
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  }, []);

  useEffect(() => {
    apiClient.sharePointEnrolmentActions
      .getSharePointCustomerEnrolmentInfo({})
      .then((resp) => {
        setSpConfig(resp.data);
        setSelectedSite(resp?.data?.selectedSite);
        setSelectedDrive(resp?.data?.selectedDrive);
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  }, []);

  useEffect(() => {
    getCustomerDrives("");
    getCustomerSites("");
  }, []);

  const columnInfo: DMSDocSetupColumnInfo<IDMSDoc>[] = [
    {
      name: "Level 1",
      valueKey: "Level1ID",
      popupInitialHeaderText: "Level 1",
      isMandatory: () => true,
      columnOptionType: DMSDocOptionType.Client,
      width: "1fr",
      popupStyle: { width: "150%" },
      allowCustom: () => true,
    },
    {
      name: "Level 2",
      valueKey: "Level2ID",
      popupInitialHeaderText: "Level 2",
      isMandatory: () => false,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
      allowCustom: () => true,
    },
    {
      name: "Level 3",
      valueKey: "Level3ID",
      popupInitialHeaderText: "Level 3",
      isMandatory: () => false,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
      allowCustom: () => true,
    },
    {
      name: "Level 4",
      valueKey: "Level4ID",
      popupInitialHeaderText: "Level 4",
      isMandatory: () => false,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
      allowCustom: () => true,
    },
  ];

  const getCustomerDrives = async (term: string) => {
    const resp = (await apiClient?.sharePointEnrolmentActions.getCustomerDrives({ searchTerm: term })).data!;

    const drives = resp.map((c) => ({
      id: c?.id ?? "",
      name: c?.name ?? "",
      description: c?.description ?? "",
      webUrl: c?.webUrl ?? "",
    }));

    setDrives(drives);

    return drives;
  };

  const getCustomerSites = async (term: string) => {
    const resp = (await apiClient?.sharePointEnrolmentActions.getCustomerSites({ searchTerm: term })).data!;

    const sites = resp.map((c) => ({
      displayName: c?.displayName ?? "",
      description: c?.description ?? "",
      webUrl: c?.webUrl ?? "",
    }));

    setSites(sites);

    return sites;
  };

  const renderer = (header: React.ReactNode, content: React.ReactNode, footer: React.ReactNode) => (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="p-2 pr-6">{header}</div>
      <div className="flex h-full w-full flex-1 flex-col gap-3 overflow-x-hidden overflow-y-scroll p-2">
        {content}
        <div className="h-[8rem] w-full flex-shrink-0" />
      </div>
      <div className="flex gap-4 p-2">{footer}</div>
    </div>
  );

  if (practiceInfo === undefined) {
    return (
      <div className="flex h-full w-full justify-center">
        <ATOSpinner />
      </div>
    );
  }

  return (
    <div className="flex h-screen flex-col overflow-hidden">
      {isLoading && (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <ATOSpinner />
        </div>
      )}

      <div className="pt-4 text-center">
        <ATOButton buttonType={ButtonType.Primary} onClick={toggleCollapse}>
          {connectionConfigurationButton}
        </ATOButton>
      </div>
      {!isCollapsed && (
        <div className="flex justify-between pt-5">
          <div className="flex w-1/2 flex-col items-center justify-start gap-y-4">
            <div className="w-2/3 overflow-hidden rounded-xl shadow-md">
              <div className="border-b bg-primary text-center text-white">Selected Site</div>
              <div className={"flex " + (selectedSite == undefined && "justify-center")}>
                {selectedSite == undefined ? (
                  <div>No Site Selected!</div>
                ) : (
                  RenderItemDetail(
                    selectedSite?.displayName ?? "",
                    selectedSite?.description ?? "",
                    selectedSite?.webUrl ?? ""
                  )
                )}
              </div>
            </div>

            <ATODropDown<SharePointSiteInfo>
              className="h-8 w-2/3"
              value={selectedDrive ?? {}}
              getDisplay={(site) =>
                RenderItemDetail(site?.displayName ?? "", site?.description ?? "", site?.webUrl ?? "")
              }
              getOptions={() => sites}
              onChange={(site) => {
                setSelectedSite(undefined);
                setIsLoading(true);

                apiClient.sharePointEnrolmentActions.setCustomerSite({ siteInfo: site }).then(() => {
                  setSelectedSite(site);
                  setSelectedDrive(undefined);
                  setIsLoading(false);
                });
              }}
            />
          </div>
          <div className="flex w-1/2 flex-col items-center justify-start gap-y-4">
            <div className="w-2/3 overflow-hidden rounded-xl shadow-md">
              <div className="border-b bg-primary text-center text-white">Selected Drive</div>
              <div className={"flex " + (selectedDrive == undefined && "justify-center")}>
                {selectedDrive == undefined ? (
                  <div>No Selected Drive!</div>
                ) : (
                  RenderItemDetail(
                    selectedDrive?.name ?? "",
                    selectedDrive?.description ?? "",
                    selectedDrive?.webUrl ?? ""
                  )
                )}
              </div>
            </div>

            <ATODropDown<SharePointDriveInfo>
              className={`h-8 w-2/3 ${!!selectedSite ? "" : "cursor-not-allowed"}`}
              value={selectedDrive ?? {}}
              getOptions={() => drives}
              getDisplay={(drive) => RenderItemDetail(drive?.name ?? "", drive?.name ?? "", drive?.name ?? "")}
              onChange={(drive) => {
                setSelectedDrive(undefined);
                setIsLoading(true);

                apiClient.sharePointEnrolmentActions
                  .setCustomerDrive({
                    driveInfo: drive,
                  })
                  .then(() => {
                    setSelectedDrive(drive);
                    setIsLoading(false);

                    setCompletionPopup(true);
                  });
              }}
            />
          </div>
        </div>
      )}
      <div
        className={
          "absolute left-0 top-0 z-50 flex h-full w-full cursor-pointer items-center justify-center bg-gray-400 bg-opacity-50 " +
          (completionPopup == false && "hidden")
        }
        onClick={() => setCompletionPopup(false)}
      >
        <div className="flex h-64 w-96 flex-col items-center justify-center rounded-xl bg-white shadow-lg">
          <FontAwesomeIcon icon={faSvg.faCheckCircle} size="5x" className=" pb-4 text-green-600" />
          <b>SharePoint Setup Complete!</b>
        </div>
      </div>

      {practiceInfo.isDMS && (
        <div className="flex h-full w-full justify-center overflow-hidden">
          <DMSDocSetup<IDMSDoc>
            pendingChanges={pendingChanges}
            dmsui={DMSName.SharePoint}
            columnInfo={columnInfo}
            isLoading={isLoading}
            isActive={true}
            setIsLoading={setIsLoading}
            setModal={setModal}
            preSave={(docSave) => {
              apiClient.sharePointEnrolmentActions
                .saveSharePointPracticeInfo({
                  fileName: actualsharePointDetail?.fileName ?? "",
                  unknownClient: actualsharePointDetail?.unknownClient ?? "",
                  baseFolder: actualsharePointDetail?.baseFolder ?? "",
                })
                .then(() => {
                  docSave();
                })
                .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
            }}
            getDefaultItem={(docOptions) => {
              return {
                DocID: "1",
                Level1ID: "",
                Level2ID: "",
                Level3ID: "",
                Level4ID: "",
              };
            }}
            content={renderer}
          >
            {(types, options) => {
              return (
                <>
                  <div className="my-6 grid w-full grid-cols-[1fr_1fr] gap-4">
                    <div className="flex h-min w-full flex-col items-center justify-center gap-3">
                      <p>Base Folder</p>
                      <ATOTextBox
                        className="w-[min(480px,80%)]"
                        value={actualsharePointDetail.baseFolder ?? ""}
                        onChange={(str) => pendingChanges.setChange("-1", "baseFolder", str)}
                      />
                    </div>
                    <div className="flex h-min w-full flex-col items-center justify-center pb-5">
                      <div className="pb-3">File Name</div>
                      <div className="z-20 h-8 w-[min(480px,80%)]">
                        <DMSDocCell<IDMSDoc>
                          pendingChanges={pendingChanges}
                          columnInfo={{
                            name: "fileName",
                            valueKey: "fileName",
                            isMandatory: () => true,
                            allowCustom: () => true,
                            getDatasource: () => options?.[DMSDocOptionType.FileName],
                            columnOptionType: DMSDocOptionType.FileName,
                            width: "",
                          }}
                          isDefaultDocType={false}
                          rowIndex={-1}
                          setModal={setModal}
                          value={{
                            Docs: "",
                            DocID: "",
                            Level1ID: "",
                            Level2ID: "",
                            Level3ID: "",
                            Level4ID: "",
                            fileName: practiceInfo?.fileName ?? "",
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex h-min w-full flex-col items-center justify-center gap-3">
                      <p>Unknown Client</p>
                      <ATOTextBox
                        className="w-[min(480px,80%)]"
                        value={actualsharePointDetail.unknownClient ?? ""}
                        onChange={(str) => pendingChanges.setChange("-1", "unknownClient", str)}
                      />
                    </div>
                  </div>
                </>
              );
            }}
          </DMSDocSetup>
          {modal}
        </div>
      )}
    </div>
  );
  function RenderItemDetail(name?: string, description?: string, webUrl?: string) {
    return (
      <div className="w-full px-2">
        <div className="inline-flex w-full flex-col items-start">
          <div className="flex w-full whitespace-nowrap">
            <span className="block w-full overflow-x-hidden overflow-ellipsis p-2 text-center text-lg font-bold">
              {name}
            </span>
          </div>
          <div className="flex w-full whitespace-nowrap">
            <span className="block w-full overflow-x-hidden overflow-ellipsis text-center italic underline">
              {description}
            </span>
          </div>
          <div className="flex w-full whitespace-nowrap">
            <a
              href={webUrl}
              target="_blank"
              className="block w-full overflow-x-hidden overflow-ellipsis text-center text-blue-500 underline"
            >
              {webUrl}
            </a>
          </div>
        </div>
      </div>
    );
  }
};
