/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_HandyMasterDisplay_ } from '../models/ApiResponse_HandyMasterDisplay_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HandyDmsActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_HandyMasterDisplay_ Success
     * @throws ApiError
     */
    public getHandyMasterInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_HandyMasterDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/HandyDMSActions/GetHandyMasterInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public updateHandyMasterInfo(
        formData: {
            fileName?: string;
            baseFolder?: string;
            isTrust?: boolean;
            userPmsReference?: string;
            unknownClient?: string;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/HandyDMSActions/UpdateHandyMasterInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
