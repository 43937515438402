/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * Client(0)
 * Year(1)
 * FileName(2)
 */
export enum DMSDocOptionType {
    Client = 0,
    Year = 1,
    FileName = 2,
}
