import { useParams } from "react-router-dom";
import { PMS } from "../../../generated";
import { XeroSetup } from "./Xero/XeroSetup";
import { APSCloud } from "./APSCloud/APSCloud";
import { CCH } from "./CCH/CCH";

const pmsScreens: { pms: number; element: React.FC<{ custId: number }> }[] = [
  { pms: PMS.XPM, element: XeroSetup },
  { pms: PMS.APSCloud, element: APSCloud },
  { pms: PMS.CCH, element: CCH },
];

export const PmsDetail = () => {
  const params = useParams();
  const pmsId = parseInt(params.pmsId ?? "");
  const custId = parseInt(params.id ?? "");

  const pmsScreen = pmsScreens.find((screen) => screen.pms === pmsId);

  if (pmsScreen) {
    const PMS = pmsScreen.element;
    return <PMS custId={custId} />;
  }

  return <div>Not supported</div>;
};
