/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * Default(0)
 * Agent(1)
 * GoogleDrive(2)
 * Sharepoint(3)
 * DropBox(4)
 * OneDrive(5)
 * Box(6)
 */
export enum IngestType {
    Default = 0,
    Agent = 1,
    GoogleDrive = 2,
    Sharepoint = 3,
    DropBox = 4,
    OneDrive = 5,
    Box = 6,
}
