/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_HowNowMasterDisplay_ } from '../models/ApiResponse_HowNowMasterDisplay_';
import type { HowNowMasterDisplay } from '../models/HowNowMasterDisplay';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HowNowDmsActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_HowNowMasterDisplay_ Success
     * @throws ApiError
     */
    public getHowNowMasterInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_HowNowMasterDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/HowNowDMSActions/GetHowNowMasterInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public updateHowNowMasterInfo(
        formData: {
            masterDisplay?: HowNowMasterDisplay;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/HowNowDMSActions/UpdateHowNowMasterInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
