import axios from "axios";

export interface AppSearchConfig {
  searchKey: string | (() => Promise<string>);
  engineName: string;
  endpoint: string;
}

export interface AppSearchRequest<T> {
  query: string;
  facets?: { [index in keyof T]?: { size: number; type: "value" } };
  filters?: {
    all?: { [index in keyof T]?: (string | number)[] };
    any?: { [index in keyof T]?: (string | number)[] };
    none?: { [index in keyof T]?: (string | number)[] };
  };
  sort?: { [index in keyof T]?: "asc" | "desc" }[];
  page: { current: number; size: number };
}

const axiosInstance = axios.create({});

export const SendAppSearchRequest = async <T>(
  config: AppSearchConfig,
  request: AppSearchRequest<T>,
  abortSignal?: AbortSignal
) => {
  const key = typeof config.searchKey === "string" ? config.searchKey : await config.searchKey();

  const response = await axiosInstance.request<AppSearchResponse<T>>({
    method: "POST",
    baseURL: `${config.endpoint}/api/as/v1/engines/${config.engineName}/search.json`,
    headers: {
      Authorization: "Bearer " + key,
    },
    data: request,
    signal: abortSignal,
  });

  return response;
};

export interface AppSearchResponse<T> {
  meta: {
    alerts: [];
    warnings: [];
    precision: number;
    engine: {
      name: string;
      type: string;
    };
    page: {
      current: number;
      total_pages: number;
      total_results: number;
      size: number;
    };
  };
  results: { [index in keyof T]: { raw: string | number | null } }[];
  facets: {
    [index in keyof T]: {
      type: string;
      data: { value: string | number; count: number }[];
    }[];
  };
}
