import { useAuth0 } from "@auth0/auth0-react";
import headerLogo from "../img/header-logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

export const UserSection = () => {
  const { user, logout } = useAuth0();

  const [isUserExpanded, setIsUserExpanded] = useState<Boolean>(false);

  const ExpandClick = () => {
    setIsUserExpanded(!isUserExpanded);
  };

  return (
    <div className="w-52">
      <div className="flex h-full flex-shrink-0 select-none items-center justify-end gap-2 py-1.5">
        <div
          className="flex aspect-square h-full cursor-pointer items-center justify-center rounded-full bg-transparent p-px"
          onClick={ExpandClick}
        >
          <img className="rounded-full shadow-[0px_0px_0px_2px] shadow-white" src={user?.picture} />
        </div>
        <FontAwesomeIcon
          className={`cursor-pointer text-white transition-transform ${isUserExpanded ? "rotate-180" : ""}`}
          icon={faSvg.faChevronDown}
          onClick={ExpandClick}
        />
        <div className="h-0 w-0">
          <div
            className={`relative mt-6 ${isUserExpanded ? "h-16" : "h-0"} z-50
                flex w-52 flex-shrink-0
                -translate-x-full flex-col 
                overflow-clip rounded-bl-md bg-black
                text-lg text-white transition-all`}
          >
            <Link
              to="/Profile"
              className="flex h-full cursor-pointer items-center justify-center gap-2 transition-colors hover:text-blue-400"
            >
              <FontAwesomeIcon icon={faSvg.faUser} /> Profile
            </Link>
            <div className="h-px bg-white" />
            <div
              className="flex h-full cursor-pointer items-center justify-center gap-2  transition-colors hover:text-red-400"
              onClick={() => logout({ logoutParams: { returnTo: window.location.href } })}
            >
              <FontAwesomeIcon icon={faSvg.faSignOut} /> Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface HeaderContent {
  name: string;
  icon?: faSvg.IconDefinition;
  path: string;
  scope: string;
}

const ATOHeader = (props: { items: HeaderContent[] }) => {
  const location = useLocation();

  return (
    <header className="flex h-12 flex-none flex-row justify-between bg-black">
      <div className="w-52">
        <NavLink className="flex h-full w-52 flex-shrink-0 py-1.5 pl-5" to={"/"}>
          <img src={headerLogo} />
        </NavLink>
      </div>
      <div className="flex gap-4 text-white">
        {props.items.map((item, i) => {
          const isCurrent = location.pathname.startsWith(item.path);

          return (
            <Link
              key={item.name}
              to={item.path}
              className="group flex h-full cursor-pointer select-none flex-col justify-between"
            >
              <div
                className={`${isCurrent ? "bg-primary" : ""} h-1 w-full transition-colors group-hover:bg-blue-800`}
              />
              <div className="flex items-center gap-2 px-2 uppercase tracking-wide">
                {item.icon && <FontAwesomeIcon icon={item.icon} />}
                {item.name}
              </div>
              <div className="h-1 w-full" />
            </Link>
          );
        })}
      </div>
      <UserSection />
    </header>
  );
};

export default ATOHeader;
