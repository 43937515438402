import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { useAMClient, useATOAuth } from "../../Hooks/ATOAuthHook";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ATOScopeChecker } from "../../Components/ATOScopeChecker";
import { ATOButton, ButtonType } from "../../Components/ATOButton";
import { useConfig } from "../../Hooks/UseConfigHook";
import { PracticeConfigDetail } from "../../generated";
import { useEffect, useState } from "react";
import { ATOLoading } from "../../Components/ATOSpinner";
import { Communication } from "./Communication/Communication";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../App";
import { ATODefaultAPIErrorModal } from "../../Components/Modal/ATODefaultAPIErrorModal";
import { SecureDeliveryConfig } from "./Other/SecureDeliveryConfig";
import { Accounting } from "./Accounting/Accounting";
import { ATOCredentials } from "./ATO/ATOCredentials";
import { Security } from "./Security/Security";
import { DmsDetail } from "./Dms/DmsDetail";
import { XeroSetup } from "./PMS/Xero/XeroSetup";
import { PmsDetail } from "./PMS/PmsDetail";

export const SettingsNavigation = ({ custId }: { custId?: number }) => {
  const config = useConfig();
  const user = useATOAuth();

  const amClient = useAMClient(config.practiceConfigUrl, user, custId);

  const [isDataLoading, setIsDataLoading] = useState(true);

  const [practiceDetail, setPracticeDetail] = useState<PracticeConfigDetail>();

  const setModal = useSetRecoilState(AppModalAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isLoading === false) {
      setIsDataLoading(true);
      amClient.practiceConfig
        .getPracticeConfig({})
        .then((result) => setPracticeDetail(result.data ?? {}))
        .catch((ex) =>
          setModal(
            <ATODefaultAPIErrorModal
              error={ex}
              onButton={() => {
                setModal(undefined);
                navigate("../");
              }}
            />
          )
        )
        .finally(() => setIsDataLoading(false));
    }
  }, [user]);

  const isLoading = isDataLoading || user.isLoading;

  const routes = [
    { name: "Practice Detail", link: "./", icon: faSvg.faClipboardList, requiredScope: undefined },
    { name: "Communication", link: "./Communication", icon: faSvg.faMailBulk },
    { name: "Accounting", link: "./Accounting", icon: faSvg.faBook },
    { name: "Security", link: "./Security", icon: faSvg.faLock },
    { name: "ATO", link: "./Ato", icon: faSvg.faDollarSign },
    { name: practiceDetail?.pms ?? "PMS", link: `./Pms/${practiceDetail?.pmsId}`, icon: faSvg.faUserFriends },
    ...(practiceDetail?.pms !== practiceDetail?.dms
      ? [{ name: practiceDetail?.dms ?? "DMS", link: `./Dms/${practiceDetail?.dmsId}`, icon: faSvg.faArchive }]
      : []),
    { name: "Communication Preferences", link: "./Commpref", icon: faSvg.faListCheck },
    { name: "Other", link: "./Other", icon: faSvg.faSms },
  ];

  const location = useLocation();

  const routeSplit = location.pathname.split("/");
  let currentRoute = routeSplit[routeSplit.length - 1];
  if (currentRoute === "" || currentRoute === "Settings") {
    currentRoute = "./";
  }

  if (isLoading) {
    return <ATOLoading />;
  }

  return (
    <div className="flex h-full w-full flex-col p-10 pt-0">
      <div className="flex justify-between py-6">
        <h1 className="text-3xl">Practice Settings</h1>
        <ATOButton
          className="flex items-center gap-2"
          buttonType={ButtonType.Primary}
          onClick={() =>
            window.open(
              "https://baw.atlassian.net/wiki/spaces/AG/pages/34013356/Managing+Practice+Settings+in+Dashboard",
              "_blank"
            )
          }
        >
          <FontAwesomeIcon icon={faSvg.faQuestionCircle} />
          <p>HELP</p>
        </ATOButton>
      </div>
      <header className="flex flex-wrap border-l border-gray-300">
        {routes.map((r) => {
          const content = (
            <Link
              key={r.name}
              to={r.link}
              className={`text-nowrap text-sm uppercase ${r.link.endsWith(currentRoute) ? "border-b-primary text-primary" : "border-b-gray-300 text-black"} translate-y-px border-b border-r border-t border-gray-300 px-4 py-3 tracking-wide hover:text-primary`}
            >
              {r.name}
            </Link>
          );

          if (r.requiredScope) {
            return (
              <ATOScopeChecker key={r.name} user={user} scope={r.requiredScope} denyAction={"hide"}>
                {content}
              </ATOScopeChecker>
            );
          }

          return content;
        })}
      </header>
      <div className="h-full border border-gray-300">
        <Routes>
          <Route path="/" element={<>Details</>} />
          <Route path="/Communication" element={<Communication />} />
          <Route path="/Accounting" element={<Accounting />} />
          <Route path="/Security" element={<Security practiceDetail={practiceDetail!} amClient={amClient} />} />
          <Route path="/Ato" element={<ATOCredentials />} />

          <Route path="/Dms/:dmsId" element={<DmsDetail />} />
          <Route path="/Pms/:pmsId" element={<PmsDetail />} />

          <Route path="/Commpref" element={<>Commpref</>} />
          <Route path="/Other" element={<SecureDeliveryConfig />} />
        </Routes>
      </div>
    </div>
  );
};
