import { atom } from "recoil";

export const PracticeListingFilterAtom = atom<{ key: string, value: string }[]>(
    {
        key: "PracticeListingFilterAtom",
        default: []
    }
);

export const PracticeListingFilterExpandAtom = atom<string[]>(
    {
        key: "PracticeListingFilterExpandAtom",
        default: []
    }
);