/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_FyiPracticeInfo_ } from '../models/ApiResponse_FyiPracticeInfo_';
import type { ApiResponse3 } from '../models/ApiResponse3';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FyiDmsActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_FyiPracticeInfo_ Success
     * @throws ApiError
     */
    public getPracticeInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_FyiPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/FyiDMSActions/GetPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_FyiPracticeInfo_ Success
     * @throws ApiError
     */
    public saveAndTestEnrolmentInfo(
        formData: {
            accessKey?: string;
            accessSecret?: string;
        },
    ): CancelablePromise<ApiResponse_FyiPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/FyiDMSActions/SaveAndTestEnrolmentInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse3 Success
     * @throws ApiError
     */
    public savePracticeInfo(
        formData: {
            fileName?: string;
            unknownClientId?: string;
            portalUrl?: string;
        },
    ): CancelablePromise<ApiResponse3> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/FyiDMSActions/SavePracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse3 Success
     * @throws ApiError
     */
    public createCollaborateCabinet(
        formData: any,
    ): CancelablePromise<ApiResponse3> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/FyiDMSActions/CreateCollaborateCabinet',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
