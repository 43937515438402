import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { useRecoilValue } from "recoil";
import { DMSDocOptionsAtom } from "./Atoms/DMSDocOptionsAtom";
import { DMSDocTypesAtom } from "./Atoms/DMSDocTypesAtom";

import { useState } from "react";
import { DMSDocCustomModal } from "./DMSDocCustomModal";
import { PendingChanges, usePendingChanges } from "../../Hooks/PendingChangesHook";
import { DMSDocOptionDisplay, DMSDocOptionType, DMSDocTypeDisplay } from "../../generated";
import { ATODropDown, OldATODropDown } from "../ATODropDown";
import { IDMSDoc } from "./IDMSDoc";
import { DMSDocSetupColumnInfo } from "./DMSDocSetupColumnInfo";
export const DMSDocCell = <TDMSDoc extends IDMSDoc>({
  columnInfo,
  value,
  rowIndex,
  isDefaultDocType,
  existingDocs,
  // pendingChangesKey,
  setModal,
  pendingChanges,
}: {
  columnInfo: DMSDocSetupColumnInfo<TDMSDoc>;
  setModal: (elem: React.ReactNode) => void;
  value: TDMSDoc;
  rowIndex: number;
  isDefaultDocType: boolean;
  existingDocs?: TDMSDoc[];
  // pendingChangesKey?: string;
  pendingChanges: PendingChanges<TDMSDoc>;
}) => {
  // const pendingChanges = usePendingChanges<TDMSDoc>();
  const valueKey = columnInfo.valueKey ?? (columnInfo.columnOptionType === "DocType" ? "DocID" : "");
  const actualValue = value[valueKey];
  const hasChange = pendingChanges.hasChange(rowIndex.toString(), valueKey) || value["isNew"] !== undefined;
  const isMandatory = columnInfo.isMandatory?.(value, columnInfo, rowIndex) ?? false;
  const isRequiredEmpty = isMandatory && (!actualValue || actualValue === "");
  const docTypes = useRecoilValue(DMSDocTypesAtom);
  const docOptions = useRecoilValue(DMSDocOptionsAtom);
  const display = columnInfo.getValue?.(value, columnInfo, rowIndex);

  const filterTypes = (search: string, selectedItem: DMSDocTypeDisplay) => {
    const isDefaultItemText = search.trim() === "" || selectedItem.docName?.toLowerCase() === search.toLowerCase();
    const existingDocIds = existingDocs?.map((ed) => ed.DocID.toString());
    return docTypes
      .filter((dt) => (isDefaultItemText ? true : dt.docName?.toLowerCase().includes(search.toLowerCase())))
      .filter((dt) => !existingDocIds?.includes(dt.docID?.toString() ?? ""));
  };
  if (columnInfo.columnOptionType === "DocType") {
    if (isDefaultDocType) {
      return <div className="flex items-center justify-center">Default</div>;
    }
    const docId = parseInt(value.DocID);
    const matchedType = docTypes.find((t) => t.docID === docId);
    return (
      <div className="relative h-full w-full">
        <div className="absolute h-full w-full pr-2">
          <OldATODropDown<DMSDocTypeDisplay>
            className={`h-full`}
            textboxClassName={`text-center ${hasChange ? "" : ""}`}
            initialValue={matchedType}
            isAutoComplete
            getData={async (search) =>
              filterTypes(search, matchedType!).sort((a, b) => a.docName!.localeCompare(b.docName ?? ""))
            }
            getDataKey={(t) => t?.docID + ""}
            getDataDisplay={(t) => <>{t.docName}</>}
            getDataName={(t) => t?.docName ?? ""}
            onChange={(t) => {
              if (t !== undefined) {
                pendingChanges.setChange(rowIndex.toString(), "DocID", t.docID);
              }
              return false;
            }}
          />
        </div>
      </div>
    );
  }
  let currentOptions = [...(docOptions?.[0] ?? []), ...(docOptions?.[1] ?? []), ...(docOptions?.[2] ?? [])];
  if (typeof columnInfo.columnOptionType === "number") {
    currentOptions = docOptions?.[columnInfo.columnOptionType] ?? [];
  }
  if (columnInfo.getDatasource) {
    currentOptions = columnInfo.getDatasource(value, columnInfo, rowIndex, currentOptions, "");
  }
  let matchedOption = currentOptions?.find((o) => o.value === actualValue && o.description !== "Header");
  const isCustomisable = columnInfo.allowCustom?.(value, columnInfo, rowIndex) ?? false;
  const wrapOptions = (opts: DMSDocOptionDisplay[]) => [
    ...(isMandatory
      ? []
      : [
          {
            name: columnInfo.columnOptionType === DMSDocOptionType.FileName ? "Default" : "N/A",
            example: "No Value",
            value: undefined,
          },
        ]),
    ...(columnInfo.popupInitialHeaderText ? [{ name: columnInfo.popupInitialHeaderText, description: "Header" }] : []),
    ...(currentOptions ?? []),
    ...(isCustomisable
      ? [{ name: `Custom`, description: "Your Custom Value", example: "Custom", value: actualValue }]
      : []),
  ];
  const actualOptions = wrapOptions(currentOptions);
  if (matchedOption === undefined) {
    if ((actualValue?.length ?? 0) > 0 && isCustomisable) {
      matchedOption = actualOptions[actualOptions.length - 1];
    } else if (!isMandatory) {
      matchedOption = actualOptions[0];
    }
  }
  const getCurrentOptions = (search: string) => {
    if (columnInfo.getDatasource) {
      return wrapOptions(columnInfo.getDatasource(value, columnInfo, rowIndex, currentOptions, search));
    }
    return actualOptions;
  };
  return (
    <>
      {display ?? (
        <div className="flex h-full items-center justify-center">
          <div className="relative h-full w-full">
            <div className="absolute flex h-full w-full flex-col items-center px-2">
              <OldATODropDown<DMSDocOptionDisplay>
                className={`h-full w-full  ${hasChange ? "bg-yellow-100" : ""} ${isRequiredEmpty ? "bg-red-100" : ""}`}
                popupHeightRem={24}
                popupStyle={columnInfo.popupStyle}
                textboxClassName={`w-full ${matchedOption !== undefined && matchedOption.name !== "Custom" ? "text-center" : ""} ${hasChange ? "bg-yellow-100" : ""} ${isRequiredEmpty ? "bg-red-100" : ""} ${matchedOption === undefined ? "text-center" : ""}`}
                initialValue={matchedOption}
                getData={async (search) => getCurrentOptions(search)}
                getDataKey={(t) => t?.value + ""}
                getDataDisplay={(t) => {
                  const exampleText = (t.name !== t.example ? t.example : t.value ?? t.example) ?? "";
                  const isHeader = t.description === "Header";
                  return (
                    <div
                      className={`group grid flex-grow-0 select-none grid-flow-dense grid-cols-[1fr_1fr] gap-2 ${isHeader ? "bg-primary pl-2 text-left text-lg text-white" : "p-2"}`}
                      onClick={(event) => isHeader && event.stopPropagation()}
                    >
                      <div className={`${isHeader || !exampleText ? "col-span-2" : "text-left"}`}>{t.name}</div>
                      {exampleText && (
                        <div className="min-w-full overflow-hidden text-ellipsis whitespace-nowrap pr-1 text-right italic">
                          {exampleText}
                        </div>
                      )}
                      {t.description !== undefined && !isHeader && (
                        <div className="col-span-2 text-left text-sm">
                          <div className="border-t-0 border-black text-gray-600">{t.description}</div>
                        </div>
                      )}
                    </div>
                  );
                }}
                getDataName={(t) => t?.name ?? "Custom"}
                onItemClick={(t) => {
                  if (t?.name === "Custom") {
                    setModal(
                      <DMSDocCustomModal
                        // pendingChangesKey={pendingChangesKey}
                        pendingChanges={pendingChanges}
                        columnInfo={columnInfo}
                        rowIndex={rowIndex}
                        setModal={setModal}
                        value={value}
                      />
                    );
                  }
                }}
                onChange={(t) => {
                  if (t !== undefined && t.name !== "Example") {
                    pendingChanges.setChange(rowIndex.toString(), columnInfo.valueKey, t.value);
                  }
                  return false;
                }}
              />
              {matchedOption !== undefined && matchedOption.name === "Custom" && (
                <div className="pointer-events-none h-0 w-full select-none pl-16 pr-6 text-right text-xs text-gray-400">
                  <div className="h-6 w-full -translate-y-full overflow-clip text-ellipsis whitespace-nowrap">
                    {matchedOption.value}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
