import { useParams } from "react-router-dom";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { CCHAtom } from "./Atoms/CCHAtom";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOSuccessModal } from "../../../../Components/ATOSuccessModal";
import { ATOSpinner } from "../../../../Components/ATOSpinner";
import CchLogo from "../CCH/cch-logo.jpg";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { CchPMSFieldsDisplay, CchPracticeInfo } from "../../../../generated";
import { useSinglePendingChanges } from "../../../../Hooks/SinglePendingChagesHook";
import { ATOSwitch } from "../../../../Components/ATOSwitch";
import { ATOTextBox } from "../../../../Components/ATOTextBox";

export const CCH = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const params = useParams();
  const amClient = useAMClient(config.cchUrl, user, custId);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [modal, setModal] = useState<React.ReactNode | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [cchFormCompleted, setCchFormCompleted] = useState(false);
  const [unmodifiedCchPracticeInfo, setUnmodifiedCchPracticeInfo] = useRecoilState(CCHAtom);

  const pendingChanges = useSinglePendingChanges<CchPracticeInfo>();
  const hasChanges = pendingChanges.listChanges().length > 0;

  const practiceInfo = pendingChanges.applyChanges(unmodifiedCchPracticeInfo ?? {});

  const getPracticeInfo = () =>
    amClient?.cchActions
      .getCchPracticeInfo({})
      .then((res) => {
        setUnmodifiedCchPracticeInfo(res?.data ?? {});
        setIsDataLoaded(true);
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />))
      .finally(() => {
        setIsLoading(false);
      });

  useEffect(() => {
    getPracticeInfo();
  }, []);

  const filterCchPMSField = (search?: string) => {
    const resp = practiceInfo.cchPmsFieldsDisplay?.filter((f) => f.fieldType === search);

    return resp;
  };

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    amClient?.cchActions
      .updateCchMaster({ cchPracticeInfo: practiceInfo })
      .then((res) => {
        setUnmodifiedCchPracticeInfo(res.data ?? {});
        pendingChanges.removeAllChanges();
        setIsLoading(false);
        setModal(<ATOSuccessModal setModal={setModal} />);
        setIsModified(false);
      })
      .catch((ex) => {
        setIsLoading(false);
        setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />);
      });
  }, [amClient, practiceInfo]);

  if (amClient === undefined || isDataLoaded === false || isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOSpinner />
        {modal}
      </div>
    );
  }

  return (
    <div className="h-full w-full overflow-y-auto overflow-x-hidden">
      <div className="h-full w-full p-3">
        <div className="flex justify-center">
          <img src={CchLogo} className="mt-2 w-36 flex-shrink-0" />
        </div>
        {practiceInfo.isConnected && (
          <div className="flex w-full flex-col items-center justify-center">
            <div className="grid w-full gap-4" style={{ gridTemplateColumns: "repeat(1, 1fr)" }}>
              <div className="flex flex-col items-center justify-center px-3 py-2">
                <div className="font-semibold text-primary">CCH iFirm Website URL</div>
                <ATOTextBox
                  value={practiceInfo?.apiUrl ?? ""}
                  className="h-full w-1/4"
                  onChange={(val) => pendingChanges.setChange("apiUrl", val)}
                />
              </div>
              <div className="flex flex-col items-center justify-center px-3 py-2">
                <div className="font-semibold text-primary">Family Groups</div>
                <ATOSwitch
                  className="text-center"
                  size="medium"
                  value={practiceInfo?.familyGroups ?? false}
                  onChange={(val) => pendingChanges.setChange("familyGroups", val)}
                />
              </div>
              <div className="flex flex-col items-center justify-center px-3 py-2">
                <div className="font-semibold text-primary">Client Group</div>
                <ATODropDown<CchPMSFieldsDisplay>
                  className="h-8 w-1/4"
                  value={practiceInfo?.userPmsReference ?? {}}
                  getOptions={() =>
                    filterCchPMSField(practiceInfo?.userPmsReference?.fieldType ?? "UserPMSReference") ?? []
                  }
                  getDisplay={(data) => data?.description ?? "N/A"}
                  onChange={(val) => pendingChanges.setChange("userPmsReference", val)}
                />
              </div>
              <div className="flex flex-col items-center justify-center px-3 py-2">
                <div className="font-semibold text-primary">DMS Client Identifier</div>
                <ATODropDown<CchPMSFieldsDisplay>
                  className="h-8 w-1/4"
                  value={practiceInfo?.dmsPrimary ?? {}}
                  getOptions={() => filterCchPMSField(practiceInfo?.dmsPrimary?.fieldType ?? "dmsPrimary") ?? []}
                  getDisplay={(data) => data?.description ?? "N/A"}
                  onChange={(val) => pendingChanges.setChange("dmsPrimary", val)}
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center p-2">
              <ATOButton buttonType={ButtonType.Confirm} onClick={handleSubmit} disabled={!hasChanges}>
                Update
              </ATOButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
