/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * WindowsFolder(1)
 * MYOBDocumentManager(2)
 * VirtualCabinet(3)
 * APSADM(4)
 * FYIDocs(5)
 * IManage(6)
 * SuiteFiles(7)
 * HowNow(8)
 * HandiSoft(9)
 * Nimbus(10)
 * GoogleDrive(11)
 * HowNowX(12)
 * SharePoint(13)
 * OneDrive(14)
 * Dropbox(15)
 * IManageCloud(17)
 * KloudConnect(19)
 */
export enum DMSName {
    WindowsFolder = 1,
    MYOBDocumentManager = 2,
    VirtualCabinet = 3,
    APSADM = 4,
    FYIDocs = 5,
    IManage = 6,
    SuiteFiles = 7,
    HowNow = 8,
    HandiSoft = 9,
    Nimbus = 10,
    GoogleDrive = 11,
    HowNowX = 12,
    SharePoint = 13,
    OneDrive = 14,
    Dropbox = 15,
    IManageCloud = 17,
    KloudConnect = 19,
}
