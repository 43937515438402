import { useCallback, useState } from "react";
import { CustomFieldDefinition, XeroActions } from "../../../../generated";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { ATOModalDouble } from "../../../../Components/Modal/ATOModalSingleDouble";
import { ModalTypes } from "../../../../Components/Modal/ATOModalTypes";
import { ATODropDown } from "../../../../Components/ATODropDown";

export const XeroCustomFieldModal = ({
  setModal,
  setIsLoading,
  apiClient,
  setIsReloading,
}: {
  setModal: (modal: React.ReactNode) => void;
  setIsLoading: (val: boolean) => void;
  apiClient: XeroActions;
  setIsReloading: (val: boolean) => void;
}) => {
  const [newCustomField, setNewCustomField] = useState<CustomFieldDefinition>({
    name: "",
    type: "Text",
    options: [""],
  });

  const handleCustomFieldOnchange = useCallback(
    (val: CustomFieldDefinition | string | number | boolean | string[], name: string) => {
      setNewCustomField((prevCustomFieldInfo) => ({
        ...prevCustomFieldInfo,
        [name]: val,
      }));
    },
    [newCustomField]
  );

  return (
    <ATOModalDouble
      className="h-[50vh] w-[600px] p-2"
      title="Create new Custom Field?"
      message={
        <div className="w-1/2">
          <p className="w-full text-start">Custom Field Name</p>
          <ATOTextBox
            name="name"
            className="w-full text-start"
            onChange={(val) => {
              handleCustomFieldOnchange(val, "name");
            }}
            value={newCustomField?.name || ""}
          ></ATOTextBox>
          <p className="mt-3 w-full text-start">Custom Field Type</p>
          <ATODropDown<string>
            className={`h-full w-full`}
            getDisplay={(r) => r}
            getOptions={() => ["Checkbox", "Dropdown", "Text"]}
            onChange={(val) => {
              handleCustomFieldOnchange(val, "type");
            }}
            value={newCustomField?.type || ""}
          />
          {newCustomField.type === "Dropdown" && (
            <div>
              <p className="mt-3 h-full w-full text-start">Options</p>
              {Object.keys(newCustomField?.options ?? {}).map((key, index) => (
                <div className="justify-between" key={key}>
                  <ATOTextBox
                    name={`options`}
                    className="mb-2 mr-3 w-4/5 text-start"
                    value={newCustomField?.options?.[Number(key)] ?? ""}
                    onChange={(val) => {
                      const newOptions = [...(newCustomField?.options ?? [])];
                      newOptions[Number(key)] = val;
                      handleCustomFieldOnchange(newOptions, `options`);
                    }}
                  ></ATOTextBox>
                  {index === (newCustomField?.options?.length || 0) - 1 ? (
                    <ATOButton
                      className="py-1 shadow-md"
                      buttonType={ButtonType.Confirm}
                      onClick={async () => {
                        setNewCustomField({
                          ...newCustomField,
                          options: [...(newCustomField?.options ?? []), ""],
                        });
                      }}
                    >
                      +
                    </ATOButton>
                  ) : (
                    <ATOButton
                      className="py-1 shadow-md"
                      buttonType={ButtonType.Error}
                      onClick={async () => {
                        const updatedOptions = (newCustomField?.options ?? []).filter(
                          (option, optionIndex) => optionIndex !== index
                        );
                        setNewCustomField({
                          ...newCustomField,
                          options: updatedOptions,
                        });
                      }}
                    >
                      -
                    </ATOButton>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      }
      onClose={() => setModal(undefined)}
      onLeftButton={() => setModal(undefined)}
      onRightButton={() => {
        setIsLoading(true);
        setModal(undefined);

        apiClient
          .createCustomField({
            customFields: newCustomField,
          })
          .catch((ex) => setModal(<ATODefaultAPIErrorModal onButton={() => setModal(undefined)} error={ex} />))
          .finally(() => {
            setIsReloading(true);
          });
      }}
      rightButton="Confirm"
    />
  );
};
export const HandleFeeForServiceAction = ({
  setModal,
  setIsLoading,
  apiClient,
}: {
  setModal: (modal: React.ReactNode) => void;
  setIsLoading: (val: boolean) => void;
  apiClient: XeroActions;
}) => {
  return (
    <ATOModalDouble
      type={ModalTypes.warning}
      className="w-[600px] p-2"
      message="Do you want to add a custom field for Fee For Service in your Xero account?"
      title="WARNING"
      onClose={() => setModal(undefined)}
      onLeftButton={() => setModal(undefined)}
      onRightButton={() => {
        setIsLoading(true);
        setModal(undefined);
        const field: CustomFieldDefinition = {
          name: "ATOmate Fee For Service",
          type: "Checkbox",
        };

        handleCreateCustomFieldAction(field, setIsLoading, setModal, apiClient);
      }}
      rightButton="Yes"
    />
  );
};

export const HandleCreateCorrespondenceDeliveryAction = ({
  setModal,
  setIsLoading,
  apiClient,
}: {
  setModal: (modal: React.ReactNode) => void;
  setIsLoading: (val: boolean) => void;
  apiClient: XeroActions;
}) => {
  return (
    <ATOModalDouble
      type={ModalTypes.warning}
      className="w-[600px] p-2"
      message="Do you want to add a custom field for Correspondence Delivery Method in your Xero account?"
      title="WARNING"
      onClose={() => setModal(undefined)}
      onLeftButton={() => setModal(undefined)}
      onRightButton={() => {
        setIsLoading(true);
        setModal(undefined);
        const field: CustomFieldDefinition = {
          name: "Correspondence Delivery Method",
          type: "Dropdown",
        };

        handleCreateCustomFieldAction(field, setIsLoading, setModal, apiClient);
      }}
      rightButton="Yes"
    />
  );
};

export const handleCreateCustomFieldAction = async (
  field: CustomFieldDefinition,
  setIsLoading: (val: boolean) => void,
  setModal: (val: React.ReactNode) => void,
  apiClient: XeroActions
) => {
  await apiClient
    .createCustomField({ customFields: field })
    .finally(() => setIsLoading(false))
    .catch((ex) => {
      setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />);
    });
};
