/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_List_VcIndexInfo_ } from '../models/ApiResponse_List_VcIndexInfo_';
import type { ApiResponse_VcMasterDisplay_ } from '../models/ApiResponse_VcMasterDisplay_';
import type { VcIndexInfo } from '../models/VcIndexInfo';
import type { VcMasterDisplay } from '../models/VcMasterDisplay';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VirtualCabinetDmsActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_VcMasterDisplay_ Success
     * @throws ApiError
     */
    public getVcMasterInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_VcMasterDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/VirtualCabinetDmsActions/GetVcMasterInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public updateVcMasterInfo(
        formData: {
            masterDisplay?: VcMasterDisplay;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/VirtualCabinetDmsActions/UpdateVcMasterInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_List_VcIndexInfo_ Success
     * @throws ApiError
     */
    public getVcIndexInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_List_VcIndexInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/VirtualCabinetDmsActions/GetVcIndexInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public updateVcIndexInfo(
        formData: {
            indices?: Array<VcIndexInfo>;
            fileIndex?: number;
            filingCabinet?: string;
            clientIndex?: number;
            clientValue?: string;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/VirtualCabinetDmsActions/UpdateVcIndexInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
