import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ATOCheckBox } from "../../Components/ATOCheckBox";
import { useRecoilState } from "recoil";
import { PracticeListingFilterAtom, PracticeListingFilterExpandAtom } from "./PracticeListingAtoms";
import { PracticeListingDisplay } from "./PracticeListing";
import { AppSearchFacetRequest, AppSearchProvider, AppSerachFacet } from "../../Hooks/AppSearchHook";

export const PracticeListingFilter = ({
  listingKey,
  appSearch,
}: {
  listingKey: keyof PracticeListingDisplay;
  appSearch: AppSearchProvider<PracticeListingDisplay>;
}) => {
  const [expandedList, setExpandedList] = useRecoilState(PracticeListingFilterExpandAtom);

  const facetRequest = appSearch.facetRequests.value![listingKey];
  const facet = appSearch.result.facets[listingKey];

  const isExpanded = expandedList.findIndex((l) => l == listingKey) > -1;

  const setIsExpanded = (val: boolean) => {
    if (val) {
      setExpandedList([...expandedList, listingKey]);
    } else {
      setExpandedList(expandedList.filter((l) => l !== listingKey));
    }
  };

  if (!facet) {
    return <></>;
  }

  return (
    <div className="flex w-full flex-col border-b border-black text-left">
      <div
        className="flex flex-shrink-0 cursor-pointer select-none items-center justify-between bg-primary text-white"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="w-5" />
        {facetRequest?.display ?? listingKey}
        <div className="w-5">
          <FontAwesomeIcon
            icon={faSvg.faChevronDown}
            className={`transition-transform ${isExpanded ? "rotate-180" : ""}`}
          />
        </div>
      </div>
      <div
        className={`flex w-full origin-top flex-col overflow-clip border-black transition-all ${isExpanded ? "max-h-96" : "max-h-0"}`}
      >
        <div className="h-px w-full bg-black" />
        <div className="p-2">
          {facet.map((d) => (
            <PracticeListingFilterItem key={d.value} data={d} facetField={listingKey} context={appSearch} />
          ))}
        </div>
      </div>
    </div>
  );
};

const PracticeListingFilterItem = ({
  data,
  facetField,
  context,
}: {
  data: AppSerachFacet;
  facetField: keyof PracticeListingDisplay;
  context: AppSearchProvider<PracticeListingDisplay>;
}) => {
  const matchedFilterIndex = context.filters.values?.[facetField]?.findIndex((f) => f === data.value) ?? -1;
  const isSelected = matchedFilterIndex > -1;

  return (
    <div
      className="flex items-center justify-between gap-1 text-center"
      onClick={() => {
        if (!isSelected) {
          context.filters.AddFilterValue(facetField, data.value);
        } else {
          context.filters.RemoveFilterValue(facetField, data.value);
        }
      }}
    >
      <div className="text-center">
        <ATOCheckBox value={isSelected} />
      </div>
      <div className="w-full text-left">{data.value}</div>
      <div className="text-center">{data.count}</div>
    </div>
  );
};
