import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

import { Ref, RefObject } from "react";
import { CSSProperties, useEffect, useState } from "react";
import useDebounce from "../Hooks/DebounceHook";
import useDidMountEffect from "../Hooks/DidMountHook";

interface Props {
  id?: string;
  ref?: RefObject<HTMLInputElement>;
  name?: string;
  className?: string;
  hasChange?: boolean;
  hasError?: boolean;
  style?: CSSProperties;
  placeholder?: string;
  value: string;
  valuePrefix?: string;
  maxStringLength?: number;
  disallowZeroLength?: boolean;
  onChange: (e: string) => boolean | void;
  onDebounce?: (str: string) => void;
  disabled?: boolean;
  type?: string;
  onBlur?: () => void;
  hasClearButton?: boolean;
}

export const ATOTextBox = (props: Props) => {
  const debouncedValue = useDebounce(props.value, 250);

  const [showStringLengthWarning, setShowStringLengthWarning] = useState<boolean>(false);
  const [showStringLengthWarningTimer, setShowStringLengthWarningTimer] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (showStringLengthWarningTimer) {
      clearTimeout(showStringLengthWarningTimer);
    }

    setShowStringLengthWarningTimer(
      setTimeout(() => {
        setShowStringLengthWarning(false);
      }, 500)
    );

    return () => clearTimeout(showStringLengthWarningTimer);
  }, [showStringLengthWarning]);

  useEffect(() => {
    if (props.onDebounce) props.onDebounce(debouncedValue);
  }, [debouncedValue]);

  const onChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updateVal = true;

    if (props.maxStringLength !== undefined && e.target.value.length > props.maxStringLength) {
      updateVal = false;
      setShowStringLengthWarning(true);
    }

    if (!props.disabled && props.onChange !== undefined && updateVal) {
      if (props.onChange(e.target.value.substring(props.valuePrefix?.length ?? 0)) === false) {
        updateVal = false;
      }
    }

    if (updateVal) {
      props.onChange(e.target.value.substring(props.valuePrefix?.length ?? 0));
    }
  };

  let textLengthIndicatorColour = "green";

  if (props.maxStringLength == props.value.length) {
    textLengthIndicatorColour = "yellow";

    if (showStringLengthWarning) {
      textLengthIndicatorColour = "red";
    }
  }

  if (props.disallowZeroLength && props.value.length == 0) {
    textLengthIndicatorColour = "red";
  }

  return (
    <div
      className={`
    flex
    items-center
    gap-0.5
    overflow-clip
    rounded-lg px-1 shadow transition-shadow
    focus:shadow-active
    ${showStringLengthWarning ? "animate-distance-1 animate-shakeX !shadow-red-400 animate-infinite" : ""}
    ${props.disabled ?? false ? "pointer-events-none opacity-50" : ""} 
    ${props.hasChange ? "shadow-yellow-400" : ""}
    ${props.hasError ? "shadow-red-400" : ""}
    ${props.disallowZeroLength && props.value.length == 0 ? "!shadow-red-400" : ""}
    ${props.className ?? ""}
    `}
    >
      <input
        type={props.type ? props.type : "text"}
        autoComplete="off"
        data-lpignore="true"
        id={props.id}
        name={props.name}
        ref={props.ref}
        style={props.style}
        className={`h-full w-full flex-grow bg-transparent py-1`}
        placeholder={props.placeholder}
        value={(props.valuePrefix ?? "") + props.value}
        onChange={onChangeEvent}
        onBlur={props.onBlur}
        aria-autocomplete="none"
      />
      {props.hasClearButton && props.value.length > 0 && (
        <div className="cursor-pointer pt-0.5" onClick={() => props.onChange("")}>
          <FontAwesomeIcon icon={faSvg.faTimes} className="text-red-600" />
        </div>
      )}
      {props.maxStringLength && (
        <div
          className={`
                text-xs transition-colors 
                ${textLengthIndicatorColour == "red" ? "text-red-500" : ""}
                ${textLengthIndicatorColour == "yellow" ? "text-yellow-500" : ""}
                ${textLengthIndicatorColour == "green" ? "text-green-500" : ""} 
            `}
        >
          {props.value.length}/{props.maxStringLength}
        </div>
      )}
    </div>
  );
};
