import { Route, Routes } from "react-router-dom";
import PracticeListing from "./PracticeListing";
import { PracticeDetailNavigation } from "./Detail/PracticeDetailNavigation";

const Practices = () => {

    return <Routes>
        <Route path="/" element={<PracticeListing />} />
        <Route path="/:id/*" element={<PracticeDetailNavigation />} />
    </Routes>
}

export default Practices;