/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_IASCannedFileNoteDisplay_ } from '../models/ApiResponse_IASCannedFileNoteDisplay_';
import type { ApiResponse_PaygNoticeDisplay_ } from '../models/ApiResponse_PaygNoticeDisplay_';
import type { PaygStatus } from '../models/PaygStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IasPaygNoticeActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_PaygNoticeDisplay_ Success
     * @throws ApiError
     */
    public updatePaygNoticeStatus(
        formData: {
            id?: number;
            fileNote?: string;
            fileNoteId?: number;
            status?: PaygStatus;
        },
    ): CancelablePromise<ApiResponse_PaygNoticeDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/IASPaygNoticeActions/UpdatePaygNoticeStatus',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IASCannedFileNoteDisplay_ Success
     * @throws ApiError
     */
    public getCannedFileNotes(
        formData: any,
    ): CancelablePromise<ApiResponse_IASCannedFileNoteDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/IASPaygNoticeActions/GetCannedFileNotes',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
