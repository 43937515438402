/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedWorkflowUpdateRequest } from '../models/AdvancedWorkflowUpdateRequest';
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_AdvancedWorkflow_ } from '../models/ApiResponse_AdvancedWorkflow_';
import type { ApiResponse_bool_ } from '../models/ApiResponse_bool_';
import type { ApiResponse_IEnumerable_AdvancedWorkflow_ } from '../models/ApiResponse_IEnumerable_AdvancedWorkflow_';
import type { ApiResponse_IEnumerable_AdvancedWorkflowUser_ } from '../models/ApiResponse_IEnumerable_AdvancedWorkflowUser_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PracticeWorkflows {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_AdvancedWorkflow_ Success
     * @throws ApiError
     */
    public listAdvancedWorkflows(
        formData: {
            custId?: number;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_AdvancedWorkflow_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeWorkflows/ListAdvancedWorkflows',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_AdvancedWorkflow_ Success
     * @throws ApiError
     */
    public getAdvancedWorkflowDetail(
        formData: {
            custId?: number;
            clientGroupId?: number;
        },
    ): CancelablePromise<ApiResponse_AdvancedWorkflow_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeWorkflows/GetAdvancedWorkflowDetail',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_AdvancedWorkflowUser_ Success
     * @throws ApiError
     */
    public getAdvancedWorkflowUsers(
        formData: {
            custId?: number;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_AdvancedWorkflowUser_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeWorkflows/GetAdvancedWorkflowUsers',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_bool_ Success
     * @throws ApiError
     */
    public getWorkflowEnabledStatus(
        formData: {
            custId?: number;
        },
    ): CancelablePromise<ApiResponse_bool_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeWorkflows/GetWorkflowEnabledStatus',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public saveWorkflow(
        formData: {
            request?: AdvancedWorkflowUpdateRequest;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeWorkflows/SaveWorkflow',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
