import { useNavigate } from "react-router-dom";
import { useAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { useEffect, useState } from "react";
import { ATOModalSingle } from "../../../Components/Modal/ATOModalSingle";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../App";
import { ATOSuccessModal } from "../../../Components/ATOSuccessModal";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATODropDown } from "../../../Components/ATODropDown";
import { CustomerDisplay } from "../../../generated";
import { ATOMutliSelect } from "../../../Components/ATOMutliSelect";
import { ATOSwitch } from "../../../Components/ATOSwitch";
import { faL } from "@fortawesome/free-solid-svg-icons";

export const CopyTemplateTool = () => {
  const user = useATOAuth();
  const config = useConfig();
  const amClient = useAMClient(config.adminUrl, user);
  const navigate = useNavigate();

  const setModal = useSetRecoilState(AppModalAtom);

  const [customers, setCustomers] = useState<CustomerDisplay[]>([]);
  const [sourcePracticeId, setSourcePracticeId] = useState(0);
  const [copyToPracticeId, setCopyToPracticeId] = useState(0);
  const [sourceFromAdmin, setSourceFromAdmin] = useState(false);
  const [copiedStatus, setCopyStatus] = useState(false);
  const [copyInProgress, setCopyInProgressFlag] = useState(false);

  useEffect(() => {
    getCustomers("");
  }, []);

  const getCustomers = async (searchText: string) => {
    const resp = (await amClient?.template.getCustomerList({ searchText: searchText })).data!;

    const newCustomersArray = resp.map((c) => ({
      custId: c.custId,
      custName: c.custName,
    }));

    setCustomers(newCustomersArray);

    return newCustomersArray;
  };

  const confirmModal = (
    <div>
      Are you sure you want to copy templates from
      {sourcePracticeId === 0 ? " admin" : sourcePracticeId.toString()} to {copyToPracticeId}?
    </div>
  );
  const showConfirmModal = () => {
    setModal(
      <ATOModalSingle
        removeCloseButton={false}
        buttonText="Yes"
        onButton={() => copyTemplate()}
        title="Confirm"
        message={confirmModal}
        onClose={() => setModal(undefined)}
      />
    );
  };

  const copyTemplate = () => {
    setModal(undefined);
    setCopyStatus(false);
    setCopyInProgressFlag(true);
    amClient?.template
      .copyTemplates({ sourcePracticeId: sourcePracticeId, copyToPracticeId: copyToPracticeId })
      .then((res) => {
        setModal(<ATOSuccessModal setModal={setModal} />);
        setCopyStatus(true);
      })
      .catch((ex) => {
        setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />);
      })
      .finally(() => {
        setCopyInProgressFlag(false);
        setCopyToPracticeId(0);
        setSourcePracticeId(0);
      });
  };

  const getSelectedOptions = (custId: number | undefined) => {
    var selectedCustIds = [custId];
    const selectableUsers = customers.filter((user) => selectedCustIds.includes(user.custId!));

    return selectableUsers;
  };

  const getSelectableOptions = (search: string, custId: number | undefined) => {
    var selectedCustIds = [custId];
    const selectableUsers = customers.filter((user) => !selectedCustIds.includes(user.custId!));

    search = search.toLowerCase();
    return selectableUsers.filter(
      (user) =>
        user.custId?.toString().includes(search) || user.custName?.toLowerCase().includes(search) || search === ""
    );
  };

  return (
    <div className="flex h-full w-full overflow-hidden">
      <div className="grid w-full grid-rows-[min-content_1fr]">
        <div className="flex-col">
          <div className={`flex w-full items-center justify-between border-b border-black py-2 `}>
            <div className="w-[100px]" />
            <div>
              {<h1 className="text-center text-xl">Copy Templates</h1>}
              <p className="mt-1 text-sm leading-6 text-gray-600">
                This tool copies templates from one practice to another.
              </p>
            </div>
            <div />
          </div>
          <div className=" grid h-full w-screen grid-cols-[1fr_2fr_1fr] pt-8">
            <div></div>
            <div className="flex w-full flex-col gap-2  p-2 ">
              <div className="flex gap-2">
                <div>Reset to default BAW templates</div>

                <ATOSwitch
                  onChange={() => {
                    setSourceFromAdmin(!sourceFromAdmin);
                    setSourcePracticeId(0);
                  }}
                  value={sourceFromAdmin}
                />
              </div>

              {sourceFromAdmin === false && (
                <>
                  <label htmlFor="sourcePracticeId" className="block ">
                    Source Practice Id
                  </label>
                  <div>
                    <ATOMutliSelect<CustomerDisplay>
                      getOptions={(search) => getSelectableOptions(search, sourcePracticeId)}
                      selectedOptions={() => getSelectedOptions(sourcePracticeId)}
                      onItemClick={(item) =>
                        sourcePracticeId !== item.custId ? setSourcePracticeId(item.custId!) : setSourcePracticeId(0)
                      }
                      getDataKey={(user) => `${user?.custId} - ${user?.custName} `}
                      getItemDisplay={(user) => {
                        return (
                          <>
                            {user?.custId}&nbsp;-&nbsp;{user?.custName}
                          </>
                        );
                      }}
                    />
                  </div>
                </>
              )}

              <label htmlFor="copyToPracticeId" className="block">
                Copy to Practice Id
              </label>
              <div>
                <ATOMutliSelect<CustomerDisplay>
                  getOptions={(search) => getSelectableOptions(search, copyToPracticeId)}
                  selectedOptions={() => getSelectedOptions(copyToPracticeId)}
                  onItemClick={(item) =>
                    copyToPracticeId !== item.custId ? setCopyToPracticeId(item.custId!) : setCopyToPracticeId(0)
                  }
                  getDataKey={(user) => `${user?.custId} - ${user?.custName} `}
                  getItemDisplay={(user) => {
                    return (
                      <>
                        {user?.custId}&nbsp;-&nbsp;{user?.custName}
                      </>
                    );
                  }}
                />
              </div>
              {copiedStatus && (
                <div className="animate-pulse font-bold text-green-700"> Templates have been copied successfully. </div>
              )}
              <div className="flex w-full justify-center">
                <ATOButton
                  className="items-center"
                  buttonType={ButtonType.Primary}
                  onClick={() => showConfirmModal()}
                  disabled={
                    copyInProgress || copyToPracticeId === 0 || (sourceFromAdmin === false && sourcePracticeId === 0)
                  }
                >
                  {copyInProgress ? "Copying..." : "Submit"}
                </ATOButton>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
