import { useParams } from "react-router-dom";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { useCallback, useEffect, useState } from "react";
import { XeroPMSFieldsDisplay, XeroPracticeInfo } from "../../../../generated";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOModalSingle } from "../../../../Components/Modal/ATOModalSingle";
import { useRecoilState } from "recoil";
import { AppModalAtom } from "../../../../App";
import { ATOLoading, ATOSpinner } from "../../../../Components/ATOSpinner";
import { xeroMasterState } from "./Atoms/XeroSetupAtom";
import { ATOSuccessModal } from "../../../../Components/ATOSuccessModal";
import {
  HandleCreateCorrespondenceDeliveryAction,
  HandleFeeForServiceAction,
  XeroCustomFieldModal,
} from "./XeroCustomFieldModals";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { Tooltip } from "../../../../Components/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { ATOCheckBox } from "../../../../Components/ATOCheckBox";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { ATOModalDouble } from "../../../../Components/Modal/ATOModalSingleDouble";
import { ModalTypes } from "../../../../Components/Modal/ATOModalTypes";
import { handleConnectAction, HandleDisconnectAction } from "./XeroConnectionActions";
import toast, { Toaster } from "react-hot-toast";
import { ATOSwitch } from "../../../../Components/ATOSwitch";

export const XeroSetup = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const params = useParams();
  const amClient = useAMClient(config.xeroUrl, user, custId);

  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [modal, setModal] = useState<React.ReactNode | undefined>(undefined);
  const [xeroCustomFieldsDisplay, setXeroCustomFields] = useState<XeroPMSFieldsDisplay[]>([]);
  const [practiceInfo, setPracticeInfo] = useRecoilState(xeroMasterState);
  const [isReloading, setIsReloading] = useState(false);

  const getPracticeInfo = () =>
    amClient.xeroActions
      ?.getXeroPracticeInfo({})
      .then((res) => {
        if (res.data) {
          setPracticeInfo(res.data);
          if (res.data.isConnected) {
            setIsConnected(true);
            getCustomFieldsData();
          }
        }

        if (res.message !== null) {
          setModal(
            <ATOModalSingle
              title={"Error with Xero connection"}
              onButton={() => setModal(undefined)}
              message={
                <p>
                  <b>{res.message}</b>
                  <br />
                  Please disconnect and reconnect, ensuring the user connecting has appropriate permissions
                </p>
              }
            />
          );
        }
      })
      .catch((ex: any) => {
        setModal(
          <ATODefaultAPIErrorModal
            error={
              <p>
                <b>
                  {ex.title}: {ex.message}
                </b>
                <br />
                Please disconnect & reconnect, ensuring the user connecting has appropriate permissions
              </p>
            }
            onButton={() => setModal(undefined)}
          />
        );
      })
      .finally(() => {
        setIsLoading(false);
      });

  useEffect(() => {
    getPracticeInfo();
  }, []);

  useEffect(() => {
    if (isReloading === true) {
      setIsReloading(false);
      setIsLoading(true);
      getPracticeInfo();
    }
  }, [isReloading]);

  const handleOnchange = useCallback((val: XeroPMSFieldsDisplay | string | number | boolean, name: string) => {
    setIsModified(true);

    setPracticeInfo((prevPracticeInfo) => ({
      ...prevPracticeInfo,
      [name]: val,
    }));
  }, []);

  const filterXeroPMSField = (search?: string) => {
    const resp = practiceInfo.xeroPMSFieldsDisplay?.filter((f) => f.fieldType === search) ?? [];
    return resp;
  };

  const getCustomFieldsData = async () => {
    const resp = (await amClient.xeroActions.getCustomFields({})).data;
    const newCustomFieldsArray = resp?.map((cfd) => ({
      fieldCode: cfd.name,
      description: cfd.name,
    }));
    setXeroCustomFields(newCustomFieldsArray!);
  };

  const handleUnknownClientAction = async () => {
    await amClient.xeroActions
      .createUnknownClient({})
      .finally(() => {
        setIsReloading(true);
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  };

  const handleUserCreateNewCustomFieldAction = () => {
    setModal(
      <XeroCustomFieldModal
        setModal={setModal}
        setIsLoading={setIsLoading}
        setIsReloading={setIsReloading}
        apiClient={amClient.xeroActions}
      />
    );
  };

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    amClient.xeroActions
      ?.updateXeroMaster({ xeroPracticeInfo: practiceInfo })
      .then((res) => {
        setPracticeInfo(res?.data as XeroPracticeInfo);
        setIsLoading(false);
        setModal(<ATOSuccessModal setModal={setModal} />);
        setIsModified(false);
      })
      .catch((ex) => {
        setIsLoading(false);
        setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />);
      });
  }, [practiceInfo]);

  return (
    <div className="h-full w-full overflow-y-auto overflow-x-hidden">
      <div className="h-full w-full p-3">
        <div className="mx-auto grid grid-cols-[22.5%_22.5%_20%_25%_10%] space-y-5">
          {isConnected ? (
            <>
              <div className="flex h-min w-full text-start">Family Group</div>
              <div className="h-4 w-full border-gray-100 text-start">
                <ATOSwitch
                  className="text-center"
                  size="medium"
                  value={practiceInfo?.familyGroups ?? false}
                  onChange={(val: boolean) => {
                    handleOnchange(val, "familyGroups");
                  }}
                />
              </div>
              <p className="flex h-min w-full text-start">DMS Client Identifier</p>
              <ATODropDown<XeroPMSFieldsDisplay>
                className={`h-8 w-full`}
                value={practiceInfo?.dmsPrimary ?? {}}
                getOptions={() => filterXeroPMSField(practiceInfo.dmsPrimary?.fieldType ?? "DMSPrimary")}
                getDisplay={(data) => data?.description ?? "N/A"}
                onChange={(val) => {
                  handleOnchange(val, "dmsPrimary");
                }}
              />
              <div></div>
              <div className="flex h-min w-full text-start">
                API Client Group
                <Tooltip className="ml-2 h-min w-min">
                  <div
                    className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
                  >
                    <div className=" h-4 w-4 rotate-45 bg-black" />
                    <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                      Use standard options available, turn off to use a custom field. <br />
                      Custom field must be added to XPM Client & Contact Report.
                    </p>
                  </div>
                </Tooltip>
              </div>
              <div className="h-4 w-full border-gray-100 text-start">
                <ATOSwitch
                  className="text-center"
                  size="medium"
                  value={practiceInfo?.userPmsReferenceApi ?? false}
                  onChange={(val: boolean) => {
                    handleOnchange(val, "userPmsReferenceApi");
                    if (val === false) {
                      getCustomFieldsData();
                    }
                  }}
                />
              </div>
              {!practiceInfo.userPmsReferenceApi ? (
                <>
                  <p className="flex h-min w-full text-start">Custom Client Group</p>
                  {/* custom field */}
                  <ATODropDown<XeroPMSFieldsDisplay>
                    className={`mr-2 h-8 w-full flex-grow`}
                    value={practiceInfo.userPmsReference ?? {}}
                    getOptions={() => xeroCustomFieldsDisplay}
                    getDisplay={(data) => (data?.description != undefined ? data.description : "N/A")}
                    onChange={(val) => handleOnchange(val, "userPmsReference")}
                  />
                  <div>
                    <Tooltip
                      icon={
                        <ATOButton
                          className="py-1 text-sm shadow-sm"
                          buttonType={ButtonType.Confirm}
                          onClick={async () => {
                            handleUserCreateNewCustomFieldAction();
                          }}
                        >
                          +
                        </ATOButton>
                      }
                    >
                      <div
                        className="flex h-0 -translate-x-[calc(100%+50px)]
                                -translate-y-1/2
                                items-center justify-center text-white"
                      >
                        <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Add new custom field</p>
                        <div className="-ml-[9px] h-4 w-4 rotate-45 bg-black" />
                      </div>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <>
                  <p className="flex h-min w-full text-start">Client Group</p>
                  <ATODropDown<XeroPMSFieldsDisplay>
                    className={`h-8 w-full`}
                    value={practiceInfo?.userPmsReference ?? {}}
                    getOptions={() =>
                      filterXeroPMSField(practiceInfo.userPmsReference?.fieldType ?? "UserPMSReference")
                    }
                    getDisplay={(data) => data?.description ?? "N/A"}
                    onChange={(val) => {
                      handleOnchange(val, "userPmsReference");
                    }}
                  />
                  <div></div>
                </>
              )}
              <div className="flex h-min w-full text-start">
                API DMS Secondary
                <Tooltip className="ml-2 h-min w-min">
                  <div
                    className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
                  >
                    <div className=" h-4 w-4 rotate-45 bg-black" />
                    <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                      Use standard options available, turn off to use a custom field. <br />
                      Custom field must be added to XPM Client & Contact Report.
                    </p>
                  </div>
                </Tooltip>
              </div>
              <ATOSwitch
                className="text-center"
                size="medium"
                value={practiceInfo?.dmsSecondaryApi ?? false}
                onChange={(val: boolean) => {
                  handleOnchange(val, "dmsSecondaryApi");
                  if (val === false) {
                    getCustomFieldsData();
                  }
                }}
              />
              {!practiceInfo.dmsSecondaryApi ? (
                <>
                  <p className="flex h-min w-full text-start">Custom DMS Secondary</p>
                  {/* custom field */}
                  <ATODropDown<XeroPMSFieldsDisplay>
                    className={`mr-2 h-8 w-full flex-grow`}
                    value={practiceInfo?.dmsSecondary ?? {}}
                    getOptions={() => xeroCustomFieldsDisplay}
                    getDisplay={(data) => (data?.description != undefined ? data.description : "")}
                    onChange={(val) => handleOnchange(val, "dmsSecondary")}
                  />
                  <div>
                    <Tooltip
                      icon={
                        <ATOButton
                          className="py-1 text-sm shadow-md"
                          buttonType={ButtonType.Confirm}
                          onClick={async () => {
                            handleUserCreateNewCustomFieldAction();
                          }}
                        >
                          +
                        </ATOButton>
                      }
                    >
                      <div
                        className="flex h-0 -translate-x-[calc(100%+50px)]
                                -translate-y-1/2
                                items-center justify-center text-white"
                      >
                        <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Add new custom field</p>
                        <div className="-ml-[9px] h-4 w-4 rotate-45 bg-black" />
                      </div>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <>
                  <p className="flex h-min w-full text-start">DMS Secondary</p>
                  <ATODropDown<XeroPMSFieldsDisplay>
                    className={`h-8 w-full`}
                    value={practiceInfo?.dmsSecondary ?? {}}
                    getOptions={() => filterXeroPMSField(practiceInfo.dmsPrimary?.fieldType ?? "DMSPrimary")}
                    getDisplay={(data) => data?.description ?? "N/A"}
                    onChange={(val) => {
                      handleOnchange(val, "dmsSecondary");
                    }}
                  />
                  <div></div>
                </>
              )}
              <div className="flex h-min w-full text-start">
                API Salutation
                <Tooltip className="ml-2 h-min w-min">
                  <div
                    className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
                  >
                    <div className=" h-4 w-4 rotate-45 bg-black" />
                    <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                      Use standard salutation field, turn off to use a custom field. <br />
                      Custom field must be added to XPM Client & Contact Report.
                    </p>
                  </div>
                </Tooltip>
              </div>
              <div className="h-4 w-full border-gray-100 text-start">
                <ATOSwitch
                  className="text-center"
                  size="medium"
                  value={practiceInfo?.salutationAPI ?? false}
                  onChange={(val: boolean) => {
                    handleOnchange(val, "salutationAPI");
                  }}
                />
              </div>
              {!practiceInfo.salutationAPI ? (
                <>
                  <p className="flex h-min w-full text-start">Custom Salutation</p>
                  {/* custom field */}

                  <ATODropDown<XeroPMSFieldsDisplay>
                    className="mr-2 h-8 w-full flex-grow"
                    value={practiceInfo?.salutation ?? {}}
                    getOptions={() => xeroCustomFieldsDisplay}
                    getDisplay={(data) => (data?.description != undefined ? data.description : "")}
                    onChange={(val) => handleOnchange(val, "salutation")}
                  />
                  <div>
                    <Tooltip
                      icon={
                        <ATOButton
                          className="py-1 text-sm shadow-sm"
                          buttonType={ButtonType.Confirm}
                          onClick={async () => {
                            handleUserCreateNewCustomFieldAction();
                          }}
                        >
                          +
                        </ATOButton>
                      }
                    >
                      <div
                        className="flex h-0 -translate-x-[calc(100%+50px)]
                                -translate-y-1/2
                                items-center justify-center text-white"
                      >
                        <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Add new custom field</p>
                        <div className="-ml-[9px] h-4 w-4 rotate-45 bg-black" />
                      </div>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <div className="col-span-3 h-8"></div>
              )}
              <div className="flex h-min w-full text-start">
                Use Contact Position
                <Tooltip className="ml-2 h-min w-min">
                  <div
                    className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
                  >
                    <div className=" h-4 w-4 rotate-45 bg-black" />
                    <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                      Use the Contact Position field to set the primary contact person.
                    </p>
                  </div>
                </Tooltip>
              </div>
              <div className="h-4 w-full border-gray-100 text-start">
                <ATOSwitch
                  className="text-center"
                  size="medium"
                  value={practiceInfo?.useContactPosition ?? false}
                  onChange={(val: boolean) => {
                    handleOnchange(val, "useContactPosition");
                    if (val === false) {
                      handleOnchange(false, "isContactPositionRequired");
                    } else {
                      handleOnchange(false, "isPrimaryRequired");
                    }
                  }}
                />
              </div>
              {practiceInfo.useContactPosition ? (
                <>
                  <p className="flex h-min w-full">Contact Position</p>
                  <div className="h-4 w-full border-gray-100 ">
                    <ATOTextBox
                      name="contactPosition"
                      className={`h-7 ${
                        practiceInfo.contactPosition !== undefined &&
                        practiceInfo.contactPosition != null &&
                        practiceInfo.contactPosition.trim().length < 3
                          ? "shadow-red-400"
                          : ""
                      }`}
                      value={practiceInfo.contactPosition ?? "ATOmate"}
                      onChange={(val) => {
                        handleOnchange(val, "contactPosition");
                      }}
                    />
                  </div>
                  <div className="flex h-min w-full self-end">
                    <Tooltip icon={<p className="mx-2 text-sm">Set Required</p>}>
                      <div
                        className="flex h-0 -translate-x-[calc(100%+50px)]
                                -translate-y-1/2
                                items-center justify-center text-white"
                      >
                        <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">
                          Requires a contact to have the Contact Position field set to the nominated (non-null) text.{" "}
                          <br />
                          Contacts without the text: '{practiceInfo.contactPosition ?? "ATOmate"}' in the Position field
                          will not be imported.
                        </p>
                        <div className="-ml-[9px] h-4 w-4 rotate-45 bg-black" />
                      </div>
                    </Tooltip>
                    <ATOCheckBox
                      name="isContactPositionRequired"
                      className="form-checkbox h-5 w-5 rounded border border-primary text-primary shadow-md"
                      value={practiceInfo?.isContactPositionRequired ?? false}
                      onChange={(val) => {
                        handleOnchange(val, "isContactPositionRequired");
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex h-min w-full text-start">
                    Require Primary Contact
                    <Tooltip className="ml-2 h-min w-min">
                      <div
                        className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
                      >
                        <div className=" h-4 w-4 rotate-45 bg-black" />
                        <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                          Use the Primary field to set the primary contact person. <br /> Contacts not marked as Primary
                          in XPM will not be imported.
                        </p>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="h-4 w-full border-gray-100 text-start">
                    <ATOSwitch
                      className="text-center"
                      size="medium"
                      value={practiceInfo?.isPrimaryRequired ?? false}
                      onChange={(val: boolean) => {
                        handleOnchange(val, "isPrimaryRequired");
                      }}
                    />
                  </div>
                  <div></div>
                </>
              )}

              <div className="flex h-min w-full text-start">
                Import CC Emails
                <Tooltip className="ml-2 h-min w-min">
                  <div
                    className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
                  >
                    <div className=" h-4 w-4 rotate-45 bg-black" />
                    <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                      Use the Contact Position field to nominate additional contacts to CC all emails for a particular
                      client to.
                      <br />
                      Additional contacts without the nominated wording will not be imported.
                    </p>
                  </div>
                </Tooltip>
              </div>
              <div className="h-4 w-full border-gray-100 text-start">
                <ATOSwitch
                  className="text-center"
                  size="medium"
                  value={practiceInfo?.usePositionCC ?? false}
                  onChange={(val: boolean) => {
                    if (val) {
                      setModal(
                        <ATOModalDouble
                          type={ModalTypes.warning}
                          className="w-[600px] p-2"
                          message={
                            <p>
                              Enabling Position CC disables the default hierarchical contacts lookup.
                              <br />
                              <br />
                              This will require the Primary contact field to be selected in XPM, unless the Use Contact
                              Position has been configured and set to required.
                              <br />
                              <br />
                              Do you want to continue?
                            </p>
                          }
                          title="WARNING"
                          onClose={() => setModal(undefined)}
                          onLeftButton={() => setModal(undefined)}
                          onRightButton={() => {
                            setModal(undefined);
                            handleOnchange(val, "usePositionCC");
                            if (!practiceInfo.isPrimaryRequired && !practiceInfo.isContactPositionRequired) {
                              handleOnchange(true, "isPrimaryRequired");
                            }
                          }}
                          rightButton="Yes"
                        />
                      );
                    } else {
                      handleOnchange(val, "usePositionCC");
                    }
                  }}
                />
              </div>
              {practiceInfo.usePositionCC ? (
                <>
                  <p className="flex h-min w-full">Contact Position CC</p>
                  <div className="h-4 w-full border-gray-100 ">
                    <ATOTextBox
                      name="positionCC"
                      className={`h-7 ${
                        practiceInfo.positionCC !== undefined &&
                        practiceInfo.positionCC !== null &&
                        practiceInfo.positionCC.trim().length < 3
                          ? "shadow-red-400"
                          : ""
                      }`}
                      value={practiceInfo?.positionCC ?? ""}
                      onChange={(val) => {
                        handleOnchange(val, "positionCC");
                      }}
                    />
                  </div>
                  <div></div>
                </>
              ) : (
                <div className="col-span-3"></div>
              )}

              <div className="flex h-min w-full text-start">
                Fee For Service
                <Tooltip className="ml-2 h-min w-min">
                  <div
                    className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
                  >
                    <div className=" h-4 w-4 rotate-45 bg-black" />
                    <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                      Enable additional templates, rules, and settings for Fee For Service. <br />
                      For more information, click the Help button.
                    </p>
                  </div>
                </Tooltip>
              </div>
              <div className="h-4 w-full border-gray-100 text-start">
                <ATOSwitch
                  className="text-center"
                  size="medium"
                  value={practiceInfo?.isTrust ?? false}
                  onChange={(val: boolean) => {
                    handleOnchange(val, "isTrust");
                  }}
                />
              </div>
              <div className="col-span-5">
                <hr className="mx-auto w-3/4" />
                <p className="text-center text-lg">Create XPM Configurations</p>
                <hr className="mx-auto w-3/4" />
              </div>
              <div className="flex h-min w-full">
                <Tooltip
                  icon={
                    <ATOButton className="text-sm" buttonType={ButtonType.Primary} onClick={handleUnknownClientAction}>
                      Create Unknown Client
                    </ATOButton>
                  }
                >
                  <div
                    className="flex h-0 -translate-y-1/2
                                    items-center
                                    justify-center pl-2 text-white"
                  >
                    <div className="h-4 w-4 rotate-45 bg-black" />
                    <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                      Create an XPM client for associating documents to when the client is not found.
                    </p>
                  </div>
                </Tooltip>
              </div>
              {practiceInfo.unknownClientInfo != null && (
                <div className="col-span-4">
                  <p className="w-full text-start text-sm">
                    Unknown Client Id: {practiceInfo.unknownClientInfo.id}
                    <ATOButton
                      className="ml-3 h-min w-min"
                      buttonType={ButtonType.Accent}
                      onClick={() => {
                        navigator.clipboard.writeText(practiceInfo?.unknownClientInfo?.id ?? "");
                        toast.custom(
                          <div className="inline-flex items-center justify-center rounded-lg bg-primary px-4 py-2 text-white shadow-sm transition-colors">
                            <p>Copied!</p>
                          </div>,
                          {
                            position: "bottom-right",
                            id: "id-clipboard",
                            duration: 1000,
                          }
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faSvg.faCopy} />
                    </ATOButton>
                    <br />
                    Unknown Client UUID: {practiceInfo.unknownClientInfo.uuid}
                    <ATOButton
                      className="ml-3 h-min w-min"
                      buttonType={ButtonType.Accent}
                      onClick={() => {
                        navigator.clipboard.writeText(practiceInfo?.unknownClientInfo?.uuid ?? "");
                        toast.custom(
                          <div className="inline-flex items-center justify-center rounded-lg bg-primary px-4 py-2 text-white shadow-sm transition-colors">
                            <p>Copied!</p>
                          </div>,
                          {
                            position: "bottom-right",
                            id: "uuid-clipboard",
                            duration: 1000,
                          }
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faSvg.faCopy} />
                    </ATOButton>
                  </p>
                </div>
              )}
              <div className="flex h-min w-full">
                <Tooltip
                  icon={
                    <ATOButton
                      className="text-sm"
                      buttonType={ButtonType.Primary}
                      onClick={() => {
                        setModal(
                          <HandleFeeForServiceAction
                            setModal={setModal}
                            setIsLoading={setIsLoading}
                            apiClient={amClient.xeroActions}
                          />
                        );
                      }}
                    >
                      Create Fee For Service
                    </ATOButton>
                  }
                >
                  <div
                    className="flex h-0 -translate-y-1/2
                                    items-center
                                    justify-center pl-2 text-white"
                  >
                    <div className="h-4 w-4 rotate-45 bg-black" />
                    <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                      If using Fee For Service (refund), create a checkbox in XPM to link clients.
                    </p>
                  </div>
                </Tooltip>
              </div>
              <div className="col-span-2 flex h-min w-full">
                <ATOButton
                  className="text-sm"
                  buttonType={ButtonType.Primary}
                  onClick={() => {
                    setModal(
                      <HandleCreateCorrespondenceDeliveryAction
                        setModal={setModal}
                        setIsLoading={setIsLoading}
                        apiClient={amClient.xeroActions}
                      />
                    );
                  }}
                >
                  Create Correspondence Delivery Method
                </ATOButton>
              </div>
              <div className="col-span-5 flex w-full place-items-center justify-start py-5">
                <ATOButton buttonType={ButtonType.Confirm} onClick={handleSubmit} disabled={!isModified}>
                  Update
                </ATOButton>
              </div>
              {modal}
              <Toaster />
            </>
          ) : (
            <div className="col-span-5 flex flex-col items-center">Customer is not connected to XPM</div>
          )}
        </div>
      </div>
    </div>
  );
};
