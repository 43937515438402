/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_IEnumerable_ModuleDetail_ } from '../models/ApiResponse_IEnumerable_ModuleDetail_';
import type { ApiResponse_IEnumerable_ToolDetail_ } from '../models/ApiResponse_IEnumerable_ToolDetail_';
import type { ApiResponse_int_ } from '../models/ApiResponse_int_';
import type { ApiResponse_PracticeDetail_ } from '../models/ApiResponse_PracticeDetail_';
import type { PracticeDetail } from '../models/PracticeDetail';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PracticeDetails {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_PracticeDetail_ Success
     * @throws ApiError
     */
    public getPracticeDetails(
        formData: {
            custId?: number;
        },
    ): CancelablePromise<ApiResponse_PracticeDetail_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeDetails/GetPracticeDetails',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_int_ Success
     * @throws ApiError
     */
    public savePracticeDetails(
        formData: {
            detail?: PracticeDetail;
        },
    ): CancelablePromise<ApiResponse_int_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeDetails/SavePracticeDetails',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_ModuleDetail_ Success
     * @throws ApiError
     */
    public getModuleList(
        formData: any,
    ): CancelablePromise<ApiResponse_IEnumerable_ModuleDetail_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeDetails/GetModuleList',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_ToolDetail_ Success
     * @throws ApiError
     */
    public getToolList(
        formData: any,
    ): CancelablePromise<ApiResponse_IEnumerable_ToolDetail_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/PracticeDetails/GetToolList',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
