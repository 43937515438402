import { useEffect, useState } from "react";
import { useATOAuth } from "../../Hooks/ATOAuthHook";
import { AMClient, TemplateAppSearchDisplay } from "../../generated";
import { useConfig } from "../../Hooks/UseConfigHook";
import { ATODefaultAPIErrorModal } from "../../Components/Modal/ATODefaultAPIErrorModal";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../App";
import { ATOLoading } from "../../Components/ATOSpinner";
import { TemplateListingRow } from "./TemplateListingRow";
import { AppSearchConfig } from "../../Helpers/AppSearchApi";
import { BaseAppSearchModel, useAppSearch } from "../../Hooks/AppSearchHook";
import { ATODropDown } from "../../Components/ATODropDown";
import { ATOTextBox } from "../../Components/ATOTextBox";
import { ATOButton, ButtonType } from "../../Components/ATOButton";

export type TemplateListingDisplay = TemplateAppSearchDisplay & BaseAppSearchModel<TemplateAppSearchDisplay>;

export const TemplateListing = () => {
  const user = useATOAuth();
  const config = useConfig();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [query, setQuery] = useState("");

  const apiClient = new AMClient({
    BASE: config.adminUrl,
    VERSION: "2",
    TOKEN: user.getAccessToken,
  });

  const appSearchTemplatesConfig: AppSearchConfig = {
    engineName: "templates",
    searchKey: user.getAccessToken!,
    endpoint: config.adminUrl,
  };

  const appSearch = useAppSearch<TemplateListingDisplay>(
    appSearchTemplatesConfig,
    {
      doc_name: {
        size: 250,
        type: "value",
      },
      doc_category: {
        size: 10,
        type: "value",
      },
      is_template_published: {
        size: 2,
        type: "value",
      },
      doc_id: {
        size: 250,
        type: "value",
      },
      is_prn: {
        size: 2,
        type: "value",
      },
      template_category: {
        size: 5,
        type: "value",
      },
    },
    [{ doc_id: "asc" }, { doc_cond_name: "asc" }],
    undefined,
    undefined,
    300
  );

  const appSearchFilter = useAppSearch<TemplateListingDisplay>(
    appSearchTemplatesConfig,
    {
      doc_name: {
        size: 250,
        type: "value",
      },
      doc_category: {
        size: 10,
        type: "value",
      },
      is_template_published: {
        size: 2,
        type: "value",
      },
      doc_id: {
        size: 250,
        type: "value",
      },
      is_prn: {
        size: 2,
        type: "value",
      },
      template_category: {
        size: 5,
        type: "value",
      },
    },
    [{ doc_name: "asc" }],
    undefined,
    undefined,
    300
  );

  if (isLoading || user.isLoading) {
    return <ATOLoading />;
  }

  const docCategoryFacets = appSearchFilter.result.facets.doc_category;
  const docCategories = ["All", ...(docCategoryFacets?.flatMap((x) => x.value + "").sort() ?? [])];
  const selectedDocCategory = (appSearch.filters.values?.doc_category?.at(0) ?? "All") + "";
  const docCategoryFilter = (filter: string) => {
    if (filter == "All") {
      appSearch.filters.RemoveFilterValue("doc_category", selectedDocCategory);
    } else {
      appSearch.filters.SetFilterValues("doc_category", [filter]);
    }
  };

  const templateCategoryFacets = appSearchFilter.result.facets.template_category;
  const templateCategories = ["All", ...(templateCategoryFacets?.flatMap((x) => x.value + "").sort() ?? [])];
  const selectedTemplateCategory = (appSearch.filters.values?.template_category?.at(0) ?? "All") + "";
  const templateCategoryFilter = (filter: string) => {
    if (filter == "All") {
      appSearch.filters.RemoveFilterValue("template_category", selectedTemplateCategory);
    } else {
      appSearch.filters.SetFilterValues("template_category", [filter]);
    }
  };

  let selectedPublishedStatus = (appSearch.filters.values?.is_template_published?.at(0) ?? "All") + "";
  const templatePublishedFilter = (filter: string) => {
    if (filter == "All") {
      appSearch.filters.SetFilterValues("is_template_published", []);
    } else {
      const published = filter.toLowerCase() === "published";
      selectedPublishedStatus = published + "";
      appSearch.filters.SetFilterValues("is_template_published", [selectedPublishedStatus]);
    }
  };

  let selectedPrnStatus = (appSearch.filters.values?.is_prn?.at(0) ?? "All") + "";
  const templatePrnFilter = (filter: string) => {
    if (filter == "All") {
      appSearch.filters.SetFilterValues("is_prn", []);
    } else {
      selectedPrnStatus = true + "";
      appSearch.filters.SetFilterValues("is_prn", [selectedPrnStatus]);
    }
  };

  const publishedOptions: Record<string, string> = {
    All: "All",
    true: "Published",
    false: "Unpublished",
  };

  const prnOptions: Record<string, string> = {
    All: "All",
    true: "PRN Only",
  };

  return (
    <div className="flex h-full flex-col gap-5">
      <div className="grid grid-cols-[1fr_1fr_1fr_1fr_150px] gap-2 pl-5 pr-10 pt-2">
        <div className="">
          <div className="p-1 text-center font-semibold">Document Category</div>
          <div className="w-full">
            <ATODropDown<String>
              className="h-8 bg-white"
              getOptions={() => docCategories}
              value={selectedDocCategory}
              getDisplay={(val) => val}
              onChange={(val) => docCategoryFilter(val.toString())}
            />
          </div>
        </div>
        <div className="">
          <div className="p-1 text-center font-semibold">Template Category</div>
          <div className="w-full">
            <ATODropDown<String>
              className="h-8 bg-white"
              getOptions={() => templateCategories}
              value={selectedTemplateCategory}
              getDisplay={(val) => val}
              onChange={(val) => templateCategoryFilter(val.toString())}
            />
          </div>
        </div>
        <div className="">
          <div className="p-1 text-center font-semibold">Template Status</div>
          <div className="w-full">
            <ATODropDown<String>
              className="h-8 bg-white"
              getOptions={() => Object.values(publishedOptions)}
              value={publishedOptions[selectedPublishedStatus]}
              getDisplay={(val) => val}
              onChange={(val) => templatePublishedFilter(val.toString())}
            />
          </div>
        </div>
        <div className="">
          <div className="p-1 text-center font-semibold">PRN</div>
          <div className="w-full">
            <ATODropDown<String>
              className="h-8 bg-white"
              getOptions={() => Object.values(prnOptions)}
              value={prnOptions[selectedPrnStatus]}
              getDisplay={(val) => val}
              onChange={(val) => templatePrnFilter(val.toString())}
            />
          </div>
        </div>
        <div className="">
          <div className="p-1 text-center font-semibold">&nbsp;</div>
          <div className="w-full">
            <ATOButton
              type="button"
              className="h-8 w-full"
              buttonType={ButtonType.Primary}
              onClick={() => {
                setQuery("");
                appSearch.filters.set(undefined);
              }}
            >
              Clear
            </ATOButton>
          </div>
        </div>
      </div>
      <div className="flex h-8 w-full gap-2 pl-5 pr-10">
        <ATOTextBox
          value={query}
          placeholder="Search"
          className="h-8 w-full border border-gray-300"
          onChange={setQuery}
          onDebounce={appSearch.query.set}
          hasClearButton={true}
        />
      </div>
      <div className="flex h-full flex-col gap-2 overflow-y-scroll px-5 pb-5">
        {appSearch.isLoading && <ATOLoading />}
        {appSearch.result.facets.doc_name?.map((template, i) => {
          if (template.count < 1) {
            return <></>;
          }

          return (
            <TemplateListingRow
              key={template.value}
              DocumentTypeName={template.value + ""}
              AppSearchTemplates={appSearch}
              ApiClient={apiClient}
            />
          );
        })}
      </div>
    </div>
  );
};
