import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Route, Routes } from "react-router-dom";
import { IASReportTool } from "./IASReportTool/IASReportTool";
import { DynamicTemplate } from "./DynamicTemplate/DynamicTemplate";
import { ATOAuthUser, useATOAuth } from "../../Hooks/ATOAuthHook";
import { ELearning } from "./ELearning/ELearning";
import { CopyTemplateTool } from "./CopyTemplate/CopyTemplate";

export const Tools = () => {
  const user = useATOAuth();

  return (
    <div className="flex h-screen w-screen flex-col">
      <div className="h-full overflow-auto">
        <Routes>
          <Route path="/*" element={<ToolTiles user={user} />} />
          <Route path="/IASUpload" element={<IASReportTool />} />
          <Route path="/DynamicTemplate*" element={<DynamicTemplate />} />
          <Route path="/ELearning/*" element={<ELearning />} />
          <Route path="/CopyTemplate*" element={<CopyTemplateTool />} />
        </Routes>
      </div>
    </div>
  );
};

const ToolTiles = ({ user }: { user: ATOAuthUser }) => {
  const routes = [
    { name: "IAS Upload", icon: faSvg.faUpload, path: "./IASUpload", scope: "bawadmin" },
    { name: "Dynamic Template", icon: faSvg.faFileInvoice, path: "./DynamicTemplate", scope: "bawadmin" },
    { name: "ELearning", icon: faSvg.faBookBookmark, path: "./ELearning", scope: "bawadmin" },
    { name: "Copy Template", icon: faSvg.faCopy, path: "./CopyTemplate", scope: "bawadmin" },
  ];

  const userScopes = user?.scope ? user.scope.split(" ") : [];
  const allowedRoutes = routes.filter((route) => userScopes.includes(route.scope));

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex w-fit gap-2 bg-white p-8 shadow-lg">
        {allowedRoutes.map((r) => (
          <NavLink
            key={r.name}
            to={r.path}
            className="flex h-32 w-32 flex-col items-center justify-around rounded-md border-2 text-center transition-colors hover:bg-gray-100"
          >
            <FontAwesomeIcon icon={r.icon} className="text-5xl" />
            <p className="uppercase">{r.name}</p>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
