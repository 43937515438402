import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef } from "react";
import { DMSDocCell } from "./DMSDocCell";
import { DMSDocSetupColumnInfo } from "./DMSDocSetupColumnInfo";
import { IDMSDoc } from "./IDMSDoc";
import { Tooltip } from "../Tooltip";
import { PendingChanges, usePendingChanges } from "../../Hooks/PendingChangesHook";
export const DMSDocRow = <TDMSDoc extends IDMSDoc>(props: {
  columnInfo: DMSDocSetupColumnInfo<TDMSDoc>[];
  setModal: (elem: React.ReactNode) => void;
  value?: TDMSDoc;
  isDefault?: boolean;
  // pendingChangesKey?: string;
  rowIndex: number;
  existingDocs?: TDMSDoc[];
  pendingChanges: PendingChanges<TDMSDoc>;
}) => {
  const isDefault = props.isDefault ?? false;
  const isHeader = props.value === undefined;
  const gridTemplate = props.columnInfo
    .filter((ci) => !ci.isHidden)
    .map((ci) => ci.width)
    .join(" 1px ");
  const splitterColour = isHeader ? "white" : "rgb(var(--colour-primary))";
  const bgColour = isHeader ? "rgb(var(--colour-primary))" : "white";
  const rowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.value?.isNew === "true") {
      rowRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [rowRef, props.value?.isNew]);
  return (
    <div
      ref={rowRef}
      style={{ gridTemplateColumns: gridTemplate, backgroundColor: bgColour }}
      className={`grid h-12
             flex-shrink-0 p-2
             ${isHeader ? "text-white" : ""}
             rounded-lg text-center
             shadow-lg`}
    >
      {props.columnInfo
        .filter((ci) => !ci.isHidden)
        .map((ci, i) =>
          isHeader ? (
            <div
              key={i}
              style={ci.style?.header}
              className="flex h-full min-w-0 items-center justify-center overflow-ellipsis whitespace-nowrap"
            >
              {ci.name === "Document Type" ? (
                <div className="flex w-full items-center justify-between gap-2 pr-1">
                  <div />
                  <p className="overflow-clip">{ci.name}</p>
                  <Tooltip icon={<FontAwesomeIcon icon={faSvg.faInfoCircle} />}>
                    <div
                      className="flex h-0 -translate-y-1/2
                                     items-center
                                     justify-center pl-2 text-white"
                    >
                      <div className="h-4 w-4 rotate-45 bg-black" />
                      <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                        This field is searchable
                      </p>
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <p className="overflow-clip">{ci.name}</p>
              )}
            </div>
          ) : (
            <DMSDocCell<TDMSDoc>
              columnInfo={ci}
              value={props.value!}
              key={`${props.rowIndex}-${i}`}
              isDefaultDocType={isDefault}
              existingDocs={props.existingDocs}
              rowIndex={props.rowIndex}
              setModal={props.setModal}
              // pendingChangesKey={props.pendingChangesKey}
              pendingChanges={props.pendingChanges}
            />
          )
        )
        .map((elem, i) => {
          if (i === 0) {
            return elem;
          }

          return (
            <>
              <div style={{ backgroundColor: splitterColour }} className="h-full w-px" />
              {elem}
            </>
          );
        })}
    </div>
  );
};
