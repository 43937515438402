import { Route, Routes } from "react-router-dom";
import { WorkflowDetailNavigation } from "./WorkflowDetailNavigation";
import { WorkflowListing } from "./WorkflowListing";

const Workflows = ({ custId }: { custId: number }) => {
  return (
    <Routes>
      <Route path="/" element={<WorkflowListing custId={custId} />} />
      <Route path="/:id/*" element={<WorkflowDetailNavigation custId={custId} />} />
    </Routes>
  );
};

export default Workflows;
