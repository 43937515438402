import React from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from '@fortawesome/free-solid-svg-icons';
import { ATOClickOutside } from "./ATOClickOutside";

interface Props {
    className?: string,
    title: string | React.ReactNode,
    children: React.ReactNode,
    footerContent?: React.ReactNode,
    titleClass?: string,
    IsBackgroundTransparent?: boolean,
    removeCloseButton?: boolean,
    onClose: (isClickOutside: boolean) => void
}

export const ATOModal = ({ className, children, title, footerContent, removeCloseButton, onClose, titleClass, IsBackgroundTransparent }: Props) => {

    return (
        <div className="fixed z-40 inset-0 overflow-y-auto" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className={`fixed inset-0 ${IsBackgroundTransparent ? "" : "bg-gray-500"} bg-opacity-75 transition-opacity`} aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all">
                    <ATOClickOutside onClick={() => onClose?.(true)}>
                        <div className={"flex flex-col " + className}>
                            {/* Modal Header */}
                            <div className="h-10 bg-gray-200 px-3 py-1 flex justify-between items-center">
                                <span className={`text-lg ${titleClass} whitespace-nowrap text-ellipsis overflow-clip`}>{title}</span>

                                {removeCloseButton ? <></> : <FontAwesomeIcon className="cursor-pointer ml-2" icon={faSvg.faTimes} onClick={() => onClose(false)} />}
                            </div>

                            {/* Modal Content */}
                            <div className="bg-white flex-1 overflow-auto">
                                {children}
                            </div>

                            {/* Modal Footer */}
                            {footerContent &&
                                <div className="bg-gray-200 px-3 p-2">
                                    {footerContent}
                                </div>
                            }
                        </div>
                    </ATOClickOutside>
                </div>
            </div>
        </div>
    )
}