/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_byte_ } from '../models/ApiResponse_byte_';
import type { ApiResponse_List_PracticeInvoice_ } from '../models/ApiResponse_List_PracticeInvoice_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvoiceHistoryActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public resendInvoiceToCustomer(
        formData: {
            invoiceNumber?: string;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/InvoiceHistoryActions/ResendInvoiceToCustomer',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_byte_ Success
     * @throws ApiError
     */
    public getInvoiceForCustomer(
        formData: {
            invoiceNumber?: string;
        },
    ): CancelablePromise<ApiResponse_byte_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/InvoiceHistoryActions/GetInvoiceForCustomer',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_List_PracticeInvoice_ Success
     * @throws ApiError
     */
    public getAllInvoicesForCustomer(
        formData: {
            custId?: number;
        },
    ): CancelablePromise<ApiResponse_List_PracticeInvoice_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/InvoiceHistoryActions/GetAllInvoicesForCustomer',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_List_PracticeInvoice_ Success
     * @throws ApiError
     */
    public regenerateLatestInvoice(
        formData: {
            invoiceId?: string;
        },
    ): CancelablePromise<ApiResponse_List_PracticeInvoice_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/InvoiceHistoryActions/RegenerateLatestInvoice',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public sendBulkInvoicesToCustomers(
        formData: any,
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/InvoiceHistoryActions/SendBulkInvoicesToCustomers',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
