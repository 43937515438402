import { useState } from "react";
import {
  ApiResponseStatus,
  ATOmateUserType,
  PracticeUserDisplay,
  PrimarySuperuserChangeRequest,
  ValidationError,
} from "../../../../generated";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { useSetRecoilState } from "recoil";
import { useSinglePendingChanges } from "../../../../Hooks/SinglePendingChagesHook";
import { AppModalAtom } from "../../../../App";
import { ATOModal } from "../../../../Components/ATOModal";
import { ATOLoading } from "../../../../Components/ATOSpinner";
import { ControlGroup } from "../ControlGroup";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";

export const ChangePrimarySuperuser = ({
  custId,
  userDetail,
  reload,
}: {
  custId: number;
  userDetail: PracticeUserDisplay;
  reload: () => void;
}) => {
  const config = useConfig();
  const user = useATOAuth();
  const amClient = useAMClient(config.adminUrl, user);

  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState<Record<keyof PrimarySuperuserChangeRequest, string>>();

  const setModal = useSetRecoilState(AppModalAtom);
  const pendingChanges = useSinglePendingChanges<PrimarySuperuserChangeRequest>();

  const primarySuperuser = pendingChanges.applyChanges({
    custId: custId,
    userId: userDetail.userId,
    name: userDetail.firstName + " " + userDetail.surname,
    email: userDetail.email ?? "",
    ticketNumber: "",
  });

  const saveChanges = () => {
    setIsLoading(true);
    amClient.practiceUsers
      .updatePrimarySuperuser({ request: primarySuperuser })
      .then((resp) => {
        if (resp.status == ApiResponseStatus.Success) {
          setIsLoading(false);
          reload();
          setModal(undefined);
        }
      })
      .catch((ex) => {
        if (ex.status == ApiResponseStatus.Warning) {
          const errors = ex.validationErrors as ValidationError[];
          let newErrorDict = {} as Record<keyof PrimarySuperuserChangeRequest, string>;

          errors.forEach((err) => {
            if (err.error) {
              const memberName = err.memberName as string;
              const error = err.error as string;
              newErrorDict[memberName as keyof PrimarySuperuserChangeRequest] = error;
            }
          });

          setErrors(newErrorDict);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (userDetail.userType === ATOmateUserType.PrimarySuperuser) {
    return (
      <ATOModal
        onClose={(isClickOutside) => {
          !isClickOutside && !isLoading && setModal(undefined);
        }}
        title={<span className="font-semibold text-yellow-800">Warning</span>}
      >
        <div className="flex h-24 w-72 items-center justify-center">User is already a Primary Superuser.</div>
      </ATOModal>
    );
  }

  if (isLoading) {
    return (
      <ATOModal
        onClose={(isClickOutside) => {
          !isClickOutside && !isLoading && setModal(undefined);
        }}
        title={"Change Primary Superuser"}
      >
        <div className="flex h-72 w-72 items-center justify-center">
          <ATOLoading />
        </div>
      </ATOModal>
    );
  }

  return (
    <ATOModal
      onClose={(isClickOutside) => {
        !isClickOutside && setModal(undefined);
      }}
      title={"Change Primary Superuser"}
    >
      <div className="flex flex-col">
        <div className="w-96 p-2">
          Please enter the name and email of the person who authorized the change. This should be a Manager, Partner,
          Existing Primary Superuser.
        </div>
        <div className="grid w-96 auto-rows-min grid-cols-[min-content_1fr] gap-2 p-2">
          <ControlGroup<PrimarySuperuserChangeRequest>
            errors={errors}
            label="Person Name"
            pendingChanges={pendingChanges}
            propName="name"
            intialValue={primarySuperuser.name}
            key={`name`}
            disallowZeroLength
          />
          <ControlGroup<PrimarySuperuserChangeRequest>
            errors={errors}
            label="Person Email"
            pendingChanges={pendingChanges}
            propName="email"
            intialValue={primarySuperuser.email}
            key={`email`}
            disallowZeroLength
          />
          <ControlGroup<PrimarySuperuserChangeRequest>
            errors={errors}
            label="Support Ticket #"
            pendingChanges={pendingChanges}
            propName="ticketNumber"
            intialValue={primarySuperuser.ticketNumber}
            key={`ticketNumber`}
            disallowZeroLength
          />
        </div>
        <div className="col-span-2 flex h-10 justify-between bg-gray-200 p-1">
          <ATOButton buttonType={ButtonType.Error} onClick={() => setModal(undefined)}>
            Cancel
          </ATOButton>
          <ATOButton buttonType={ButtonType.Confirm} onClick={saveChanges}>
            Save
          </ATOButton>
        </div>
      </div>
    </ATOModal>
  );
};
