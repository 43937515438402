import * as faSvg from '@fortawesome/free-solid-svg-icons';

export enum ModalTypes {
    info,
    warning,
    error
}

export const getModalIcon = (type: ModalTypes) => {

    if (type === ModalTypes.warning) {
        return faSvg.faExclamationTriangle;
    }

    if (type === ModalTypes.error) {
        return faSvg.faTimesCircle;
    }

    return faSvg.faInfoCircle;
}

export const getModalColour = (type: ModalTypes) => {
    if (type === ModalTypes.info) return "text-primary";

    if (type === ModalTypes.warning) return "text-yellow-500";

    if (type === ModalTypes.error) return "text-red-600";

    return "text-black";
}