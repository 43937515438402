import React from "react";
import { DMSName } from "../../../generated";
import { IDmsDetail } from "./IDmsDetail";
import { useParams } from "react-router-dom";
import { NimbusDetail } from "./Nimbus/NimbusDetail";
import { FyiDocSetup } from "./FYIDocSetup/FyiDocSetup";
import { SuiteFiles } from "./SuiteFiles/SuiteFiles";
import { WindowsFolder } from "./WindowsFolder/WindowsFolder";
import { SharePoint } from "./SharePoint/SharePoint";
import { Dropbox } from "./Dropbox/Dropbox";
import { Google } from "./Google/Google";
import { HandiSoft } from "./HandiSoft/HandiSoft";
import { HowNowX } from "./HowNowX/HowNowX";
import { Myob } from "./Myob/Myob";
import { HowNow } from "./HowNow/HowNow";
import { KloudConnect } from "./KloudConnect/KloudConnect";

const dmsScreens: { dms: number; element: React.FC<{ custId: number }> }[] = [
  { dms: DMSName.Nimbus, element: NimbusDetail },
  { dms: DMSName.FYIDocs, element: FyiDocSetup },
  { dms: DMSName.SuiteFiles, element: SuiteFiles },
  { dms: DMSName.WindowsFolder, element: WindowsFolder },
  { dms: DMSName.SharePoint, element: SharePoint },
  { dms: DMSName.Dropbox, element: Dropbox },
  { dms: DMSName.GoogleDrive, element: Google },
  { dms: DMSName.HandiSoft, element: HandiSoft },
  { dms: DMSName.HowNowX, element: HowNowX },
  { dms: DMSName.MYOBDocumentManager, element: Myob },
  { dms: DMSName.HowNow, element: HowNow },
  { dms: DMSName.KloudConnect, element: KloudConnect },
];

export const DmsDetail = () => {
  const params = useParams();
  const custId = parseInt(params.id ?? "");
  const dmsId = parseInt(params.dmsId ?? "");

  const dmsScreen = dmsScreens.find((screen) => screen.dms === dmsId);

  if (dmsScreen) {
    const DMS = dmsScreen.element;
    return <DMS custId={custId} />;
  }

  return <div>Not supported</div>;
};
