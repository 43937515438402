import { ReactNode, useState } from "react";
import { AMClient } from "../../../generated";
import { ATOModal } from "../../../Components/ATOModal";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { ATOSpinner } from "../../../Components/ATOSpinner";
import { ATOTextBox } from "../../../Components/ATOTextBox";
import { ATOSuccessModal } from "../../../Components/ATOSuccessModal";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";

export const CreateDomainModal = (props: { amClient: AMClient; title: string; setModal: (val: ReactNode) => void }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalDomainName, setModalDomainName] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const validateDomainInput = (domain: string) => {
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;
    return domainRegex.test(domain) && !domain.includes("@");
  };

  const handleCreateDomain = () => {
    if (!validateDomainInput(modalDomainName ?? "")) {
      return setError("Invalid domain");
    }
    setIsLoading(true);
    props.amClient.communicationHandler
      .createPracticeDomainDetails({ domainName: modalDomainName ?? "" })
      .finally(() => {
        setIsLoading(false);
        props.setModal(<ATOSuccessModal setModal={props.setModal} />);
      })
      .catch((ex) => {
        props.setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => props.setModal(undefined)} />);
      });
  };

  return (
    <ATOModal
      className="h-[min(40vh,200px)] w-[min(60vw,800px)]"
      title={props.title}
      onClose={() => props.setModal(undefined)}
      footerContent={
        <div className="flex justify-around">
          <ATOButton
            buttonType={ButtonType.Primary}
            className="items-center"
            onClick={handleCreateDomain}
            disabled={isLoading}
          >
            Create
          </ATOButton>

          <ATOButton buttonType={ButtonType.Error} onClick={() => props.setModal(undefined)}>
            Cancel
          </ATOButton>
        </div>
      }
    >
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center">
          {isLoading ? (
            <div className="flex h-full w-full items-center justify-center">
              <ATOSpinner />
            </div>
          ) : (
            <>
              <div className="flex w-full items-center justify-center">
                <ATOTextBox
                  className="w-full"
                  value={modalDomainName ?? ""}
                  placeholder="Enter Domain"
                  onChange={(val) => {
                    setModalDomainName(val.toLowerCase());
                    setError(null);
                  }}
                />
              </div>
              <div className="mt-2 w-full text-start text-red-500">{error && <div>{error}</div>}</div>
            </>
          )}
        </div>
      </div>
    </ATOModal>
  );
};
