import { useEffect, useState } from "react";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { ItemPendingChanges, usePendingChangesWithState } from "../../../../Hooks/PendingChangesHook";
import { ApiResponseStatus, DMSDocOptionType, DMSName, DropboxPracticeInfo } from "../../../../generated";
import { IDMSDoc } from "../../../../Components/DMSDoc/IDMSDoc";
import { AppModalAtom } from "../../../../App";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { DMSDocSetupColumnInfo } from "../../../../Components/DMSDoc/DMSDocSetupColumnInfo";
import { ATOSpinner } from "../../../../Components/ATOSpinner";
import { DMSDocSetup } from "../../../../Components/DMSDoc/DMSDocSetup";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { DropboxDoc } from "./Models/DropboxDoc";
import { DMSDocCell } from "../../../../Components/DMSDoc/DMSDocCell";

const dropboxAtom = atom<ItemPendingChanges<IDMSDoc>[]>({
  key: "dropboxAtom",
  default: [],
});

export const Dropbox = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const apiClient = useAMClient(config.dropboxUrl, user, custId);
  const [showOptions, setShowOptions] = useState(false);
  const [practiceInfo, setPracticeInfo] = useState<DropboxPracticeInfo>();
  const setModal = useSetRecoilState(AppModalAtom);
  const pendingChanges = usePendingChangesWithState<IDMSDoc>(useRecoilState(dropboxAtom));
  const [isLoading, setIsLoading] = useState(false);

  const actualDropboxDetail = pendingChanges.applyChanges("-1", {
    fileName: practiceInfo?.fileName ?? "",
    unknownClientID: practiceInfo?.unknownClient ?? "",
    baseFolder: practiceInfo?.baseFolder ?? "",
    DocID: "-1",
    Level1ID: "",
    Level2ID: "",
    Level3ID: "",
    Level4ID: "",
  });

  useEffect(() => {
    apiClient.dropBoxEnrolmentActions
      .getDropboxPracticeInfo({})
      .then((resp) => {
        setPracticeInfo(resp?.data);

        if (resp?.data?.isConnected) {
          setShowOptions(resp?.data?.isRootnameSpaceEqual ?? false);
        }
        if (resp.status !== ApiResponseStatus.Success) {
          throw resp.message;
        }
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  }, []);

  const columnInfo: DMSDocSetupColumnInfo<IDMSDoc>[] = [
    {
      name: "Level 1",
      valueKey: "Level1ID",
      popupInitialHeaderText: "Level 1",
      isMandatory: () => true,
      allowCustom: () => true,
      columnOptionType: DMSDocOptionType.Client,
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 2",
      valueKey: "Level2ID",
      popupInitialHeaderText: "Level 2",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 3",
      valueKey: "Level3ID",
      popupInitialHeaderText: "Level 3",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 4",
      valueKey: "Level4ID",
      popupInitialHeaderText: "Level 4",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
  ];

  if (practiceInfo === undefined) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOSpinner />
      </div>
    );
  }

  return (
    <div className="box-border flex h-screen flex-col items-center justify-center">
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.5)]">
          <ATOSpinner />
        </div>
      )}
      <>
        <DMSDocSetup<IDMSDoc>
          dmsui={DMSName.Dropbox}
          columnInfo={columnInfo}
          isLoading={isLoading}
          isActive={true}
          setIsLoading={setIsLoading}
          setModal={setModal}
          pendingChanges={pendingChanges}
          preSave={(docSave) => {
            apiClient.dropBoxEnrolmentActions
              .saveDropboxPracticeInfo({
                fileName: actualDropboxDetail?.fileName ?? "",
                unknownClient: actualDropboxDetail?.unknownClient ?? "",
                baseFolder: actualDropboxDetail?.baseFolder ?? "",
              })
              .then(() => {
                docSave();
              })
              .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
          }}
          getDefaultItem={(opts, docs) => {
            return {
              DocID: "0",
              Level1ID: "",
              Level2ID: "",
              Level3ID: "",
              Level4ID: "",
              BaseFolder: "",
              UnknownClient: "",
            };
          }}
        >
          {(types, options) => {
            return (
              <>
                <div className="my-6 grid w-full grid-cols-[1fr,1fr,1fr] gap-4">
                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <p>Unknown Client ID</p>
                    <ATOTextBox
                      className="w-[min(480px,80%)]"
                      value={actualDropboxDetail?.unknownClient ?? ""}
                      onChange={(str) => pendingChanges.setChange("-1", "unknownClient", str)}
                    />
                  </div>
                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <p>Base Folder</p>
                    <ATOTextBox
                      className="w-[min(480px,80%)]"
                      value={actualDropboxDetail?.baseFolder ?? ""}
                      onChange={(str) => pendingChanges.setChange("-1", "baseFolder", str)}
                    />
                  </div>
                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <p>Filename</p>
                    <div className="z-20 h-8 w-[min(480px,80%)]">
                      <DMSDocCell<IDMSDoc>
                        pendingChanges={pendingChanges}
                        columnInfo={{
                          name: "fileName",
                          valueKey: "fileName",
                          isMandatory: () => false,
                          allowCustom: () => true,
                          getDatasource: () => options?.[DMSDocOptionType.FileName],
                          columnOptionType: DMSDocOptionType.FileName,
                          width: "",
                        }}
                        isDefaultDocType={false}
                        rowIndex={-1}
                        setModal={setModal}
                        value={{
                          DocID: "",
                          Docs: "",
                          fileName: actualDropboxDetail?.fileName ?? "",
                          Level1ID: "",
                          Level2ID: "",
                          Level3ID: "",
                          Level4ID: "",
                          UnknownClient: "",
                          BaseFolder: "",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </DMSDocSetup>
      </>
    </div>
  );
};
