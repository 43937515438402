import { useParams } from "react-router-dom";
import { useAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { AMClient, CommunicationSettingsDisplay } from "../../../generated";
import useDidMountEffect from "../../../Hooks/DidMountHook";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOSuccessModal } from "../../../Components/ATOSuccessModal";
import { Tooltip } from "../../../Components/Tooltip";
import { ATOTextBox } from "../../../Components/ATOTextBox";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { ATOCheckBox } from "../../../Components/ATOCheckBox";
import { ATODropDown } from "../../../Components/ATODropDown";
import { EditModal } from "./EditModal";
import { ViewDomainDetailsModal } from "./ViewDomainDetailsModal";
import { ViewSignatureModal } from "./ViewSignatureModal";
import { ATOLoading } from "../../../Components/ATOSpinner";

export const Communication = () => {
  const config = useConfig();
  const user = useATOAuth();
  const params = useParams();
  const custId = parseInt(params.id ?? "");
  const amClient = useAMClient(config.practiceConfigUrl, user, custId);

  const [modal, setModal] = useState<React.ReactNode | undefined>(undefined);
  const [communicationSettings, setCommunicationSettings] = useState<CommunicationSettingsDisplay>();
  const [useDefaultSenderEmailAsPostal, setUseDefaultSenderEmailAsPostal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHour, setSelectedHour] = useState("00");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [selectedSecond, setSelectedSecond] = useState("00");
  const [selectedPeriod, setSelectedPeriod] = useState("AM");

  const hours = Array.from({ length: 12 }, (_, i) => ("0" + (i + 1)).slice(-2));
  const minutes = Array.from({ length: 4 }, (_, i) => ("0" + i * 15).slice(-2));

  const handleHourChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newHour = e.target.value;
    setSelectedHour(newHour);
    calculateNotifyClientAfter(newHour, selectedMinute, selectedSecond, selectedPeriod);
  };

  const handleMinuteChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newMinute = e.target.value;
    setSelectedMinute(newMinute);
    calculateNotifyClientAfter(selectedHour, newMinute, selectedSecond, selectedPeriod);
  };

  const handleSecondChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newSecond = e.target.value;
    setSelectedSecond(newSecond);
    calculateNotifyClientAfter(selectedHour, selectedMinute, newSecond, selectedPeriod);
  };

  const handlePeriodChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newPeriod = e.target.value;
    setSelectedPeriod(newPeriod);
    calculateNotifyClientAfter(selectedHour, selectedMinute, selectedSecond, newPeriod);
  };

  const calculateNotifyClientAfter = (hour: string, minute: string, second: string, period: string) => {
    let hour24 = parseInt(hour);
    if (period === "PM" && hour24 !== 12) {
      hour24 += 12;
    } else if (period === "AM" && hour24 === 12) {
      hour24 = 0;
    }
    const notifyClientAfter = `${hour24.toString().padStart(2, "0")}:${minute.padStart(2, "0")}:${second.padStart(2, "0")}`;

    setCommunicationSettings((prevSettings) => ({
      ...prevSettings,
      autoSendAfter: notifyClientAfter,
    }));
  };

  const handleUploadEmailSignature = () => {
    const emailTo = config.signature_to;
    const subject = "Update Practice Default Signature";
    if (window?.top?.location) {
      window.top.location = `mailto:${emailTo}?subject=${encodeURIComponent(subject)}`;
    }
  };

  const fileNameOptions: Record<string, string> = {
    "Notice of Assessment - 2022 - John Doe.pdf": "{DocType} - {CAL_DocYear} - {NoTrimFirstName} {Surname_Company}.pdf",
    "Notice of Assessment 2022 John Doe.pdf": "{DocType} {CAL_DocYear} {FirstName} {Surname_Company}.pdf",
    "Notice of Assessment_2022_John_Doe.pdf": "{DocType}_{CAL_DocYear}_{FirstName}_{NoTrimSurname_Company}.pdf",
    "Notice of Assessment_John_Doe.pdf": "{DocType}_{FirstName}_{NoTrimSurname_Company}.pdf",
  };

  const getDisplayName = (value: string) => {
    const entry = Object.entries(fileNameOptions).find(([key, val]) => val === value);
    return entry ? entry[0] : value;
  };

  const getValueFromDisplayName = (displayName: string) => {
    return fileNameOptions[displayName] || displayName;
  };

  useEffect(() => {
    if (!user.isLoading) {
      setIsLoading(true);
      amClient.communicationHandler
        .getCommunicationSettings({})
        .then((res) => {
          setCommunicationSettings(res.data);
          const autoSendAfter = res?.data?.autoSendAfter;
          const timeParts = autoSendAfter ? autoSendAfter.split(":") : [];

          let hour = timeParts.length > 0 ? parseInt(timeParts[0]) : 0;
          let minute = timeParts.length > 1 ? timeParts[1] : "00";

          let period = "AM";

          if (hour >= 12) {
            period = "PM";
            hour = hour === 12 ? 12 : hour % 12;
          } else if (hour === 0) {
            hour = 12;
          }

          const formattedHour = hour.toString().padStart(2, "0");
          setSelectedHour(formattedHour);
          setSelectedMinute(minute);
          setSelectedPeriod(period);
          setUseDefaultSenderEmailAsPostal(
            res?.data?.emailFrom?.toLowerCase() == res.data?.postalManagerEmail?.toLowerCase()
          );
        })
        .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />))
        .finally(() => setIsLoading(false));
    }
  }, [user]);

  const handleOnchange = useCallback((val: string | number | boolean, name: string) => {
    setCommunicationSettings((communicationSetting) => ({
      ...communicationSetting,
      [name]: val,
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    const updatedCommunicationSettings = {
      ...communicationSettings,
      postalManagerEmail: useDefaultSenderEmailAsPostal
        ? communicationSettings?.emailFrom
        : communicationSettings?.postalManagerEmail,
    };
    amClient.communicationHandler
      .updateCommunicationSettings({ communicationSettings: updatedCommunicationSettings })
      .then(() => {
        setModal(<ATOSuccessModal setModal={setModal} />);
        window.parent.postMessage("emailSettingsSaved", "*");
      })
      .catch((ex) => {
        setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />);
      })
      .finally(() => setIsLoading(false));
  }, [amClient, communicationSettings, useDefaultSenderEmailAsPostal]);

  if (isLoading || user.isLoading) {
    return <ATOLoading />;
  }

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="grid h-full w-full auto-rows-min grid-cols-2 gap-4 overflow-y-auto overflow-x-hidden p-2">
        <div className="flex w-full flex-col gap-2">
          <label className="flex items-center text-primary">
            <b>Default From Email Address</b>
            <Tooltip className="ml-2 h-min w-min">
              <div
                className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
              >
                <div className=" h-4 w-4 rotate-45 bg-black" />
                <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                  The default address ATOmate emails will be sent from.
                </p>
              </div>
            </Tooltip>
          </label>
          <div className="flex items-center gap-2">
            <ATOTextBox
              value={communicationSettings?.emailFrom ?? ""}
              disabled={true}
              placeholder="Enter Default From Email Address"
              className="w-full"
              onChange={function (e: string): boolean | void {
                throw new Error("Function not implemented.");
              }}
            />
            <ATOButton
              className="h-full w-8"
              buttonType={ButtonType.Primary}
              onClick={() =>
                setModal(
                  <EditModal
                    amClient={amClient}
                    title="Default Sender Email Address"
                    validateInput={true}
                    initialValue={communicationSettings?.emailFrom ?? ""}
                    nullable={false}
                    setModal={setModal}
                    onConfirm={(
                      inputValue: string,
                      showDomainDetailsModal: boolean,
                      updatedInputValue: string | null | undefined
                    ) => {
                      handleOnchange(inputValue, "emailFrom");
                      setUseDefaultSenderEmailAsPostal(inputValue == communicationSettings?.postalManagerEmail);
                      if (showDomainDetailsModal) {
                        setModal(
                          <ViewDomainDetailsModal
                            domainName={updatedInputValue ?? inputValue}
                            amClient={amClient}
                            setModal={setModal}
                            onClose={() => {
                              setModal(undefined);
                            }}
                          />
                        );
                      } else {
                        setModal(undefined);
                      }
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faSvg.faEdit} className="ml-1" />
            </ATOButton>
          </div>
        </div>

        <div style={{ gridTemplateColumns: "subgrid" }} className="row-span-2 grid gap-4">
          <div className="flex w-full flex-col gap-2">
            <label className="flex items-center text-primary">
              <b>Default Postal Manager Email Address</b>
              <Tooltip className="ml-2 h-min w-min">
                <div
                  className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
                >
                  <div className=" h-4 w-4 rotate-45 bg-black" />
                  <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                    Email address for preparing printable copies to post.
                  </p>
                </div>
              </Tooltip>
            </label>

            <div className="flex h-8 w-full items-center justify-between gap-2">
              <p className="text-start">Use Default From Email Address</p>
              <ATOCheckBox
                name="useDefaultSenderEmail"
                displayLabel={true}
                className="h-4 w-1/2 border-gray-100"
                value={useDefaultSenderEmailAsPostal ?? false}
                onChange={(val) => {
                  setUseDefaultSenderEmailAsPostal(val);
                }}
              />
            </div>
          </div>
          {!useDefaultSenderEmailAsPostal && (
            <div className="flex w-full flex-col gap-2">
              <label className="flex items-center text-primary">
                <b>Postal Manager Email Address</b>
                <Tooltip className="ml-2 h-min w-min">
                  <div
                    className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
                  >
                    <div className=" h-4 w-4 rotate-45 bg-black" />
                    <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                      Email address for preparing printable copies to post.
                    </p>
                  </div>
                </Tooltip>
              </label>

              <div className="flex h-8 w-full items-center justify-between gap-2">
                <ATOTextBox
                  name="postalManagerEmail"
                  value={communicationSettings?.postalManagerEmail ?? ""}
                  disabled={true}
                  placeholder="Enter Default Postal Manager Email Address"
                  className="w-full"
                  onChange={function (e: string): boolean | void {
                    throw new Error("Function not implemented.");
                  }}
                />
                <ATOButton
                  className="h-full w-8"
                  buttonType={ButtonType.Primary}
                  onClick={() =>
                    setModal(
                      <EditModal
                        amClient={amClient}
                        title="Postal Manager Email Address"
                        validateInput={true}
                        initialValue={communicationSettings?.postalManagerEmail ?? ""}
                        nullable={true}
                        setModal={setModal}
                        onConfirm={(
                          inputValue: string,
                          showDomainDetailsModal: boolean,
                          updatedInputValue: string | null | undefined
                        ) => {
                          handleOnchange(inputValue, "postalManagerEmail");
                          if (showDomainDetailsModal) {
                            setModal(
                              <ViewDomainDetailsModal
                                domainName={updatedInputValue ?? inputValue}
                                amClient={amClient}
                                setModal={setModal}
                                onClose={() => {
                                  setModal(undefined);
                                }}
                              />
                            );
                          } else {
                            setModal(undefined);
                          }
                        }}
                      />
                    )
                  }
                >
                  <FontAwesomeIcon icon={faSvg.faEdit} className="ml-1" />
                </ATOButton>
              </div>
            </div>
          )}
        </div>

        <div className="flex w-full flex-col gap-2">
          <label className="flex items-center text-primary">
            <b>Default Sender Name</b>
            <Tooltip className="ml-2 h-min w-min">
              <div
                className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
              >
                <div className=" h-4 w-4 rotate-45 bg-black" />
                <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                  The name that will appear in the email header.
                </p>
              </div>
            </Tooltip>
          </label>

          <div className="flex w-full items-center gap-2">
            <ATOTextBox
              name="emailSenderName"
              value={communicationSettings?.emailSenderName ?? ""}
              disabled={true}
              placeholder="Enter Default Sender Name"
              className="w-full"
              onChange={function (e: string): boolean | void {
                throw new Error("Function not implemented.");
              }}
            />

            <ATOButton
              className="h-full w-8"
              buttonType={ButtonType.Primary}
              onClick={() =>
                setModal(
                  <EditModal
                    amClient={amClient}
                    title="Default Sender Name"
                    validateInput={false}
                    initialValue={communicationSettings?.emailSenderName ?? ""}
                    nullable={false}
                    setModal={setModal}
                    onConfirm={(
                      inputValue: string,
                      showDomainDetailsModal: boolean,
                      updatedInputValue: string | null | undefined
                    ) => {
                      handleOnchange(inputValue, "emailSenderName");
                      if (showDomainDetailsModal) {
                        setModal(
                          <ViewDomainDetailsModal
                            amClient={amClient}
                            setModal={setModal}
                            onClose={() => {
                              setModal(undefined);
                            }}
                          />
                        );
                      } else {
                        setModal(undefined);
                      }
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faSvg.faEdit} className="ml-1" />
            </ATOButton>
          </div>
        </div>

        <div className="flex w-full flex-col gap-2">
          <label className="flex items-center text-primary">
            <b>Default Email Signature</b>
            <Tooltip className="ml-2 h-min w-min">
              <div
                className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
              >
                <div className=" h-4 w-4 rotate-45 bg-black" />
                <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                  Email signature used for default sender.
                </p>
              </div>
            </Tooltip>
          </label>
          <div className="flex w-full justify-between">
            <div className="mb-2 mr-4 flex w-1/3 items-center">
              <ATOButton
                buttonType={ButtonType.Primary}
                onClick={handleUploadEmailSignature}
                className="w-full px-4 py-2 text-sm"
              >
                <FontAwesomeIcon icon={faSvg.faUpload} className="mr-1" />
                Upload Email Signature
              </ATOButton>
            </div>
            <div className="mb-2 mr-4 flex w-1/3 items-center">
              <ATOButton
                buttonType={ButtonType.Confirm}
                onClick={() => window.open("https://baw.atlassian.net/l/cp/uupqMV7A", "_blank", "noopener,noreferrer")}
                className="w-full px-4 py-2 text-sm"
              >
                <FontAwesomeIcon icon={faSvg.faQuestionCircle} className="mr-1" />
                Signature Help
              </ATOButton>
            </div>
            <div className="mb-2 mr-4 flex w-1/3 items-center">
              <ATOButton
                className="w-full px-4 py-2 text-sm"
                buttonType={ButtonType.Primary}
                disabled={communicationSettings?.signatureURL === null}
                onClick={() =>
                  setModal(
                    <ViewSignatureModal
                      signatureUrl={communicationSettings?.signatureURL ?? ""}
                      onClose={() => setModal(undefined)}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faSvg.faEye} className="mr-1" /> View Signature
              </ATOButton>
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col gap-2">
          <label className="flex items-center text-primary">
            <b>BCC Email</b>
            <Tooltip className="ml-2 h-min w-min">
              <div
                className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
              >
                <div className=" h-4 w-4 rotate-45 bg-black" />
                <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                  Send a BCC of documents sent using the email action.
                </p>
              </div>
            </Tooltip>
          </label>
          <div className="flex w-full items-center gap-2">
            <ATOTextBox
              name="emailSenderName"
              placeholder="Enter BCC Email"
              value={communicationSettings?.emailBCC ?? ""}
              disabled={true}
              className="w-full"
              onChange={function (e: string): boolean | void {
                throw new Error("Function not implemented.");
              }}
            />
            <ATOButton
              className="h-full w-8"
              buttonType={ButtonType.Primary}
              onClick={() =>
                setModal(
                  <EditModal
                    amClient={amClient}
                    title="BCC Email"
                    validateInput={true}
                    initialValue={communicationSettings?.emailBCC ?? ""}
                    nullable={true}
                    setModal={setModal}
                    onConfirm={(
                      inputValue: string,
                      showDomainDetailsModal: boolean,
                      updatedInputValue: string | null | undefined
                    ) => {
                      handleOnchange(inputValue, "emailBCC");
                      if (showDomainDetailsModal) {
                        setModal(
                          <ViewDomainDetailsModal
                            domainName={updatedInputValue ?? inputValue}
                            amClient={amClient}
                            setModal={setModal}
                            onClose={() => {
                              setModal(undefined);
                            }}
                          />
                        );
                      } else {
                        setModal(undefined);
                      }
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faSvg.faEdit} className="ml-1" />
            </ATOButton>
          </div>
        </div>
        <div className="flex w-full flex-col gap-2">
          <label className="flex items-center text-primary">
            <b>Email Attachment Filename</b>
            <Tooltip className="ml-2 h-min w-min">
              <div
                className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
              >
                <div className=" h-4 w-4 rotate-45 bg-black" />
                <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                  File name format for email attachment.
                </p>
              </div>
            </Tooltip>
          </label>
          <div className="flex w-full items-center gap-2">
            <ATODropDown<string>
              className="w-full"
              value={getDisplayName(communicationSettings?.emailFileName ?? "")}
              getDisplay={(r) => r}
              getOptions={() => Object.values(fileNameOptions)}
              onChange={(val) => {
                handleOnchange(getValueFromDisplayName(val), "emailFileName");
              }}
            />
          </div>
        </div>

        <div className="flex w-full flex-col gap-2">
          <label className="flex items-center text-primary">
            <b>Practice Timezone</b>
            <Tooltip className="ml-2 h-min w-min">
              <div
                className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
              >
                <div className=" h-4 w-4 rotate-45 bg-black" />
                <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                  Set practice local timezone.
                </p>
              </div>
            </Tooltip>
          </label>
          <div className="flex w-full items-center gap-2">
            <ATODropDown<string>
              className="w-full"
              value={communicationSettings?.offset?.toString() ?? ""}
              getOptions={() => [
                "Australia/Adelaide",
                "Australia/Brisbane",
                "Australia/Darwin",
                "Australia/Hobart",
                "Australia/Melbourne",
                "Australia/Perth",
                "Australia/Sydney",
              ]}
              getDisplay={(r) => r}
              onChange={(val) => {
                handleOnchange(val, "offset");
              }}
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-2">
          <label className="flex items-center text-primary">
            <b>Notify Client After</b>
            <Tooltip className="ml-2 h-min w-min">
              <div
                className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
              >
                <div className=" h-4 w-4 rotate-45 bg-black" />
                <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                  The earliest time each day ATOmate will automatically sends emails for templates set to Notify Client.
                </p>
              </div>
            </Tooltip>
          </label>
          <div className="flex w-full items-center gap-2">
            <select
              className="rounded border border-gray-300 p-1"
              value={selectedHour}
              onChange={(val) => handleHourChange(val)}
            >
              {hours.map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
            <select
              className="rounded border border-gray-300 p-1"
              value={selectedMinute}
              onChange={(e) => handleMinuteChange(e)}
            >
              {minutes.map((minute) => (
                <option key={minute} value={minute}>
                  {minute}
                </option>
              ))}
            </select>
            <select
              className="rounded border border-gray-300 p-1"
              value={selectedPeriod}
              onChange={(e) => handlePeriodChange(e)}
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </div>
        </div>
        <div className="flex w-full flex-col gap-2">
          <label className="flex items-center text-primary">
            <b>Save Communication Audit Trail to DMS</b>
            <Tooltip className="ml-2 h-min w-min">
              <div
                className="flex h-0 -translate-y-1/2
                                    items-center 
                                    justify-center pl-2 text-white"
              >
                <div className=" h-4 w-4 rotate-45 bg-black" />
                <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                  Create a file in DMS containing the send email, Document and Audit Trail.
                </p>
              </div>
            </Tooltip>
          </label>
          <div className="mb-4 flex w-full">
            <ATOCheckBox
              name="downloadCommunicationArchive"
              className="h-4 w-1/12 items-center justify-center border-gray-100"
              value={communicationSettings?.downloadCommunicationArchive ?? false}
              onChange={(val) => {
                handleOnchange(val, "downloadCommunicationArchive");
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-between border-t border-gray-300 px-2 py-1">
        <ATOButton
          buttonType={ButtonType.Primary}
          onClick={() =>
            setModal(
              <ViewDomainDetailsModal
                amClient={amClient}
                setModal={setModal}
                onClose={() => {
                  setModal(undefined);
                }}
              />
            )
          }
        >
          View Domain Details
        </ATOButton>
        <ATOButton buttonType={ButtonType.Confirm} onClick={handleSubmit}>
          Save
        </ATOButton>
      </div>
      {modal}
    </div>
  );
};
