/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_Guid_ } from '../models/ApiResponse_Guid_';
import type { ApiResponse_IEnumerable_ATOCredential_ } from '../models/ApiResponse_IEnumerable_ATOCredential_';
import type { ApiResponse_IEnumerable_SampleDocument_ } from '../models/ApiResponse_IEnumerable_SampleDocument_';
import type { ATOCredential } from '../models/ATOCredential';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CredentialManager {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_ATOCredential_ Success
     * @throws ApiError
     */
    public getCredentials(
        formData: any,
    ): CancelablePromise<ApiResponse_IEnumerable_ATOCredential_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CredentialManager/GetCredentials',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_ATOCredential_ Success
     * @throws ApiError
     */
    public setCredentials(
        formData: {
            credentials?: Array<ATOCredential>;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_ATOCredential_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CredentialManager/SetCredentials',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_Guid_ Success
     * @throws ApiError
     */
    public testCredentials(
        formData: {
            abn?: string;
            tan?: string;
            ssid?: string;
        },
    ): CancelablePromise<ApiResponse_Guid_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CredentialManager/TestCredentials',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_SampleDocument_ Success
     * @throws ApiError
     */
    public getTestCredentialResult(
        formData: {
            token?: string;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_SampleDocument_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CredentialManager/GetTestCredentialResult',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
