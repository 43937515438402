/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_List_CustomerForIASDropdown_ } from '../models/ApiResponse_List_CustomerForIASDropdown_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IasDocumentUpload {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_List_CustomerForIASDropdown_ Success
     * @throws ApiError
     */
    public getEligibleCustomers(
        formData: {
            query?: string;
        },
    ): CancelablePromise<ApiResponse_List_CustomerForIASDropdown_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/IASDocumentUpload/GetEligibleCustomers',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public uploadFile(
        formData: {
            fileStream?: Blob;
            fileName?: string;
            custId?: number;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/IASDocumentUpload/UploadFile',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
