/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * Success(0)
 * Warning(1)
 * Failed(2)
 */
export enum ApiResponseStatus {
    Success = 0,
    Warning = 1,
    Failed = 2,
}
