import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

import { useEffect, useState } from "react";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import {
  AdvancedWorkflow,
  AdvancedWorkflowUpdateRequest,
  AdvancedWorkflowUser,
  ClientGroupUserType,
} from "../../../../generated";
import { ATOLoading } from "../../../../Components/ATOSpinner";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "../../../../Components/Tooltip";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../../App";
import { WorkflowEditUsers } from "./WorkflowEditUsers";
import { useSinglePendingChanges } from "../../../../Hooks/SinglePendingChagesHook";
import { ATOSuccessModal } from "../../../../Components/ATOSuccessModal";

export const WorkflowListing = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const amClient = useAMClient(config.adminUrl, user);

  const [worklows, setWorklows] = useState<AdvancedWorkflow[]>([]);
  const [users, setUsers] = useState<AdvancedWorkflowUser[]>([]);
  const [awFlag, setAWFlag] = useState(true);

  const [awSetupFilter, setAWSetupFilter] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const setModal = useSetRecoilState(AppModalAtom);

  const clearFilter = () => {
    setFilterText("");
  };

  const filterWorkflows = worklows
    .filter((u) => awSetupFilter === "All" || u.isSetup === (awSetupFilter === "Setup"))
    .filter((u) => filterText === "" || u.clientGroupName?.toLowerCase().includes(filterText.toLowerCase()));

  const reloadWorkflows = () => {
    setIsLoading(true);

    amClient.practiceWorkflows
      .listAdvancedWorkflows({ custId })
      .then((resp) => setWorklows(resp.data ?? []))
      .catch((ex) => {
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
            }}
          />
        );
      })
      .finally(() => setIsLoading(false));

    amClient.practiceWorkflows
      .getAdvancedWorkflowUsers({ custId })
      .then((resp) => {
        setUsers(resp.data ?? []);
      })
      .catch((ex) => {
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
            }}
          />
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    reloadWorkflows();

    amClient.practiceWorkflows.getWorkflowEnabledStatus({ custId }).then((resp) => setAWFlag(resp.data ?? false));
  }, []);

  const columns = {
    clientGroup: { name: "Client Groups", width: "1fr" },
    approver: { name: "Approver", width: "1fr" },
    reviewer: { name: "Reviewer", width: "1fr" },
    sender: { name: "Sender", width: "1fr" },
    postalManager: { name: "Postal Manager", width: "1fr" },
    actions: { name: "Actions", width: "150px" },
  };
  const spanString = `span ${Object.keys(columns).length * 2 - 1} / span ${Object.keys(columns).length * 2 - 1}`;

  return (
    <>
      <div className="flex h-full w-full overflow-hidden">
        <div className="grid w-full grid-rows-[min-content_1fr]">
          <div className=" grid h-full w-screen grid-cols-[250px_1fr_100px] gap-2 p-2 pr-8">
            <div>
              <ATODropDown<string>
                className="w-full"
                value={awSetupFilter}
                getDisplay={(r) => r}
                getOptions={() => ["All", "Setup", "Not Setup"]}
                onChange={(val) => {
                  setAWSetupFilter(val);
                }}
              />
            </div>
            <div>
              <ATOTextBox className="h-8" onChange={(val) => setFilterText(val)} value={filterText}></ATOTextBox>
            </div>
            <div>
              <ATOButton onClick={clearFilter} className="h-8 w-32">
                Clear
              </ATOButton>
            </div>
          </div>
          <div className="h-full overflow-hidden">
            <div
              style={{
                gridTemplateColumns: Object.values(columns)
                  .map((v, i) => (i === 0 ? v.width : "1px " + v.width))
                  .join(" "),
              }}
              className="grid h-full w-full grid-rows-[min-content_minmax(0,1fr)]"
            >
              <div
                style={{
                  gridTemplateColumns: "subgrid",
                  gridColumn: spanString,
                }}
                className="grid w-full items-center overflow-clip border-b border-black bg-primary text-white"
              >
                {Object.values(columns).map((c, i) => (
                  <>
                    {i > 0 ? <div className="h-4/5 bg-white" /> : <></>}
                    <p key={i} className="w-full text-center">
                      {c.name}
                    </p>
                  </>
                ))}
              </div>
              {isLoading ? (
                <div style={{ gridColumn: spanString }}>
                  <ATOLoading />
                </div>
              ) : (
                <div
                  style={{
                    gridTemplateColumns: "subgrid",
                    gridColumn: spanString,
                  }}
                  className="grid h-full w-full auto-rows-min place-content-start gap-y-2 overflow-x-clip overflow-y-scroll pt-2 last:pb-2"
                >
                  <WorkflowIterator custId={custId} workflows={filterWorkflows} spanString={spanString} users={users} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const WorkflowIterator = ({
  custId,
  workflows,
  users,
  spanString,
}: {
  custId: number;
  workflows: AdvancedWorkflow[];
  users: AdvancedWorkflowUser[];
  spanString: string;
}) => {
  return (
    <>
      {workflows.map((c, i) => (
        <WorkflowRow custId={custId} workflowDetail={c} users={users} spanString={spanString} />
      ))}
    </>
  );
};

export const WorkflowRow = ({
  custId,
  workflowDetail,
  users,
  spanString,
}: {
  custId: number;
  workflowDetail: AdvancedWorkflow;
  users: AdvancedWorkflowUser[];
  spanString: string;
}) => {
  const config = useConfig();
  const user = useATOAuth();
  const setModal = useSetRecoilState(AppModalAtom);
  const amClient = useAMClient(config.adminUrl, user);

  const navigate = useNavigate();

  const pendingChanges = useSinglePendingChanges<Record<string, number[]>>();
  const clientGroupUsers = pendingChanges.applyChanges(workflowDetail?.clientGroupUsers ?? {});

  const setGroupUsers = (userType: ClientGroupUserType, userIds: number[]) => {
    pendingChanges.setChange(userType + "", userIds);
  };

  const saveWorkflow = () => {
    const updateRequest: AdvancedWorkflowUpdateRequest = {
      custId: custId,
      clientGroupId: workflowDetail.clientGroupId,
      clientGroupUsers: clientGroupUsers,
    };

    amClient.practiceWorkflows
      .saveWorkflow({ request: updateRequest })
      .then((resp) => {
        setModal(<ATOSuccessModal setModal={setModal} />);
        pendingChanges.removeAllChanges();
        navigate(0);
      })
      .catch((ex) => {
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
            }}
          />
        );
      });
  };

  return (
    <div
      key={workflowDetail.clientGroupId?.toString() ?? ""}
      style={{
        gridTemplateColumns: "subgrid",
        gridColumn: spanString,
      }}
      className="mx-2 grid min-h-8 place-items-center rounded-lg border border-black bg-gray-100"
    >
      <p className="px-2">{workflowDetail.clientGroupName + " GROUP"}</p>
      <div className="h-4/5 w-px bg-black" />
      <p className="px-2">
        <WorkflowEditUsers
          clientGroupName={workflowDetail.clientGroupName!}
          userType={ClientGroupUserType.Approver}
          users={users}
          clientGroupUsers={clientGroupUsers}
          setGroupUsers={setGroupUsers}
          inline={true}
        />
      </p>
      <div className="h-4/5 w-px bg-black" />
      <p className="px-2">
        <WorkflowEditUsers
          clientGroupName={workflowDetail.clientGroupName!}
          userType={ClientGroupUserType.Reviewer}
          users={users}
          clientGroupUsers={clientGroupUsers}
          setGroupUsers={setGroupUsers}
          inline={true}
        />
      </p>
      <div className="h-4/5 w-px bg-black" />
      <p className="px-2">
        <WorkflowEditUsers
          clientGroupName={workflowDetail.clientGroupName!}
          userType={ClientGroupUserType.Sender}
          users={users}
          clientGroupUsers={clientGroupUsers}
          setGroupUsers={setGroupUsers}
          inline={true}
        />
      </p>
      <div className="h-4/5 w-px bg-black" />
      <p>
        <WorkflowEditUsers
          clientGroupName={workflowDetail.clientGroupName!}
          userType={ClientGroupUserType.Postal}
          users={users}
          clientGroupUsers={clientGroupUsers}
          setGroupUsers={setGroupUsers}
          inline={true}
        />
      </p>
      <div className="h-4/5 w-px bg-black" />
      <div className="flex gap-2">
        {pendingChanges.listChanges().length !== 0 && (
          <ATOButton buttonType={ButtonType.Primary} onClick={saveWorkflow} className="animate-pulse">
            <Tooltip icon={<FontAwesomeIcon icon={faSvg.faSave} />}>
              <div
                className="-ml-[15px] flex h-0
                                -translate-x-[calc(100%+6px)] -translate-y-1/2
                                items-center justify-center text-white"
              >
                <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Edit</p>
                <div className="-ml-[9px] h-4 w-4 rotate-45 bg-black" />
              </div>
            </Tooltip>
          </ATOButton>
        )}
        <Link
          to={`./${workflowDetail.clientGroupId}`}
          className="flex items-center justify-end gap-2 text-2xl text-primary"
        >
          <Tooltip icon={<FontAwesomeIcon icon={faSvg.faEdit} className="cursor-pointer text-lg text-primary" />}>
            <div
              className="-ml-[15px] flex h-0
                                -translate-x-[calc(100%+6px)] -translate-y-1/2
                                items-center justify-center text-white"
            >
              <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Edit</p>
              <div className="-ml-[9px] h-4 w-4 rotate-45 bg-black" />
            </div>
          </Tooltip>
        </Link>
      </div>
    </div>
  );
};
