import { useNavigate, useParams } from "react-router-dom";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../../App";
import {
  AdvancedWorkflow,
  AdvancedWorkflowUpdateRequest,
  AdvancedWorkflowUser,
  ClientGroupUserType,
} from "../../../../generated";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOLoading } from "../../../../Components/ATOSpinner";
import { ATOScopeChecker } from "../../../../Components/ATOScopeChecker";
import React from "react";
import { WorkflowEditUsers } from "./WorkflowEditUsers";
import { useSinglePendingChanges } from "../../../../Hooks/SinglePendingChagesHook";
import { ATOSuccessModal } from "../../../../Components/ATOSuccessModal";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";

export const WorkflowDetailNavigation = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const navigate = useNavigate();

  const user = useATOAuth();
  const amClient = useAMClient(config.adminUrl, user);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [workflowDetail, setWorkflowDetail] = useState<AdvancedWorkflow>();
  const [users, setUsers] = useState<AdvancedWorkflowUser[]>([]);
  const setModal = useSetRecoilState(AppModalAtom);

  const pendingChanges = useSinglePendingChanges<Record<string, number[]>>();

  const clientGroupUsers = pendingChanges.applyChanges(workflowDetail?.clientGroupUsers ?? {});

  const params = useParams();
  const id = parseInt(params.id ?? "");

  useEffect(() => {
    if (!user.isLoading) {
      setIsLoading(true);
      amClient.practiceWorkflows
        .getAdvancedWorkflowDetail({ custId: custId, clientGroupId: id })
        .then((resp) => {
          setIsLoading(true);
          setWorkflowDetail(resp.data);
        })
        .catch((ex) => {
          setModal(
            <ATODefaultAPIErrorModal
              error={{ message: ex.message }}
              onButton={() => {
                setModal(undefined);
                navigate("../");
              }}
            />
          );
        })
        .finally(() => setIsLoading(false));

      amClient.practiceWorkflows
        .getAdvancedWorkflowUsers({ custId })
        .then((resp) => {
          setUsers(resp.data ?? []);
        })
        .catch((ex) => {
          setModal(
            <ATODefaultAPIErrorModal
              error={{ message: ex.message }}
              onButton={() => {
                setModal(undefined);
              }}
            />
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [user]);

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOLoading />
      </div>
    );
  }

  const saveWorkflow = () => {
    const updateRequest: AdvancedWorkflowUpdateRequest = {
      custId: custId,
      clientGroupId: id,
      clientGroupUsers: clientGroupUsers,
    };

    amClient.practiceWorkflows
      .saveWorkflow({ request: updateRequest })
      .then((resp) => {
        setModal(<ATOSuccessModal setModal={setModal} />);
        navigate("../");
      })
      .catch((ex) => {
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
              navigate("../");
            }}
          />
        );
      })
      .finally(() => setIsLoading(false));
  };

  const setGroupUsers = (userType: ClientGroupUserType, userIds: number[]) => {
    pendingChanges.setChange(userType + "", userIds);
  };

  return (
    <ATOScopeChecker className="h-full w-full" user={user} scope="practice:write" denyAction={"disable"}>
      <div className="grid h-full w-full flex-grow grid-cols-[3fr_1fr] flex-col px-[12%] pt-2">
        <div className={`col-span-4 flex w-full items-center justify-between border-b border-black py-2 `}>
          <div className="w-[50px]" />
          <div>
            {<h1 className="text-center text-xl">Edit Advanced Workflow</h1>}
            <p>PMS Client Owner Name: {workflowDetail?.clientGroupName}</p>
          </div>
          <div>
            <ATOButton
              buttonType={ButtonType.Primary}
              disabled={pendingChanges.listChanges().length === 0}
              onClick={saveWorkflow}
            >
              Save Changes
            </ATOButton>
          </div>
        </div>
        <div className="grid auto-rows-min grid-cols-[1fr_3fr] gap-2 p-2">
          <div>Client Number</div>
          <div>{workflowDetail?.clientNumber}</div>
          <WorkflowEditUsers
            users={users}
            clientGroupUsers={clientGroupUsers}
            userType={ClientGroupUserType.Approver}
            label="Approvers"
            setGroupUsers={setGroupUsers}
          />

          <WorkflowEditUsers
            users={users}
            clientGroupUsers={clientGroupUsers}
            userType={ClientGroupUserType.Reviewer}
            label="Reviewers"
            setGroupUsers={setGroupUsers}
          />

          <WorkflowEditUsers
            users={users}
            clientGroupUsers={clientGroupUsers}
            userType={ClientGroupUserType.FeeForService}
            label="Fee for Service"
            setGroupUsers={setGroupUsers}
          />

          <WorkflowEditUsers
            users={users}
            clientGroupUsers={clientGroupUsers}
            userType={ClientGroupUserType.Sender}
            label="Sender"
            single={true}
            setGroupUsers={setGroupUsers}
          />

          <WorkflowEditUsers
            users={users}
            clientGroupUsers={clientGroupUsers}
            userType={ClientGroupUserType.Postal}
            label="Postal Manager"
            single={true}
            setGroupUsers={setGroupUsers}
          />

          <WorkflowEditUsers
            users={users}
            clientGroupUsers={clientGroupUsers}
            userType={ClientGroupUserType.SenderOptional}
            label="Optional Senders"
            setGroupUsers={setGroupUsers}
          />

          <WorkflowEditUsers
            users={users}
            clientGroupUsers={clientGroupUsers}
            userType={ClientGroupUserType.Escalation}
            label="Escalation Users"
            setGroupUsers={setGroupUsers}
          />

          <WorkflowEditUsers
            users={users}
            clientGroupUsers={clientGroupUsers}
            userType={ClientGroupUserType.BCC}
            label="BCC Users"
            setGroupUsers={setGroupUsers}
          />
        </div>
      </div>
    </ATOScopeChecker>
  );
};
