/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_HowNowXMasterDisplay_ } from '../models/ApiResponse_HowNowXMasterDisplay_';
import type { HowNowXMasterDisplay } from '../models/HowNowXMasterDisplay';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HowNowXActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_HowNowXMasterDisplay_ Success
     * @throws ApiError
     */
    public disconnect(
        formData: any,
    ): CancelablePromise<ApiResponse_HowNowXMasterDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/HowNowXActions/Disconnect',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_HowNowXMasterDisplay_ Success
     * @throws ApiError
     */
    public getHowNowXMasterInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_HowNowXMasterDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/HowNowXActions/GetHowNowXMasterInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public updateHowNowXMasterInfo(
        formData: {
            masterDisplay?: HowNowXMasterDisplay;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/HowNowXActions/UpdateHowNowXMasterInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
