import { useCallback, useEffect, useState } from "react";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { ItemPendingChanges, usePendingChangesWithState } from "../../../../Hooks/PendingChangesHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../../App";
import { DMSDocOptionType, DMSName, HowNowMasterDisplay } from "../../../../generated";
import { IDMSDoc } from "../../../../Components/DMSDoc/IDMSDoc";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOSpinner } from "../../../../Components/ATOSpinner";
import { DMSDocSetupColumnInfo } from "../../../../Components/DMSDoc/DMSDocSetupColumnInfo";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { Tooltip } from "../../../../Components/Tooltip";
import { DMSDocCell } from "../../../../Components/DMSDoc/DMSDocCell";
import { DMSDocSetup } from "../../../../Components/DMSDoc/DMSDocSetup";
import hownowLogo from "../HowNow/hownow-logo.png";

const howNowAtom = atom<ItemPendingChanges<IDMSDoc>[]>({
  key: "howNowAtom",
  default: [],
});

export const HowNow = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const apiClient = useAMClient(config.practiceConfigUrl, user, custId);
  const [practiceInfo, setPracticeInfo] = useState<HowNowMasterDisplay>();
  const setModal = useSetRecoilState(AppModalAtom);
  const pendingChanges = usePendingChangesWithState<IDMSDoc>(useRecoilState(howNowAtom));
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  useEffect(() => {
    apiClient.howNowDmsActions
      .getHowNowMasterInfo({})
      .then((resp) => {
        setPracticeInfo(resp.data);
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  }, []);

  const actualHowNowDetail = pendingChanges.applyChanges("-1", {
    fileName: practiceInfo?.fileName ?? "",
    unknownClientID: practiceInfo?.unknownClientID ?? "",
    serverName: practiceInfo?.serverName ?? "",
    portNumber: practiceInfo?.portNumber ?? "",
    username: practiceInfo?.username ?? "",
    apiKey: practiceInfo?.apiKey ?? "",
    documentStatus: practiceInfo?.documentStatus ?? "",
    createAsUser: practiceInfo?.createAsUser ?? "",
    DocID: "-1",
    Level1ID: "",
    Level2ID: "",
    Level3ID: "",
    Level4ID: "",
  });

  const filingCabinets = ["Clients", "Contacts"];

  if (apiClient === undefined || practiceInfo === undefined) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOSpinner />
      </div>
    );
  }

  const columnInfo: DMSDocSetupColumnInfo<IDMSDoc>[] = [
    {
      name: "Cabinet",
      valueKey: "FilingCabinet",
      popupInitialHeaderText: "Cabinet",
      isMandatory: () => true,
      allowCustom: () => true,
      getDatasource: () =>
        filingCabinets.map((c) => {
          return {
            name: c,
            value: c,
          };
        }),
      columnOptionType: DMSDocOptionType.Client,
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 1",
      valueKey: "Level1ID",
      popupInitialHeaderText: "Level 1",
      isMandatory: () => true,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 2",
      valueKey: "Level2ID",
      popupInitialHeaderText: "Level 2",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 3",
      valueKey: "Level3ID",
      popupInitialHeaderText: "Level 3",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 4",
      valueKey: "Level4ID",
      popupInitialHeaderText: "Level 4",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
  ];

  const connectionConfigurationButton = isCollapsed
    ? "Open General & Server Configuration"
    : "Close General & Server Configuration";

  return (
    <div className="box-border flex h-screen flex-col items-center justify-center">
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.5)]">
          <ATOSpinner />
        </div>
      )}
      <DMSDocSetup<IDMSDoc>
        dmsui={DMSName.HowNow}
        columnInfo={columnInfo}
        isLoading={isLoading}
        isActive={true}
        setIsLoading={setIsLoading}
        setModal={setModal}
        preSave={(docSave) => {
          apiClient.howNowDmsActions
            .updateHowNowMasterInfo({ masterDisplay: actualHowNowDetail as HowNowMasterDisplay })
            .then(() => {
              docSave();
            })
            .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
        }}
        getDefaultItem={(docOptions) => {
          return {
            FilingCabinet: filingCabinets[0],
            DocID: "1",
            Level1ID: "{ClientCode}",
            Level2ID: "",
            Level3ID: "",
            Level4ID: "",
          };
        }}
        pendingChanges={pendingChanges}
      >
        {(types, options) => {
          return (
            <>
              <div className="flex justify-center">
                <img src={hownowLogo} className="mt-2 h-16 flex-shrink-0" />
              </div>
              <div className="pt-4 text-center">
                <ATOButton buttonType={ButtonType.Primary} onClick={toggleCollapse}>
                  {connectionConfigurationButton}
                </ATOButton>
              </div>
              {!isCollapsed && (
                <div className="my-6 grid w-full grid-cols-[1fr_1fr]">
                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <div className="flex">
                      Filename
                      <Tooltip className="ml-2 h-min w-min">
                        <div
                          className="flex h-0 w-52
                                -translate-y-1/2 items-center 
                                justify-center pl-2 text-white"
                        >
                          <div className=" h-4 w-4 rotate-45 bg-black" />
                          <p className="z-11 -ml-[9px] rounded bg-black px-2 text-base">
                            Filename is used to rename the document when it's saved to HowNow.
                          </p>
                        </div>
                      </Tooltip>
                    </div>
                    <div className="z-20 h-8 w-[min(480px,80%)]">
                      <DMSDocCell<IDMSDoc>
                        columnInfo={{
                          name: "fileName",
                          valueKey: "fileName",
                          isMandatory: () => true,
                          allowCustom: () => true,
                          getDatasource: () => options?.[DMSDocOptionType.FileName],
                          columnOptionType: DMSDocOptionType.FileName,
                          width: "",
                        }}
                        pendingChanges={pendingChanges}
                        isDefaultDocType={false}
                        rowIndex={-1}
                        setModal={setModal}
                        value={{
                          cabinets: "",
                          DocID: "",
                          Level1ID: "",
                          Level2ID: "",
                          Level3ID: "",
                          Level4ID: "",
                          fileName: pendingChanges.getChange("-1", "fileName")?.value ?? actualHowNowDetail?.fileName,
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <div className="flex">
                      Unknown Client ID
                      <Tooltip className="ml-2 h-min w-min">
                        <div
                          className="flex h-0 w-52
                                -translate-y-1/2 items-center 
                                justify-center pl-2 text-white"
                        >
                          <div className=" h-4 w-4 rotate-45 bg-black" />
                          <p className="z-10 -ml-[9px] rounded bg-black px-2 text-base">
                            Files will be saved to HowNow against Unknown Client when client is not matches. Commonly
                            happens when ABN or TFN is not found in our database.
                          </p>
                        </div>
                      </Tooltip>
                    </div>
                    <ATOTextBox
                      className="w-[min(480px,80%)]"
                      value={actualHowNowDetail?.unknownClientID ?? ""}
                      onChange={(str) => pendingChanges.setChange("-1", "unknownClientID", str)}
                    />
                  </div>

                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <div className="flex">
                      Server Name
                      <Tooltip className="ml-2 h-min w-min">
                        <div
                          className="flex h-0 w-52
                                    -translate-y-1/2 items-center 
                                    justify-center pl-2 text-white"
                        >
                          <div className=" h-4 w-4 rotate-45 bg-black" />
                          <p className="z-10 -ml-[9px] rounded bg-black px-2 text-base">It's provided by HowNow.</p>
                        </div>
                      </Tooltip>
                    </div>
                    <ATOTextBox
                      className="w-[min(480px,80%)]"
                      value={actualHowNowDetail?.serverName ?? ""}
                      onChange={(str) => pendingChanges.setChange("-1", "serverName", str)}
                    />
                  </div>

                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <div className="flex">
                      Port Number
                      <Tooltip className="ml-2 h-min w-min">
                        <div
                          className="flex h-0 w-52
                                    -translate-y-1/2 items-center 
                                    justify-center pl-2 text-white"
                        >
                          <div className=" h-4 w-4 rotate-45 bg-black" />
                          <p className="z-10 -ml-[9px] rounded bg-black px-2 text-base">
                            It's provided by HowNow. Commonly, a 4 digit number e.g. 8092.
                          </p>
                        </div>
                      </Tooltip>
                    </div>
                    <ATOTextBox
                      className="w-[min(480px,80%)]"
                      value={actualHowNowDetail?.portNumber ?? ""}
                      onChange={(str) => pendingChanges.setChange("-1", "portNumber", str)}
                    />
                  </div>

                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <div className="flex">
                      Username
                      <Tooltip className="ml-2 h-min w-min">
                        <div
                          className="flex h-0 w-52
                                    -translate-y-1/2 items-center 
                                    justify-center pl-2 text-white"
                        >
                          <div className=" h-4 w-4 rotate-45 bg-black" />
                          <p className="z-10 -ml-[9px] rounded bg-black px-2 text-base">
                            It's provided by HowNow. It's required for the ATOmate to be able to connect to NowNow.
                          </p>
                        </div>
                      </Tooltip>
                    </div>
                    <ATOTextBox
                      className="w-[min(480px,80%)]"
                      value={actualHowNowDetail?.username ?? ""}
                      onChange={(str) => pendingChanges.setChange("-1", "username", str)}
                    />
                  </div>

                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <div className="flex">
                      Api Key
                      <Tooltip className="ml-2 h-min w-min">
                        <div
                          className="flex h-0 w-52
                                    -translate-y-1/2 items-center 
                                    justify-center pl-2 text-white"
                        >
                          <div className=" h-4 w-4 rotate-45 bg-black" />
                          <p className="z-10 -ml-[9px] rounded bg-black px-2 text-base">
                            It's provided by HowNow. It's required for the ATOmate to be able to connect to NowNow.
                          </p>
                        </div>
                      </Tooltip>
                    </div>
                    <ATOTextBox
                      className="w-[min(480px,80%)]"
                      type="password"
                      value={actualHowNowDetail?.apiKey ?? ""}
                      onChange={(str) => pendingChanges.setChange("-1", "apiKey", str)}
                    />
                  </div>

                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <div className="flex">
                      Document Status
                      <Tooltip className="ml-2 h-min w-min">
                        <div
                          className="flex h-0 w-52
                                    -translate-y-1/2 items-center 
                                    justify-center pl-2 text-white"
                        >
                          <div className=" h-4 w-4 rotate-45 bg-black" />
                          <p className="z-10 -ml-[9px] rounded bg-black px-2 text-base">
                            When files are saved to HowNow, their Status will be set to this. Most common status used is
                            'Draft'.
                          </p>
                        </div>
                      </Tooltip>
                    </div>
                    <ATOTextBox
                      className="w-[min(480px,80%)]"
                      value={actualHowNowDetail?.documentStatus ?? ""}
                      onChange={(str) => pendingChanges.setChange("-1", "documentStatus", str)}
                    />
                  </div>

                  <div className="flex h-min w-full flex-col items-center justify-center p-2">
                    <div className="flex">
                      Create As User
                      <Tooltip className="ml-2 h-min w-min">
                        <div
                          className="flex h-0 w-52
                                    -translate-y-1/2 items-center 
                                    justify-center pl-2 text-white"
                        >
                          <div className=" h-4 w-4 rotate-45 bg-black" />
                          <p className="z-10 -ml-[9px] rounded bg-black px-2 text-base">
                            When a file is saved to HowNow, it would appear that following user created the file.
                          </p>
                        </div>
                      </Tooltip>
                    </div>
                    <ATOTextBox
                      className="w-[min(480px,80%)]"
                      value={actualHowNowDetail?.createAsUser ?? ""}
                      onChange={(str) => pendingChanges.setChange("-1", "createAsUser", str)}
                    />
                  </div>
                </div>
              )}
            </>
          );
        }}
      </DMSDocSetup>
    </div>
  );
};
