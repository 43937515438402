import { useCallback, useEffect, useState } from "react";
import { ATOModal } from "../../../Components/ATOModal";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import { FileUploadDetails } from "./FileUploadDetails";
import { AMClient, CustomerForIASDropdown } from "../../../generated";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { useATOAuth } from "../../../Hooks/ATOAuthHook";
import { useNavigate } from "react-router-dom";
import { ATOTextBox } from "../../../Components/ATOTextBox";
import { ATOSpinner } from "../../../Components/ATOSpinner";

export interface UploadableFile {
  file: File;
  errors: FileError[];
  status: "pending" | "inprogress" | "failed" | "success";
}

export const IASReportTool = () => {
  const user = useATOAuth();
  const config = useConfig();
  const navigate = useNavigate();

  const [client, setClient] = useState<AMClient | undefined>(undefined);
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerForIASDropdown>({});
  const [customers, setCustomers] = useState<CustomerForIASDropdown[]>([]);
  const [query, setQuery] = useState<string>("");
  const [isCustomerListVisible, setIsCustomerListVisible] = useState<boolean>(true);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [isListLoading, setIsListLoading] = useState<boolean>(false);

  const maxFileSize = 104857600; //100MB
  const minFileSize = 100;

  const filteredCustomers = customers.filter((customer) => {
    const nameMatch = customer.name && customer.name.toLowerCase().includes(query.toLowerCase());
    const idMatch = customer.custId && customer.custId.toString().includes(query);
    return nameMatch || idMatch;
  });

  const onCloseHandler = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!user.isLoading) {
      const client = new AMClient({
        BASE: config.iasToolUrl,
        VERSION: "2",
        TOKEN: user.getAccessToken,
      });

      setClient(client);
    }
  }, [user]);

  const onDrop = useCallback((accFiles: File[], rejectedFiles: FileRejection[]) => {
    const mappedAcceptedFiles = accFiles.map<UploadableFile>((file) => ({ file, errors: [], status: "pending" }));
    const mappedRejectedFiles = rejectedFiles.map<UploadableFile>((file) => ({
      file: file.file,
      errors: file.errors,
      status: "failed",
    }));
    setFiles((curr) => [...curr, ...mappedAcceptedFiles, ...mappedRejectedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-excel": [".xls"],
    },
    maxSize: maxFileSize,
    minSize: minFileSize,
  });

  const successfulFileCallback = useCallback(
    (fileIndex: number) => {
      const file = files[fileIndex];

      setFiles([
        ...files.filter((f, i) => i < fileIndex),
        { ...file, status: "success" },
        ...files.filter((f, i) => i > fileIndex),
      ]);
    },
    [files]
  );

  const failedFileCallback = useCallback(
    (fileIndex: number, fileError: FileError[]) => {
      const file = files[fileIndex];
      setFiles([
        ...files.filter((f, i) => i < fileIndex),
        { ...file, status: "failed", errors: [...fileError] },
        ...files.filter((f, i) => i > fileIndex),
      ]);
    },
    [files]
  );

  useEffect(() => {
    if (files.length > 0) {
      if (files.every((f) => f.status !== "inprogress")) {
        const fileIndex = files.findIndex((f) => f.status === "pending");
        if (fileIndex > -1) {
          const file = files[fileIndex];
          const newFiles: UploadableFile[] = [
            ...files.filter((f, i) => i < fileIndex),
            { ...file, status: "inprogress" },
            ...files.filter((f, i) => i > fileIndex),
          ];

          setFiles(newFiles);

          client?.iasDocumentUpload
            .uploadFile({ fileStream: file.file, fileName: file.file.name, custId: selectedCustomer.custId })
            .then(() => {
              successfulFileCallback(fileIndex);
            })
            .catch((err) => {
              const fileError: FileError[] = [{ message: err.message.split(".")[0] ?? err.message, code: "" }];
              failedFileCallback(fileIndex, fileError);
            });
        }
      }
    }
  }, [files]);

  const handleCustomerChange = (val: CustomerForIASDropdown) => {
    setSelectedCustomer(val);
    setQuery(val.name ? val.name : "");
    setIsCustomerListVisible(false);
  };

  const handleQueryChange = (val: string) => {
    setIsListLoading(true);
    if (query === "") {
      setSelectedCustomer({});
    }
    setQuery(val);
  };

  const handleOnDebounce = (val: string) => {
    if (client !== undefined) {
      client.iasDocumentUpload.getEligibleCustomers({query}).then((resp) => {
        setCustomers(resp.data ?? [])
        setIsListLoading(false);
      });
    }
    setIsCustomerListVisible(true);
  };


  return (
    <ATOModal
      className="h-[80%] w-[600px]"
      onClose={() => onCloseHandler()}
      IsBackgroundTransparent={true}
      title="IAS Upload Tool"
    >
      <div className={`flex w-full items-center justify-center p-4 ${isListLoading ? 'overflow-y-hidden' : ''}`}>
        <div className="w-full rounded">
          <div className="flex flex-col gap-2">
            {selectedCustomer.custId === undefined && (
              <div className="animate-shakeX text-center text-red-500">
                You need to choose a practice before you can upload a document
              </div>
            )}
            <ATOTextBox
              value={query}
              className="w-full border border-gray-300"
              onChange={handleQueryChange}
              onDebounce={handleOnDebounce}
              hasClearButton
            />
            {isListLoading && (<ATOSpinner />)}

            {!isListLoading && isCustomerListVisible && filteredCustomers.length > 0 && (
              <ul className="mt-2 max-h-40 overflow-y-auto rounded-md border border-gray-300">
                {filteredCustomers.slice(0, 10).map((customer) => (
                  <li
                    key={customer.custId}
                    className="cursor-pointer p-2 hover:bg-gray-200"
                    onClick={() => {
                      handleCustomerChange(customer);
                    }}
                  >
                    {customer.name}
                  </li>
                ))}
              </ul>
            )}
            {selectedCustomer.custId !== undefined && (
              <div>
                {showModalMessage && (
                  <div className="animate-shakeX text-center text-red-500">
                    Please wait for your uploads to complete
                  </div>
                )}
                <div className="h-28 p-1" {...getRootProps()}>
                  <div className="flex h-full w-full items-center justify-center rounded-lg border-2 border-dashed border-primary">
                    <input {...getInputProps()} />
                    <p>Drag and drop some files here, or click to select files.</p>
                  </div>
                </div>
                <div className="grid max-h-64 w-full overflow-y-auto overflow-x-hidden">
                  {files
                    .map((file, idx) => <FileUploadDetails key={idx} file={file} />)
                    .reduce(
                      (result, val) => (
                        <>
                          {result}
                          <div className="h-px w-full bg-gray-300 first:h-0 last:h-px" />
                          {val}
                        </>
                      ),
                      <></>
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ATOModal>
  );
};
