import { useEffect, useState } from "react";

import fyiDocLogo from "../FYIDocSetup/FYIDoc-Logo.png";
import { FyiDoc, FyiMaster } from "./Models/FyiDoc";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { ATOSuccessModal } from "../../../../Components/ATOSuccessModal";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOModal } from "../../../../Components/ATOModal";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ATOModalSingle } from "../../../../Components/Modal/ATOModalSingle";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { DMSDocSetupColumnInfo } from "../../../../Components/DMSDoc/DMSDocSetupColumnInfo";
import { ATOSpinner } from "../../../../Components/ATOSpinner";
import { DMSDocSetup } from "../../../../Components/DMSDoc/DMSDocSetup";
import { DMSDocOptionType, DMSName, FYICabinetDisplay, FyiPracticeInfo } from "../../../../generated";
import { DMSDocCell } from "../../../../Components/DMSDoc/DMSDocCell";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../../App";
import { ItemPendingChanges, usePendingChangesWithState } from "../../../../Hooks/PendingChangesHook";

const fyiMasterAtom = atom<ItemPendingChanges<FyiMaster>[]>({
  key: "fyiMaster",
  default: [],
});

export const FyiDocSetup = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();

  const apiClient = useAMClient(config.fyiDocUrl, user, custId);
  const setModal = useSetRecoilState(AppModalAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [practiceInfo, setPracticeInfo] = useState<FyiPracticeInfo>();
  const pendingChanges = usePendingChangesWithState<FyiMaster>(useRecoilState(fyiMasterAtom));

  useEffect(() => {
    apiClient.fyiDmsActions
      .getPracticeInfo({})
      .then((resp) => {
        setPracticeInfo(resp.data);
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  }, []);

  const actualFyiDetail = pendingChanges.applyChanges("-1", {
    fileName: practiceInfo?.fileName ?? "",
    unknownClientID: practiceInfo?.unknownClientID ?? "",
    portalUrl: practiceInfo?.portalUrl ?? "",
    DocID: "-1",
    Cabinet: "",
    CategoryName: "",
    CategoryValue: "",
    YearName: "",
    YearValue: "",
    Level1ID: "",
    Level2ID: "",
    Level3ID: "",
    Level4ID: "",
  });

  const blankCabinet: FYICabinetDisplay = {
    cabinetName: "",
    categories: [],
  };

  const firstCabinet = practiceInfo?.cabinets?.at(0) ?? blankCabinet;

  const isConnected = (practiceInfo?.cabinets?.length ?? 0) > 0;

  const handlePortalAction = async () => {
    apiClient.fyiDmsActions
      .createCollaborateCabinet({})
      .finally(() => {
        pendingChanges.setChange("-1", "hasCollaborateCabinet", true);
        setModal(<ATOSuccessModal setModal={setModal} />);
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
  };

  const columnInfo: DMSDocSetupColumnInfo<FyiMaster>[] = [
    {
      name: "Cabinet",
      valueKey: "Cabinet",
      popupInitialHeaderText: "System Provided",
      isMandatory: () => true,
      getDatasource: () =>
        practiceInfo?.cabinets?.map((c) => {
          return {
            name: c.cabinetName,
            value: c.cabinetName,
          };
        }) ?? [],
      columnOptionType: "all",
      width: "1fr",
    },
    {
      name: "Category Name",
      valueKey: "CategoryName",
      popupInitialHeaderText: "System Provided",
      isMandatory: (item) => !!item.CategoryValue,
      getDatasource: (item) =>
        practiceInfo?.cabinets
          ?.find((c) => c.cabinetName === item.Cabinet)
          ?.categories?.map((c) => {
            return {
              name: c.categoryName,
              value: c.categoryName,
            };
          }) ?? [],
      columnOptionType: "all",
      width: "1fr",
    },
    {
      name: "Category Value",
      valueKey: "CategoryValue",
      popupInitialHeaderText: "System Provided",
      isMandatory: (item) => !!item.CategoryName !== !!item.CategoryValue, //xor
      allowCustom: () => true,
      getDatasource: (item, colInfo, row, opts) => [
        ...(practiceInfo?.cabinets
          ?.find((c) => c.cabinetName === item.Cabinet)
          ?.categories?.find((c) => c.categoryName === item.CategoryName)
          ?.options?.map((c) => {
            return {
              name: c.optionName,
              value: c.optionName,
            };
          }) ?? []),
        { name: "Data Driven", description: "Header" },
        ...opts,
      ],
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Year Category",
      valueKey: "YearName",
      popupInitialHeaderText: "System Provided",
      isMandatory: () => false,
      getDatasource: (item) =>
        practiceInfo?.cabinets
          ?.find((c) => c.cabinetName === item.Cabinet)
          ?.categories?.map((c) => {
            return {
              name: c.categoryName,
              value: c.categoryName,
            };
          }) ?? [],
      columnOptionType: "all",
      width: "1fr",
    },
    {
      name: "Year Value",
      valueKey: "YearValue",
      isMandatory: () => false,
      allowCustom: () => true,
      getDatasource: (item, colInfo, row, opts) => [
        { name: "Data Driven", description: "Header" },
        ...opts,
        { name: "System Provided", description: "Header" },
        ...(practiceInfo?.cabinets
          ?.find((c) => c.cabinetName === item.Cabinet)
          ?.categories?.find((c) => c.categoryName === item.YearName)
          ?.options?.map((c) => {
            return {
              name: c.optionName,
              value: c.optionName,
            };
          }) ?? []),
      ],
      columnOptionType: DMSDocOptionType.Year,
      width: "1fr",
      popupStyle: { width: "150%" },
    },
  ];

  if (practiceInfo === undefined) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOSpinner />
      </div>
    );
  }

  return (
    <div className="box-border flex h-screen flex-col items-center justify-center">
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.5)]">
          <ATOSpinner />
        </div>
      )}

      <DMSDocSetup<FyiMaster>
        dmsui={DMSName.FYIDocs}
        columnInfo={columnInfo}
        isLoading={isLoading}
        isActive={true}
        setIsLoading={setIsLoading}
        setModal={setModal}
        pendingChanges={pendingChanges}
        preSave={(docSave) => {
          apiClient.fyiDmsActions
            .savePracticeInfo({
              fileName: actualFyiDetail.fileName ?? "",
              unknownClientId: actualFyiDetail.unknownClientID ?? "",
              portalUrl: actualFyiDetail.portalUrl ?? "",
            })
            .then(() => {
              docSave();
            })
            .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
        }}
        getDefaultItem={(opts, docs) => {
          return {
            Cabinet: firstCabinet.cabinetName ?? "",
            YearName:
              (firstCabinet.categories?.findIndex((c) => c.categoryName === "Year") ?? -1) > -1
                ? "Year"
                : firstCabinet.categories?.at(0)?.categoryName ?? "",
            YearValue: opts?.[DMSDocOptionType.Year]?.[0]?.value ?? "",
            DocID: "0",
            CategoryName: "",
            CategoryValue: "",
            Level1ID: "",
            Level2ID: "",
            Level3ID: "",
            Level4ID: "",
            fileName: "",
            portalUrl: "",
            unknownClientID: "",
          };
        }}
      >
        {(types, options) => {
          return (
            <>
              <div className="flex justify-center">
                <img src={fyiDocLogo} className="mt-2 h-16 w-16 flex-shrink-0" alt="Fyi Docs Logo" />
              </div>
              <div className="my-6 grid w-full grid-cols-[1fr_1fr] gap-4">
                {isConnected && (
                  <>
                    {practiceInfo.isPortal && (
                      <>
                        {!practiceInfo.hasCollaborateCabinet && (
                          <div className="col-span-2 flex w-full justify-center">
                            <ATOButton className="text-lg" buttonType={ButtonType.Primary} onClick={handlePortalAction}>
                              Set Up FYI Collaborate Cabinet
                            </ATOButton>
                          </div>
                        )}
                        <div className="flex h-min w-full flex-col items-center justify-center p-2">
                          <p>Portal URL</p>
                          <ATOTextBox
                            className="w-[min(480px,80%)]"
                            value={actualFyiDetail?.portalUrl ?? ""}
                            onChange={(str) => pendingChanges.setChange("-1", "portalUrl", str)}
                          />
                        </div>
                      </>
                    )}
                    <div className="flex h-min w-full flex-col items-center justify-center p-2">
                      <p>Filename</p>
                      <div className="z-20 h-8 w-[min(480px,80%)]">
                        <DMSDocCell<FyiMaster>
                          pendingChanges={pendingChanges}
                          columnInfo={{
                            name: "fileName",
                            valueKey: "fileName",
                            isMandatory: () => true,
                            allowCustom: () => true,
                            getDatasource: () => options?.[DMSDocOptionType.FileName],
                            columnOptionType: DMSDocOptionType.FileName,
                            width: "",
                          }}
                          isDefaultDocType={false}
                          rowIndex={-1}
                          setModal={setModal}
                          value={{
                            DocID: "",
                            fileName: actualFyiDetail?.fileName ?? "",
                            CategoryName: "",
                            CategoryValue: "",
                            Cabinet: "",
                            YearName: "",
                            YearValue: "",
                            Level1ID: "",
                            Level2ID: "",
                            Level3ID: "",
                            Level4ID: "",
                            portalUrl: "",
                            unknownClientID: "",
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex h-min w-full flex-col items-center justify-center p-2">
                      <p>Unknown Client ID</p>
                      <ATOTextBox
                        className="w-[min(480px,80%)]"
                        value={actualFyiDetail?.unknownClientID ?? ""}
                        onChange={(str) => pendingChanges.setChange("-1", "unknownClientID", str)}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          );
        }}
      </DMSDocSetup>
    </div>
  );
};
