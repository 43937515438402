import { atom } from "recoil";
import { SecureDeliveryConfigDisplay } from "../../../../generated";

export const secureDeliveryConfigAtom = atom<SecureDeliveryConfigDisplay | undefined>({
  key: "secureDeliveryConfigs",
  default: {
    secureDeliveryConfigId: 0,
    custId: 0,
    senderName: "",
    deliveryInstructions: "",
    passwordTemplate: "",
    templatePreference: 0,
  },
});
