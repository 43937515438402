import { useEffect, useState } from "react";
import { AMClient, SendGridDomainRecordDisplay } from "../../../generated";
import useDidMountEffect from "../../../Hooks/DidMountHook";
import { ATOSpinner } from "../../../Components/ATOSpinner";
import { ATOSuccessModal } from "../../../Components/ATOSuccessModal";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOModalDouble } from "../../../Components/Modal/ATOModalSingleDouble";
import { ModalTypes } from "../../../Components/Modal/ATOModalTypes";
import { ATOModalSingle } from "../../../Components/Modal/ATOModalSingle";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "../../../Components/Tooltip";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { CreateDomainModal } from "./CreateDomainModal";

export const DomainDetailsAccordion = (props: {
  amClient: AMClient;
  setModal: (modal: React.ReactNode) => void;
  domainName?: string | null;
}) => {
  const [activeRecord, setActiveRecord] = useState<SendGridDomainRecordDisplay | null>(null);
  const [domainDetails, setDomainDetails] = useState<SendGridDomainRecordDisplay[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [copiedText, setCopiedText] = useState<string | null>(null);
  const [modalDomainName, setModalDomainName] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    props.amClient?.communicationHandler
      .getDomainDetails({})
      .then((record) => {
        setDomainDetails(record?.data ?? []);
        if (props.domainName) {
          const filteredRecord = record?.data?.find((detail) => detail.domainName === props.domainName);
          if (filteredRecord) {
            setActiveRecord(filteredRecord);
          }
        }
      })
      .finally(() => setIsLoading(false));
  }, [props.amClient]);

  const getDomainDetails = () => {
    setIsLoading(true);
    props.amClient?.communicationHandler
      .getDomainDetails({})
      .then((record) => {
        setDomainDetails(record?.data ?? []);
      })
      .finally(() => setIsLoading(false));
  };

  useDidMountEffect(() => {
    getDomainDetails();
  }, [props.amClient]);

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-white">
        <ATOSpinner />
      </div>
    );
  }

  if (domainDetails.length === 0) {
    return <div className="flex h-full w-full items-center justify-center bg-white">Empty</div>;
  }

  const toggleDetails = (record: SendGridDomainRecordDisplay) => {
    if (!isLoading) {
      setActiveRecord((prevState) => (prevState?.sendGridDomainId === record.sendGridDomainId ? null : record));
    }
  };

  const handleSendEmail = () => {
    setIsLoading(true);
    props.amClient.communicationHandler
      .sendDomainDetailsEmail({ sendgridDomainRecordId: activeRecord?.sendGridDomainRecordId })
      .finally(() => {
        setIsLoading(false);
        props.setModal(<ATOSuccessModal setModal={props.setModal} />);
      })
      .catch((ex) => {
        props.setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => props.setModal(undefined)} />);
      });
  };

  const createDomainSetupAlert = () => {
    props.amClient.communicationHandler
      .createDomainSetupAlert({ domainName: activeRecord?.domainName ?? "" })
      .finally(() => {
        props.setModal(<ATOSuccessModal setModal={props.setModal} />);
      })
      .catch((ex) => {
        props.setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => props.setModal(undefined)} />);
      });
  };

  const showSupportModal = () => {
    props.setModal(
      <ATOModalDouble
        type={ModalTypes.warning}
        className="w-[600px] p-2"
        message="Are you sure you want to create a support ticket?"
        onClose={() => props.setModal(undefined)}
        onLeftButton={() => props.setModal(undefined)}
        title="WARNING"
        onRightButton={() => {
          props.setModal(undefined);
          createDomainSetupAlert();
        }}
        rightButton="Yes"
      />
    );
  };

  const showDeleteDomainModal = () => {
    props.setModal(
      <ATOModalDouble
        type={ModalTypes.warning}
        className="w-[600px] p-2"
        message="Are you sure you want to delete domain?"
        onClose={() => props.setModal(undefined)}
        onLeftButton={() => props.setModal(undefined)}
        title="WARNING"
        onRightButton={() => {
          props.setModal(undefined);
          handleDeleteDomain();
        }}
        rightButton="Yes"
      />
    );
  };

  const handleDeleteDomain = () => {
    props.amClient.communicationHandler
      .deleteDomainDetails({ sendgridDomainRecordId: activeRecord?.sendGridDomainRecordId })
      .finally(() => {
        props.setModal(
          <ATOModalSingle title="Success!" message="Successfully deleted" onButton={() => props.setModal(undefined)} />
        );
      })
      .catch((ex) => {
        props.setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => props.setModal(undefined)} />);
      });
  };

  const handleVerify = () => {
    setIsLoading(true);
    props.amClient.communicationHandler
      .verifyDomainDetails({ sendgridDomainRecordId: activeRecord?.sendGridDomainRecordId })
      .then(() => {
        getDomainDetails();
      })
      .finally(() => setIsLoading(false))
      .catch((ex) => {
        props.setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => props.setModal(undefined)} />);
      });
  };

  const showCreateDomainModal = () => {
    props.setModal(<CreateDomainModal amClient={props.amClient} title="Enter Domain Name" setModal={props.setModal} />);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    setTimeout(() => setCopiedText(null), 2000);
  };

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="grid-cols grid h-full w-full overflow-y-auto overflow-x-hidden">
        <div className="flex h-full w-full flex-col border-t border-black">
          {domainDetails.map((detail) => (
            <React.Fragment key={detail.sendGridDomainId}>
              <div
                className="flex h-20 cursor-pointer items-center justify-between border-b border-black bg-primary px-2 text-xl tracking-wider text-white"
                onClick={() => toggleDetails(detail)}
              >
                <div className="w-32">
                  {!detail.isValid ||
                  !detail.isDmarcValid ||
                  (detail.additionalCnameKey && detail.additionalCnameValue && !detail.hasPermissionOverDomain) ? (
                    <FontAwesomeIcon icon={faSvg.faExclamationTriangle} className="text-yellow-400" />
                  ) : (
                    <FontAwesomeIcon icon={faSvg.faCheck} className="text-green-400" />
                  )}
                </div>
                <p>{detail.domainName}</p>
                <div className="flex w-32 justify-end">
                  <FontAwesomeIcon
                    className={`transition-transform ${activeRecord?.sendGridDomainId === detail.sendGridDomainId ? "rotate-180" : ""}`}
                    icon={faSvg.faChevronUp}
                  />
                </div>
              </div>
              {activeRecord?.sendGridDomainId === detail.sendGridDomainId && (
                <div className="bg-gray-50">
                  <React.Fragment>
                    <div className="grid grid-cols-12 gap-1 p-4 text-sm">
                      <div className="col-span-2 font-bold">Type</div>
                      <div className="col-span-5 font-bold">Host</div>
                      <div className="col-span-5 font-bold">Value</div>
                      {[
                        {
                          isValid: detail.dnsMailCnameIsValid,
                          type: detail.dnsMailCnameType,
                          host: detail.dnsMailCnameHost,
                          data: detail.dnsMailCnameData,
                        },
                        {
                          isValid: detail.dnsDkim1IsValid,
                          type: detail.dnsDkim1Type,
                          host: detail.dnsDkim1Host,
                          data: detail.dnsDkim1Data,
                        },
                        {
                          isValid: detail.dnsDkim2IsValid,
                          type: detail.dnsDkim2Type,
                          host: detail.dnsDkim2Host,
                          data: detail.dnsDkim2Data,
                        },
                        {
                          isValid: detail.isDmarcValid,
                          type: "TXT",
                          host: `_dmarc.${detail.domainName}`,
                          data: "v=DMARC1;p=none;",
                        },
                        {
                          isValid: detail.hasPermissionOverDomain,
                          type: "TXT",
                          host: detail.additionalCnameKey,
                          data: detail.additionalCnameValue,
                        },
                      ].map(
                        (record, index) =>
                          record.host &&
                          record.data && (
                            <React.Fragment key={index}>
                              <div
                                className={`col-span-2 grid grid-cols-6 ${record.isValid ? "text-green-500" : "text-red-500"} flex items-center`}
                              >
                                {!record.isValid ? (
                                  <Tooltip
                                    icon={
                                      <FontAwesomeIcon icon={faSvg.faExclamationTriangle} className="text-yellow-400" />
                                    }
                                  >
                                    <div
                                      className="flex h-0 -translate-y-1/2
                                                                items-center 
                                                                justify-center pl-2 text-white"
                                    >
                                      <div className=" h-4 w-4 rotate-45 bg-black" />
                                      <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                                        Missing
                                      </p>
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <Tooltip icon={<FontAwesomeIcon icon={faSvg.faCheck} className="text-green-400" />}>
                                    <div
                                      className="flex h-0 -translate-y-1/2
                                                              items-center 
                                                              justify-center pl-2 text-white"
                                    >
                                      <div className=" h-4 w-4 rotate-45 bg-black" />
                                      <p className="z-10 -ml-[9px] whitespace-nowrap rounded bg-black px-2 text-base">
                                        Valid
                                      </p>
                                    </div>
                                  </Tooltip>
                                )}
                                <p>{record?.type?.toUpperCase()}</p>
                              </div>
                              <div
                                className={`col-span-5 grid grid-cols-6 ${record.isValid ? "text-green-500" : "text-red-500"} flex items-center`}
                              >
                                <p className="col-span-5">{record.host}</p>
                                <div className="relative">
                                  <ATOButton
                                    buttonType={ButtonType.Primary}
                                    className="ml-2 rounded px-2 py-1"
                                    onClick={() => copyToClipboard(record?.host ?? "")}
                                  >
                                    <FontAwesomeIcon icon={faSvg.faCopy} />
                                    {copiedText === record.host && (
                                      <span className="absolute -top-6 left-1/2 -translate-x-1/2 transform rounded bg-black px-2 py-1 text-xs text-white">
                                        Copied
                                      </span>
                                    )}
                                  </ATOButton>
                                </div>
                              </div>
                              <div
                                className={`col-span-5 grid grid-cols-6 ${record.isValid ? "text-green-500" : "text-red-500"} flex items-center`}
                              >
                                <p className="col-span-5">{record.data}</p>
                                <div className="relative">
                                  <ATOButton
                                    buttonType={ButtonType.Primary}
                                    className="ml-2 rounded px-2 py-1"
                                    onClick={() => copyToClipboard(record?.data ?? "")}
                                  >
                                    <FontAwesomeIcon icon={faSvg.faCopy} />
                                    {copiedText === record.data && (
                                      <span className="absolute -top-6 left-1/2 -translate-x-1/2 transform rounded bg-black px-2 py-1 text-center text-xs text-white">
                                        Copied
                                      </span>
                                    )}
                                  </ATOButton>
                                </div>
                              </div>
                            </React.Fragment>
                          )
                      )}
                    </div>
                    <div className="flex h-16 items-center justify-center">
                      <div className="space-x-4">
                        <ATOButton className="text-sm" buttonType={ButtonType.Confirm} onClick={handleSendEmail}>
                          <FontAwesomeIcon icon={faSvg.faEnvelope} className="mr-1" />
                          Send Email
                        </ATOButton>

                        <ATOButton className="text-sm" buttonType={ButtonType.Primary} onClick={handleVerify}>
                          <FontAwesomeIcon icon={faSvg.faCheck} className="mr-1" />
                          Verify
                        </ATOButton>

                        <ATOButton className="text-sm" buttonType={ButtonType.Error} onClick={showDeleteDomainModal}>
                          <FontAwesomeIcon icon={faSvg.faTrash} className="mr-1" />
                          Delete
                        </ATOButton>
                        <ATOButton className="text-sm" buttonType={ButtonType.Warning} onClick={showSupportModal}>
                          <FontAwesomeIcon icon={faSvg.faQuestionCircle} className="mr-1" />
                          Support
                        </ATOButton>
                      </div>
                      <div className="space-x-4"></div>
                    </div>
                  </React.Fragment>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="flex w-full items-center justify-between border-t border-gray-300 px-2 py-1">
        <ATOButton buttonType={ButtonType.Confirm} onClick={showCreateDomainModal} className="text-sm">
          Create Domain
        </ATOButton>
      </div>
    </div>
  );
};
