/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_DropboxPracticeInfo_ } from '../models/ApiResponse_DropboxPracticeInfo_';
import type { ApiResponse_List_FileFolderDisplay_ } from '../models/ApiResponse_List_FileFolderDisplay_';
import type { ApiResponse4 } from '../models/ApiResponse4';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DropBoxEnrolmentActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_DropboxPracticeInfo_ Success
     * @throws ApiError
     */
    public getDropboxPracticeInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_DropboxPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/DropBoxEnrolmentActions/GetDropboxPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_List_FileFolderDisplay_ Success
     * @throws ApiError
     */
    public getListFolders(
        formData: any,
    ): CancelablePromise<ApiResponse_List_FileFolderDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/DropBoxEnrolmentActions/GetListFolders',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse4 Success
     * @throws ApiError
     */
    public saveDropboxPracticeInfo(
        formData: {
            fileName?: string;
            baseFolder?: string;
            unknownClient?: string;
        },
    ): CancelablePromise<ApiResponse4> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/DropBoxEnrolmentActions/SaveDropboxPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
