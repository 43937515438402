/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_IEnumerable_CustomFieldDefinition_ } from '../models/ApiResponse_IEnumerable_CustomFieldDefinition_';
import type { ApiResponse_IEnumerable_XeroPMSFieldsDisplay_ } from '../models/ApiResponse_IEnumerable_XeroPMSFieldsDisplay_';
import type { ApiResponse_UnknownClientInfo_ } from '../models/ApiResponse_UnknownClientInfo_';
import type { ApiResponse_XeroPracticeInfo_ } from '../models/ApiResponse_XeroPracticeInfo_';
import type { CustomFieldDefinition } from '../models/CustomFieldDefinition';
import type { XeroPracticeInfo } from '../models/XeroPracticeInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class XeroActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_XeroPracticeInfo_ Success
     * @throws ApiError
     */
    public disconnectFromXero(
        formData: any,
    ): CancelablePromise<ApiResponse_XeroPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/XeroActions/DisconnectFromXero',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_XeroPMSFieldsDisplay_ Success
     * @throws ApiError
     */
    public getXeroPmsFields(
        formData: {
            searchTerm?: string;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_XeroPMSFieldsDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/XeroActions/GetXeroPMSFields',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_XeroPracticeInfo_ Success
     * @throws ApiError
     */
    public getXeroPracticeInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_XeroPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/XeroActions/GetXeroPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_CustomFieldDefinition_ Success
     * @throws ApiError
     */
    public getCustomFields(
        formData: any,
    ): CancelablePromise<ApiResponse_IEnumerable_CustomFieldDefinition_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/XeroActions/GetCustomFields',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_XeroPracticeInfo_ Success
     * @throws ApiError
     */
    public updateXeroMaster(
        formData: {
            xeroPracticeInfo?: XeroPracticeInfo;
        },
    ): CancelablePromise<ApiResponse_XeroPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/XeroActions/UpdateXeroMaster',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_CustomFieldDefinition_ Success
     * @throws ApiError
     */
    public createCustomField(
        formData: {
            customFields?: CustomFieldDefinition;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_CustomFieldDefinition_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/XeroActions/CreateCustomField',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_UnknownClientInfo_ Success
     * @throws ApiError
     */
    public createUnknownClient(
        formData: any,
    ): CancelablePromise<ApiResponse_UnknownClientInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/XeroActions/CreateUnknownClient',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
