import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

type Size = {
  minWidth: string;
  height: string;
  fontSize?: string;
};

type Sizes = {
  small: Size;
  medium: Size;
  large: Size;
  xl: Size;
};

const sizeDict: Sizes = {
  small: {
    minWidth: "54px",
    height: "26px",
  },
  medium: {
    minWidth: "66px",
    height: "30px",
  },
  large: {
    minWidth: "78px",
    height: "34px",
    fontSize: "15px",
  },
  xl: {
    minWidth: "90px",
    height: "38px",
    fontSize: "16px",
  },
};

interface Props {
  size?: keyof Sizes | Size;
  value: boolean;
  onChange: (val: boolean) => void;
  className?: string;
  hasChange?: boolean;
  disabled?: boolean;
  displayLabel?: Boolean;
  enableDisplay?: React.ReactNode;
  disableDisplay?: React.ReactNode;
  enableDisplayIcon?: React.ReactNode;
  disableDisplayIcon?: React.ReactNode;
  enabledColour?: string;
  disableColour?: string;
}

export const ATOSwitch = ({
  size = "medium",
  value,
  onChange,
  className,
  hasChange,
  disabled,
  displayLabel = true,
  enableDisplay = "ON",
  disableDisplay = "OFF",
  enabledColour = "rgb(96 196 20)",
  disableColour = "rgb(255 75 75)",
  enableDisplayIcon = (
    <div className="flex h-full w-full items-center justify-center bg-white pl-px">
      <FontAwesomeIcon style={{ color: enabledColour }} icon={faSvg.faCheck} />
    </div>
  ),
  disableDisplayIcon = (
    <div className="flex h-full w-full items-center justify-center bg-white">
      <FontAwesomeIcon style={{ color: disableColour }} icon={faSvg.faTimes} />
    </div>
  ),
}: Props) => {
  const selectedSize = typeof size === "string" ? sizeDict[size] : size;

  return (
    <div
      onClick={() => onChange?.(!value)}
      className={`flex cursor-pointer select-none items-center gap-1 ${className ?? ""} ${disabled ? "pointer-events-none grayscale" : ""}`}
    >
      <input
        type="checkbox"
        className="hidden"
        checked={value}
        onChange={(evt) => evt.preventDefault()}
        disabled={disabled}
      />
      <div
        style={{
          backgroundColor: value ? enabledColour : disableColour,
          borderRadius: `calc(${selectedSize.height} / 2)`,
          fontSize: "14px",
          ...selectedSize,
        }}
        className={`group flex h-6 w-14 items-center justify-start text-pretty rounded-xl p-1 text-sm transition-all ${hasChange ? "shadow-lg shadow-yellow-400" : ""}`}
      >
        <div className={`${value ? "w-full" : "w-0"} overflow-clip pt-px transition-all`}>
          {displayLabel ? enableDisplay : ""}
        </div>
        <div className="aspect-square h-full">
          <div className="h-full w-full overflow-clip rounded-full">
            {(value ? enableDisplayIcon : disableDisplayIcon) ?? <div className="h-full w-full bg-black" />}
          </div>
        </div>
        <div className={`${value ? "w-0" : "w-full"} overflow-clip pt-px text-right transition-all`}>
          {displayLabel ? disableDisplay : ""}
        </div>
      </div>
    </div>
  );
};
