/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_DevTimeStartupInfo_ } from '../models/ApiResponse_DevTimeStartupInfo_';
import type { ApiResponse_IEnumerable_DevTimeDisplay_ } from '../models/ApiResponse_IEnumerable_DevTimeDisplay_';
import type { ApiResponse1 } from '../models/ApiResponse1';
import type { DevTimeDisplay } from '../models/DevTimeDisplay';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DevTimeTool {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_DevTimeStartupInfo_ Success
     * @throws ApiError
     */
    public getStartupInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_DevTimeStartupInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/DevTimeTool/GetStartupInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_DevTimeDisplay_ Success
     * @throws ApiError
     */
    public getDevTimes(
        formData: {
            startDate?: string;
            endDate?: string;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_DevTimeDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/DevTimeTool/GetDevTimes',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse1 Success
     * @throws ApiError
     */
    public addDevTime(
        formData: {
            devTime?: DevTimeDisplay;
        },
    ): CancelablePromise<ApiResponse1> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/DevTimeTool/AddDevTime',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
