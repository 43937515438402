import { useState } from "react";
import nimbusLogo from "../Nimbus/nimbus.png";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useParams } from "react-router-dom";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ATOValueHeader } from "../../../../Components/ATOValueHeader";
import { ATOTextBox } from "../../../../Components/ATOTextBox";

export const NimbusDetail = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const amClient = useAMClient(config.practiceConfigUrl, user, custId);

  const [modal, setModal] = useState<React.ReactNode | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [isCollapsed, setCollapsed] = useState(false);
  const connectionConfigurationButton = isCollapsed ? "Open General Configuration" : "Close General Configuration";
  const toggleServerSettingCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="flex justify-center">
        <img src={nimbusLogo} className="mt-2 h-16 flex-shrink-0" />
      </div>
      <div className="flex justify-center">
        <ATOButton buttonType={ButtonType.Primary} className="ml-2" onClick={toggleServerSettingCollapse}>
          {connectionConfigurationButton}
        </ATOButton>
      </div>
      {!isCollapsed && (
        <div className="flex w-full items-center justify-center ">
          <div className="grid w-4/5 grid-cols-[2fr_3fr]  gap-4 pt-4">
            <ATOValueHeader
              className="mt-1 flex-shrink-0"
              // hasChange={pendingChanges.hasChange("ATOmateURL")}
            >
              ATOmate URL:
            </ATOValueHeader>
            <ATOTextBox
              className="w-full"
              //hasChange={pendingChanges.hasChange("ATOmateURL")}
              disallowZeroLength
              value={""}
              onChange={(val) => {}}
            />

            <ATOValueHeader
              className="mt-1 flex-shrink-0"
              // hasChange={pendingChanges.hasChange("ATOmateURL")}
            >
              ATOmate Username:
            </ATOValueHeader>
            <ATOTextBox
              className="w-full"
              //hasChange={pendingChanges.hasChange("ATOmateURL")}
              disallowZeroLength
              value={""}
              onChange={(val) => {}}
            />

            <ATOValueHeader
              className="mt-1 flex-shrink-0"
              // hasChange={pendingChanges.hasChange("ATOmateURL")}
            >
              ATOmate Password:
            </ATOValueHeader>
            <ATOTextBox
              className="w-full"
              //hasChange={pendingChanges.hasChange("ATOmateURL")}
              disallowZeroLength
              value={""}
              onChange={(val) => {}}
            />

            <ATOValueHeader
              className="mt-1 flex-shrink-0"
              // hasChange={pendingChanges.hasChange("ATOmateURL")}
            >
              Nimbus Integration:
            </ATOValueHeader>
            <ATOTextBox
              className="w-full"
              //hasChange={pendingChanges.hasChange("ATOmateURL")}
              disallowZeroLength
              value={""}
              onChange={(val) => {}}
            />
          </div>
        </div>
      )}
    </div>
  );
};
