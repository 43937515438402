/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_ApsPracticeInfo_ } from '../models/ApiResponse_ApsPracticeInfo_';
import type { ApiResponse_string_ } from '../models/ApiResponse_string_';
import type { ApsPracticeInfo } from '../models/ApsPracticeInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApsActionHandler {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_ApsPracticeInfo_ Success
     * @throws ApiError
     */
    public updateApsMaster(
        formData: {
            apsPracticeInfo?: ApsPracticeInfo;
        },
    ): CancelablePromise<ApiResponse_ApsPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ApsActionHandler/UpdateApsMaster',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_string_ Success
     * @throws ApiError
     */
    public apsCloudAuthorization(
        formData: {
            endpoint?: string;
        },
    ): CancelablePromise<ApiResponse_string_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ApsActionHandler/ApsCloudAuthorization',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
