import React from "react";

export interface Config {
  auth0_domain: string;
  auth0_clientId: string;
  auth0_audience: string;
  signature_to: string;
  adminUrl: string;
  billingUrl: string;
  templateHelperUrl: string;
  practiceConfigUrl: string;
  dmsUrl: string;
  fyiDocUrl: string;
  iasToolUrl: string;
  eLearningUrl: string;
  mshUrl: string;
  suiteFilesUrl: string;
  sharePointUrl: string;
  dropboxUrl: string;
  googleUrl: string;
  howNowXUrl: string;
  xeroUrl: string;
  apsCloudUrl: string;
  cchUrl: string;
  kloudConnectUrl: string;
}

declare global {
  interface Window {
    __env__: Config;
  }
}

const emptyConfig: Config = {
  auth0_domain: "",
  auth0_clientId: "",
  auth0_audience: "",
  signature_to: "",
  adminUrl: "",
  billingUrl: "",
  templateHelperUrl: "",
  practiceConfigUrl: "",
  dmsUrl: "",
  fyiDocUrl: "",
  iasToolUrl: "",
  eLearningUrl: "",
  mshUrl: "",
  suiteFilesUrl: "",
  sharePointUrl: "",
  dropboxUrl: "",
  googleUrl: "",
  howNowXUrl: "",
  xeroUrl: "",
  apsCloudUrl: "",
  cchUrl: "",
  kloudConnectUrl: "",
};

type ConfigKeys = keyof Config;

const configKeys: ConfigKeys[] = Object.keys(emptyConfig) as ConfigKeys[];

let envConfig = {};

for (const key of configKeys) {
  const envValue = process.env["REACT_APP_" + key];

  if (!envValue && process.env.NODE_ENV === "development") {
    console.error("Could not find env var: REACT_APP_" + key);
  }

  envConfig = {
    ...envConfig,
    [key]: envValue,
  };
}

if (window.__env__) {
  envConfig = window.__env__;
}

export const envVarConfig = envConfig as Config;

export const AppConfig = React.createContext(envVarConfig);
