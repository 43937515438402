/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AccountingHandler } from './services/AccountingHandler';
import { ApsActionHandler } from './services/ApsActionHandler';
import { ApsHandler } from './services/ApsHandler';
import { BillingActions } from './services/BillingActions';
import { CchActions } from './services/CchActions';
import { CommunicationHandler } from './services/CommunicationHandler';
import { ContactDetails } from './services/ContactDetails';
import { CredentialManager } from './services/CredentialManager';
import { DevTimeTool } from './services/DevTimeTool';
import { DmsDocOptionsAndActions } from './services/DmsDocOptionsAndActions';
import { DropBoxEnrolmentActions } from './services/DropBoxEnrolmentActions';
import { ELearningHandler } from './services/ELearningHandler';
import { FyiDmsActions } from './services/FyiDmsActions';
import { GoogleEnrolmentActions } from './services/GoogleEnrolmentActions';
import { HandyDmsActions } from './services/HandyDmsActions';
import { HowNowDmsActions } from './services/HowNowDmsActions';
import { HowNowXActions } from './services/HowNowXActions';
import { IasDocumentUpload } from './services/IasDocumentUpload';
import { IasPaygNoticeActions } from './services/IasPaygNoticeActions';
import { InvoiceHistoryActions } from './services/InvoiceHistoryActions';
import { KloudConnectActions } from './services/KloudConnectActions';
import { MyobdmsActions } from './services/MyobdmsActions';
import { PlaceholderTool } from './services/PlaceholderTool';
import { PracticeConfig } from './services/PracticeConfig';
import { PracticeDetails } from './services/PracticeDetails';
import { PracticeUsers } from './services/PracticeUsers';
import { PracticeWorkflows } from './services/PracticeWorkflows';
import { SecureDeliveryHandler } from './services/SecureDeliveryHandler';
import { SharePointEnrolmentActions } from './services/SharePointEnrolmentActions';
import { SuiteEnrolmentActions } from './services/SuiteEnrolmentActions';
import { Template } from './services/Template';
import { VirtualCabinetDmsActions } from './services/VirtualCabinetDmsActions';
import { VirtualCabinetPortal } from './services/VirtualCabinetPortal';
import { WindowsFolderDmsActions } from './services/WindowsFolderDmsActions';
import { XeroActions } from './services/XeroActions';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AMClient {

    public readonly accountingHandler: AccountingHandler;
    public readonly apsActionHandler: ApsActionHandler;
    public readonly apsHandler: ApsHandler;
    public readonly billingActions: BillingActions;
    public readonly cchActions: CchActions;
    public readonly communicationHandler: CommunicationHandler;
    public readonly contactDetails: ContactDetails;
    public readonly credentialManager: CredentialManager;
    public readonly devTimeTool: DevTimeTool;
    public readonly dmsDocOptionsAndActions: DmsDocOptionsAndActions;
    public readonly dropBoxEnrolmentActions: DropBoxEnrolmentActions;
    public readonly eLearningHandler: ELearningHandler;
    public readonly fyiDmsActions: FyiDmsActions;
    public readonly googleEnrolmentActions: GoogleEnrolmentActions;
    public readonly handyDmsActions: HandyDmsActions;
    public readonly howNowDmsActions: HowNowDmsActions;
    public readonly howNowXActions: HowNowXActions;
    public readonly iasDocumentUpload: IasDocumentUpload;
    public readonly iasPaygNoticeActions: IasPaygNoticeActions;
    public readonly invoiceHistoryActions: InvoiceHistoryActions;
    public readonly kloudConnectActions: KloudConnectActions;
    public readonly myobdmsActions: MyobdmsActions;
    public readonly placeholderTool: PlaceholderTool;
    public readonly practiceConfig: PracticeConfig;
    public readonly practiceDetails: PracticeDetails;
    public readonly practiceUsers: PracticeUsers;
    public readonly practiceWorkflows: PracticeWorkflows;
    public readonly secureDeliveryHandler: SecureDeliveryHandler;
    public readonly sharePointEnrolmentActions: SharePointEnrolmentActions;
    public readonly suiteEnrolmentActions: SuiteEnrolmentActions;
    public readonly template: Template;
    public readonly virtualCabinetDmsActions: VirtualCabinetDmsActions;
    public readonly virtualCabinetPortal: VirtualCabinetPortal;
    public readonly windowsFolderDmsActions: WindowsFolderDmsActions;
    public readonly xeroActions: XeroActions;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:7210/api',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.accountingHandler = new AccountingHandler(this.request);
        this.apsActionHandler = new ApsActionHandler(this.request);
        this.apsHandler = new ApsHandler(this.request);
        this.billingActions = new BillingActions(this.request);
        this.cchActions = new CchActions(this.request);
        this.communicationHandler = new CommunicationHandler(this.request);
        this.contactDetails = new ContactDetails(this.request);
        this.credentialManager = new CredentialManager(this.request);
        this.devTimeTool = new DevTimeTool(this.request);
        this.dmsDocOptionsAndActions = new DmsDocOptionsAndActions(this.request);
        this.dropBoxEnrolmentActions = new DropBoxEnrolmentActions(this.request);
        this.eLearningHandler = new ELearningHandler(this.request);
        this.fyiDmsActions = new FyiDmsActions(this.request);
        this.googleEnrolmentActions = new GoogleEnrolmentActions(this.request);
        this.handyDmsActions = new HandyDmsActions(this.request);
        this.howNowDmsActions = new HowNowDmsActions(this.request);
        this.howNowXActions = new HowNowXActions(this.request);
        this.iasDocumentUpload = new IasDocumentUpload(this.request);
        this.iasPaygNoticeActions = new IasPaygNoticeActions(this.request);
        this.invoiceHistoryActions = new InvoiceHistoryActions(this.request);
        this.kloudConnectActions = new KloudConnectActions(this.request);
        this.myobdmsActions = new MyobdmsActions(this.request);
        this.placeholderTool = new PlaceholderTool(this.request);
        this.practiceConfig = new PracticeConfig(this.request);
        this.practiceDetails = new PracticeDetails(this.request);
        this.practiceUsers = new PracticeUsers(this.request);
        this.practiceWorkflows = new PracticeWorkflows(this.request);
        this.secureDeliveryHandler = new SecureDeliveryHandler(this.request);
        this.sharePointEnrolmentActions = new SharePointEnrolmentActions(this.request);
        this.suiteEnrolmentActions = new SuiteEnrolmentActions(this.request);
        this.template = new Template(this.request);
        this.virtualCabinetDmsActions = new VirtualCabinetDmsActions(this.request);
        this.virtualCabinetPortal = new VirtualCabinetPortal(this.request);
        this.windowsFolderDmsActions = new WindowsFolderDmsActions(this.request);
        this.xeroActions = new XeroActions(this.request);
    }
}

