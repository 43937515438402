import { useEffect, useState, useCallback } from "react";

import { useRecoilState } from "recoil";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";

import { Editor } from "@tinymce/tinymce-react";

import { ATOSuccessModal } from "../../../Components/ATOSuccessModal";
import { useAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { useParams } from "react-router-dom";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { SecureDeliveryConfigDisplay } from "../../../generated";
import { ATOLoading, ATOSpinner } from "../../../Components/ATOSpinner";
import { ATOCheckBox } from "../../../Components/ATOCheckBox";
import { ATOTextBox } from "../../../Components/ATOTextBox";
import { ATODropDown } from "../../../Components/ATODropDown";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { Tooltip } from "../../../Components/Tooltip";
import { useSinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";
import { secureDeliveryConfigAtom } from "./Atoms/SecureDeliveryConfigAtom";
import { ATOSwitch } from "../../../Components/ATOSwitch";

export const SecureDeliveryConfig = () => {
  const config = useConfig();
  const user = useATOAuth();
  const params = useParams();
  const custId = parseInt(params.id ?? "");
  const amClient = useAMClient(config.practiceConfigUrl, user, custId);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [modal, setModal] = useState<React.ReactNode | undefined>(undefined);
  const [unmodifiedSecureDeliveryConfig, setUnmodifiedSecureDeliveryConfig] = useRecoilState(secureDeliveryConfigAtom);
  const [isLoading, setIsLoading] = useState(false);

  const pendingChanges = useSinglePendingChanges<SecureDeliveryConfigDisplay>();
  const hasChanges = pendingChanges.listChanges().length > 0;

  const beforeUnloadHandler = useCallback(
    (event: BeforeUnloadEvent) => {
      if (hasChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [hasChanges]
  );

  const secureDeliveryConfig = pendingChanges.applyChanges(unmodifiedSecureDeliveryConfig ?? {});
  const hasErrors = secureDeliveryConfig.senderName == "" || secureDeliveryConfig.deliveryInstructions == "";

  useEffect(() => {
    if (!isDataLoaded) {
      amClient?.secureDeliveryHandler
        .getSecureDeliveryConfigs({})
        .then((res) => {
          setUnmodifiedSecureDeliveryConfig(res.data);
          setIsDataLoaded(true);
        })
        .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />));
    }
  }, [amClient, isDataLoaded]);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    amClient?.secureDeliveryHandler
      .updateSecureDeliveryConfigs({ SecureDeliveryConfigDisplay: secureDeliveryConfig })
      .then((res) => {
        setUnmodifiedSecureDeliveryConfig(res.data);
        pendingChanges.removeAllChanges();
        setIsLoading(false);
        setModal(<ATOSuccessModal setModal={setModal} />);
      })
      .catch((ex) => {
        setIsLoading(false);
        setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />);
      });
  }, [amClient, secureDeliveryConfig]);

  if (amClient === undefined || isDataLoaded === false) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOSpinner />
        {modal}
      </div>
    );
  }

  const templateFormatOptions: Record<string, number> = {
    "Use Email Template": 0,
    "Use Email Template With Additional Instructions": 1,
  };

  const matchedOption = secureDeliveryConfig?.passwordOptions?.find(
    (o) => o.placeholder === secureDeliveryConfig?.passwordTemplate
  ) ?? { description: "Custom", placeholder: secureDeliveryConfig?.passwordTemplate };

  if (isLoading || user.isLoading) {
    return <ATOLoading />;
  }
  return (
    <div className="h-full w-full">
      <div className="flex h-full w-full flex-col gap-3 p-3">
        <div className="border-b-2 border-gray-500 pb-3 sm:col-span-4">
          <div className="flex h-min w-full">
            <div className="flex h-min w-full">
              <p className="flex pr-5 text-start">
                Do you want to deliver Tax File Number Registration documents to clients password protected?
              </p>
              <ATOSwitch
                hasChange={pendingChanges.hasChange("active")}
                value={secureDeliveryConfig.active ?? false}
                onChange={() => pendingChanges.setChange("active", !secureDeliveryConfig.active)}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-[1fr_2fr_1fr] gap-3">
          <div className="flex w-full flex-col gap-1">
            <div className="flex items-center gap-1 text-primary">
              <b>Sender Name</b>
              <Tooltip className="z-20 w-fit -translate-y-px cursor-help text-sm">
                <div
                  className="flex h-0 -translate-y-1/2
                                translate-x-[6px]
                                items-center justify-start text-white"
                >
                  <div className="-mr-[9px] h-4 w-4 flex-shrink-0 rotate-45 bg-black" />
                  <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">
                    The name that appears first in the text message
                  </p>
                </div>
              </Tooltip>
            </div>
            <ATOTextBox
              name="senderName"
              className={`w-full 
                            ${pendingChanges.hasChange("senderName") ? "shadow-yellow-400" : ""} 
                            ${secureDeliveryConfig.senderName == "" ? "shadow-red-400" : ""}
                        `}
              maxStringLength={50}
              disallowZeroLength
              value={secureDeliveryConfig.senderName ?? ""}
              onChange={(val) => {
                val !== secureDeliveryConfig.senderName && pendingChanges.setChange("senderName", val);
              }}
            />
          </div>

          <div className="flex w-full flex-col gap-1">
            <div className="flex items-center gap-1 text-primary">
              <b>Delivery Instructions</b>
              <Tooltip className="z-10 w-fit -translate-y-px cursor-help text-sm">
                <div
                  className="flex h-0 -translate-y-1/2
                                translate-x-[6px]
                                items-center justify-start text-white"
                >
                  <div className="-mr-[9px] h-4 w-4 flex-shrink-0 rotate-45 bg-black" />
                  <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">
                    Additional instructions added to the text message
                  </p>
                </div>
              </Tooltip>
            </div>
            <ATOTextBox
              name="deliveryInstructions"
              maxStringLength={100}
              disallowZeroLength
              className={`w-full 
                            ${pendingChanges.hasChange("deliveryInstructions") ? "shadow-yellow-400" : ""}
                            ${secureDeliveryConfig.deliveryInstructions == "" ? "shadow-red-400" : ""}
                        `}
              value={secureDeliveryConfig.deliveryInstructions ?? ""}
              onChange={(val) => {
                val !== secureDeliveryConfig.deliveryInstructions &&
                  pendingChanges.setChange("deliveryInstructions", val);
              }}
            />
          </div>
          {/* <div className="w-full flex flex-col gap-1 invisible">
                    <div className="flex items-center gap-1 text-primary">
                        <b>Password Template</b>
                        <Tooltip className="h-min w-fit cursor-help text-sm -translate-y-px">
                            <div className="flex items-center justify-start
                                text-white
                                h-0 translate-x-[6px] -translate-y-1/2">
                                <div className="flex-shrink-0 bg-black h-4 w-4 -mr-[9px] rotate-45" />
                                <p className="bg-black rounded z-10 px-2 text-base whitespace-nowrap">Used to generate password that are sent to clients</p>
                            </div>
                        </Tooltip>
                    </div>
                    <ATODropDown<SecureDeliveryPasswordOption>
                        className="h-8"
                        textboxClassName={pendingChanges.hasChange("-1", "passwordTemplate") ? "bg-yellow-100" : ""}
                        popupHeightRem={24}
                        value={matchedOption}
                        getData={async () => [...(secureDeliveryConfig?.passwordOptions ?? []), { description: "Custom", placeholder: secureDeliveryConfig?.passwordTemplate }]}
                        getDataKey={o => o?.description ?? "Custom"}
                        getDataDisplay={t => {
                            const exampleText = (t.description !== t.example ? t.example : t.description ?? t.example) ?? "";

                            const isHeader = t.description === "Header";

                            return <div className={`flex-grow-0 group grid grid-flow-dense grid-cols-[1fr_1fr] gap-2 select-none ${isHeader ? "bg-primary text-white text-left pl-2 text-lg" : "p-2"}`}
                                onClick={event => isHeader && event.stopPropagation()}
                            >
                                <div className={`${isHeader || !exampleText ? "col-span-2" : "text-left"}`}>{t.description}</div>
                                {
                                    exampleText &&
                                    <div className="min-w-full text-right italic whitespace-nowrap overflow-hidden text-ellipsis pr-1">
                                        {exampleText}
                                    </div>
                                }

                                {
                                    t.description !== undefined && !isHeader
                                    && <div className="col-span-2 text-sm text-left">
                                        <div className="border-t-0 border-black text-gray-600">
                                            {t.description}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        }
                        getDataName={t => t?.description ?? "Custom"}
                        onItemClick={t => {
                            if (t.description === "Custom") {
                                setModal(<SecureDeliveryCustom secureDeliveryConfig={secureDeliveryConfig} setModal={setModal} />);
                            }
                            else {
                                t.placeholder !== secureDeliveryConfig.passwordTemplate && pendingChanges.setChange("-1", "passwordTemplate", t.placeholder);
                            }

                        }}
                    />
                    {
                        matchedOption.description === "Custom" &&
                        <div className="w-full h-0 text-gray-400 text-xs text-right pr-6 pointer-events-none select-none">
                            <div className="w-full h-7 -translate-y-full">
                                {matchedOption.placeholder}
                            </div>
                        </div>
                    }
                </div> */}
          <div className="flex w-full flex-col gap-1">
            <b className="text-primary">Template Format</b>
            <ATODropDown<string>
              className={`h-8 ${pendingChanges.hasChange("templatePreference") ? "bg-yellow-100" : ""}`}
              getOptions={() => Object.keys(templateFormatOptions)}
              value={Object.keys(templateFormatOptions)[secureDeliveryConfig.templatePreference ?? 0]}
              getDisplay={(val) => val}
              onChange={(val) => {
                const newIndex = templateFormatOptions[val];
                if (newIndex !== secureDeliveryConfig.templatePreference) {
                  pendingChanges.setChange("templatePreference", newIndex);
                }
              }}
            />
          </div>
        </div>
        <div
          className={`flex h-full flex-col gap-3 ${pendingChanges.hasChange("templateInstructions") === true ? "[&>div]:border-yellow-100" : ""}`}
        >
          <div className={`${secureDeliveryConfig.templatePreference === 1 ? "h-full pb-6 [&>div]:!h-full" : "h-0"}`}>
            {secureDeliveryConfig.templatePreference === 1 && (
              <>
                <span className="flex items-center gap-1 text-primary">
                  <b>Template Instructions</b>
                  <Tooltip className="h-min w-fit -translate-y-px cursor-help text-sm">
                    <div
                      className="flex h-0 -translate-y-1/2
                                translate-x-[6px]
                                items-center justify-start text-white"
                    >
                      <div className="-mr-[9px] h-4 w-4 flex-shrink-0 rotate-45 bg-black" />
                      <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">
                        Placed after the main content of templates when Secure Delivery is used
                      </p>
                    </div>
                  </Tooltip>
                </span>
                <Editor
                  tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                  value={secureDeliveryConfig.templateInstructions ?? ""}
                  onEditorChange={(val) =>
                    val !== secureDeliveryConfig.templateInstructions &&
                    pendingChanges.setChange("templateInstructions", val)
                  }
                  init={{
                    resize: false,
                    promotion: false,
                    statusbar: false,
                    menubar: false,
                    toolbar:
                      "undo redo | bold italic underline strikethrough |fontfamily | fontsize | forecolor backcolor | styles | searchreplace | bullist numlist | outdent indent| alignleft aligncenter alignright alignjustify | link unlink | image table charmap emoticons hr | preview removeformat",
                    toolbar_mode: "sliding",
                    plugins:
                      "fullscreen image link media codesample charmap pagebreak anchor searchreplace table emoticons preview lists",
                    font_size_formats:
                      "8pt 9pt 10pt 11pt 12pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 21pt 22pt 23pt 24pt 25pt 26pt 27pt 28pt 29pt 30pt 31pt 32pt 33pt 34pt 35pt 36pt 48pt 60pt 72pt 96pt",
                    font_family_formats:
                      "Andale Mono=andale mono,monospace;Arial=arial,helvetica,sans-serif;Arial Black=arial black,sans-serif;Arial Narrow=arial narrow;AvenirNext LT=Avenir Next LT Pro;Book Antiqua=book antiqua,palatino,serif;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier,monospace;Calibri=calibri;Century Gothic=century gothic;Franklin Gothic Book=Franklin Gothic Book;Georgia=georgia,palatino,serif;Helvetica=helvetica,arial,sans-serif;Impact=impact,sans-serif;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco,monospace;Times New Roman=times new roman,times,serif;Trebuchet MS=trebuchet ms,geneva,sans-serif;Verdana=verdana,geneva,sans-serif;",
                    content_css: process.env.PUBLIC_URL + "/tinymce/custom.css",
                    style_formats: [
                      { title: "Paragraph", format: "p" },
                      { title: "Heading 1", format: "h1" },
                      { title: "Heading 2", format: "h2" },
                      { title: "Heading 3", format: "h3" },
                      { title: "Heading 4", format: "h4" },
                      { title: "Heading 5", format: "h5" },
                      { title: "Heading 6", format: "h6" },
                      { title: "Preformatted", format: "pre" },
                    ],
                    elementpath: false,
                    contextmenu: false,
                    forced_root_block: "p",
                    entity_encoding: "raw",
                    invalid_elements: "script,applet,iframe",
                    schema: "html5",
                    browser_spellcheck: true,
                    paste_as_text: true,
                    format_empty_lines: true,
                    keep_styles: true,
                  }}
                />
              </>
            )}
          </div>
          <div className="flex h-20 w-full flex-col justify-between gap-2">
            <div className="flex items-center gap-2">
              <p
                className={`${pendingChanges.hasChange("securePRNWithTFN") ? "border-b border-y-yellow-200 italic" : ""}`}
              >
                Include document with TFN & PRN?
              </p>
              <ATOSwitch
                hasChange={pendingChanges.hasChange("securePRNWithTFN")}
                value={secureDeliveryConfig.securePRNWithTFN ?? false}
                onChange={(val) => {
                  if (val === false) {
                    pendingChanges.setChange("overrideSecureDeliveryInQueue", false);
                    pendingChanges.setChange("securePRNWithTFN", val);
                  } else {
                    pendingChanges.setChange("securePRNWithTFN", val);
                  }
                }}
              />
            </div>
            {secureDeliveryConfig.securePRNWithTFN && (
              <div className="flex items-center gap-2">
                <p
                  className={`${pendingChanges.hasChange("overrideSecureDeliveryInQueue") ? "border-b border-y-yellow-200 italic" : ""}`}
                >
                  Allow Emailing Of TFN based PRN's
                </p>
                <ATOSwitch
                  hasChange={pendingChanges.hasChange("overrideSecureDeliveryInQueue")}
                  value={secureDeliveryConfig.overrideSecureDeliveryInQueue ?? false}
                  onChange={() =>
                    pendingChanges.setChange(
                      "overrideSecureDeliveryInQueue",
                      !secureDeliveryConfig.overrideSecureDeliveryInQueue
                    )
                  }
                />
                <Tooltip className="h-min w-fit -translate-y-px cursor-help text-sm text-primary">
                  <div
                    className="flex h-0 -translate-y-1/2
                                    translate-x-[6px]
                                    items-center justify-start text-white"
                  >
                    <div className="-mr-[9px] h-4 w-4 flex-shrink-0 rotate-45 bg-black" />
                    <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">
                      Allows documents containing TFN based Payment Reference Numbers to be emailed
                    </p>
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        <div className="col-span-2 flex w-full justify-start gap-2">
          <ATOButton buttonType={ButtonType.Confirm} onClick={handleSubmit} disabled={hasErrors}>
            Update
          </ATOButton>

          <ATOButton
            buttonType={ButtonType.Warning}
            onClick={() => pendingChanges.removeAllChanges()}
            disabled={pendingChanges.listChanges().length < 1}
          >
            Reset
          </ATOButton>

          {/* <ATOButton buttonType={ButtonType.Primary}>
                    <div className="flex gap-1 items-center">
                        Help
                        <FontAwesomeIcon icon={faSvg.faQuestionCircle} />
                    </div>
                </ATOButton> */}
        </div>
      </div>
      {modal}
    </div>
  );
};
