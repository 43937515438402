import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

import { useNavigate, useParams } from "react-router-dom";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../../App";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import {
  ApiResponseStatus,
  ATOmateUserType,
  PracticeUserDisplay,
  ToolDetail,
  ValidationError,
} from "../../../../generated";
import { useSinglePendingChanges } from "../../../../Hooks/SinglePendingChagesHook";
import { ATOScopeChecker } from "../../../../Components/ATOScopeChecker";
import { ControlGroup } from "../ControlGroup";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { ATOSwitch } from "../../../../Components/ATOSwitch";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ATOModal } from "../../../../Components/ATOModal";
import { DeleteUser } from "./DeleteUser";
import { ResetMfa } from "./ResetMfa";
import { ChangePrimarySuperuser } from "./ChangePrimarySuperuser";
import { ChangePrimaryContact } from "./ChangePrimaryContact";
import { ATOLoading } from "../../../../Components/ATOSpinner";
import { ATOValueHeader } from "../../../../Components/ATOValueHeader";

export const UserDetailNavigation = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const navigate = useNavigate();

  const user = useATOAuth();
  const amClient = useAMClient(config.adminUrl, user);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userDetail, setUserDetail] = useState<PracticeUserDisplay>();
  const [tools, setTools] = useState<ToolDetail[]>([]);
  const [errors, setErrors] = useState<Record<keyof PracticeUserDisplay, string>>();

  const pendingChanges = useSinglePendingChanges<PracticeUserDisplay>();

  const setModal = useSetRecoilState(AppModalAtom);
  const params = useParams();
  const id = parseInt(params.id ?? "");

  useEffect(() => {
    if (!user.isLoading) {
      setIsLoading(true);
      amClient.practiceUsers
        .getPracticeUserDetail({ custId: custId, userId: id })
        .then((resp) => {
          setIsLoading(true);
          setUserDetail(resp.data);
        })
        .catch((ex) => {
          setModal(
            <ATODefaultAPIErrorModal
              error={{ message: ex.message }}
              onButton={() => {
                setModal(undefined);
                navigate("../");
              }}
            />
          );
        })
        .finally(() => setIsLoading(false));

      amClient.practiceUsers
        .getPracticeTools({ custId })
        .then((resp) => {
          setTools(resp.data ?? []);
        })
        .finally(() => setIsLoading(false));
    }
  }, [user]);

  const actualUserDetail = pendingChanges.applyChanges(
    userDetail ?? {
      email: "",
      firstName: "",
      surname: "",
      position: "",
      userId: 0,
      userType: ATOmateUserType.User,
      tools: [],
    }
  );

  const userTypes: Record<string, string> = {
    "10": "Superuser",
    "11": "User",
    "12": "Primary Superuser",
  };

  const positions: Record<string, string> = {
    "": "None",
    Director: "Director",
    Partner: "Partner",
  };

  const handleToolsAssigned = (toolId: number) => {
    if (actualUserDetail?.tools?.includes(toolId!)) {
      const remainingTools = actualUserDetail?.tools.filter((x) => x !== toolId);
      pendingChanges.setChange("tools", [...remainingTools]);
    } else {
      pendingChanges.setChange("tools", [toolId, ...(actualUserDetail?.tools ?? [])]);
    }
  };

  const saveChanges = () => {
    setIsLoading(true);

    amClient.practiceUsers
      .savePracticeUserDetail({ custId: custId, pracitce: actualUserDetail })
      .catch((ex) => {
        if (ex.status == ApiResponseStatus.Warning) {
          const errors = ex.validationErrors as ValidationError[];
          let newErrorDict = {} as Record<keyof PracticeUserDisplay, string>;

          errors.forEach((err) => {
            if (err.error) {
              const memberName = err.memberName as string;
              const error = err.error as string;
              newErrorDict[memberName as keyof PracticeUserDisplay] = error;
            }
          });

          setErrors(newErrorDict);
        } else {
          setModal(
            <ATODefaultAPIErrorModal
              error={{ message: ex.message }}
              onButton={() => {
                setModal(undefined);
                navigate("../");
              }}
            />
          );
        }
      })
      .finally(() => setIsLoading(false));
  };

  const previewSignature = () => {
    setIsLoading(true);

    amClient.practiceUsers
      .getSignatures({ custId: custId, userId: id })
      .then((resp) => {
        setModal(
          <ATOModal
            key={1}
            children={<iframe className="aspect-video w-full" srcDoc={resp.data + ""}></iframe>}
            onClose={() => {
              setModal(undefined);
            }}
            title="Email Signature Preview"
            IsBackgroundTransparent={false}
            className="w-[800px]"
          ></ATOModal>
        );
      })
      .catch((ex) => {
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
            }}
          />
        );
      })
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOLoading />
      </div>
    );
  }

  return (
    <ATOScopeChecker className="h-full w-full" user={user} scope="practice:write" denyAction={"disable"}>
      <div className="grid h-full w-full flex-grow grid-cols-[3fr_1fr] flex-col px-[12%] pt-2">
        <div className={`col-span-4 flex w-full items-center justify-between border-b border-black py-2 `}>
          <div className="w-[50px]" />
          {<h1 className="text-center text-xl">Edit User</h1>}
          <div></div>
        </div>
        <div className="grid auto-rows-min grid-cols-[1fr_3fr] gap-2 p-2">
          <ControlGroup<PracticeUserDisplay>
            errors={errors}
            label="First Name"
            pendingChanges={pendingChanges}
            propName="firstName"
            intialValue={actualUserDetail.firstName}
            key={`firstName`}
            disallowZeroLength
          />
          <ControlGroup<PracticeUserDisplay>
            errors={errors}
            label="Last Name"
            pendingChanges={pendingChanges}
            propName="surname"
            intialValue={actualUserDetail.surname}
            key={`surname`}
            disallowZeroLength
          />
          <ControlGroup<PracticeUserDisplay>
            errors={errors}
            label="Email"
            pendingChanges={pendingChanges}
            propName="email"
            intialValue={actualUserDetail.email}
            key={`email`}
            disallowZeroLength
          />
          <ATOValueHeader
            className="mt-1 h-min flex-shrink-0 text-nowrap"
            hasChange={pendingChanges.hasChange("userType")}
          >
            User Type:
          </ATOValueHeader>
          {userDetail?.userType === ATOmateUserType.PrimarySuperuser ? (
            <div> Primary Superuser </div>
          ) : (
            <ATODropDown<ATOmateUserType>
              value={actualUserDetail.userType ?? ATOmateUserType.User}
              getDisplay={(r) => userTypes[r.toString()]}
              getOptions={() => [
                ATOmateUserType.Superuser,
                ATOmateUserType.User,
                ...(userDetail?.userType === ATOmateUserType.PrimarySuperuser
                  ? [ATOmateUserType.PrimarySuperuser]
                  : []),
              ]}
              onChange={(val) => pendingChanges.setChange("userType", val)}
            />
          )}

          <ATOValueHeader
            className="mt-1 h-min flex-shrink-0 text-nowrap"
            hasChange={pendingChanges.hasChange("position")}
          >
            Position:
          </ATOValueHeader>
          <ATODropDown<string>
            value={positions[actualUserDetail.position ?? ""]}
            getDisplay={(r) => r}
            getOptions={() => Object.values(positions)}
            onChange={(val) => pendingChanges.setChange("position", val)}
          />
          <ATOValueHeader
            className="mt-1 h-min flex-shrink-0 text-nowrap"
            hasChange={pendingChanges.hasChange("tools")}
          >
            Tools:
          </ATOValueHeader>
          <div className="rounded-lg border-2 border-gray-300 p-2">
            {tools.map((tool, i) => {
              return (
                <div className="grid grid-cols-[2fr_1fr] p-1 odd:bg-gray-200">
                  <div className="flex"> {tool.toolName} </div>
                  <ATOSwitch
                    onChange={() => {
                      handleToolsAssigned(tool.toolId!);
                    }}
                    value={actualUserDetail?.tools?.includes(tool.toolId!) === true}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-y-2 p-2">
          <ATOButton className="h-8 w-full " buttonType={ButtonType.Primary} onClick={() => navigate("../")}>
            <FontAwesomeIcon icon={faSvg.faArrowLeftLong} className={`mx-1 px-1 pt-1 text-lg text-white`} />
            Back
          </ATOButton>
          <ATOButton className="h-8 w-full " buttonType={ButtonType.Primary} onClick={saveChanges}>
            <FontAwesomeIcon icon={faSvg.faSave} className={`mx-1 px-1 pt-1 text-lg text-white`} />
            Save
          </ATOButton>
          <ATOButton className="h-8 w-full " buttonType={ButtonType.Primary} onClick={previewSignature}>
            <FontAwesomeIcon icon={faSvg.faEye} className={`mx-1 px-1 pt-1 text-lg text-white`} />
            Preview Signature
          </ATOButton>
          <a
            href={`mailto:${config.signature_to}?subject=Code-${btoa(JSON.stringify({ CustID: custId, UserID: id }))}`}
          >
            <ATOButton className="h-8 w-full " buttonType={ButtonType.Primary}>
              <FontAwesomeIcon icon={faSvg.faUpload} className={`mx-1 px-1 pt-1 text-lg text-white`} />
              Upload Email Signature
            </ATOButton>
          </a>
          <ATOButton
            className="h-8 w-full "
            buttonType={userDetail?.isPrimaryContact ? ButtonType.Default : ButtonType.Primary}
            onClick={() =>
              setModal(<ChangePrimaryContact custId={custId} reload={() => navigate("../")} userDetail={userDetail!} />)
            }
          >
            <FontAwesomeIcon icon={faSvg.faUserTie} className={`mx-1 px-1 pt-1 text-lg text-white`} />
            Make Primary Contact
          </ATOButton>
          <ATOButton
            className="h-8 w-full "
            buttonType={
              userDetail?.userType === ATOmateUserType.PrimarySuperuser ? ButtonType.Default : ButtonType.Primary
            }
            onClick={() =>
              setModal(
                <ChangePrimarySuperuser custId={custId} reload={() => navigate("../")} userDetail={userDetail!} />
              )
            }
          >
            <FontAwesomeIcon icon={faSvg.faUser} className={`mx-1 px-1 pt-1 text-lg text-white`} />
            Make Primary Superuser
          </ATOButton>
          <ATOButton
            className="h-8 w-full "
            buttonType={ButtonType.Primary}
            onClick={() => setModal(<ResetMfa custId={custId} userDetail={userDetail!} setErrorModal={setModal} />)}
          >
            <FontAwesomeIcon icon={faSvg.faMobileAlt} className={`mx-1 px-1 pt-1 text-lg text-white`} />
            Reset MFA
          </ATOButton>
          <ATOButton
            className="h-8 w-full "
            buttonType={ButtonType.Error}
            onClick={() =>
              setModal(<DeleteUser custId={custId} userDetail={userDetail!} backToList={() => navigate("../")} />)
            }
          >
            <FontAwesomeIcon icon={faSvg.faTrash} className={`mx-1 px-1 pt-1 text-lg text-white`} />
            Delete User
          </ATOButton>
        </div>
      </div>
    </ATOScopeChecker>
  );
};
