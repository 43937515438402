import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

export const ATOSuccessModal = ({ setModal }: { setModal: (val: React.ReactNode) => void }) => {
  useEffect(() => {
    const closeHandle = setTimeout(() => setModal(undefined), 1400);
    const fadeHandle = setTimeout(() => setAnimState("fade"), 700);

    return () => {
      clearTimeout(closeHandle);
      clearTimeout(fadeHandle);
    };
  }, []);

  const [animState, setAnimState] = useState<"jumpin" | "fade">("jumpin");

  return (
    <div
      className={`
        fixed left-0 
        top-0 z-40
        flex h-full w-full
        items-center justify-center overflow-clip 
        bg-gray-400 bg-opacity-30
        duration-700
        `}
    >
      <FontAwesomeIcon
        icon={faSvg.faCheckCircle}
        size="10x"
        className={`
                text-green-600
                ${animState === "jumpin" ? "animate-jump-in" : ""} 
                ${animState === "fade" ? "animate-jump-out" : ""}`}
      />
    </div>
  );
};
