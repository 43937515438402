/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * MYOB(1)
 * ReckonElite(4)
 * HandiSoft(5)
 * APSCloud(6)
 * MPM(7)
 * XPM(9)
 * CCH(11)
 * LodgeIT(12)
 * MPO(13)
 * KloudConnect(14)
 * AccessPM(15)
 */
export enum PMS {
    MYOB = 1,
    ReckonElite = 4,
    HandiSoft = 5,
    APSCloud = 6,
    MPM = 7,
    XPM = 9,
    CCH = 11,
    LodgeIT = 12,
    MPO = 13,
    KloudConnect = 14,
    AccessPM = 15,
}
