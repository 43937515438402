import { ATOMutliSelect } from "../../../Components/ATOMutliSelect";
import { AdvancedWorkflowUser, ClientGroupUserType } from "../../../generated";

export const DefaultWorkflowUsers = ({
  users,
  userType,
  clientGroupUsers,
  label,
  setGroupUsers,
}: {
  users: AdvancedWorkflowUser[];
  userType: ClientGroupUserType;
  clientGroupUsers?: Record<string, number[]> | null | undefined;
  label: string;
  setGroupUsers: (userType: ClientGroupUserType, userIds: number[]) => any;
}) => {
  let allUsers = clientGroupUsers?.[userType] ?? [];

  const getSelectedOptions = (userType: ClientGroupUserType) => {
    var selectedUserIds = allUsers ?? [];
    const selectableUsers = users.filter((user) => selectedUserIds.includes(user.userId!));

    return selectableUsers;
  };

  const getSelectableOptions = (search: string, userType: ClientGroupUserType) => {
    var selectedUserIds = allUsers ?? [];
    const selectableUsers = users.filter((user) => !selectedUserIds.includes(user.userId!));

    search = search.toLowerCase();
    return selectableUsers.filter(
      (user) =>
        user.firstName?.toLowerCase().includes(search) ||
        user.lastName?.toLowerCase().includes(search) ||
        user.email?.toLowerCase().includes(search) ||
        search === ""
    );
  };

  const selectOption = (item: AdvancedWorkflowUser) => {
    var selectedUser = allUsers;
    if (allUsers?.includes(item.userId!)) {
      selectedUser = allUsers.filter((u) => u !== item.userId);
    } else {
      selectedUser = [item.userId!, ...(allUsers ?? [])];
    }

    setGroupUsers(userType, selectedUser);
  };

  return (
    <div className="h-full w-full">
      <label className="flex items-center text-primary">
        <b>{label}</b>
      </label>
      <ATOMutliSelect<AdvancedWorkflowUser>
        getOptions={(search) => getSelectableOptions(search, userType)}
        selectedOptions={() => getSelectedOptions(userType)}
        onItemClick={(item) => selectOption(item)}
        getDataKey={(user) => `${user?.firstName} ${user?.lastName} `}
        getItemDisplay={(user) => {
          return (
            <>
              {user?.firstName}&nbsp;
              {user?.lastName}
              {user?.email !== undefined && <span> &lt;{user?.email}&gt;</span>}
            </>
          );
        }}
        isItemDisabled={(user) => {
          if (userType == ClientGroupUserType.Sender || userType == ClientGroupUserType.Postal) {
            return user.hasSignature === false;
          }

          return false;
        }}
        disabledItemMessage="(This user is missing an email signature.)"
      />
    </div>
  );
};
