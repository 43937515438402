import { useEffect, useState } from "react";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { useAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../App";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOCredential } from "../../../generated";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ATOTextBox } from "../../../Components/ATOTextBox";
import { PendingChanges, usePendingChanges } from "../../../Hooks/PendingChangesHook";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { ATOSpinner } from "../../../Components/ATOSpinner";
import { SinglePendingChanges, useSinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";

type ATOCredentialDisplay = ATOCredential & { isDeleted: boolean; isExisting: boolean };

export const ATOCredentials = () => {
  const config = useConfig();
  const user = useATOAuth();
  const params = useParams();
  const custId = parseInt(params.id ?? "");
  const amClient = useAMClient(config.mshUrl, user, custId);

  const [modal, setModal] = useRecoilState(AppModalAtom);
  const [isLoadingState, setIsLoadingState] = useState(true);
  const [apiItems, setApiItems] = useState<ATOCredentialDisplay[]>([]);
  const pendingChanges = usePendingChanges<ATOCredentialDisplay>();
  const items = [...apiItems.map((item, i) => pendingChanges.applyChanges(i.toString(), item))];
  const isLoading = isLoadingState;

  useEffect(() => {
    amClient.credentialManager
      .getCredentials({})
      .then((result) => {
        setApiItems(
          (result?.data ?? []).map((d) => ({
            ...d,
            isDeleted: false,
            isExisting: true,
          }))
        );
        setIsLoadingState(false);
      })
      .catch((ex) => {
        setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(null)} />);
        setIsLoadingState(false);
      });
  }, []);

  return (
    <>
      {isLoading && (
        <div className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-gray-600 bg-opacity-20">
          <ATOSpinner />
        </div>
      )}
      {modal && <div className="fixed bottom-0 left-0 right-0 top-0">{modal}</div>}
      <div className="grid h-full w-full grid-cols-1 grid-rows-[min-content_minmax(0,1fr)_min-content] overflow-clip">
        <div className="flex justify-between border-b px-4 pb-2 pt-2">
          <h3 className="text-2xl">ATO Connector Setup</h3>
          <ATOButton buttonType={ButtonType.Primary} onClick={() => window.open("https://am.ato.gov.au/")}>
            ATO Access Manager
          </ATOButton>
        </div>
        <div className="grid h-full grid-cols-[1fr_1fr_1fr_min-content] grid-rows-[min-content_min-content_min-content_minmax(0,1fr)] place-items-center gap-3 gap-y-0 overflow-clip">
          <div className="col-span-4 h-2" />
          <p className="w-full text-center">ABN</p>
          <p className="w-full text-center">TAN/RAN</p>
          <p className="w-full text-center">SSID</p>
          <div className="flex w-full justify-end">
            <ATOButton
              buttonType={ButtonType.Confirm}
              onClick={() => setApiItems([...apiItems, {} as ATOCredentialDisplay])}
            >
              Create
            </ATOButton>
            <div className="h-full overflow-y-scroll pl-3" />
          </div>
          <div className="col-span-4 h-2" />
          {items.length === 0 ? (
            <div className="col-span-4">No Items</div>
          ) : (
            <div
              style={{ gridTemplateColumns: "subgrid" }}
              className="col-span-4 my-3 grid h-full auto-rows-min gap-x-3 overflow-y-scroll border-t bg-white [&>div:nth-child(even)]:bg-gray-100"
            >
              {items.map((item, index) =>
                item.isDeleted ? (
                  <></>
                ) : (
                  <ATOCredentialItem
                    key={index.toString()}
                    cred={item}
                    id={index.toString()}
                    pendingChanges={pendingChanges}
                    onDelete={() => {
                      if (!item.isExisting) {
                        setApiItems([
                          ...apiItems.filter((item, i) => i < index),
                          { ...item, isDeleted: true },
                          ...apiItems.filter((item, i) => i > index),
                        ]);
                      } else {
                        pendingChanges.setChange(index.toString(), "isDeleted", true);
                      }
                    }}
                  />
                )
              )}
            </div>
          )}
        </div>
        <div className="flex w-full justify-end gap-4 border-t px-4 py-4">
          <ATOButton
            buttonType={ButtonType.Warning}
            disabled={pendingChanges.getChangeCount() < 1}
            onClick={() => pendingChanges.replaceAllChanges([])}
          >
            Reset
          </ATOButton>
          <ATOButton
            buttonType={ButtonType.Confirm}
            disabled={
              pendingChanges.getChangeCount() < 1 ||
              items
                .filter((i) => !i.isDeleted && !i.isExisting)
                .find((i) => i.abn?.length !== 11 || i.tan?.length !== 8) !== undefined
            }
            onClick={() => {
              setIsLoadingState(true);
              amClient.credentialManager
                .setCredentials({ credentials: items.filter((i) => !i.isDeleted) })
                .then((resp) => {
                  pendingChanges.removeAllChanges();
                  setApiItems(
                    (resp?.data ?? []).map((d) => ({
                      ...d,
                      isDeleted: false,
                      isExisting: true,
                    }))
                  );
                })
                .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />))
                .finally(() => setIsLoadingState(false));
            }}
          >
            Save
          </ATOButton>
        </div>
      </div>
    </>
  );
};

const ATOCredentialItem = ({
  cred,
  pendingChanges,
  id,
  onDelete,
}: {
  cred: ATOCredentialDisplay;
  pendingChanges: PendingChanges<ATOCredentialDisplay>;
  id: string;
  onDelete: () => void;
}) => {
  return (
    <div style={{ gridTemplateColumns: "subgrid" }} className="h-13 col-span-4 grid p-2">
      <ATOTextBox
        disallowZeroLength
        value={cred.abn ?? ""}
        maxStringLength={11}
        // getTextLengthIndicatorColor={(len, max) => {
        //   if (len < max) return "red";
        //   if (len === max) return "green";
        //   return "red";
        // }}
        className="bg-white"
        onChange={(val) => {
          pendingChanges.setChange(
            id,
            "abn",
            val === "" || (isFinite(val as any) && parseInt(val) < 100000000000) ? val : cred.abn
          );
          return false;
        }}
      />
      <ATOTextBox
        disallowZeroLength
        maxStringLength={8}
        value={pendingChanges.hasChange(id, "tan") ? cred.tan || "" : ""}
        placeholder={cred.tan?.padStart(8, "*")}
        // getTextLengthIndicatorColor={(len, max) => {
        //   if (cred.isExisting) return "green";
        //   if (len < max) return "red";
        //   if (len === max) return "green";
        //   return "red";
        // }}
        className="bg-white"
        onChange={(val) => {
          if (val !== cred.tan) {
            pendingChanges.setChanges(id, [
              { propName: "isExisting", val: false },
              {
                propName: "tan",
                val: val === "" || (isFinite(val as any) && parseInt(val) < 100000000) ? val : cred.tan,
              },
            ]);
          }
          return false;
        }}
      />
      <div className="flex w-full items-center justify-center gap-2">
        <p className="text-center text-lg">{cred.ssid ?? ""}</p>
        {cred.ssid && cred.ssid.trim().length > 0 && (
          <FontAwesomeIcon
            icon={faSvg.faCopy}
            size="sm"
            className="hover:cursor-pointer"
            onClick={() => {
              if (cred.ssid) {
                navigator.clipboard.writeText(cred.ssid);
              }
            }}
          />
        )}
      </div>

      <div className="flex gap-2">
        {/* <ATOButton buttonType={cred.isTested ? ButtonType.Confirm : ButtonType.Warning}>Test</ATOButton> */}
        <ATOButton buttonType={ButtonType.Error} onClick={() => onDelete()}>
          Delete
        </ATOButton>
      </div>
    </div>
  );
};
