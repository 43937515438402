import { CategoryModuleViewModel, ELearningModuleViewModel } from "../../../generated";
import { useSinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../App";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { ATOModal } from "../../../Components/ATOModal";
import { ATOTextBox } from "../../../Components/ATOTextBox";
import { EditELearningCategory } from "./EditELearningCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

export const EditELearningModule = (props: {
  category: CategoryModuleViewModel;
  index: number | null;
  setUpdatedCategory: (val: CategoryModuleViewModel) => void;
}) => {
  const setModal = useSetRecoilState(AppModalAtom);
  const pendingModuleChanges = useSinglePendingChanges<ELearningModuleViewModel>();
  const actualModule = pendingModuleChanges.applyChanges(
    props.index !== null ? props.category.modules![props.index] : ({} as ELearningModuleViewModel)
  );
  // const [actualModule, setActualModule] = useState<ELearningModuleViewModel>(
  //   props.index !== null
  //     ? pendingModuleChanges.applyChanges(props.category.modules?.[props.index] ?? {})
  //     : {} as ELearningModuleViewModel
  // );
  const onChangeModule = (val: string, prop: keyof ELearningModuleViewModel) => {
    pendingModuleChanges.setChange(prop, val);
    //  setActualModule(      props.index !== null
    //   ? pendingModuleChanges.applyChanges(props.category.modules?.[props.index] ?? {})
    //   : {} as ELearningModuleViewModel);
  };
  const onCancelModule = () => {
    pendingModuleChanges.removeAllChanges();
    setModal(<EditELearningCategory category={props.category} setUpdatedCategory={props.setUpdatedCategory} />);
  };
  const onSaveModule = () => {
    pendingModuleChanges.removeAllChanges();
    const updatedModules = [...(props.category?.modules || [])];

    if (props.index === null) {
      updatedModules.push(actualModule);
    } else {
      updatedModules.splice(props.index, 1, actualModule);
    }
    const updatedCategory = {
      ...props.category,
      modules: updatedModules,
    };
    setModal(<EditELearningCategory category={updatedCategory} setUpdatedCategory={props.setUpdatedCategory} />);
  };

  return (
    <ATOModal
      className="w-[min(600px)]"
      onClose={() => onCancelModule()}
      removeCloseButton={true}
      title={props.category ? "Edit Module" : "Add Module"}
    >
      <div className=" bg-[#f7f5f5] p-2 text-center shadow-xl hover:shadow-[#6e6969]">
        <span className="text-xl">Name:</span>
        <ATOTextBox
          className="text-2xl font-extrabold text-blue-500"
          hasChange={pendingModuleChanges.hasChange("moduleName")}
          disallowZeroLength
          value={actualModule?.moduleName?.toUpperCase() ?? ""}
          onChange={(val) => onChangeModule(val, "moduleName")}
        />
        <span className="mt-2 text-xl">Weight:</span>
        <ATOTextBox
          className="text-2xl font-extrabold text-blue-500"
          hasChange={pendingModuleChanges.hasChange("moduleWeight")}
          disallowZeroLength
          value={actualModule?.moduleWeight?.toString() ?? ""}
          onChange={(val) => (val === "" || !isNaN(val as any)) && onChangeModule(val, "moduleWeight")}
        />
        <span className="mt-2 text-xl">Questions number:</span>
        <ATOTextBox
          className="text-2xl font-extrabold text-blue-500"
          hasChange={pendingModuleChanges.hasChange("questions")}
          disallowZeroLength
          value={actualModule?.questions?.toString() ?? ""}
          onChange={(val) => (val === "" || !isNaN(val as any)) && onChangeModule(val, "questions")}
        />
        <span className="mt-2 text-xl">Minutes for reading the module:</span>
        <ATOTextBox
          className="text-2xl font-extrabold text-blue-500"
          hasChange={pendingModuleChanges.hasChange("readingTimeInMinute")}
          disallowZeroLength
          value={actualModule?.readingTimeInMinute?.toString() ?? ""}
          onChange={(val) => (val === "" || !isNaN(val as any)) && onChangeModule(val, "readingTimeInMinute")}
        />
        <span className="mt-2 text-xl">Page Url:</span>
        <ATOTextBox
          className="text-2xl font-extrabold text-blue-500"
          hasChange={pendingModuleChanges.hasChange("readingTimeInMinute")}
          disallowZeroLength
          value={actualModule?.pageUrl?.toString() ?? ""}
          onChange={(val) => onChangeModule(val, "pageUrl")}
        />
      </div>
      <div className="col-span-2 flex h-10 justify-between bg-gray-200 p-1">
        <ATOButton buttonType={ButtonType.Error} onClick={() => onCancelModule()}>
          <FontAwesomeIcon icon={faSvg.faUndo} className={`mx-1 px-1 pt-1 text-lg text-white`} />
          Cancel
        </ATOButton>
        <ATOButton buttonType={ButtonType.Confirm} onClick={() => onSaveModule()}>
          <FontAwesomeIcon icon={faSvg.faSave} className={`mx-1 px-1 pt-1 text-lg text-white`} />
          Save
        </ATOButton>
      </div>
    </ATOModal>
  );
};
