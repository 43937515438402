import { ReactNode, useState } from "react";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../../App";
import { useATOAuth, useAMClient } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { ATOModal } from "../../../../Components/ATOModal";
import { ATOLoading } from "../../../../Components/ATOSpinner";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ApiResponseStatus, PracticeUserDisplay } from "../../../../generated";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";

export const ResetMfa = ({
  custId,
  userDetail,
  setErrorModal,
}: {
  custId: number;
  userDetail: PracticeUserDisplay;
  setErrorModal: (val: ReactNode) => void;
}) => {
  const config = useConfig();
  const user = useATOAuth();
  const amClient = useAMClient(config.adminUrl, user);
  const setModal = useSetRecoilState(AppModalAtom);

  const [isLoading, setIsLoading] = useState(false);

  const resetUserMfa = () => {
    setIsLoading(true);
    amClient.practiceUsers
      .resetMfa({ custId, userId: userDetail.userId })
      .then((resp) => {
        if (resp.status == ApiResponseStatus.Success) {
          setIsLoading(false);
          setModal(undefined);
        }
      })
      .catch((ex) =>
        setErrorModal(<ATODefaultAPIErrorModal title="Error" error={ex} onButton={() => setErrorModal(undefined)} />)
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <ATOModal
        onClose={(isClickOutside) => {
          !isClickOutside && !isLoading && setModal(undefined);
        }}
        title={"Confirm"}
      >
        <div className="flex h-72 w-72 items-center justify-center">
          <ATOLoading />
        </div>
      </ATOModal>
    );
  }

  return (
    <ATOModal
      onClose={(isClickOutside) => {
        !isClickOutside && setModal(undefined);
      }}
      title={"Confirm"}
    >
      <div className="flex flex-col">
        <div className="w-96 p-2">
          Please confirm that you want to reset MFA for
          <b>
            {" "}
            {userDetail.firstName + " " + userDetail.surname}({userDetail.email})
          </b>
        </div>

        <div className="col-span-2 flex h-10 justify-between bg-gray-200 p-1">
          <ATOButton buttonType={ButtonType.Error} onClick={() => setModal(undefined)}>
            Cancel
          </ATOButton>
          <ATOButton buttonType={ButtonType.Confirm} onClick={resetUserMfa}>
            Reset MFA
          </ATOButton>
        </div>
      </div>
    </ATOModal>
  );
};
