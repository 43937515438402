/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_IEnumerable_DMSDocDisplay_ } from '../models/ApiResponse_IEnumerable_DMSDocDisplay_';
import type { ApiResponse_IEnumerable_DMSDocOptionDisplay_ } from '../models/ApiResponse_IEnumerable_DMSDocOptionDisplay_';
import type { ApiResponse_IEnumerable_DMSDocTypeDisplay_ } from '../models/ApiResponse_IEnumerable_DMSDocTypeDisplay_';
import type { ApiResponse2 } from '../models/ApiResponse2';
import type { DMSDocOptionType } from '../models/DMSDocOptionType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DmsDocOptionsAndActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_DMSDocDisplay_ Success
     * @throws ApiError
     */
    public getDmsDocs(
        formData: any,
    ): CancelablePromise<ApiResponse_IEnumerable_DMSDocDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/DMSDocOptionsAndActions/GetDMSDocs',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse2 Success
     * @throws ApiError
     */
    public updateDmsDocs(
        formData: {
            dmsDocs?: Array<Record<string, string>>;
        },
    ): CancelablePromise<ApiResponse2> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/DMSDocOptionsAndActions/UpdateDMSDocs',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_DMSDocOptionDisplay_ Success
     * @throws ApiError
     */
    public getDmsDocOptions(
        formData: {
            optionType?: DMSDocOptionType;
        },
    ): CancelablePromise<ApiResponse_IEnumerable_DMSDocOptionDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/DMSDocOptionsAndActions/GetDMSDocOptions',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_DMSDocTypeDisplay_ Success
     * @throws ApiError
     */
    public getDmsDocTypes(
        formData: any,
    ): CancelablePromise<ApiResponse_IEnumerable_DMSDocTypeDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/DMSDocOptionsAndActions/GetDMSDocTypes',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
