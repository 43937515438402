import { Auth0ContextInterface, User, useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { AMClient } from "../generated";
import { atom, useRecoilState } from "recoil";

export const useATOAuth = () => {
  const refreshWithinSeconds = 60;

  const auth0 = useAuth0();
  const [user, setUser] = useRecoilState<ATOAuthUser>(userAtom);

  useEffect(() => {
    if ((user?.exp ?? 0) - Date.now() / 1000 < refreshWithinSeconds) {
      auth0
        .getAccessTokenSilently()
        .then((token) => jwtDecode<RawATOUser>(token))
        .then((user) =>
          setUser({
            isLoading: false,
            getAccessToken: auth0.getAccessTokenSilently,
            scopes: user?.scope?.split(" ") ?? [],
            auth0Context: auth0,
            ...user,
          })
        )
        .catch((ex) =>
          setUser({
            isLoading: false,
            getAccessToken: auth0.getAccessTokenSilently,
            scopes: user?.scope?.split(" ") ?? [],
            auth0Context: {
              error: ex as Error,
              ...auth0,
            },
          })
        );
    }
  }, []);

  return (
    user ??
    ({
      isLoading: true,
      auth0Context: auth0,
    } as ATOAuthUser)
  );
};

interface RawATOUser {
  scope?: string;
  exp?: number;
  UserID?: number;
  CustID?: string;
}

export type ATOAuthUser = {
  scopes?: string[];
  getAccessToken: () => Promise<string>;
  isLoading: boolean;
  auth0Context: Auth0ContextInterface<User>;
} & RawATOUser;

const userAtom = atom<ATOAuthUser>({
  key: "ATOAuthUser",
  default: undefined,
});

export const useAMClient = (url: string, user: ATOAuthUser, custId?: number) => buildAMClient(url, user, custId);

export const buildAMClient = (url: string, user: ATOAuthUser, custId?: number) =>
  new AMClient({
    BASE: url,
    VERSION: "2",
    TOKEN: () => user.getAccessToken(),
    HEADERS: custId === undefined ? undefined : { "x-custid": custId?.toString() ?? "" },
  });
