import { ReactNode, useState } from "react";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../../App";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { ATOModal } from "../../../../Components/ATOModal";
import { ATOLoading } from "../../../../Components/ATOSpinner";
import { CustomerContactDisplay, CustomerContactRole, ApiResponseStatus, ValidationError } from "../../../../generated";
import { useATOAuth, useAMClient } from "../../../../Hooks/ATOAuthHook";
import { useSinglePendingChanges } from "../../../../Hooks/SinglePendingChagesHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { ControlGroup } from "../ControlGroup";
import { ATOValueHeader } from "../../../../Components/ATOValueHeader";

export const EditContact = ({
  custId,
  contact,
  reload,
  isSubscriptionOwnerPresent,
}: {
  custId: number;
  contact?: CustomerContactDisplay;
  reload: () => void;
  isSubscriptionOwnerPresent: boolean;
}) => {
  const config = useConfig();
  const user = useATOAuth();
  const amClient = useAMClient(config.adminUrl, user);

  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState<Record<keyof CustomerContactDisplay, string>>();

  const setModal = useSetRecoilState(AppModalAtom);
  const pendingChanges = useSinglePendingChanges<CustomerContactDisplay>();

  const actualContact = pendingChanges.applyChanges(
    contact ?? { firstName: "", email: "", role: CustomerContactRole.IT, phone: "", surname: "", title: "" }
  );

  const getCustomerContactRoles = (role: CustomerContactRole | undefined) => {
    let customerContactRoles = [CustomerContactRole.IT];
    if (user.scope?.includes("billing:write")) {
      customerContactRoles.push(CustomerContactRole.Billing);
      if (!isSubscriptionOwnerPresent || role === CustomerContactRole.SubscriptionOwner) {
        customerContactRoles.push(CustomerContactRole.SubscriptionOwner);
      }
    }

    return customerContactRoles;
  };

  const saveChanges = () => {
    setIsLoading(true);
    amClient.contactDetails
      .saveCustomerContact({ custId, contact: actualContact })
      .then((resp) => {
        if (resp.status == ApiResponseStatus.Success) {
          setIsLoading(false);
          reload();
          setModal(undefined);
        }
      })
      .catch((ex) => {
        if (ex.status == ApiResponseStatus.Warning) {
          const errors = ex.validationErrors as ValidationError[];
          let newErrorDict = {} as Record<keyof CustomerContactDisplay, string>;

          errors.forEach((err) => {
            if (err.error) {
              const memberName = err.memberName as string;
              const error = err.error as string;
              newErrorDict[memberName as keyof CustomerContactDisplay] = error;
            }
          });

          setErrors(newErrorDict);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <ATOModal
        onClose={(isClickOutside) => {
          !isClickOutside && !isLoading && setModal(undefined);
        }}
        title={contact ? "Edit Contact" : "Add Contact"}
      >
        <div className="flex h-72 w-72 items-center justify-center">
          <ATOLoading />
        </div>
      </ATOModal>
    );
  }

  return (
    <ATOModal
      onClose={(isClickOutside) => {
        !isClickOutside && setModal(undefined);
      }}
      title={contact ? "Edit Contact" : "Add Contact"}
    >
      <div className="flex flex-col overflow-x-clip">
        <div className="grid w-96 auto-rows-min grid-cols-[min-content_1fr] gap-2 p-2">
          <ATOValueHeader className="mt-1 h-min flex-shrink-0 text-nowrap" hasChange={pendingChanges.hasChange("role")}>
            Role:
          </ATOValueHeader>
          <ATODropDown<CustomerContactRole>
            value={
              actualContact.role ??
              (isSubscriptionOwnerPresent ? CustomerContactRole.IT : CustomerContactRole.SubscriptionOwner)
            }
            getDisplay={(r) => CustomerContactRole[r]}
            getOptions={() => getCustomerContactRoles(contact?.role)}
            onChange={(val) => pendingChanges.setChange("role", val)}
          />
          <ControlGroup<CustomerContactDisplay>
            errors={errors}
            label="Title"
            pendingChanges={pendingChanges}
            propName="title"
            intialValue={actualContact.title}
            key={`title`}
            disallowZeroLength
          />
          <ControlGroup<CustomerContactDisplay>
            errors={errors}
            label="First Name"
            pendingChanges={pendingChanges}
            propName="firstName"
            intialValue={actualContact.firstName}
            key={`firstName`}
            disallowZeroLength
          />
          <ControlGroup<CustomerContactDisplay>
            errors={errors}
            label="Surname"
            pendingChanges={pendingChanges}
            propName="surname"
            intialValue={actualContact.surname}
            key={`surname`}
            disallowZeroLength
          />
          <ControlGroup<CustomerContactDisplay>
            errors={errors}
            label="Email"
            pendingChanges={pendingChanges}
            propName="email"
            intialValue={actualContact.email}
            key={`email`}
            disallowZeroLength
          />
          <ControlGroup<CustomerContactDisplay>
            errors={errors}
            label="Phone"
            pendingChanges={pendingChanges}
            propName="phone"
            intialValue={actualContact.phone}
            key={`phone`}
            disallowZeroLength
          />
        </div>
        <div className="col-span-2 flex h-10 justify-between bg-gray-200 p-1">
          <ATOButton buttonType={ButtonType.Error} onClick={() => setModal(undefined)}>
            Cancel
          </ATOButton>
          <ATOButton buttonType={ButtonType.Confirm} onClick={saveChanges}>
            Save
          </ATOButton>
        </div>
      </div>
    </ATOModal>
  );
};
