import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useEffect, useState } from "react";
import { ATOLoading, ATOSpinner } from "../../Components/ATOSpinner";
import { useConfig } from "../../Hooks/UseConfigHook";
import { ATOTextBox } from "../../Components/ATOTextBox";
import { ATODropDown } from "../../Components/ATODropDown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

import { ATOButton, ButtonType } from "../../Components/ATOButton";
import { ATOScopeChecker } from "../../Components/ATOScopeChecker";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { PracticeListingFilter } from "./PracticeListingFilter";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { PracticeListingFilterAtom } from "./PracticeListingAtoms";
import { PracticeListingRow } from "./PracticeListingRow";
import { Link } from "react-router-dom";
import { ATODefaultAPIErrorModal } from "../../Components/Modal/ATODefaultAPIErrorModal";
import { AppModalAtom } from "../../App";
import useDebounce from "../../Hooks/DebounceHook";
import { ItemPendingChanges, PendingChanges } from "../../Hooks/PendingChangesHook";
import { useATOAuth } from "../../Hooks/ATOAuthHook";
import { AppSearchConfig } from "../../Helpers/AppSearchApi";
import { useAppSearch } from "../../Hooks/AppSearchHook";
import { TypedObject } from "../../Helpers/TypedObject";
import { AppSearchColumnInfo } from "../../Components/AppSearch/Models/AppSearchColumnInfo";
import { Tooltip } from "../../Components/Tooltip";
import { PracticeCreationWizard } from "./Create/PracticeCreationWizard";

const PracticeListing = () => {
  const config = useConfig();

  const setModal = useSetRecoilState(AppModalAtom);

  const user = useATOAuth();

  const [query, setQuery] = useState("");

  const [isFiltersExpanded, setIsFiltersExpanded] = useState<boolean>(false);

  const practiceListingConfig: AppSearchConfig = {
    engineName: "practices",
    searchKey: user.getAccessToken,
    endpoint: config.adminUrl,
  };
  const appSearch = useAppSearch<PracticeListingDisplay>(practiceListingConfig, {
    dms_name: { size: 5, type: "value", display: "DMS" },
    pms_name: { size: 5, type: "value", display: "PMS" },
    is_active: { size: 2, type: "value", display: "Active" },
    is_billing_enabled: { size: 2, type: "value", display: "Billing" },
  });

  const columnInfo = [
    { name: "id", dataType: "text", key: "id", widthProperty: "70px" },
    { name: "Name", dataType: "text", key: "cust_name" },
    { name: "PMS", dataType: "text", key: "pms_name", widthProperty: "240px" },
    { name: "DMS", dataType: "text", key: "dms_name", widthProperty: "240px" },
    {
      name: "Status",
      dataType: (val) => {
        const isLive = val.is_billing_enabled === "true" && val.is_active === "true";
        const isMissingUser = false;
        const isMissingBillingInfo = false;

        return (
          <div className="flex w-full justify-around">
            <Tooltip
              className={`${isLive ? "invisible" : ""} `}
              isBefore
              icon={<FontAwesomeIcon icon={faSvg.faWarning} className="mb-px animate-pulse text-yellow-400" />}
            >
              <div className="flex h-0 -translate-x-[calc(100%+6px)] -translate-y-1/2 items-center justify-end text-white">
                <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Practice is not live</p>
                <div className="-ml-[9px] h-4 w-4 flex-shrink-0 rotate-45 bg-black" />
              </div>
            </Tooltip>
            <Tooltip
              className={`${isMissingUser ? "invisible" : ""} `}
              isBefore
              icon={<FontAwesomeIcon icon={faSvg.faStopCircle} className="animate-pulse text-red-400" />}
            >
              <div className="flex h-0 -translate-x-[calc(100%+6px)] -translate-y-1/2 items-center justify-end text-white">
                <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Missing required user/s</p>
                <div className="-ml-[9px] h-4 w-4 flex-shrink-0 rotate-45 bg-black" />
              </div>
            </Tooltip>
            <Tooltip
              className={`${isMissingBillingInfo ? "invisible" : ""} `}
              isBefore
              icon={<FontAwesomeIcon icon={faSvg.faFileInvoiceDollar} className="animate-pulse text-red-400" />}
            >
              <div className="flex h-0 -translate-x-[calc(100%+6px)] -translate-y-1/2 items-center justify-end text-white">
                <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Missing billing info</p>
                <div className="-ml-[9px] h-4 w-4 flex-shrink-0 rotate-45 bg-black" />
              </div>
            </Tooltip>
          </div>
        );
      },
      key: "id",
      widthProperty: "120px",
    },
    { name: "Active", dataType: "checkbox", key: "is_active", disabled: true, widthProperty: "40px" },
    { name: "Billing", dataType: "checkbox", key: "is_billing_enabled", disabled: true, widthProperty: "40px" },
    {
      name: "Actions",
      dataType: (val) => (
        <Link to={`/Practices/${val.id}/`} className="flex items-center justify-end gap-2 text-2xl text-primary">
          <FontAwesomeIcon icon={faSvg.faEdit} />
        </Link>
      ),
      key: "id",
      widthProperty: "40px",
    },
  ] as AppSearchColumnInfo<PracticeListingDisplay>[];

  if (user.isLoading) {
    return <ATOLoading />;
  }

  return (
    <div className="flex h-full justify-center">
      <div className="grid h-full w-full grid-cols-[min-content,1fr] grid-rows-[6rem,calc(100%-6rem)]">
        <header className="col-2 col-start-2 flex h-full w-full flex-col justify-between p-2">
          <div className="flex justify-between">
            <div className="w-10">
              <ATOScopeChecker scope="billing:write" user={user}>
                <ATOButton
                  buttonType={ButtonType.Error}
                  className="flex h-8 w-64 items-center gap-2"
                  onClick={() => setModal(<PracticeCreationWizard />)}
                >
                  <div className="flex h-full items-center justify-center gap-2">
                    <p>Bulk Send Invoices</p>
                    <FontAwesomeIcon icon={faSvg.faMailBulk} size="1x" />
                    <FontAwesomeIcon icon={faSvg.faWarning} size="1x" />
                  </div>
                </ATOButton>
              </ATOScopeChecker>
            </div>
            <p className="h-8 text-xl">Practices</p>
            <div className="flex w-10 justify-end">
              <ATOScopeChecker scope="billing:write" user={user}>
                <ATOButton
                  buttonType={ButtonType.Confirm}
                  className="flex h-8 items-center gap-2"
                  onClick={() => setModal(<PracticeCreationWizard />)}
                >
                  <div className="flex h-full items-center justify-center gap-2">
                    <p>Create</p>
                    <FontAwesomeIcon icon={faSvg.faPlusCircle} size="1x" />
                  </div>
                </ATOButton>
              </ATOScopeChecker>
            </div>
          </div>
          <div className="flex h-8 w-full gap-2">
            <ATOTextBox
              value={query}
              className="w-full border border-gray-300"
              onChange={setQuery}
              onDebounce={appSearch.query.set}
              hasClearButton
            />
            <ATODropDown<number>
              className="w-16 bg-white"
              getOptions={() => [10, 25, 50, 100]}
              value={appSearch.pageSize.value!}
              getDisplay={(val) => val}
              onChange={(val) => appSearch.pageSize.set(val ?? 25)}
            />
          </div>
        </header>
        <div className="row-span-2 row-start-1 h-full flex-grow-0 border-r border-black">
          {isFiltersExpanded ? (
            <>
              <div className="flex  w-full items-center justify-between border-b border-black bg-gray-200 px-1.5 text-center text-lg">
                <div className="w-2.5" />
                Filters
                <FontAwesomeIcon
                  icon={faSvg.faChevronLeft}
                  className="w-2.5 cursor-pointer text-xl"
                  onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                />
              </div>
              <div className="min-w-64 flex-col items-center overflow-y-auto">
                {TypedObject.keys(appSearch.facetRequests.value ?? {}).map((k) => (
                  <PracticeListingFilter key={k} listingKey={k} appSearch={appSearch} />
                ))}
              </div>
            </>
          ) : (
            <div className="h-full w-7 bg-gray-200">
              <p className="flex rotate-90 items-center gap-1 pl-1 text-lg">
                <FontAwesomeIcon
                  icon={faSvg.faChevronUp}
                  className="cursor-pointer"
                  onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                />
                Filters
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <div className="flex h-8 bg-black pr-[17px] text-white">
            <PracticeListingRow colInfo={columnInfo} isHeader />
          </div>
          <div className="h-full overflow-y-scroll [&>*:nth-child(even)]:bg-gray-200 [&>*:nth-child(odd)]:bg-white ">
            {appSearch.isLoading ? (
              <div className="h-full w-full">
                <ATOLoading />
              </div>
            ) : (
              appSearch.result.data.map((r) => <PracticeListingRow key={r.id} colInfo={columnInfo} data={r} />)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export interface PracticeListingDisplay {
  cust_name: string;
  dms_name: string;
  id: string;
  is_active: string;
  is_billing_enabled: string;
  pms_name: string;
}

export default PracticeListing;
