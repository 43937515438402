import { useState } from "react";
import { CategoryModuleViewModel, ELearningModuleViewModel } from "../../../generated";
import { useSinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";
import { useSetRecoilState } from "recoil";
import { AppModalAtom, BlockerAtom } from "../../../App";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { ATOModal } from "../../../Components/ATOModal";
import { ATOTextBox } from "../../../Components/ATOTextBox";
import { ATOCheckBox } from "../../../Components/ATOCheckBox";
import { EditELearningModule } from "./EditELearningModule";

export const EditELearningCategory = (props: {
  category: CategoryModuleViewModel | null;
  setUpdatedCategory: (val: CategoryModuleViewModel) => void;
}) => {
  const setModal = useSetRecoilState(AppModalAtom);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const pendingCategoryChanges = useSinglePendingChanges<CategoryModuleViewModel>();
  const actualCategory = pendingCategoryChanges.applyChanges(props.category ?? ({} as CategoryModuleViewModel));

  const onSelectModule = (midx: number) => {
    if (selectedId === midx) {
      setSelectedId(null);
    } else {
      setSelectedId(midx);
    }
  };
  const onEditModule = () =>
    setModal(
      <EditELearningModule category={actualCategory} index={selectedId} setUpdatedCategory={props.setUpdatedCategory} />
    );
  const onAddModule = () =>
    setModal(
      <EditELearningModule category={actualCategory} index={null} setUpdatedCategory={props.setUpdatedCategory} />
    );
  const onDeleteModule = () => {
    if (selectedId !== null) {
      const updatedModules = [...actualCategory.modules!];
      updatedModules.splice(selectedId, 1);
      pendingCategoryChanges.setChange("modules", updatedModules);
      setSelectedId(null);
    }
  };
  const onCancelCategory = () => {
    pendingCategoryChanges.removeAllChanges();
    setModal(undefined);
  };
  const onSaveCategory = () => {
    pendingCategoryChanges.removeAllChanges();
    setSelectedId(null);
    props.setUpdatedCategory(actualCategory);
    setModal(undefined);
  };
  return (
    <ATOModal
      className="w-[min(600px)]"
      onClose={() => onCancelCategory()}
      removeCloseButton={true}
      title={actualCategory ? "Edit Category" : "Add Category"}
    >
      <div className="bg-[#f7f5f5] p-2 text-center shadow-xl hover:shadow-[#6e6969]">
        <span className="text-xl">Name</span>
        <ATOTextBox
          className="text-2xl font-extrabold text-blue-500"
          hasChange={pendingCategoryChanges.hasChange("categoryName")}
          disallowZeroLength
          value={actualCategory.categoryName?.toUpperCase() ?? ""}
          onChange={(val) => pendingCategoryChanges.setChange("categoryName", val)}
        />
        <span className="mt-2 text-xl">Weight</span>
        <ATOTextBox
          className="text-2xl font-extrabold text-blue-500"
          hasChange={pendingCategoryChanges.hasChange("categoryWeight")}
          disallowZeroLength
          value={actualCategory.categoryWeight?.toString() ?? ""}
          onChange={(val) =>
            (val === "" || !isNaN(val as any)) && pendingCategoryChanges.setChange("categoryWeight", val)
          }
        />
        {actualCategory.modules?.length! > 0 &&
          (actualCategory.modules?.length! > 1 ? (
            <div className="grid grid-cols-2 gap-2 pt-8">
              {actualCategory.modules?.map((module, midx) => (
                <div key={midx} className="bg-white p-2 text-center shadow-xl hover:shadow-[#6e6969]">
                  <span className="text-base font-extrabold">
                    {module.moduleName && module.moduleName.toUpperCase()}-({module.moduleWeight})
                  </span>
                  <ATOCheckBox
                    className="float-start size-6"
                    key={midx}
                    value={midx === selectedId}
                    onChange={() => onSelectModule(midx)}
                  />
                  <div className="grid grid-cols-2 divide-x-2 divide-blue-600 pt-6 text-[12px] font-semibold">
                    <div className="pr-2">
                      <div>{module.readingTimeInMinute}</div>
                      <div>Minutes</div>
                    </div>
                    <div className="pl-2">
                      <div>{module.questions}</div>
                      <div>Questions</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex place-content-center pt-8">
              <div className="bg-white p-2 text-center shadow-xl hover:shadow-[#6e6969]">
                <span className="text-base font-extrabold">
                  {actualCategory.modules![0].moduleName!.toUpperCase()}-({actualCategory.modules![0].moduleWeight})
                </span>
                <ATOCheckBox
                  className="float-start size-6"
                  key={0}
                  value={0 === selectedId}
                  onChange={() => onSelectModule(0)}
                />
                <div className="grid grid-cols-2 divide-x-2 divide-blue-600 pt-6 text-[12px] font-semibold">
                  <div className="pr-2">
                    <div>{actualCategory.modules![0].readingTimeInMinute}</div>
                    <div>Minutes</div>
                  </div>
                  <div className="pl-2">
                    <div>{actualCategory.modules![0].questions}</div>
                    <div>Questions</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="mt-2 flex h-10 justify-between bg-gray-200 p-1">
        <ATOButton buttonType={ButtonType.Error} onClick={() => onCancelCategory()}>
          <FontAwesomeIcon icon={faSvg.faUndo} className={`mx-1 px-1 pt-1 text-lg text-white`} />
          Cancel
        </ATOButton>
        {selectedId === null ? (
          <ATOButton buttonType={ButtonType.Primary} onClick={() => onAddModule()}>
            <FontAwesomeIcon icon={faSvg.faAdd} className={`mx-1 px-1 pt-1 text-lg text-white`} />
            Add
          </ATOButton>
        ) : (
          <>
            <ATOButton buttonType={ButtonType.Primary} onClick={() => onEditModule()}>
              <FontAwesomeIcon icon={faSvg.faEdit} className={`mx-1 px-1 pt-1 text-lg text-white`} />
              Edit
            </ATOButton>
            <ATOButton buttonType={ButtonType.Primary} onClick={() => onDeleteModule()}>
              <FontAwesomeIcon icon={faSvg.faDeleteLeft} className={`mx-1 px-1 pt-1 text-lg text-white`} />
              Delete
            </ATOButton>
          </>
        )}
        <ATOButton buttonType={ButtonType.Confirm} onClick={() => onSaveCategory()}>
          <FontAwesomeIcon icon={faSvg.faSave} className={`mx-1 px-1 pt-1 text-lg text-white`} />
          Save
        </ATOButton>
      </div>
    </ATOModal>
  );
};
