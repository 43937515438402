import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import exp from "constants";
import { useEffect, useState } from "react";
import { DMSDocRow } from "./DMSDocRow";
import { DMSDocSetupColumnInfo } from "./DMSDocSetupColumnInfo";
import { IDMSDoc } from "./IDMSDoc";
import { useRecoilState } from "recoil";
import { DMSDocTypesAtom } from "./Atoms/DMSDocTypesAtom";
import { DMSDocOptionsAtom } from "./Atoms/DMSDocOptionsAtom";
import { DMSDocOptionDisplay, DMSDocOptionType, DMSDocTypeDisplay, DMSName } from "../../generated";
import { useConfig } from "../../Hooks/UseConfigHook";
import { PendingChanges, usePendingChanges } from "../../Hooks/PendingChangesHook";
import { ATODefaultAPIErrorModal } from "../Modal/ATODefaultAPIErrorModal";
import { ATOButton, ButtonType } from "../ATOButton";
import { Tooltip } from "../Tooltip";
import { useAMClient, useATOAuth } from "../../Hooks/ATOAuthHook";
import { useParams } from "react-router-dom";
type Headers = Record<string, string>;
type Prop<TDMSDoc extends IDMSDoc> = {
  dmsui: DMSName;
  columnInfo: DMSDocSetupColumnInfo<TDMSDoc>[];
  setModal: (elem: React.ReactNode) => void;
  isLoading: boolean;
  isActive: boolean;
  setIsLoading: (value: boolean) => void;
  getDefaultItem: (
    docOptions: { [key in DMSDocOptionType]: DMSDocOptionDisplay[] },
    existingDocs: TDMSDoc[]
  ) => TDMSDoc;
  hasOtherChanges?: boolean;
  isBulkEditable?: boolean;
  // pendingChangesKey?: string;
  preSave?: (saveDocs: () => void) => void;
  children?: (
    docTypes: DMSDocTypeDisplay[],
    docOptions: { [key in DMSDocOptionType]: DMSDocOptionDisplay[] },
    uneditedDocs: TDMSDoc[],
    docs: TDMSDoc[]
  ) => React.ReactNode;
  content?: (header: React.ReactNode, content: React.ReactNode, footer: React.ReactNode) => React.ReactNode;
  bulkEditForm?: (item: TDMSDoc, rowIndex: number, actualDocs: IDMSDoc[]) => React.ReactNode;
  pendingChanges: PendingChanges<TDMSDoc>;
};

export const DMSDocSetup = <TDMSDoc extends IDMSDoc>(props: Prop<TDMSDoc>) => {
  const headers: Headers = {
    DMSUI: props.dmsui.toString(),
  };
  const config = useConfig();
  const user = useATOAuth();
  const params = useParams();

  const custId = parseInt(params.id ?? "");
  const amClient = useAMClient(config.dmsUrl, user, custId);
  // const pendingChanges = usePendingChanges<TDMSDoc>();
  const pendingChanges = props.pendingChanges;
  const [hasRemovedRow, setHasRemovedRow] = useState<boolean>(false);
  const hasChanges = pendingChanges.getChangeCount() > 0 || props.hasOtherChanges || hasRemovedRow;
  const [docTypes, setDocTypes] = useRecoilState(DMSDocTypesAtom);
  const [docOptions, setDocOptions] = useRecoilState(DMSDocOptionsAtom);
  const [docs, setDocs] = useState<TDMSDoc[]>();
  const actualDocs = docs?.map((d, i) => pendingChanges.applyChanges(i.toString(), d) ?? d) ?? [];
  let requiredFieldsPopulated = true;
  for (let i = 0; i < actualDocs.length; i++) {
    if (!requiredFieldsPopulated) {
      break;
    }
    const row = actualDocs[i];
    for (const columnInfo of props.columnInfo.filter(
      (c) => c.isMandatory !== undefined && c.isMandatory(row, c, i) === true
    )) {
      const value = row[columnInfo.valueKey];
      if (!value || value === "") {
        requiredFieldsPopulated = false;
        break;
      }
    }
  }
  const isSaveable = hasChanges && requiredFieldsPopulated;
  useEffect(() => {
    props.setIsLoading(true);
    const promises = [
      amClient.dmsDocOptionsAndActions.getDmsDocTypes({}).then((resp) => {
        setDocTypes(resp.data!);
      }),
      amClient.dmsDocOptionsAndActions.getDmsDocOptions({ optionType: undefined }).then((resp) => {
        const docOptions = resp.data!.reduce(
          (prev, curr) => {
            prev[curr.docOptionType!] = [...(prev[curr.docOptionType!] ?? []), curr];
            return prev;
          },
          {} as { [key in DMSDocOptionType]: DMSDocOptionDisplay[] }
        );
        setDocOptions(docOptions);
        amClient.dmsDocOptionsAndActions.getDmsDocs({}).then((resp) => {
          let data = resp.data!;
          if ((resp.data?.length ?? 0) < 1) {
            data = [{ ...props.getDefaultItem(docOptions, actualDocs), DocID: "1" }];
          }
          setDocs(
            data.map((d) => {
              return { ...(d as TDMSDoc), DocID: (d["DocID"] ?? "1").toString() };
            })
          );
        });
      }),
    ];
    Promise.all(promises)
      .then(() => props.setIsLoading(false))
      .catch((err) =>
        props.setModal(<ATODefaultAPIErrorModal error={err} onButton={() => props.setModal(undefined)} />)
      );
  }, []);
  const missingDocTypes = docTypes?.filter((dt) => !actualDocs?.some((d) => d.DocID === dt.docID?.toString())) ?? [];
  const addButtonStyle = missingDocTypes.length > 0 ? ButtonType.Confirm : ButtonType.Default;
  const saveChangesStyle = isSaveable ? ButtonType.Primary : ButtonType.Default;
  const addRow = (item?: TDMSDoc) => {
    if (props.getDefaultItem === undefined) {
      return;
    }
    const newItem = {
      ...(item ?? props.getDefaultItem(docOptions, actualDocs)),
      isNew: "true",
      DocID: missingDocTypes[0].docID,
    };
    const existingChangeRows = pendingChanges.listChangeRows();
    const newChanges = actualColumnInfo
      .filter((c) => newItem[c.valueKey])
      .map((c) => {
        return { prop: c.valueKey, value: newItem[c.valueKey] as any };
      });
    pendingChanges.replaceAllChanges([
      ...existingChangeRows,
      { id: actualDocs.length.toString(), changes: newChanges },
    ]);
    if (missingDocTypes.length > 0) {
      setDocs([
        ...docs!.map((d) => {
          return { ...d, isNew: d["isNew"] !== undefined ? d["isNew"] : undefined };
        }),
        newItem,
      ]);
    }
  };
  const editRow = (item: TDMSDoc, rowIndex: number) => {
    if (props.bulkEditForm === undefined) {
      return;
    }
    props.setModal(
      props.bulkEditForm(
        item,
        rowIndex,
        actualDocs.filter((ed) => ed.DocID !== item.DocID)
      )
    );
  };
  const removeRow = (rowIndex: number) => {
    if (rowIndex > 0) {
      const existingChanges = pendingChanges.listChangeRows();
      pendingChanges.replaceAllChanges(
        existingChanges
          .filter((ec) => parseInt(ec.id) !== rowIndex)
          .map((ec) => {
            const intId = parseInt(ec.id);
            let newId = intId;
            if (intId >= rowIndex) {
              newId -= 1;
            }
            return { ...ec, id: newId.toString() };
          })
      );
      setDocs(docs?.filter((d, i) => i !== rowIndex));
      setHasRemovedRow(true);
    }
  };
  const saveChanges = () => {
    if (!isSaveable) {
      return;
    }
    const savingContinued = () => {
      props.setIsLoading(true);
      amClient.dmsDocOptionsAndActions
        .updateDmsDocs({ dmsDocs: actualDocs })
        .then(() => {
          setDocs(
            actualDocs.map((ad) => {
              delete ad["isNew"];
              return ad;
            })
          );
          pendingChanges.removeAllChanges();
          setHasRemovedRow(false);
        })
        .catch((err) =>
          props.setModal(<ATODefaultAPIErrorModal error={err} onButton={() => props.setModal(undefined)} />)
        )
        .finally(() => {
          props.setIsLoading(false);
        });
    };
    let saveFunc = savingContinued;
    if (props.preSave) {
      saveFunc = () => props.preSave?.(savingContinued);
    }
    saveFunc();
  };
  const defaultRenderer = (header: React.ReactNode, content: React.ReactNode, footer: React.ReactNode) => (
    <div className="flex h-full min-h-0 w-full flex-col overflow-clip">
      <div className="p-2 pr-6">{header}</div>
      <div className="flex h-full w-full flex-1 flex-col gap-3 overflow-x-hidden overflow-y-scroll p-2">
        {content}
        {/* {[DMSName.VirtualCabinet, DMSName.GoogleDrive].includes(props.dmsui) !== true && (
          <div className="h-[24rem] w-full flex-shrink-0" />
        )} */}
      </div>
      <div className="flex gap-4 p-2">{footer}</div>
    </div>
  );
  const contentRenderer = props.content ?? defaultRenderer;
  const actualColumnInfo: DMSDocSetupColumnInfo<TDMSDoc>[] = [
    { name: "Document Type", valueKey: "DocID", columnOptionType: "DocType", width: "1.5fr" },
    ...props.columnInfo,
    {
      name: "Actions",
      valueKey: "",
      getValue: (item, colInfo, rowIndex) => (
        <DMSDocRowActions
          {...{ colInfo, item, isBulkEditable: props.isBulkEditable, removeRow, addRow, editRow, rowIndex }}
        />
      ),
      columnOptionType: "Controls",
      width: props.isBulkEditable ? "160px" : "120px",
    },
  ];
  const header = (
    <DMSDocRow
      setModal={props.setModal}
      columnInfo={actualColumnInfo}
      rowIndex={-1}
      pendingChanges={props.pendingChanges}
    />
  );
  const content = (
    <>
      {!props.isLoading && actualDocs !== undefined && (
        <>
          {actualDocs.map((d, i) => (
            <DMSDocRow
              setModal={props.setModal}
              // pendingChangesKey={props.pendingChangesKey}
              pendingChanges={props.pendingChanges}
              columnInfo={actualColumnInfo}
              value={d}
              key={`${d.DocID}-${i}`}
              isDefault={d.DocID === "1"}
              existingDocs={actualDocs.filter((ed) => ed.DocID !== d.DocID)}
              rowIndex={i}
            />
          ))}
        </>
      )}
    </>
  );
  const footer = (
    <>
      <ATOButton className="shadow-md" buttonType={addButtonStyle} onClick={() => addRow()}>
        Add
      </ATOButton>
      <ATOButton
        className="shadow-md"
        buttonType={saveChangesStyle}
        onClick={() => saveChanges()}
        disabled={!isSaveable}
      >
        Save
      </ATOButton>
    </>
  );
  return (
    <div className="flex h-full w-full flex-grow-0 flex-col">
      {props.children?.(docTypes, docOptions, docs ?? [], actualDocs) ?? <></>}
      {props.isActive && contentRenderer(header, content, footer)}
    </div>
  );
};
export const DMSDocRowActions = <TDMSDoc extends IDMSDoc>(props: {
  colInfo: DMSDocSetupColumnInfo<TDMSDoc>;
  isBulkEditable?: boolean;
  item: TDMSDoc;
  rowIndex: number;
  removeRow: (rowIndex: number) => void;
  addRow: (item: TDMSDoc) => void;
  editRow: (item: TDMSDoc, rowIndex: number) => void;
}) => {
  return (
    <div className="flex h-full flex-row items-center justify-evenly gap-2 pl-2">
      {props.isBulkEditable && (
        <Tooltip
          isBefore
          icon={
            <ATOButton
              className="h-min w-min"
              buttonType={ButtonType.Confirm}
              onClick={() => props.editRow(props.item, props.rowIndex)}
            >
              <FontAwesomeIcon icon={faSvg.faEllipsisV} />
            </ATOButton>
          }
        >
          <div
            className="flex h-0 -translate-x-[calc(100%+6px)]
                      -translate-y-1/2
                      items-center justify-center text-white"
          >
            <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Edit all</p>
            <div className="-ml-[9px] h-4 w-4 rotate-45 bg-black" />
          </div>
        </Tooltip>
      )}
      <Tooltip
        isBefore
        icon={
          <ATOButton className="h-min w-min" buttonType={ButtonType.Confirm} onClick={() => props.addRow(props.item)}>
            <FontAwesomeIcon icon={faSvg.faCopy} />
          </ATOButton>
        }
      >
        <div
          className="flex h-0 -translate-x-[calc(100%+6px)]
                    -translate-y-1/2
                    items-center justify-center text-white"
        >
          <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Copy</p>
          <div className="-ml-[9px] h-4 w-4 rotate-45 bg-black" />
        </div>
      </Tooltip>
      {props.item.DocID !== "1" && (
        <Tooltip
          isBefore
          icon={
            <ATOButton
              className="h-min w-min"
              buttonType={ButtonType.Error}
              onClick={() => props.removeRow(props.rowIndex)}
            >
              <FontAwesomeIcon icon={faSvg.faTimes} />
            </ATOButton>
          }
        >
          <div
            className="flex h-0 -translate-x-[calc(100%+6px)]
                      -translate-y-1/2
                      items-center justify-center text-white"
          >
            <p className="z-10 whitespace-nowrap rounded bg-black px-2 text-base">Remove</p>
            <div className="-ml-[9px] h-4 w-4 rotate-45 bg-black" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
