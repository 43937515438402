import React, { CSSProperties, useEffect, useState } from "react";
import PropTypes from "prop-types";

interface Props {
  id?: string;
  name?: string;
  value?: boolean | string;
  onChange?: (val: boolean) => void;
  className?: string;
  enabled?: boolean;
  displayLabel?: Boolean;
}

export const ATOCheckBox = ({
  id,
  name,
  value,
  onChange,
  className,
  enabled
}: Props) => {
  if (typeof value === "string") {
    value = value === "true";
  }

  return (
    <input
      type="checkbox"
      id={id}
      name={name}
      checked={value}
      onChange={(e) => onChange?.(e.target.checked)}
      className={className}
      disabled={!(enabled ?? true)}
    />
  );
};