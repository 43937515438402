/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_CommunicationSettingsDisplay_ } from '../models/ApiResponse_CommunicationSettingsDisplay_';
import type { ApiResponse_IEnumerable_SendGridDomainRecordDisplay_ } from '../models/ApiResponse_IEnumerable_SendGridDomainRecordDisplay_';
import type { ApiResponse_SendGridDomainStatus_ } from '../models/ApiResponse_SendGridDomainStatus_';
import type { CommunicationSettingsDisplay } from '../models/CommunicationSettingsDisplay';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CommunicationHandler {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_CommunicationSettingsDisplay_ Success
     * @throws ApiError
     */
    public getCommunicationSettings(
        formData: any,
    ): CancelablePromise<ApiResponse_CommunicationSettingsDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/GetCommunicationSettings',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_IEnumerable_SendGridDomainRecordDisplay_ Success
     * @throws ApiError
     */
    public getDomainDetails(
        formData: any,
    ): CancelablePromise<ApiResponse_IEnumerable_SendGridDomainRecordDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/GetDomainDetails',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public createSendgridDomain(
        formData: {
            domainName?: string;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/CreateSendgridDomain',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_SendGridDomainStatus_ Success
     * @throws ApiError
     */
    public checkSendgridDomainOwnershipAndAvailability(
        formData: {
            domainName?: string;
        },
    ): CancelablePromise<ApiResponse_SendGridDomainStatus_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/CheckSendgridDomainOwnershipAndAvailability',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public verifyOwnership(
        formData: {
            domainName?: string;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/VerifyOwnership',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public updateCommunicationSettings(
        formData: {
            communicationSettings?: CommunicationSettingsDisplay;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/UpdateCommunicationSettings',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public sendDomainDetailsEmail(
        formData: {
            sendgridDomainRecordId?: number;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/SendDomainDetailsEmail',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public verifyDomainDetails(
        formData: {
            sendgridDomainRecordId?: number;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/VerifyDomainDetails',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public deleteDomainDetails(
        formData: {
            sendgridDomainRecordId?: number;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/DeleteDomainDetails',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public createDomainSetupAlert(
        formData: {
            domainName?: string;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/CreateDomainSetupAlert',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public createPracticeDomainDetails(
        formData: {
            domainName?: string;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CommunicationHandler/CreatePracticeDomainDetails',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
