import { useEffect, useState } from "react";
import { AppSearchConfig, SendAppSearchRequest } from "../Helpers/AppSearchApi";
import axios from "axios";
import { TypedObject } from "../Helpers/TypedObject";

export type BaseAppSearchModel<T> = {
  id?: string;
} & {
  [index in keyof T]: string;
};

interface AppSearchState<T> {
  value: T | undefined;
  set: (val: T) => void;
}

export type AppSearchFacetRequest<T> = {
  [index in keyof T]?: { size: number; type: "value"; display?: string };
};

export type AppSearchSort<T> = { [index in keyof T]: "asc" | "desc" };

export interface AppSearchProvider<T> {
  query: AppSearchState<string>;
  page: AppSearchState<number>;
  pageSize: AppSearchState<number>;
  filters: AppSearchFilterActions<T>;
  excludeFilters: AppSearchState<AppSearchFilters<T>>;
  sort: AppSearchState<AppSearchSort<T>[]>;
  facetRequests: AppSearchState<AppSearchFacetRequest<T>>;
  result: AppSearchResult<T>;
  error?: AppSearchState<string>;
  isLoading: boolean;
  refreshData: (abortSignal?: AbortSignal) => void;
}

export type AppSearchFilters<T> = { [index in keyof T]?: (string | number)[] };

export interface AppSearchFilterActions<T> {
  values: AppSearchFilters<T> | undefined;
  set: (value: AppSearchFilters<T> | undefined) => void;
  SetFilterValues: (key: keyof T, values: (string | number)[]) => void;
  AddFilterValue: (key: keyof T, value: string | number) => void;
  RemoveFilterValue: (key: keyof T, value: string | number) => void;
}

export type AppSearchFacets<T> = { [index in keyof T]?: AppSerachFacet[] };

export type AppSerachFacet = { value: string | number; count: number };

export interface AppSearchResult<T> {
  pageInfo: {
    totalPages: number;
    totalResults: number;
  };
  data: T[];
  facets: AppSearchFacets<T>;
}

export const useAppSearch = <T extends BaseAppSearchModel<T>>(
  config: AppSearchConfig,
  defaultFacetRequests: AppSearchFacetRequest<T> | undefined = undefined,
  defaultSort: AppSearchSort<T>[] | undefined = undefined,
  defaultFilters: AppSearchFilters<T> | undefined = undefined,
  defaultExludeFilters: AppSearchFilters<T> | undefined = undefined,
  defaultPageSize = 25,
  defaultQuery = ""
) => {
  const [query, setQuery] = useState<string>(defaultQuery);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const [facetRequests, setFacetRequests] = useState<AppSearchFacetRequest<T> | undefined>(defaultFacetRequests);

  const [excludeFilters, setExcludeFilters] = useState<AppSearchFilters<T> | undefined>(defaultExludeFilters);

  const [filters, setFilters] = useState<AppSearchFilters<T> | undefined>(defaultFilters);
  const filterActions: AppSearchFilterActions<T> = {
    AddFilterValue: (key, value) => {
      let existingFilterItems = filters?.[key] ?? [];

      if (!existingFilterItems.includes(value)) {
        existingFilterItems = [...existingFilterItems, value];
      }

      setFilters({ ...(filters ?? {}), [key]: existingFilterItems } as AppSearchFilters<T>);
    },
    RemoveFilterValue: (key, value) => {
      let existingFilter = filters?.[key];

      if (!existingFilter) {
        return;
      }

      existingFilter = [...existingFilter.filter((v) => v !== value)];

      const updatedFilters: AppSearchFilters<T> = { ...filters, [key]: existingFilter } as AppSearchFilters<T>;

      const newFilters = {} as AppSearchFilters<T>;

      Object.keys(updatedFilters).forEach((k) => {
        const keyItems = updatedFilters[k as keyof T];

        if ((keyItems?.length ?? 0) > 0) {
          newFilters[key] = keyItems;
        }
      });

      setFilters(newFilters);
    },
    SetFilterValues: (key, values) => {
      if (values?.length > 0) {
        setFilters({ ...filters, [key]: values } as AppSearchFilters<T>);
      } else {
        setFilters({ ...filters, [key]: undefined } as AppSearchFilters<T>);
      }
    },
    set: setFilters,
    values: filters,
  };

  const [sort, setSort] = useState<AppSearchSort<T>[] | undefined>(defaultSort);

  useEffect(() => {
    const controller = new AbortController();

    if (config && config.searchKey) {
      provider.refreshData(controller.signal);
    }

    return () => {
      controller.abort();
    };
  }, [query, page, pageSize, facetRequests, filters, sort, config.searchKey]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [results, setResults] = useState<AppSearchResult<T>>({
    data: [],
    facets: {} as AppSearchFacets<T>,
    pageInfo: { totalPages: 0, totalResults: 0 },
  });

  const [error, setError] = useState<string>();

  const provider: AppSearchProvider<T> = {
    query: { value: query, set: setQuery },
    page: { value: page, set: setPage },
    pageSize: { value: pageSize, set: setPageSize },
    facetRequests: { value: facetRequests, set: setFacetRequests },
    sort: { value: sort, set: setSort },
    error: { value: error, set: setError },
    filters: filterActions,
    excludeFilters: { value: excludeFilters, set: setExcludeFilters },
    result: results,
    isLoading: isLoading,
    refreshData: (abortSignal) => {
      setIsLoading(true);

      SendAppSearchRequest<T>(
        config,
        {
          query: query,
          page: { current: page, size: pageSize },
          facets: {
            ...TypedObject.map(facetRequests, (key) => {
              return { size: facetRequests![key]!.size, type: facetRequests![key]!.type };
            }),
          },
          filters: {
            any: filters,
            none: excludeFilters,
          },
          sort: sort,
        },
        abortSignal
      )
        .then((resp) => {
          let facets = {} as AppSearchFacets<T>;

          Object.keys(resp.data.facets ?? {}).forEach((f) => {
            const respFacet = resp.data.facets[f as keyof T][0];

            facets[f as keyof T] = respFacet.data;
          });

          const data = resp.data.results.map((r) => {
            const newResult = {} as T;

            Object.keys(r)
              .filter((r) => r !== "_meta")
              .forEach((key) => (newResult[key as keyof T] = r[key as keyof T].raw as any));

            return newResult;
          });

          const result = {
            pageInfo: {
              totalPages: resp.data.meta.page.total_pages,
              totalResults: resp.data.meta.page.total_results,
            },
            facets: facets,
            data: data,
          };

          if (result.pageInfo.totalPages < page) {
            setPage(Math.max(1, Math.min(result.pageInfo.totalPages, 100)));
          }

          setResults(result);
        })
        .catch((ex) => axios.isCancel(ex) || setError(ex))
        .finally(() => abortSignal?.aborted || setIsLoading(false));
    },
  };

  return provider;
};
