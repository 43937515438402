export const TypedObject = {
  keys: <T>(obj: T | undefined) => {
    return Object.keys(obj ?? {}).map((k) => k as keyof T);
  },
  filterKey: <T>(obj: T | undefined, key: keyof T) => {
    const newResult = {} as T;

    Object.keys(obj ?? {})
      .filter((k) => k !== key)
      .forEach((key) => (newResult[key as keyof T] = obj![key as keyof T]));

    return newResult;
  },
  map: <T, TResult>(obj: T | undefined, predicate: (key: keyof T) => any, filter?: (key: keyof T) => boolean) => {
    const newResult = {} as TResult;

    TypedObject.keys(obj ?? {})
      .filter((key) => filter?.(key) ?? true)
      .forEach((key) => (newResult[key] = predicate(key) as never));

    return newResult;
  },
};
