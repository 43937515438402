import { Route, Routes } from "react-router-dom";
import { TemplateListing } from "./TemplateListing";
import { TemplateDetailNavigation } from "./TemplateDetailNavigation";

export const Templates = () => {
  return (
    <Routes>
      <Route path="/" element={<TemplateListing />} />
      <Route path="/:id/*" element={<TemplateDetailNavigation />} />
    </Routes>
  );
};
