/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * None(0)
 * FYICollaborate(1)
 * VirtualCabinet(2)
 * MyProsperity(3)
 */
export enum PortalType {
    None = 0,
    FYICollaborate = 1,
    VirtualCabinet = 2,
    MyProsperity = 3,
}
