import "./styles/index.css";
import "@fontsource/roboto";
import React, { Suspense, useState } from "react";
import { BrowserRouter, useBlocker, useLocation } from "react-router-dom";
import history from "history/browser";
import { Config } from "./Models/Config";
import { AppState, Auth0Provider, useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useConfig } from "./Hooks/UseConfigHook";
import { ATOLoading } from "./Components/ATOSpinner";
import BawAdmin from "./BawAdmin/BawAdmin";
import Dashboard from "./Dashboard/Dashboard";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { ATOUnsavedChangesModal } from "./Components/Modal/ATOUnsavedChangesModal";

export const App = () => {
  const { user } = useAuth0();

  return (
    <div className="h-screen w-screen">
      {user?.bawadmin === true ? <BawAdmin /> : <Dashboard />}
      <AppModal />
    </div>
  );
};

const AppModal = () => {
  const [modal, setModal] = useRecoilState(AppModalAtom);

  const isBlocked = useRecoilValue(BlockerAtom);

  const blocker = useBlocker(isBlocked);

  if (blocker.state === "blocked" && !modal) {
    setModal(<ATOUnsavedChangesModal blocker={blocker} setModal={setModal} />);
  }

  return <>{modal && <div className="fixed bottom-0 left-0 right-0 top-0 z-50 h-full w-full">{modal}</div>}</>;
};

export const AppModalAtom = atom<React.ReactNode>({
  key: "AppModalAtom",
  default: undefined,
});

export const BlockerAtom = atom<boolean>({
  key: "BlockerAtom",
  default: false,
  effects: [
    (val) => {
      val.trigger = "set";
      let unblock = history.block(() => {});
      unblock();
      val.onSet((newVal) => {
        if (newVal === true) {
          unblock = history.block(() => {});
        } else {
          unblock();
        }
      });
    },
  ],
});
