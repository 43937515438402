import { useState } from "react";

export interface Change<T> {
  prop: keyof T;
  value: any;
}

export interface ItemPendingChanges<T> {
  id: string;
  changes: Change<T>[];
}

export interface PendingChanges<T> {
  getChangeCount: () => number;

  listChangeRows: () => ItemPendingChanges<T>[];

  getChangeRow: (id: string) => ItemPendingChanges<T> | undefined;
  getChange: (id: string, propName: keyof T) => Change<T> | undefined;

  hasChange: (id: string, propName: keyof T) => boolean;

  applyChanges: (id: string, data: T) => T;

  setChange: (id: string, propName: keyof T, val: any) => void;
  setChanges: (id: string, changes: { propName: keyof T; val: any }[]) => void;

  replaceAllChanges: (changes: ItemPendingChanges<T>[]) => void;

  removeAllChanges: () => void;
  removeChange: (id: string, propName: keyof T) => void;
}

export type PendingChangesState<T extends {}> = [
  ItemPendingChanges<T>[],
  React.Dispatch<React.SetStateAction<ItemPendingChanges<T>[]>>,
];

export const usePendingChangesWithState = <T extends {}>(state: PendingChangesState<T>) => {
  const [pendingChangesState, setPendingChangesState] = state;

  const helper: PendingChanges<T> = {
    getChangeCount: () => helper.listChangeRows().reduce((val, change) => (val += change.changes.length), 0),

    listChangeRows: () => pendingChangesState.filter((pc) => pc.changes?.length > 0),

    getChangeRow: (id: string) => pendingChangesState.find((pc) => pc.id === id),

    getChange: (id: string, propName: keyof T) => helper.getChangeRow(id)?.changes.find((c) => c.prop === propName),

    hasChange: (id: string, propName: keyof T) => helper.getChange(id, propName) !== undefined,

    applyChanges: (id: string, data: T) => {
      const changeRow = helper.getChangeRow(id);

      const newData = { ...(data as any) };

      changeRow?.changes.forEach((change) => {
        if (data) {
          newData[change.prop] = change.value;
        }
      });

      return newData as T;
    },

    setChange: (id: string, propName: keyof T, val: any) => {
      const existingChange = helper.getChangeRow(id);

      const newChange: ItemPendingChanges<T> = {
        id: id,
        changes: [
          ...(existingChange?.changes.filter((ec) => ec.prop !== propName) ?? []),
          { prop: propName, value: val },
        ],
      };

      setPendingChangesState([...pendingChangesState.filter((pc) => pc.id !== id), newChange]);
    },

    setChanges: (id: string, changes: { propName: keyof T; val: any }[]) => {
      const existingChange = helper.getChangeRow(id);

      const newChange: ItemPendingChanges<T> = {
        id: id,
        changes: [
          ...(existingChange?.changes.filter((ec) => changes.findIndex((c) => c.propName !== ec.prop) === -1) ?? []),
          ...changes.map((c) => {
            return {
              prop: c.propName,
              value: c.val,
            };
          }),
        ],
      };

      setPendingChangesState([...pendingChangesState.filter((pc) => pc.id !== id), newChange]);
    },

    removeAllChanges: () => {
      setPendingChangesState([]);
    },

    replaceAllChanges: (changes: ItemPendingChanges<T>[]) => {
      setPendingChangesState(changes);
    },

    removeChange: (id: string, propName: keyof T) => {
      const existingChange = helper.getChangeRow(id);

      const newChange: ItemPendingChanges<T> = {
        id: id,
        changes: [...(existingChange?.changes.filter((ec) => ec.prop !== propName) ?? [])],
      };

      const newPCState = pendingChangesState.filter((pc) => pc.id !== id);

      if (newChange.changes.length > 0) {
        newPCState.push(newChange);
      }

      setPendingChangesState(newPCState);
    },
  };

  return helper;
};

export const usePendingChanges = <T extends {}>() => {
  return usePendingChangesWithState<T>(useState<ItemPendingChanges<T>[]>([]));
};
