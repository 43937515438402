import { useState } from "react";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { ATODropDown } from "../../../Components/ATODropDown";
import { AMClient, PracticeConfigDetail } from "../../../generated";
import { ATOLoading } from "../../../Components/ATOSpinner";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../../App";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";

export const Security = ({
  practiceDetail,
  amClient,
}: {
  practiceDetail: PracticeConfigDetail;
  amClient: AMClient;
}) => {
  const [securityData, setSecurityData] = useState<PracticeConfigDetail>(practiceDetail);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setModal = useSetRecoilState(AppModalAtom);

  const handleUpdate = async () => {
    setIsLoading(true);
    amClient.practiceConfig
      .updatePracticeConfig({ detail: securityData })
      .catch((ex) => {
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
            }}
          />
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleSecurityChange = (newIsSsoEnabled: boolean) => {
    setSecurityData((prevData) => ({
      ...prevData,
      isSsoEnabled: newIsSsoEnabled,
    }));
  };

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOLoading />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col gap-3 p-3">
      <div className="flex h-full w-full flex-col gap-3 p-3">
        <label className="flex items-center text-primary">
          <b>Authentication Source</b>
        </label>
        <div className="flex w-full items-center gap-2">
          <ATODropDown<string>
            className="w-1/2"
            value={securityData?.isSsoEnabled ? "Office 365" : "Username & Password \\ Google"}
            getOptions={() => ["Office 365", "Username & Password \\ Google"]}
            getDisplay={(r) => r}
            onChange={(val) => handleSecurityChange(val === "Office 365")}
          />
        </div>
        <div className="col-span-2 flex w-full justify-start gap-2">
          <ATOButton buttonType={ButtonType.Confirm} onClick={handleUpdate}>
            Update
          </ATOButton>
        </div>
      </div>
    </div>
  );
};
