/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_MYOBMasterDisplay_ } from '../models/ApiResponse_MYOBMasterDisplay_';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MyobdmsActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_MYOBMasterDisplay_ Success
     * @throws ApiError
     */
    public getMyobPracticeInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_MYOBMasterDisplay_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/MYOBDMSActions/GetMYOBPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public updateMyobPracticeInfo(
        formData: {
            unknownClient?: string;
            fileName?: string;
            baseFolder?: string;
            isTrust?: boolean;
            trustAccountName?: string;
            userPmsReference?: string;
            familyGroups?: boolean;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/MYOBDMSActions/UpdateMYOBPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
