import React from "react";
import PropTypes from 'prop-types';

interface Props {
    className?: string,
    children: React.ReactNode | string
    buttonType?: ButtonType,
    disabled?: boolean,
    onClick?: () => void,
    type?: 'button' | 'submit' | 'reset'
}

export enum ButtonType {
    Confirm = "bg-green-600 text-white hover:bg-green-700",
    Warning = "bg-yellow-500 text-white hover:bg-yellow-600",
    Error = "bg-red-600 text-white hover:bg-red-700",
    Default = "bg-gray-400 text-white hover:bg-gray-500",
    Primary = "bg-primary text-white hover:bg-blue-800",
    Accent = "bg-white text-black hover:bg-blue-200",
    Dark = "bg-black text-white hover:bg-gray-700"
}

export const ATOButton = ({ className, children, buttonType, disabled, onClick, type }: Props) => {
    return (
        <button
            type={type ?? "button"}
            className={`${className} inline-flex justify-center items-center rounded-lg shadow-sm px-4 py-2 transition-colors ${buttonType ?? ButtonType.Default} ${disabled ? "pointer-events-none grayscale" : ""}`}
            onClick={onClick}
        >
            {children}
        </button>
    )
}