/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse_CchPracticeInfo_ } from '../models/ApiResponse_CchPracticeInfo_';
import type { CchPracticeInfo } from '../models/CchPracticeInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CchActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_CchPracticeInfo_ Success
     * @throws ApiError
     */
    public getCchPracticeInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_CchPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CchActions/GetCchPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_CchPracticeInfo_ Success
     * @throws ApiError
     */
    public updateCchMaster(
        formData: {
            cchPracticeInfo?: CchPracticeInfo;
        },
    ): CancelablePromise<ApiResponse_CchPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/CchActions/UpdateCchMaster',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
