import React, { useEffect, useRef, cloneElement } from "react";
import { User } from "@auth0/auth0-spa-js";
import { redirect } from "react-router-dom";
import { ATOAuthUser } from "../Hooks/ATOAuthHook";

type Props =
  | {
      children: JSX.Element;
      className?: string;
      user: ATOAuthUser;
      scope: string;
      denyAction?: "hide" | "disable" | ((children: JSX.Element) => JSX.Element);
      redirectTarget?: never;
    }
  | {
      children: JSX.Element;
      className?: string;
      user: ATOAuthUser;
      scope: string;
      denyAction: "redirect";
      redirectTarget: string;
    };

export const ATOScopeChecker = (props: Props) => {
  let { children, user, scope, denyAction } = props;
  if (!denyAction) {
    denyAction = "disable";
  }

  const isAllow = user.scope?.includes(scope) ?? false;

  if (isAllow) {
    return children;
  }

  if (denyAction === "hide") {
    return <></>;
  } else if (denyAction === "disable") {
    return (
      <div className={`cursor-not-allowed grayscale ${props.className ?? ""} pointer-events-none select-none`}>
        {children}
      </div>
    );
  } else if (denyAction === "redirect") {
    redirect(props.redirectTarget!);
  } else if (typeof denyAction !== typeof String) {
    return denyAction?.(children);
  }

  return <></>;
};
