import { ATOSpinner } from "../../../Components/ATOSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { UploadableFile } from "./IASReportTool";

export const FileUploadDetails = (props: { file: UploadableFile }) => {
  return (
    <div
      className={`flex h-16 w-full items-center justify-between whitespace-pre-wrap py-0 text-sm ${props.file.status == "failed" ? "text-red-500" : props.file.status == "success" ? "text-green-500" : "text-blue-500"}`}
    >
      <div className="flex w-[1fr] flex-col justify-start text-left">
        <p>{props.file.file.name}</p>
        {props.file.errors.map((error, idx) => (
          <span key={idx}>
            {error.code === "file-invalid-type"
              ? "File Type must be .CSV, .XLS, or .XLSX"
              : "An error has occurred, please try again and if the issue persists, please contact support"}
          </span>
        ))}
      </div>
      <div className="flex flex-col justify-end">
        {(() => {
          switch (props.file.status) {
            case "inprogress":
            case "pending":
              return <ATOSpinner className="h-6" />;
            case "failed":
              return <FontAwesomeIcon icon={faSvg.faTimes} className="fa-w-16 fa-4x h-6" />;
            case "success":
              return <FontAwesomeIcon icon={faSvg.faCheck} className="fa-w-16 fa-4x h-6" />;
            default:
              return <ATOSpinner className="h-6" />;
          }
        })()}
      </div>
    </div>
  );
};
