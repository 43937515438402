import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const Tooltip = (props: {
  className?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  isBefore?: boolean;
}) => {
  return (
    <div className={`group mt-px flex items-center justify-center ${props.className}`}>
      {props.isBefore && (
        <div className="relative">
          <div className="invisible absolute group-hover:visible">{props.children}</div>
        </div>
      )}
      {props.icon ?? <FontAwesomeIcon icon={faSvg.faInfoCircle} className="cursor-help" />}
      {!props.isBefore && (
        <div className="relative">
          <div className="invisible absolute group-hover:visible">{props.children}</div>
        </div>
      )}
    </div>
  );
};
