import { useCallback, useEffect, useState } from "react";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { atom, useRecoilState } from "recoil";
import {
  DMSDocOptionType,
  DMSName,
  KCPracticeInfo,
  KloudConnectPMSFieldsDisplay,
  UnknownClientOptions,
} from "../../../../generated";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOSuccessModal } from "../../../../Components/ATOSuccessModal";
import { CreateUnknownClientModal } from "./CreateUnknownClientModal";
import { DMSDocSetupColumnInfo } from "../../../../Components/DMSDoc/DMSDocSetupColumnInfo";
import { IDMSDoc } from "../../../../Components/DMSDoc/IDMSDoc";
import { ATOSpinner } from "../../../../Components/ATOSpinner";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { DMSDocSetup } from "../../../../Components/DMSDoc/DMSDocSetup";
import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { ATOSwitch } from "../../../../Components/ATOSwitch";
import KloudConnectLogo from "../KloudConnect/KloudConnect.png";
import { ItemPendingChanges, usePendingChangesWithState } from "../../../../Hooks/PendingChangesHook";

const kloudConnectAtom = atom<ItemPendingChanges<IDMSDoc>[]>({
  key: "kloudConnectAtom",
  default: [],
});

export const KloudConnect = ({ custId }: { custId: number }) => {
  const config = useConfig();
  const user = useATOAuth();
  const apiClient = useAMClient(config.kloudConnectUrl, user, custId);
  const [modal, setModal] = useState<React.ReactNode | undefined>(undefined);
  const [practiceInfo, setPracticeInfo] = useState<KCPracticeInfo>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [metadataOptions, setMetadataOptions] = useState<string[]>([]);
  const [siteOptions, setSiteOptions] = useState<string[]>([]);
  const [unknownClientOptions, setUnknownClientOptions] = useState<UnknownClientOptions>({});
  const pendingChanges = usePendingChangesWithState<IDMSDoc>(useRecoilState(kloudConnectAtom));

  const getPracticeInfo = () => {
    apiClient?.kloudConnectActions
      .getKloudConnectPracticeInfo({})
      .then((res) => {
        setPracticeInfo(res.data);
        return res.data;
      })
      .then((data) => {
        if (data?.isConnected) {
          if (data.siteName && data.siteName.trim()) {
            getMetadataOptions(data.siteName);
          }
          setSiteOptions(data?.siteNames ?? []);
          GetUserSelectionOptions();
        }
      })
      .then(() => {
        setIsDataLoaded(true);
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />))
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getPracticeInfo();
  }, []);

  const getMetadataOptions = (siteName: string) => {
    apiClient?.kloudConnectActions
      .getSiteMetadataOptions({ siteName })
      .then((res) => {
        setMetadataOptions(res?.data ?? []);
        setIsDataLoaded(true);
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const GetUserSelectionOptions = () => {
    apiClient?.kloudConnectActions
      .getUserSelectionOptions({})
      .then((res) => {
        setUnknownClientOptions({
          divisions: res?.data?.divisions || {},
          partners: res?.data?.partners || {},
        });
      })
      .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnchange = useCallback((val: KloudConnectPMSFieldsDisplay | string | number | boolean, name: string) => {
    setIsModified(true);
    pendingChanges.setChange("-1", name, val);
    setPracticeInfo((prevPracticeInfo) => ({
      ...prevPracticeInfo,
      [name]: val,
    }));
  }, []);

  const filterPMSField = (search?: string) => {
    const resp = practiceInfo?.kloudConnectPMSFieldsDisplay?.filter((f) => f.fieldType === search) ?? [];
    return resp;
  };

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    apiClient?.kloudConnectActions
      .updateKloudConnectPracticeInfo({ practiceInfo: practiceInfo })
      .then((res) => {
        setPracticeInfo(res.data);
        setIsLoading(false);
        setModal(<ATOSuccessModal setModal={setModal} />);
        setIsModified(false);
      })
      .catch((ex) => {
        setIsLoading(false);
        setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />);
      });
  }, [apiClient, practiceInfo]);

  const openCreateUnknownClientModal = () => {
    setModal(
      <CreateUnknownClientModal
        setModal={setModal}
        apiClient={apiClient.kloudConnectActions}
        options={unknownClientOptions}
      />
    );
  };

  const columnInfo: DMSDocSetupColumnInfo<IDMSDoc>[] = [
    {
      name: "Level 1",
      valueKey: "Level1ID",
      popupInitialHeaderText: "Level 1",
      isMandatory: () => true,
      allowCustom: () => true,
      columnOptionType: DMSDocOptionType.Client,
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Level 2",
      valueKey: "Level2ID",
      popupInitialHeaderText: "Level 2",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Primary Metadata",
      valueKey: "PrimaryMetadataValue",
      popupInitialHeaderText: "Primary",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
    {
      name: "Secondary Metadata",
      valueKey: "SecondaryMetadataValue",
      popupInitialHeaderText: "Secondary",
      isMandatory: () => false,
      allowCustom: () => true,
      columnOptionType: "all",
      width: "1fr",
      popupStyle: { width: "150%" },
    },
  ];

  function getMetadataName(value: string | null | undefined, defaultValue: string): string {
    return value && value.trim() ? value : defaultValue;
  }

  const updatedColumnInfo = columnInfo.map((col) => {
    if (col.valueKey === "PrimaryMetadataValue") {
      return { ...col, name: getMetadataName(practiceInfo?.primaryMetadataName, "Primary Metadata Value") };
    } else if (col.valueKey === "SecondaryMetadataValue") {
      return { ...col, name: getMetadataName(practiceInfo?.secondaryMetadataName, "Secondary Metadata Value") };
    }
    return col;
  });

  const renderer = (header: React.ReactNode, content: React.ReactNode, footer: React.ReactNode) => (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="p-2 pr-6">{header}</div>
      <div className="flex h-full w-full flex-1 flex-col gap-3 overflow-x-hidden overflow-y-scroll p-2">
        {content}
        <div className="h-[8rem] w-full flex-shrink-0" />
      </div>
      <div className="flex gap-4 p-2">{footer}</div>
    </div>
  );

  if (apiClient === undefined || isDataLoaded === false) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ATOSpinner />
        {modal}
      </div>
    );
  }

  let gridSize = 1;
  if (practiceInfo?.isPms && practiceInfo.isDms) {
    gridSize = 3;
  } else if (practiceInfo?.isPms && !practiceInfo.isDms) {
    gridSize = 1;
  } else if (!practiceInfo?.isPms && practiceInfo?.isDms) {
    gridSize = 2;
  }

  return (
    <>
      <div className="flex h-full w-full flex-col items-center overflow-clip">
        <div className="flex">
          <img src={KloudConnectLogo} className="h-24" />
        </div>
        {practiceInfo?.isConnected ? (
          <>
            {practiceInfo?.isPms && (
              <div className="flex w-full">
                <div
                  className="grid h-fit w-full"
                  style={{
                    gridTemplateColumns: `repeat(${gridSize - (practiceInfo.isDms ? 1 : 0)}, 1fr)`,
                  }}
                >
                  {!practiceInfo.isDms && (
                    <div className="flex h-min w-full flex-col items-center justify-center py-2">
                      <div className="flex items-center font-semibold text-primary">Family Groups</div>
                      <ATOSwitch
                        className="text-center"
                        size="medium"
                        value={practiceInfo?.familyGroups ?? false}
                        onChange={(val: boolean) => {
                          handleOnchange(val, "familyGroups");
                        }}
                      />
                    </div>
                  )}

                  <div className="flex h-min w-full flex-col items-center justify-center py-2">
                    <div className="flex items-center font-semibold text-primary">Client Group</div>
                    <ATODropDown<KloudConnectPMSFieldsDisplay>
                      className={`h-8 w-[min(480px,80%)] px-2`}
                      value={practiceInfo?.userPmsReference ?? {}}
                      getOptions={() => filterPMSField(practiceInfo.userPmsReference?.fieldType ?? "UserPMSReference")}
                      getDisplay={(data) => data?.description ?? "N/A"}
                      onChange={(val) => {
                        handleOnchange(val, "userPmsReference");
                      }}
                    />
                  </div>
                  <div className="flex h-min w-full flex-col items-center justify-center py-2">
                    <div className="flex items-center font-semibold text-primary">DMS Client Identifier</div>
                    <ATODropDown<KloudConnectPMSFieldsDisplay>
                      className={`h-8 w-[min(480px,80%)] px-2`}
                      value={practiceInfo?.dmsPrimary ?? {}}
                      getOptions={() => filterPMSField(practiceInfo.userPmsReference?.fieldType ?? "dmsPrimary")}
                      getDisplay={(data) => data?.description ?? "N/A"}
                      onChange={(val) => {
                        handleOnchange(val, "dmsPrimary");
                      }}
                    />
                  </div>
                  {!practiceInfo?.isDms && (
                    <div className="flex h-min w-full flex-col items-center justify-center py-2">
                      <ATOButton buttonType={ButtonType.Confirm} onClick={handleSubmit} disabled={!isModified}>
                        Save
                      </ATOButton>
                    </div>
                  )}
                </div>
              </div>
            )}
            {practiceInfo.isDms && (
              <div className="flex h-full w-full justify-center overflow-hidden">
                <DMSDocSetup<IDMSDoc>
                  dmsui={DMSName.KloudConnect}
                  columnInfo={updatedColumnInfo}
                  isLoading={isLoading}
                  isActive={true}
                  setIsLoading={setIsLoading}
                  setModal={setModal}
                  pendingChanges={pendingChanges}
                  preSave={(docSave) => {
                    const itemToSave: IDMSDoc = pendingChanges.applyChanges("-1", {
                      DocID: "",
                      Level1ID: "",
                      Level2ID: "",
                      Level3ID: "",
                      Level4ID: "",
                      primaryMetadataName: practiceInfo?.primaryMetadataName ?? "",
                      secondaryMetadataName: practiceInfo?.secondaryMetadataName ?? "",
                      siteName: practiceInfo?.siteName ?? "",
                      unknownClientId: practiceInfo?.unknownClientId ?? "",
                    });

                    setPracticeInfo((prevState) => ({
                      ...prevState,
                      primaryMetadataName: itemToSave.primaryMetadataName,
                      secondaryMetadataName: itemToSave.secondaryMetadataName,
                      unknownClientId: itemToSave.unknownClientId,
                      siteName: itemToSave.siteName,
                    }));

                    apiClient?.kloudConnectActions
                      .updateKloudConnectPracticeInfo({ practiceInfo: practiceInfo })
                      .then(() => {
                        docSave();
                        pendingChanges.removeAllChanges();
                      })
                      .catch((ex) =>
                        setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />)
                      );
                  }}
                  getDefaultItem={(docOptions) => {
                    return {
                      DocID: "1",
                      Level1ID: "",
                      Level2ID: "",
                      Level3ID: "",
                      Level4ID: "",
                    };
                  }}
                  content={renderer}
                >
                  {(types, options) => {
                    return (
                      <>
                        <div className="my-6 grid w-full grid-cols-[1fr,1fr,1fr,1fr] gap-4">
                          <div className="flex h-min w-full flex-col items-center justify-center p-2">
                            <div className="flex items-center font-semibold text-primary">Primary Metadata Name</div>
                            <ATODropDown<string>
                              className={`h-8 w-[min(480px,70%)]`}
                              value={practiceInfo?.primaryMetadataName ?? ""}
                              getOptions={() => {
                                return metadataOptions.filter(
                                  (option) => option.toString() !== practiceInfo?.secondaryMetadataName
                                );
                              }}
                              getDisplay={(data) => data.toString()}
                              onChange={(val) => {
                                handleOnchange(val, "primaryMetadataName");
                              }}
                            />
                          </div>
                          <div className="flex h-min w-full flex-col items-center justify-center p-2">
                            <div className="flex items-center font-semibold text-primary">Secondary Metadata Name</div>
                            <ATODropDown<string>
                              className={`h-8 w-[min(480px,70%)]`}
                              value={practiceInfo?.secondaryMetadataName ?? ""}
                              getOptions={() => {
                                return metadataOptions.filter(
                                  (option) => option.toString() !== practiceInfo?.primaryMetadataName
                                );
                              }}
                              getDisplay={(data) => data.toString()}
                              onChange={(val) => {
                                handleOnchange(val, "secondaryMetadataName");
                              }}
                            />
                          </div>
                          <div className="flex h-min w-full flex-col items-center justify-center p-2">
                            <div className="flex items-center font-semibold text-primary">Sites</div>
                            <ATODropDown<string>
                              className={`h-8 w-[min(480px,70%)]`}
                              value={practiceInfo?.siteName ?? ""}
                              getOptions={() => siteOptions}
                              getDisplay={(data) => data.toString()}
                              onChange={(val) => {
                                handleOnchange(val, "siteName");
                              }}
                            />
                          </div>
                          <div className="flex h-min w-full flex-col items-center justify-center p-2">
                            <div className="flex items-center font-semibold text-primary">Unknown Client</div>
                            <div className="flex w-full items-center justify-center gap-2">
                              <ATOTextBox
                                className="w-[min(480px,70%)]"
                                value={practiceInfo?.unknownClientId ?? ""}
                                onChange={(val) => handleOnchange(val, "unknownClientId")}
                              />
                              <ATOButton buttonType={ButtonType.Primary} onClick={openCreateUnknownClientModal}>
                                Create
                              </ATOButton>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }}
                </DMSDocSetup>
              </div>
            )}
          </>
        ) : (
          <div className="col-span-6 flex flex-col items-center">Connect to Kloud Connect</div>
        )}

        {modal}
      </div>
    </>
  );
};
