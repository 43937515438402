/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * None(0)
 * BGL(1)
 * Class(2)
 */
export enum SmsfType {
    None = 0,
    BGL = 1,
    Class = 2,
}
