import { useState } from "react";
import { Change } from "./PendingChangesHook";

export interface SinglePendingChanges<T> {
  getChangeCount: () => number;

  listChanges: () => Change<T>[];

  getChange: (propName: keyof T) => Change<T> | undefined;

  hasChange: (propName: keyof T) => boolean;

  applyChanges: (data: T) => T;

  setChange: (propName: keyof T, val: any) => void;

  replaceAllChanges: (changes: Change<T>[]) => void;

  removeAllChanges: () => void;
  removeChange: (propName: keyof T) => void;
}

export type SinglePendingChangesState<T extends {}> = [Change<T>[], React.Dispatch<React.SetStateAction<Change<T>[]>>];

export const useSinglePendingChangesWithState = <T extends {}>(state: SinglePendingChangesState<T>) => {
  const [pendingChanges, setPendingChanges] = state;

  const helper: SinglePendingChanges<T> = {
    getChangeCount: () => helper.listChanges().length,

    listChanges: () => pendingChanges,

    getChange: (propName: keyof T) => helper.listChanges().find((c) => c.prop === propName),

    hasChange: (propName: keyof T) => helper.getChange(propName) !== undefined,

    applyChanges: (data: T) => {
      const newData = Array.isArray(data) ? [...(data as any)] : { ...(data as any) };

      helper.listChanges().forEach((change) => {
        if (data) {
          newData[change.prop] = change.value;
        }
      });

      return newData as T;
    },

    setChange: (propName: keyof T, val: any) => {
      setPendingChanges([...pendingChanges.filter((pc) => pc.prop !== propName), { prop: propName, value: val }]);
    },

    removeAllChanges: () => {
      setPendingChanges([]);
    },

    replaceAllChanges: (changes: Change<T>[]) => {
      setPendingChanges(changes);
    },

    removeChange: (propName: keyof T) => {
      setPendingChanges([...pendingChanges.filter((pc) => pc.prop !== propName)]);
    },
  };

  return helper;
};

export const useSinglePendingChanges = <T extends {}>() => {
  return useSinglePendingChangesWithState(useState<Change<T>[]>([]));
};
