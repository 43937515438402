import { ATOModal } from "../../../Components/ATOModal";

export const ViewSignatureModal = (props: { signatureUrl: string; onClose: () => void }) => {
  return (
    <ATOModal onClose={props.onClose} title={`Signature`}>
      <div className="h-[450px] w-[900px] p-4">
        <iframe className="h-full w-full" src={props.signatureUrl}></iframe>
      </div>
    </ATOModal>
  );
};
