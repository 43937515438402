/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 * Approver(1)
 * Reviewer(2)
 * Escalation(3)
 * Postal(4)
 * Sender(5)
 * Supervisor(6)
 * FeeForService(7)
 * SenderOptional(8)
 * BCC(9)
 */
export enum ClientGroupUserType {
    Approver = 1,
    Reviewer = 2,
    Escalation = 3,
    Postal = 4,
    Sender = 5,
    Supervisor = 6,
    FeeForService = 7,
    SenderOptional = 8,
    BCC = 9,
}
