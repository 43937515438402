/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { ApiResponse_List_SharePointDriveInfo_ } from '../models/ApiResponse_List_SharePointDriveInfo_';
import type { ApiResponse_List_SharePointSiteInfo_ } from '../models/ApiResponse_List_SharePointSiteInfo_';
import type { ApiResponse_SharePointCustomerEnrolmentInfo_ } from '../models/ApiResponse_SharePointCustomerEnrolmentInfo_';
import type { ApiResponse_SharePointPracticeInfo_ } from '../models/ApiResponse_SharePointPracticeInfo_';
import type { SharePointDriveInfo } from '../models/SharePointDriveInfo';
import type { SharePointSiteInfo } from '../models/SharePointSiteInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SharePointEnrolmentActions {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param formData
     * @returns ApiResponse_SharePointCustomerEnrolmentInfo_ Success
     * @throws ApiError
     */
    public getSharePointCustomerEnrolmentInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_SharePointCustomerEnrolmentInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SharePointEnrolmentActions/GetSharePointCustomerEnrolmentInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_SharePointPracticeInfo_ Success
     * @throws ApiError
     */
    public getSharePointPracticeInfo(
        formData: any,
    ): CancelablePromise<ApiResponse_SharePointPracticeInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SharePointEnrolmentActions/GetSharePointPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public saveSharePointPracticeInfo(
        formData: {
            fileName?: string;
            baseFolder?: string;
            unknownClient?: string;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SharePointEnrolmentActions/SaveSharePointPracticeInfo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_List_SharePointDriveInfo_ Success
     * @throws ApiError
     */
    public getCustomerDrives(
        formData: {
            searchTerm?: string;
        },
    ): CancelablePromise<ApiResponse_List_SharePointDriveInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SharePointEnrolmentActions/GetCustomerDrives',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse_List_SharePointSiteInfo_ Success
     * @throws ApiError
     */
    public getCustomerSites(
        formData: {
            searchTerm?: string;
        },
    ): CancelablePromise<ApiResponse_List_SharePointSiteInfo_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SharePointEnrolmentActions/GetCustomerSites',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public setCustomerDrive(
        formData: {
            driveInfo?: SharePointDriveInfo;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SharePointEnrolmentActions/SetCustomerDrive',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public setCustomerSite(
        formData: {
            siteInfo?: SharePointSiteInfo;
        },
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SharePointEnrolmentActions/SetCustomerSite',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public sendCustomerEnrolment(
        formData: any,
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SharePointEnrolmentActions/SendCustomerEnrolment',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns ApiResponse Success
     * @throws ApiError
     */
    public disconnectSharePoint(
        formData: any,
    ): CancelablePromise<ApiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/SharePointEnrolmentActions/DisconnectSharePoint',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
