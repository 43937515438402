import { useSetRecoilState } from "recoil";
import { TypedObject } from "../../../Helpers/TypedObject";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { AMClient, PracticeDetail } from "../../../generated";
import { PracticeDetailScreen } from "../Detail/PracticeDetailScreen";
import { SetupComponent } from "./SetupComponent";
import { AppModalAtom, BlockerAtom } from "../../../App";
import { buildAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { useEffect, useState } from "react";
import { SinglePendingChanges, useSinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";
import { Config } from "../../../Models/Config";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { PracticeDetailDisplay } from "../Model/PracticeDetailDisplay";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOSuccessModal } from "../../../Components/ATOSuccessModal";
import { ATOLoading } from "../../../Components/ATOSpinner";

const PracticeCreationPracticeInfoValidationRules = {
  custId: (data) => !isNaN(data.custId as number),
  custName: (data) => (data.custName ?? "").length > 2,
} as {
  [index in keyof PracticeDetail]: (data: PracticeDetail) => boolean;
};

export const PracticeDetailComponent: SetupComponent<PracticeDetail> = {
  defaultData: async (getAmClient: (getUrl: (cfg: Config) => string) => AMClient, custId: number) => {
    const amClient = getAmClient((cfg) => cfg.adminUrl);

    const toolList = await amClient.practiceDetails.getToolList({});
    const moduleList = await amClient.practiceDetails.getModuleList({});

    const data = {
      toolList: toolList.data!,
      moduleList: moduleList.data!,
    } as PracticeDetailDisplay;

    return data;
  },
  validateData: (data) =>
    TypedObject.map(PracticeCreationPracticeInfoValidationRules, (key) =>
      PracticeCreationPracticeInfoValidationRules[key]!(data)
    ),
  saveData: async (
    getAmClient: (getUrl: (cfg: Config) => string) => AMClient,
    custId: number,
    data: PracticeDetail
  ) => {
    const amClient = getAmClient((cfg) => cfg.adminUrl);

    await amClient.practiceDetails.savePracticeDetails({ detail: data });
  },
  saveOrder: 1,
  Element: (props) => <PracticeDetailScreen {...props} isNewPractice={props.custId === 0} />,
};

export const PracticeDetailComponentWrapper = ({
  custId,
  practiceDetail,
  setPracticeDetail,
}: {
  custId: number;
  practiceDetail: PracticeDetail;
  setPracticeDetail: (val: PracticeDetail) => void;
}) => {
  const user = useATOAuth();
  const config = useConfig();
  const setIsBlocked = useSetRecoilState(BlockerAtom);
  const setModal = useSetRecoilState(AppModalAtom);

  const getAmClient = (getUrl: (cfg: Config) => string, custId?: number) => buildAMClient(getUrl(config), user, custId);

  const [defaultData, setDefaultData] = useState<PracticeDetailDisplay>();
  const [isLoading, setIsLoading] = useState(true);

  const loadData = async () => {
    if (typeof PracticeDetailComponent.defaultData === "function") {
      const func = PracticeDetailComponent.defaultData as (
        getAmClient: (getUrl: (cfg: Config) => string) => AMClient,
        custId: number
      ) => Promise<any>;

      try {
        const data = await func(getAmClient, custId);

        setDefaultData(data);
      } catch (ex) {
        throw ex;
      } finally {
        setIsLoading(false);
      }
    } else {
      setDefaultData(PracticeDetailComponent.defaultData);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const pendingChanges = useSinglePendingChanges<PracticeDetail>();

  const hasChanges = pendingChanges.listChanges().length > 0;

  const actualPracticeDetail: PracticeDetailDisplay = {
    ...(pendingChanges.applyChanges(practiceDetail ?? {}) ?? []),
    ...defaultData,
  };

  useEffect(() => {
    setIsBlocked(hasChanges);
  }, [hasChanges]);

  if (isLoading) {
    return <ATOLoading />;
  }

  return (
    <>
      <div className="flex h-full flex-grow flex-col">
        <PracticeDetailComponent.Element
          custId={custId}
          data={actualPracticeDetail}
          pendingChanges={pendingChanges}
          key={`PracticeDetailComponentWrapper-${custId}`}
        />
        <div className="flex h-16 w-full flex-shrink-0 items-center justify-end gap-2 bg-black px-2">
          <ATOButton
            buttonType={ButtonType.Warning}
            onClick={() => {
              pendingChanges.removeAllChanges();
            }}
          >
            Reset
          </ATOButton>
          <ATOButton
            buttonType={ButtonType.Confirm}
            disabled={!hasChanges}
            onClick={() => {
              setIsLoading(true);

              PracticeDetailComponent.saveData(getAmClient, custId, actualPracticeDetail)
                .then(() => setPracticeDetail(actualPracticeDetail))
                .then(() => pendingChanges.removeAllChanges())
                .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />))
                .finally(() => setIsLoading(false));
            }}
          >
            Save
          </ATOButton>
        </div>
      </div>
    </>
  );
};
