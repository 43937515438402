import React from "react";
import * as faSvg from '@fortawesome/free-solid-svg-icons';

//components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ATOModal } from "../ATOModal"
import { ATOButton, ButtonType } from "../ATOButton";
import { getModalColour, getModalIcon, ModalTypes } from "./ATOModalTypes";

interface Props {
    className?: string;
    title: string,
    message: string | React.ReactNode,
    buttonText?: string,
    type?: ModalTypes,

    removeCloseButton?: boolean,
    onClose?: (isClickOutside: boolean) => void,
    onButton: () => void
}

export const ATOModalSingle = (props: Props) => {
    return <ATOModal
        title={
            <div className="w-full h-full flex gap-2 items-center">
                <FontAwesomeIcon icon={getModalIcon(props.type ?? ModalTypes.info)} className={getModalColour(props.type ?? ModalTypes.info)} size="lg" />
                <p className="flex-grow">{props.title}</p>
            </div>}
        footerContent={
            <div className="w-full flex justify-end">
                <ATOButton className="h-6" buttonType={ButtonType.Primary} onClick={props.onButton}>{props.buttonText ?? "OK"}</ATOButton>
            </div>
        }
        removeCloseButton={props.removeCloseButton ?? true}
        onClose={props.onClose ?? (() => { })}
    >
        <div className={`${props.className} flex items-center justify-center p-3`}>
            {props.message}
        </div>
    </ATOModal >
}