export const ATOValueHeader = ({
  children,
  className,
  hasChange,
  hasError,
}: {
  className?: string;
  children: React.ReactNode;
  hasChange?: boolean;
  hasError?: boolean;
}) => (
  <div
    className={`mb-1 ml-1 w-fit border-b border-primary ${className} 
      ${hasChange ? "border-yellow-400" : ""} 
      ${hasError ? "border-red-400" : ""}`}
  >
    {children}
  </div>
);
