import { useState } from "react";
import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { ATODropDown } from "../../../../Components/ATODropDown";
import { ATOModal } from "../../../../Components/ATOModal";
import { ATOSpinner } from "../../../../Components/ATOSpinner";
import { ATODefaultAPIErrorModal } from "../../../../Components/Modal/ATODefaultAPIErrorModal";
import { KloudConnectActions, UnknownClientOptions } from "../../../../generated";

export const CreateUnknownClientModal = ({
  setModal,
  apiClient,
  options,
}: {
  setModal: (modal: React.ReactNode) => void;
  apiClient: KloudConnectActions;
  options?: UnknownClientOptions;
}) => {
  const [selectedDivisionId, setSelectedDivisionId] = useState<number | null>(null);
  const [selectedPartnerId, setSelectedPartnerId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalDivisionValue, setModalDivisionValue] = useState<string | null>(null);
  const [modalPartnerValue, setModalPartnerValue] = useState<string | null>(null);

  const findKeyByValue = (obj: Record<string, string>, value: string) => {
    return Object.keys(obj).find((key) => obj[key] === value) || "";
  };

  const handleCreateUnknownClient = async () => {
    setIsLoading(true);

    apiClient
      .createKloudConnectUnknownClient({
        divisionId: selectedDivisionId ?? undefined,
        partnerId: selectedPartnerId ?? undefined,
      })
      .catch((err) => setModal(<ATODefaultAPIErrorModal error={err} onButton={() => setModal(undefined)} />))
      .finally(() => {
        setIsLoading(false);
        setModal(undefined);
      });
  };

  return (
    <ATOModal
      className="h-[min(80vh,400px)] w-[min(60vw,600px)]"
      title={"Create new Unknown Client?"}
      onClose={() => setModal(undefined)}
      footerContent={
        <div className="flex justify-around">
          <ATOButton
            buttonType={ButtonType.Primary}
            className="items-center"
            onClick={handleCreateUnknownClient}
            disabled={isLoading || !selectedDivisionId || !selectedPartnerId}
          >
            Create
          </ATOButton>
          <ATOButton buttonType={ButtonType.Error} onClick={() => setModal(undefined)}>
            Cancel
          </ATOButton>
        </div>
      }
    >
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center">
          {isLoading ? (
            <div className="flex h-full w-full items-center justify-center">
              <ATOSpinner />
            </div>
          ) : (
            <>
              <div className="h-full w-1/2">
                <p className="mt-3 w-full text-start">Division</p>
                <ATODropDown<string>
                  className="h-full w-full"
                  getOptions={() => Object.values(options?.divisions || {})}
                  getDisplay={(val) => val}
                  onChange={(val) => {
                    const key = findKeyByValue(options?.divisions ?? {}, val);
                    setModalDivisionValue(val);
                    setSelectedDivisionId(key ? Number(key) : null);
                  }}
                  value={modalDivisionValue ?? ""}
                />
                <p className="mt-3 w-full text-start">Partner</p>
                <ATODropDown<string>
                  className="h-full w-full"
                  getOptions={() => Object.values(options?.partners || {})}
                  getDisplay={(val) => val}
                  onChange={(val) => {
                    const key = findKeyByValue(options?.partners ?? {}, val);
                    setModalPartnerValue(val);
                    setSelectedPartnerId(key || null);
                  }}
                  value={modalPartnerValue ?? ""}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </ATOModal>
  );
};
