import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { AMClient, TemplateVariant } from "../../generated";
import { useState } from "react";
import { ATOSwitch } from "../../Components/ATOSwitch";
import { ATODefaultAPIErrorModal } from "../../Components/Modal/ATODefaultAPIErrorModal";
import { useSetRecoilState } from "recoil";
import { AppModalAtom } from "../../App";
import { TemplateListingDisplay } from "./TemplateListing";
import { AppSearchProvider } from "../../Hooks/AppSearchHook";
import { ATOModal } from "../../Components/ATOModal";
import { ATOButton, ButtonType } from "../../Components/ATOButton";
import { Link } from "react-router-dom";

export interface Props {
  DocumentTypeName?: string;
  AppSearchTemplates?: AppSearchProvider<TemplateListingDisplay>;
  ApiClient: AMClient | undefined;
}

export const TemplateListingRow = ({ DocumentTypeName, AppSearchTemplates, ApiClient }: Props) => {
  const [opened, setOpen] = useState<boolean>(false);
  const setModal = useSetRecoilState(AppModalAtom);

  const docCondTemplates = AppSearchTemplates?.result.data.filter((x) => x.doc_name == DocumentTypeName);
  if (docCondTemplates?.length == 0) {
    return <></>;
  }

  const docType = docCondTemplates?.at(0);

  const toggleDocTypeActiveFlag = (docId: number | undefined) => {
    ApiClient?.template
      .toggleDocTypeActiveStatus({
        docId: docId,
      })
      .then((resp) => {
        AppSearchTemplates?.refreshData();
      })
      .catch((ex) =>
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
            }}
          />
        )
      );
  };

  const toggleDocCondActiveFlag = (docCondId: number | undefined) => {
    ApiClient?.template
      .toggleDocConditionActiveStatus({
        docCondId: docCondId,
      })
      .then((resp) => {
        AppSearchTemplates?.refreshData();
      })
      .catch((ex) =>
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
            }}
          />
        )
      );
  };

  const previewTemplate = (docCondId: number | undefined) => {
    ApiClient?.template
      .getTemplate({
        docCondId: docCondId,
        draft: false,
        variant: TemplateVariant.Email,
      })
      .then((resp) => {
        const body = (
          <div className="flex w-full flex-col p-2">
            <div className="flex">
              <div className="mr-0 flex flex-col">
                <span className="text-xl font-semibold">EMAIL PREVIEW: {resp.data?.docConditionName}</span>
                <span className=" ml-1 mt-1 flex h-6 w-min rounded-full bg-blue-500 p-[2px] px-4 align-bottom text-sm text-white">
                  Published
                </span>
              </div>
              <div className="ml-auto">
                <Link to={`/Templates/${resp.data?.docCondID}/`} className="">
                  <ATOButton className="h-8" onClick={() => setModal(undefined)} buttonType={ButtonType.Primary}>
                    Edit
                  </ATOButton>
                </Link>
              </div>
            </div>
            <div className="mt-2 grid grid-cols-[3fr_1fr] gap-2">
              <div className="p-2 shadow-md">
                <div className="text-lg">{resp.data?.subject}</div>
                <iframe className="aspect-video w-full" srcDoc={resp.data?.body + ""}></iframe>
              </div>
              <div className="flex flex-col gap-2 p-2 shadow-md">
                <div>Template Version</div>
                <div>
                  <ATOSwitch
                    className="text-center"
                    enableDisplay={`Email`}
                    disableDisplay={`Portal`}
                    size="xl"
                    value={docType?.is_doc_active == "true"}
                    onChange={() => toggleDocTypeActiveFlag(docType?.doc_id)}
                  />
                </div>
                <div className="pt-4">Send Test Email</div>
                <div>
                  <input className="shadow-sm" />
                </div>
                <div>
                  <ATOButton className="h-8 w-full" buttonType={ButtonType.Primary}>
                    Send
                  </ATOButton>
                </div>
              </div>
            </div>
          </div>
        );
        const modal = (
          <ATOModal
            key={1}
            children={body}
            onClose={() => {
              setModal(undefined);
            }}
            title=""
            IsBackgroundTransparent={false}
            className="w-[800px]"
          ></ATOModal>
        );
        setModal(modal);
      })
      .catch((ex) =>
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
            }}
          />
        )
      );
  };

  return (
    <div
      className={`grid w-full cursor-pointer auto-rows-min grid-cols-1 overflow-clip rounded-xl transition-all ${opened ? "max-h-[600px] border-black" : "max-h-12"} select-none border-2 hover:border-black`}
    >
      <div
        className={`grid h-12 grid-cols-[50px_1fr_50px] place-items-center transition-all hover:bg-black hover:text-white
                      ${opened ? "bg-black text-white" : "bg-gray-200 text-black"}
        `}
        onClick={() => {
          setOpen(!opened);
        }}
      >
        <FontAwesomeIcon icon={opened ? faSvg.faMinus : faSvg.faPlus} className="text-lg" />
        <p className="text-lg font-semibold">{DocumentTypeName}</p>
        <FontAwesomeIcon
          icon={faSvg.faAngleRight}
          className={`text-lg transition-transform ${opened ? "rotate-90" : ""}`}
        />
      </div>
      <div className="">
        <div className="flex h-12 border-b-2 border-dotted border-black py-2">
          <div className="flex h-full  px-3 text-lg leading-loose">Disable '{DocumentTypeName}'</div>
          <ATOSwitch
            className="text-center"
            enableDisplay={`Active`}
            disableDisplay={`Disabled`}
            size="xl"
            value={docType?.is_doc_active == "true"}
            onChange={() => toggleDocTypeActiveFlag(docType?.doc_id)}
          />
        </div>
        <div className="max-h-[500px] overflow-y-auto">
          {docCondTemplates?.map((docTemplate, i) => {
            const enabled = docTemplate.is_doc_cond_active == "true";
            return (
              <div key={docTemplate.doc_cond_id} className="grid grid-cols-3  py-1 odd:bg-gray-100">
                <div className={`flex pl-10 text-lg font-semibold leading-loose text-black`}>
                  {docTemplate.doc_cond_name}
                  {docTemplate.is_prn === "true" && (
                    <span className="ml-1 mt-1 h-6 rounded-full bg-black p-[2px] px-2 align-bottom text-sm text-white">
                      PRN
                    </span>
                  )}
                </div>
                <div className="text-center">
                  <ATOSwitch
                    className="justify-center"
                    enableDisplay={`Active`}
                    disableDisplay={`Disabled`}
                    size={{ minWidth: "96px", height: "38px" }}
                    value={enabled}
                    onChange={() => toggleDocCondActiveFlag(docTemplate.doc_cond_id)}
                  />
                </div>
                <div className="flex place-content-end pr-5">
                  <button
                    disabled={!enabled}
                    className={`h-10 cursor-pointer rounded-sm border-2 border-solid border-blue-600 px-8
                   leading-loose  hover:bg-blue-600 hover:font-semibold hover:text-white ${!enabled ? "pointer-events-none grayscale" : ""}`}
                    onClick={() => previewTemplate(docTemplate.doc_cond_id)}
                  >
                    View
                  </button>
                  <Link to={`/Templates/${docTemplate.doc_cond_id}/`} className="">
                    <button
                      disabled={!enabled}
                      className={`ml-3 h-10 cursor-pointer rounded-sm border-2 border-solid border-blue-600 px-8
                   leading-loose  hover:bg-blue-600 hover:font-semibold hover:text-white ${!enabled ? "pointer-events-none grayscale" : ""}`}
                      onClick={() => {}}
                    >
                      Edit
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
