import React from "react";
import * as faSvg from '@fortawesome/free-solid-svg-icons';

//components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ATOModal } from "../ATOModal"
import { ATOButton } from "../ATOButton";
import { getModalColour, getModalIcon, ModalTypes } from "./ATOModalTypes";

interface Props {
    className?: string,
    title: string,
    message: string | React.ReactNode,
    leftButton?: string,
    rightButton?: string,
    type?: ModalTypes,

    onClose: (isClickOutside: boolean) => void,

    onLeftButton: () => void,
    onRightButton: () => void,
}

export const ATOModalDouble = (props: Props) => {
    return <ATOModal 
        title={
        <div className="w-full h-full flex gap-2 items-center">
            <FontAwesomeIcon icon={getModalIcon(props.type ?? ModalTypes.info)} className={getModalColour(props.type ?? ModalTypes.info)} size="lg" />
            <p className="flex-grow">{props.title}</p>
        </div>}
        footerContent={
            <div className="w-full flex justify-end gap-2">
                <ATOButton className="h-6" onClick={props.onLeftButton}>{props.leftButton ?? "Cancel"}</ATOButton>
                <ATOButton className="h-6" onClick={props.onRightButton}>{props.rightButton ?? "OK"}</ATOButton>
            </div>
        }
        removeCloseButton
        onClose={props.onClose}
    >
        <div className={`flex items-center justify-center ${props.className}`}>
            {props.message}
        </div>
    </ATOModal>
}