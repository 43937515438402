import { useEffect, useState } from "react";
import { ELearningDetails } from "./ELearningDetails";
import { ATOLoading } from "../../../Components/ATOSpinner";
import { ApiResponseStatus, CategoryModuleViewModel } from "../../../generated";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { useAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { useSetRecoilState } from "recoil";
import { AppModalAtom, BlockerAtom } from "../../../App";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { ATOSuccessModal } from "../../../Components/ATOSuccessModal";
import { EditELearningCategory } from "./EditELearningCategory";

export const ELearning = () => {
  const config = useConfig();
  const user = useATOAuth();
  const amClient = useAMClient(config.eLearningUrl, user);
  const setModal = useSetRecoilState(AppModalAtom);
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [initialCategories, setInitialCategories] = useState<CategoryModuleViewModel[]>([]);
  const [actualCategories, setActualCategories] = useState<CategoryModuleViewModel[]>([]);
  const [seletedCategory, setSelectedCategory] = useState<CategoryModuleViewModel | null>(null);
  const [indexCategory, setIndexCategory] = useState<number | null>(null);
  const [updatedCategory, setUpdatedCategory] = useState<CategoryModuleViewModel | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  useEffect(() => {
    if (updatedCategory !== null) {
      const updatedCategories = actualCategories ? [...actualCategories] : [];
      if (indexCategory !== null) {
        updatedCategories.splice(indexCategory, 1, updatedCategory);
        setSelectedCategory(null);
        setIndexCategory(null);
      } else {
        updatedCategories.splice(0, 0, updatedCategory);
      }
      setActualCategories(updatedCategories);
    }
  }, [updatedCategory]);

  useEffect(() => {
    if (!user.isLoading) {
      setIsApiLoading(true);
      amClient.eLearningHandler
        .getAllModules({})
        .then((result) => {
          setActualCategories(result.data ?? []);
          setInitialCategories(result.data ?? []);
          if (result.status !== ApiResponseStatus.Success) {
            throw result.message;
          }
        })
        .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />))
        .finally(() => setIsApiLoading(false));
    }
  }, []);

  const clearChanges = () => {
    setIsClearing(true);
    setActualCategories(initialCategories);
    setTimeout(() => setIsClearing(false), 1);
  };

  const onSaveCategories = (categories: CategoryModuleViewModel[]) => {
    setIsSaving(true);

    amClient.eLearningHandler
      .saveAllModules({ categories })
      .then((result) => {
        setActualCategories(result.data ?? []);
        setInitialCategories(result.data ?? []);
        setModal(<ATOSuccessModal setModal={setModal} />);
        if (result.status !== ApiResponseStatus.Success) {
          throw result.message;
        }
      })
      .catch((ex) =>
        setModal(
          <ATODefaultAPIErrorModal
            error={{ message: ex.message }}
            onButton={() => {
              setModal(undefined);
            }}
          />
        )
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onEditCategory = () =>
    seletedCategory &&
    setModal(<EditELearningCategory category={seletedCategory} setUpdatedCategory={setUpdatedCategory} />);
  const onAddCategory = () =>
    setModal(<EditELearningCategory category={null} setUpdatedCategory={setUpdatedCategory} />);
  const onDeleteModule = () => {
    if (seletedCategory !== null) {
      const updatedCategories = [...actualCategories!];
      const index = updatedCategories.findIndex((c) => c.categoryId === seletedCategory.categoryId);
      updatedCategories.splice(index, 1);
      setActualCategories(updatedCategories);
      setSelectedCategory(null);
    }
  };

  if (user.isLoading || isApiLoading || isClearing || isSaving) {
    return <ATOLoading />;
  }

  return (
    <div className="flex h-full w-full place-content-center">
      {actualCategories.length > 0 ? (
        <>
          <div className="w-[min(20%,200px)] p-4">
            <ATOButton className="h-8 w-full" buttonType={ButtonType.Primary} onClick={() => onAddCategory()}>
              <FontAwesomeIcon icon={faSvg.faAdd} className={`mx-1 px-1 pt-1 text-lg text-white`} />
              Add Category
            </ATOButton>
            <ATOButton className="mt-4 h-8 w-full" buttonType={ButtonType.Primary} onClick={() => onDeleteModule()}>
              <FontAwesomeIcon icon={faSvg.faEdit} className={`mx-1 px-1 pt-1 text-lg text-white`} />
              Delete
            </ATOButton>
            <ATOButton className="mt-4 h-8 w-full" buttonType={ButtonType.Primary} onClick={() => onEditCategory()}>
              <FontAwesomeIcon icon={faSvg.faDeleteLeft} className={`mx-1 px-1 pt-1 text-lg text-white`} />
              Edit
            </ATOButton>
            <ATOButton className="mt-4 h-8 w-full" buttonType={ButtonType.Error} onClick={clearChanges}>
              <FontAwesomeIcon icon={faSvg.faUndo} className={`mx-1 px-1 pt-1 text-lg text-white`} />
              Reset
            </ATOButton>
            <ATOButton
              className="mt-4 h-8 w-full"
              buttonType={ButtonType.Confirm}
              onClick={() => onSaveCategories(actualCategories)}
            >
              <FontAwesomeIcon icon={faSvg.faSave} className={`mx-1 px-1 pt-1 text-lg text-white`} />
              Save
            </ATOButton>
          </div>
          <div className="h-[80%] w-[min(80%,1000px)] p-4">
            <ELearningDetails //pendingChanges={pendingChanges}
              categories={actualCategories}
              setCurrentCategory={setSelectedCategory}
              setIndexCategory={setIndexCategory}
            />
          </div>
        </>
      ) : (
        <div className="animate-shakeX text-center text-red-500">ELearning have no category to learn.</div>
      )}
    </div>
  );
};
